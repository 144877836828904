var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("option-field", {
    attrs: {
      "has-transparent-bg": "",
      "display-name": _vm.displayName,
      options: _vm.supportedLanguages,
      layout: "condensed",
      "data-qa": "footer-translation-dropdown",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "label",
          fn: function () {
            return [_vm._t("default")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selectedLanguage,
      callback: function ($$v) {
        _vm.selectedLanguage = $$v
      },
      expression: "selectedLanguage",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }