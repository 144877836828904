import ServiceBase from './ServiceBase';

export default class SystemStatusService extends ServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    getGlobalApplicationStatistics() {
        return this.request('get', 'statistics/getGlobalApplicationStatistics');
    }
}