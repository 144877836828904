import { analyticsManager } from './AnalyticsManager';
import gamefoundPlugin from './GamefoundPlugin';
import initialState from '@/InitialState';
const plugins = [];

if (initialState.internalAnalyticsTrackingEnabled) {
    plugins.push(gamefoundPlugin({}));
}

const analytics = analyticsManager({
    plugins,
});

export default analytics;
