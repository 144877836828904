import ServiceBase from './ServiceBase';

export default class CartsLegacyService extends ServiceBase {
    getCartDetails(model) {
        return this.request('get', 'legacy/carts/getCartDetails', { params: model });
    }

    setOrderItemOption(orderItemID, optionValueID, projectID) {
        return this.request('post', 'legacy/carts/setOrderItemOption', {
            orderItemID,
            optionValueID,
            projectID,
        });
    }

    setOrderItemQuantity(orderItemID, quantity, projectID) {
        const model = {
            orderItemID,
            quantity,
            projectID,
        };

        // TODO: this should be two separate methods, but because on legacy V1 UI
        // we allow deleting items by setting 0 quantity.
        // Separating this would actually reduce readability.
        if (quantity === 0) {
            return this.request('post', 'carts/removeOrderItem', model);
        }

        return this.request('post', 'legacy/carts/setOrderItemQuantity', model);
    }

    splitOrderItem(orderItem, extractedQuantity) {
        const params = {
            orderItemID: orderItem.orderItemID,
            extractedQuantity,
        };

        return this.request('post', 'legacy/carts/splitOrderItem', params);
    }

    reverseSplitOrderItem(orderItemID) {
        return this.request('post', 'legacy/carts/reverseSplitOrderItem', { orderItemID });
    }
}
