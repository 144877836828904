var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasOrderDetails
        ? _c("base-summary-list", {
            staticClass: "_mt-5",
            attrs: { "summary-list-data": _vm.summaryListModel },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "gfu-heading gfu-heading--top-divider _mt-3 _mb-2",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.Resources.YourPledge.OrderSummaryHeader)
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2042349886
            ),
          })
        : _c("skeleton-base-summary-list", { attrs: { "items-count": 5 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }