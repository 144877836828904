import Formatting from './Formatting';

const Formatter = {
    forceUTCDates: true,

    format(value, format = null, cultureInfo = null) {
        if (typeof value === 'undefined' || value === null)
            return value;

        if (!cultureInfo) {
            cultureInfo = Formatting.CultureInfo.currentCulture;
        }
        else if (typeof cultureInfo.valueOf() === 'string') { // if culture info is a string, the 'overload' is to use it as a currency symbol
            cultureInfo = Formatting.CultureInfo.forCurrency(cultureInfo);
        }

        if (value instanceof Date) {
            if (!format) {
                format = 'g';
            }

            if (Formatter.forceUTCDates) {
                value = Formatter.dateAsLocal(value);
            }

            return Formatting.Date.toFormattedString(value, format, cultureInfo);
        }

        if (typeof value === 'number') {
            return Formatting.Number.toFormattedString(value, format, cultureInfo);
        }

        return `${ value }`;
    },

    dateAsUTC(date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    },

    dateAsLocal(date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    },

    formatString() {
        return Formatting.String.format(...arguments);
    },
};

if (window.Intl) {
    Formatter.locale = Intl.DateTimeFormat().resolvedOptions().locale;
}

export default Formatter;
