var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "product-card-list-provider",
    _vm._b(
      {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({
              productList,
              onItemAdded,
              getAddProductToCartModel,
            }) {
              return [
                _c(
                  "ul",
                  { class: _vm.productCardListClasses },
                  _vm._l(productList, function (product) {
                    return _c(
                      "li",
                      {
                        key: product.productId,
                        staticClass:
                          "gfu-grid__cell gfu-1of1 gfu-1of2--m gfu-1of3--l",
                      },
                      [
                        _c(
                          "product-card",
                          _vm._b(
                            {
                              attrs: {
                                context: "modal",
                                "show-abstract": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "action",
                                    fn: function () {
                                      return [
                                        _c(
                                          "add-product-to-cart",
                                          _vm._b(
                                            {
                                              attrs: {
                                                "text-align": "center",
                                                "button-text":
                                                  _vm.Resources.Product
                                                    .ProductCardAddToOrderButton,
                                              },
                                              on: { "item-added": onItemAdded },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "buttonContents",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "_flexbtwn",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gfu-btn__text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .Resources
                                                                      .Product
                                                                      .ProductCardAddToOrderButton
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("base-icon", {
                                                              staticClass:
                                                                "_ml-1",
                                                              attrs: {
                                                                "icon-namespace":
                                                                  "ga",
                                                                name: "plus-bold-icon",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            "add-product-to-cart",
                                            getAddProductToCartModel(product),
                                            false
                                          )
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            "product-card",
                            product,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      },
      "product-card-list-provider",
      { ..._vm.$props },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }