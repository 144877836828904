
    export default {
        props: {
            items: {
                type: Array,
                default: () => [],
            },
            sliderPosition: {
                type: Number,
                required: true,
            },
        },
        computed: {
            itemsWithActions() {
                return this.checkItemType(p => p.projectActions.length);
            },
            itemsWithoutActions() {
                return this.checkItemType(p => !p.projectActions.length);
            },
        },
        methods: {
            checkItemType(callback) {
                return this.items.length ? this.items.filter(callback) : [];
            },
            buildNode(i) {
                return this.$scopedSlots.item({ item: i });
            },
        },
        render(h) {
            const firstList = this.itemsWithActions.map(this.buildNode);
            const secondList = this.itemsWithoutActions.map(this.buildNode);
            let nodes = [this.$slots.default];

            if (firstList.length) {
                nodes = [firstList, this.$slots.default, secondList];
            }
            else if (secondList.length >= this.sliderPosition) {
                nodes = [secondList.slice(0, this.sliderPosition), this.$slots.default, secondList.slice(this.sliderPosition)];
            }
            else if (secondList.length < this.sliderPosition) {
                nodes = [secondList, this.$slots.default];
            }

            return h('div', nodes);
        },
    };

