import { defineStore } from 'pinia';
import Config from '@/Config';

export const usePageSectionsStore = defineStore({
    id: 'pageSections',
    state: () => ({
        pageSections: [],
    }),
    actions: {
        addPageSection({ section, parentSection }) {
            if (parentSection) {
                parentSection.sections.push(section);
            }
            else {
                const updatedSections = [...this.pageSections];
                updatedSections.push(section);
                this.pageSections = updatedSections;
            }
        },
    },
    getters: {
        rewardSectionUrl: state => {
            if (state.pageSections.some(i => i.urlName === 'rewards')) {
                return '#/section/rewards';
            }

            return Config.projectNavigation.backProjectUrl;
        },
    },
});