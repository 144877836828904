var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isAuthenticated
        ? _c(
            "button",
            {
              staticClass:
                "gfu-comment-reply gfu-btn gfu-btn--light-outline gfu-btn--soft gfu-btn--tight gfu-btn--lowercase",
              attrs: { "data-qa": "project-comment-button:Reply" },
              on: {
                click: function ($event) {
                  return _vm.$emit("reply")
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.Resources.Comments.ReplyCommentAction) +
                  "\n    "
              ),
            ]
          )
        : _c(
            "tooltip",
            {
              attrs: {
                text: _vm.Resources.Comments.LogInToReplyTooltip,
                "data-qa": "project-comment-reply:Tooltip",
              },
            },
            [
              _c(
                "button",
                _vm._b(
                  {
                    staticClass:
                      "gfu-comment-reply gfu-btn gfu-btn--light-outline gfu-btn--soft gfu-btn--tight gfu-btn--lowercase",
                    attrs: { "data-qa": "project-comment-button:Reply" },
                  },
                  "button",
                  _vm.$attrs,
                  false
                ),
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.Resources.Comments.ReplyCommentAction) +
                      "\n        "
                  ),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }