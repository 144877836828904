import axios from 'axios';
import Config from '@/Config';
import { parseJSON } from '@/Utils/JsonUtility';

// Replace default parser to handle ISO dates
axios.defaults.transformResponse = [parseJSON];
class ApiClient {
    constructor() {
        const apiClient = axios.create({
            baseURL: Config.http.root,
        });

        // always parse error response to json
        apiClient.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                // handling of json response when Axios is expecting Blob
                if (error.response?.data instanceof Blob
                    && error.response?.data?.type === 'application/json') {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            error.response.data = JSON.parse(reader.result);
                            resolve(Promise.reject(error));
                        };

                        reader.onerror = () => {
                            reject(error);
                        };

                        reader.readAsText(error.response.data);
                    });
                }

                return Promise.reject(error);
            },
        );

        return apiClient;
    }
}

export const apiClient = new ApiClient();

export default ApiClient;