var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "touch",
    { on: { "swipe-left": _vm.onNext, "swipe-right": _vm.onPrev } },
    [
      _c(
        "div",
        { staticClass: "gfu-check-list-table" },
        [
          _c("div", { staticClass: "gfu-check-list-table__content" }, [
            _c(
              "div",
              { staticClass: "gfu-check-list-table-titles" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "gfu-check-list-table-titles__title gfu-check-list-table-titles__title--divider",
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.tableData.dividerName) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.tableData.columns, function (column, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "gfu-check-list-table-titles__title",
                        {
                          "gfu-check-list-table-titles__title--highlighted":
                            _vm.isHighlighted(index + 1),
                        },
                        {
                          "is-active":
                            _vm.activeIndex === index + 1 && _vm.isMobile,
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(column.title) +
                              "\n                        "
                          ),
                          _c(
                            "tooltip",
                            { attrs: { text: column.tooltipText } },
                            [_c("span", { staticClass: "_fa _fa--info" })]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gfu-check-list-table-rows" },
              _vm._l(_vm.tableData.rows, function (row, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "_flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-check-list-table-rows__description" },
                      [
                        _c("h4", { staticClass: "_my-0 _fw-eb" }, [
                          _vm._v(_vm._s(row.title)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "gfu-check-list-table-rows__paragraph",
                          },
                          [_vm._v(_vm._s(row.description))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(row.support, function (isSupported, name, index) {
                      return _c(
                        "div",
                        {
                          key: `is-supported:${index}`,
                          class: [
                            "gfu-check-list-table-rows__value",
                            {
                              "gfu-check-list-table-rows__value--highlighted":
                                _vm.isHighlighted(index + 1),
                            },
                            {
                              "is-active":
                                _vm.activeIndex === index + 1 && _vm.isMobile,
                            },
                          ],
                        },
                        [
                          isSupported
                            ? _c("span", {
                                staticClass: "_fa _tc--accent fa-2x _fa--check",
                              })
                            : _c("span", {
                                staticClass:
                                  "gfu-check-list-table-rows__icon gfu-check-list-table-rows__icon--light _fa fa-2x _fa--times",
                              }),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _vm.isMobile
            ? [
                _vm.isPrevItemAvailable
                  ? _c("base-slider-navigation", {
                      staticClass: "gfu-partners-slider-nav--prev",
                      attrs: { type: "prev", icon: "angle-left" },
                      on: { click: _vm.onPrev },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isNextItemAvailable
                  ? _c("base-slider-navigation", {
                      staticClass: "gfu-partners-slider-nav--next",
                      attrs: { type: "next", icon: "angle-right" },
                      on: { click: _vm.onNext },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "nav",
                  { staticClass: "gfu-base-slider-indicators" },
                  _vm._l(_vm.tableData.columns, function (item, index) {
                    return _c(
                      "a",
                      {
                        key: index,
                        class: [
                          "gfu-base-slider-indicators__indicator",
                          {
                            "is-active":
                              _vm.activeIndex === index + 1 && _vm.isMobile,
                          },
                        ],
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSetCurrentIndex(index + 1)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(index) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }