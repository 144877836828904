var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-bottom-bar" },
    [
      _vm.showMainExpandButton
        ? _c(
            "div",
            {
              staticClass:
                "gfu-project-mini-wizard-expandable__expand-button-container",
              on: { click: _vm.onToggleExpandClick },
            },
            [
              _c("button", {
                staticClass:
                  "gfu-project-mini-wizard-expandable__expand-button gfu-btn gfu-btn--circle _fa _fa--chevron-up",
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "gfu-slide-up" },
          on: {
            "before-enter": _vm.beforeEnter,
            "after-leave": _vm.afterLeave,
          },
        },
        [
          _vm.isExpanded
            ? _c(
                "div",
                {
                  staticClass:
                    "gfu-project-mini-wizard-expandable__content-wrapper",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-project-mini-wizard-expandable__expand-button-container",
                      on: { click: _vm.onToggleExpandClick },
                    },
                    [
                      _c("button", {
                        staticClass:
                          "gfu-project-mini-wizard-expandable__expand-button gfu-btn gfu-btn--circle _fa _fa--chevron-down",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "detect-overflow",
                    {
                      staticClass:
                        "gfu-project-mini-wizard-expandable__content",
                    },
                    [
                      _c("div", {
                        staticClass:
                          "gfu-project-mini-wizard-expandable__shadow",
                      }),
                      _vm._v(" "),
                      (_vm.isPledgePlaced && !_vm.hasSameItemsAsParent) ||
                      !_vm.isPledgeEmpty
                        ? _c("div", { staticClass: "_tac _pt-1 _mt-1" }, [
                            _c(
                              "a",
                              {
                                staticClass: "gfu-link gfu-link--accent",
                                attrs: {
                                  href: _vm.currentPledge.cartDetailsUrl,
                                  "data-qa":
                                    "mini-wizard-button:ManageYourPledge",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.cartDetailsLabel) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isEditingPledge && _vm.canEditPledge
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--accent _mt-1",
                              attrs: {
                                type: "button",
                                disabled: _vm.isProcessing,
                                "data-qa":
                                  "mini-wizard-expandable-summary:EditPledge",
                              },
                              on: { click: _vm.onEditPledge },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardEditPledgeButton
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "gfu-project-mini-wizard-expandable__pledge",
                        },
                        [
                          !_vm.allItemsRemoved
                            ? [
                                _c("mini-wizard-items-content", {
                                  attrs: {
                                    "currency-symbol": _vm.currencySymbol,
                                    "rewards-section": _vm.rewardsSection,
                                    "addons-section": _vm.addonsSection,
                                    "is-unmodified-pledge":
                                      _vm.isUnmodifiedPledge,
                                    "can-delete-items": _vm.canDeleteItems,
                                    "pledge-date": _vm.pledgeDate,
                                    "show-addons": !_vm.isPledgeEmpty,
                                    "show-delete-button": true,
                                    "delete-method": _vm.onDeleteItem,
                                    "show-rewards":
                                      !_vm.allChargeableItemsRemoved,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.rewardsSection
                                        ? {
                                            key: "chooseRewardsButton",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary",
                                                    attrs: {
                                                      href: "",
                                                      "data-qa":
                                                        "mini-wizard-expandable-button:ChooseReward",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.onItemsListButtonClick(
                                                          {
                                                            name: "page-section",
                                                            params: {
                                                              section:
                                                                _vm
                                                                  .rewardsSection
                                                                  .urlName,
                                                            },
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                                                    }),
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.Resources
                                                            .ProjectHome
                                                            .MiniWizardChooseRewardsButton
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                      _vm.addonsSection
                                        ? {
                                            key: "chooseAddonsButton",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary",
                                                    attrs: {
                                                      href: "",
                                                      "data-qa":
                                                        "mini-wizard-expandable-button:ChooseAddons",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.onItemsListButtonClick(
                                                          {
                                                            name: "page-section",
                                                            params: {
                                                              section:
                                                                _vm
                                                                  .addonsSection
                                                                  .urlName,
                                                            },
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                                                    }),
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.Resources
                                                            .ProjectHome
                                                            .MiniWizardChooseAddonsButton
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                      {
                                        key: "addMoreItemsButton",
                                        fn: function () {
                                          return [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary _mt-2",
                                                attrs: {
                                                  href: "",
                                                  "data-qa":
                                                    "mini-wizard-expandable-button:AddMoreItems",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.onItemsListButtonClick(
                                                      {
                                                        name: "page-section",
                                                        params: {
                                                          section:
                                                            _vm.rewardsSection
                                                              .urlName,
                                                        },
                                                      }
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                                                }),
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.Resources.ProjectHome
                                                        .MiniWizardAddMoreItemsButton
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.allItemsRemoved
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard-item gfu-btn gfu-btn--block gfu-btn--thin gfu-btn--light-outline _fo _ttr",
                                  attrs: {
                                    "data-qa": `mini-wizard-item:Empty`,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "gfu-btn__text _lhb" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardRemovedAllItemsText
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm.allChargeableItemsRemoved
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "gfu-project-mini-wizard-item gfu-btn gfu-btn--block gfu-btn--thin gfu-btn--light-outline _fo _ttr _mt-1",
                                  attrs: {
                                    "data-qa": `mini-wizard-item:Empty`,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "gfu-btn__text _lhb" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardRemovedPaidItemsText
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.promoItem
                            ? _c("project-promo-item", {
                                staticClass:
                                  "_mt-3 gfu-1of1 gfu-bt gfu-bt--caption",
                                attrs: {
                                  "data-qa":
                                    "mini-wizard-expandable-project-promo-item",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPledgeImplicitEditMode
                            ? _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary _mt-2",
                                    attrs: {
                                      href: "",
                                      "data-qa":
                                        "mini-wizard-expandable-button:AddMoreItems",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onItemsListButtonClick({
                                          name: "page-section",
                                          params: {
                                            section: _vm.rewardsSection.urlName,
                                          },
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "_fa _fa--plus gfu-btn__icon gfu-icon _fl",
                                    }),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardAddMoreItemsButton
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isUnfinishedOrder && _vm.isEditingPledge
                            ? _c("div", { staticClass: "_tac _pt-1 _mt-1" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "gfu-link gfu-link--accent",
                                    attrs: {
                                      href: "",
                                      "data-qa":
                                        "mini-wizard-expandable-button:RevertChanges",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onRevertChanges.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardRevertChangesButton
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("progress-overlay", {
                        attrs: { visible: _vm.isProcessing },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.isPledgeImplicitEditMode
        ? _c(
            "mini-wizard-expandable-implicit-edit-mode-content",
            _vm._b(
              {},
              "mini-wizard-expandable-implicit-edit-mode-content",
              _vm.$props,
              false
            )
          )
        : _c(
            "div",
            {
              staticClass: "gfu-project-bottom-bar__content",
              class: [
                {
                  "gfu-project-mini-wizard-expandable__summary gfu-project-mini-wizard-expandable__summary--compact":
                    _vm.isPledgePlaced,
                  "gfu-project-mini-wizard-expandable__summary--alt-background":
                    _vm.isEditingPledge,
                  "gfu-project-mini-wizard-expandable__summary--image":
                    _vm.isPledgePlaced && _vm.isUnmodifiedPledge,
                },
              ],
            },
            [
              _c("div", { staticClass: "gfu-project-mini-wizard-expandable" }, [
                _c(
                  "div",
                  {
                    staticClass: "_flexwrap _jc-sb _ai-c gfu-1of1 _ai-fs _mb-0",
                    attrs: { "data-qa": "mini-wizard-expandable-summary" },
                  },
                  [
                    _vm.isUnmodifiedPledge
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "gfu-bt--b1",
                              attrs: {
                                "data-qa":
                                  "mini-wizard-expandable-summary:Backer",
                              },
                            },
                            [_c("strong", [_vm._v(_vm._s(_vm.summaryLabel))])]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gfu-1of1 _flexbtwn _ai-c" },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "gfu-bt--caption _ttu" },
                                  [_vm._v(_vm._s(_vm.orderDetailsLabel))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-project-mini-wizard__price _tc--inverse",
                                    attrs: {
                                      "data-qa":
                                        "mini-wizard-expandable-summary:AlreadyPledged",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$format(
                                            _vm.pledge
                                              .totalDiscountedValueConverted,
                                            "C2",
                                            _vm.pledge.currencySymbol
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.hasTaxHandlingEnabled
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-project-mini-wizard__caption _tc--inverse",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.taxesSummaryLabel) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _vm.isAwaitingPaymentPledge
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "gfu-project-mini-wizard-expandable__cta gfu-btn gfu-btn--soft gfu-btn--narrow gfu-btn--light-transparent",
                                      attrs: {
                                        href: _vm.currentPledge.resumeOrderUrl,
                                        "data-qa":
                                          "mini-wizard-expandable-summary:ResumePayment",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .MiniWizardResumePaymentButton
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm.canEditPledge
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "gfu-btn gfu-btn--soft gfu-btn--transparent-inverse _px-5",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.isProcessing,
                                        "data-qa":
                                          "mini-wizard-expandable-summary:EditPledge",
                                      },
                                      on: { click: _vm.onEditPledge },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .MiniWizardEditPledgeShortButton
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm.isEditingPledge
                      ? [
                          _c(
                            "div",
                            {
                              staticClass:
                                "gfu-bt--caption gfu-1of1 _mb-1 _mt-1",
                              attrs: {
                                "data-qa":
                                  "mini-wizard-expandable-summary:Text",
                              },
                            },
                            [
                              _vm.pledge.parentOrder
                                ? _c("strong", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.miniWizardPledgeModifiedText
                                        ) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isPledgePlaced || _vm.isEditingPledge
                      ? _c(
                          "div",
                          { staticClass: "_flexcol" },
                          [
                            _c(
                              "span",
                              {
                                class: [
                                  "gfu-bt--caption _ttu",
                                  { "_tc--lighter": !_vm.isPledgePlaced },
                                ],
                              },
                              [
                                _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      text: _vm.Resources.Homepage
                                        .MiniWizardNoShippingTooltip,
                                    },
                                  },
                                  [_c("span", { staticClass: "_fa _fa--info" })]
                                ),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.yourPledgeLabel) +
                                    "\n                    "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("display-currency-price", {
                              attrs: {
                                "effective-price":
                                  _vm.pledge.totalDiscountedValueConverted,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "effective-price",
                                    fn: function ({ effectivePrice }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "gfu-project-mini-wizard__price",
                                              {
                                                "_tc--inverse":
                                                  _vm.isPledgePlaced,
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(effectivePrice) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.hasTaxHandlingEnabled
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gfu-project-mini-wizard__caption",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.taxesSummaryLabel
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3028303878
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canSubmit
                      ? [
                          !_vm.isUnmodifiedPledge
                            ? _c("go-to-checkout", {
                                attrs: {
                                  "continue-url": _vm.currentPledge.checkoutUrl,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ onInvoke }) {
                                        return [
                                          _c(
                                            "tooltip",
                                            {
                                              attrs: {
                                                enabled:
                                                  !_vm.canProceedToCheckout,
                                                text: _vm.currentPledge
                                                  .canProceedToCheckoutValidationResult
                                                  .message,
                                              },
                                            },
                                            [
                                              _c(
                                                "base-button",
                                                {
                                                  staticClass:
                                                    "gfu-project-mini-wizard-expandable__cta",
                                                  attrs: {
                                                    enabled:
                                                      _vm.canProceedToCheckout,
                                                    theme: "accent",
                                                    width: "narrow",
                                                    "data-qa":
                                                      "mini-wizard-expandable-button:Pledge",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return onInvoke.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(_vm.submitLabel) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  850518460
                                ),
                              })
                            : _vm._e(),
                        ]
                      : _vm.canCancel
                      ? _c(
                          "base-button",
                          {
                            staticClass:
                              "gfu-project-mini-wizard-expandable__cta",
                            attrs: {
                              disabled: !_vm.currentPledge.canCancelPledge,
                              theme: "accent",
                              width: "narrow",
                              "data-qa":
                                "mini-wizard-expandable-button:CancelPledge",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.onCancelPledge.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .MiniWizardCancelPledgeButton
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }