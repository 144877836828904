var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isCurrencySelectorVisible && _vm.currentSelectedCurrencyId
    ? _c(
        "form",
        {
          ref: "form",
          attrs: { action: _vm.actionUrl, method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "returnUrl" },
            domProps: { value: _vm.returnUrl },
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "projectCurrencyID" },
            domProps: { value: _vm.currentProjectCurrencyID },
          }),
          _vm._v(" "),
          _c("click-outside", { attrs: { call: _vm.onClickOutside } }, [
            _c(
              "div",
              { staticClass: "gfu-currency-selector" },
              [
                _c(
                  "div",
                  {
                    class: [
                      "gfu-currency-selector__select",
                      { "is-expanded": _vm.isDropdownExpanded },
                    ],
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "gfu-currency-selector__label",
                        attrs: {
                          "data-qa": "currency-selector:SelectedCurrency",
                        },
                        on: { click: _vm.onClickLabel },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.currentCurrency.shortName) +
                            "\n                    "
                        ),
                        _c("span", {
                          staticClass: "_fa _fa--chevron-down _tc--accent",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSelectedCurrencyId,
                            expression: "currentSelectedCurrencyId",
                          },
                        ],
                        attrs: { name: "currency", autocomplete: "off" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.currentSelectedCurrencyId = $event.target
                                .multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.onChange,
                          ],
                        },
                      },
                      [
                        _c(
                          "optgroup",
                          { attrs: { label: "Project currencies" } },
                          _vm._l(
                            _vm.checkoutCurrencies,
                            function (checkoutCurrency) {
                              return _c(
                                "option",
                                {
                                  key: checkoutCurrency.currencyID,
                                  domProps: {
                                    value: checkoutCurrency.currencyID,
                                    selected:
                                      _vm.currentCurrency.currencyID ===
                                      checkoutCurrency.currencyID,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(checkoutCurrency.shortName) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _vm.hasDisplayCurrencies
                          ? [
                              _c(
                                "optgroup",
                                { attrs: { label: "Display currencies" } },
                                _vm._l(
                                  _vm.displayCurrencies,
                                  function (displayCurrency) {
                                    return _c(
                                      "option",
                                      {
                                        key: displayCurrency.currencyID,
                                        domProps: {
                                          value: displayCurrency.currencyID,
                                          selected:
                                            _vm.currentCurrency.currencyID ===
                                            displayCurrency.currencyID,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(displayCurrency.shortName) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "gfu-fade" } }, [
                  _vm.isDropdownExpanded
                    ? _c(
                        "div",
                        {
                          staticClass: "gfu-currency-selector__dropdown",
                          attrs: {
                            "data-qa": "currency-selector:CurrenciesList",
                          },
                        },
                        [
                          _vm.hasDisplayCurrencies
                            ? _c(
                                "div",
                                {
                                  staticClass: "gfu-currency-selector-label",
                                  attrs: {
                                    "data-qa":
                                      "currency-selector:CheckoutCurrency",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Project currencies\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(
                            _vm.checkoutCurrencies,
                            function (checkoutCurrency) {
                              return _c(
                                "div",
                                {
                                  key: checkoutCurrency.currencyID,
                                  class: [
                                    "gfu-currency-selector-option",
                                    {
                                      "is-selected":
                                        _vm.currentCurrency.currencyID ===
                                        checkoutCurrency.currencyID,
                                    },
                                  ],
                                  attrs: {
                                    "data-qa": `currency-selector-project-currency:${checkoutCurrency.currencyID}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCurrentSelectedCurrencyID(
                                        checkoutCurrency.currencyID
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(checkoutCurrency.name) +
                                      " - " +
                                      _vm._s(checkoutCurrency.shortName) +
                                      " (" +
                                      _vm._s(checkoutCurrency.symbol) +
                                      ")\n                    "
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _vm.hasDisplayCurrencies
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "gfu-currency-selector-label",
                                    attrs: {
                                      "data-qa":
                                        "currency-selector:DisplayCurrencies",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Display currencies\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.displayCurrencies,
                                  function (displayCurrency) {
                                    return _c(
                                      "div",
                                      {
                                        key: displayCurrency.currencyID,
                                        class: [
                                          "gfu-currency-selector-option",
                                          {
                                            "is-selected":
                                              _vm.currentCurrency.currencyID ===
                                              displayCurrency.currencyID,
                                          },
                                        ],
                                        attrs: {
                                          "data-qa": `currency-selector-display-currency:${displayCurrency.currencyID}`,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setCurrentSelectedCurrencyID(
                                              displayCurrency.currencyID
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(displayCurrency.name) +
                                            " - " +
                                            _vm._s(displayCurrency.shortName) +
                                            " (" +
                                            _vm._s(displayCurrency.symbol) +
                                            ")\n                        "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("change-display-currency-modal", {
            attrs: {
              "is-visible": _vm.isCurrencyModalVisible,
              currency: _vm.selectedCurrency,
            },
            on: { accept: _vm.onAccept, cancel: _vm.onCancel },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }