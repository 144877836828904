// -------------------------------------------------------------------------------------
// String
// -------------------------------------------------------------------------------------

const String = {};

export default String;

String.endsWith = function endsWith(inputString, suffix) {
    return inputString.substr(inputString.length - suffix.length) === suffix;
};

String.startsWith = function startsWith(inputString, prefix) {
    return inputString.substr(0, prefix.length) === prefix;
};

String.trim = function trim(inputString) {
    return inputString.replace(/^\s+|\s+$/g, '');
};

String.trimEnd = function trimEnd(inputString) {
    return inputString.replace(/\s+$/, '');
};

String.trimStart = function trimStart(inputString) {
    return inputString.replace(/^\s+/, '');
};

String.format = function format(format, args) {
    return String.toFormattedString(false, arguments);
};

String.localeFormat = function localeFormat(format, args) {
    return String.toFormattedString(true, arguments);
};

String.toFormattedString = function toFormattedString(useLocale, args) {
    let result = '';
    const format = args[0];
    for (let i = 0; ;) {
        const open = format.indexOf('{', i);
        const close = format.indexOf('}', i);
        if (open < 0 && close < 0) {
            result += format.slice(i);
            break;
        }
        if (close > 0 && (close < open || open < 0)) {
            if (format.charAt(close + 1) !== '}') {
                throw new Error('Format string brace mismatch');
            }
            result += format.slice(i, close + 1);
            i = close + 2;
            continue;
        }
        result += format.slice(i, open);
        i = open + 1;
        if (format.charAt(i) === '{') {
            result += '{';
            i++;
            continue;
        }
        if (close < 0) throw new Error('Format string brace mismatch');
        const brace = format.substring(i, close);
        const colonIndex = brace.indexOf(':');
        const argNumber = parseInt(colonIndex < 0 ? brace : brace.substring(0, colonIndex), 10) + 1;
        if (isNaN(argNumber)) throw new Error('Format string invalid');
        const argFormat = colonIndex < 0 ? '' : brace.substring(colonIndex + 1);
        let arg = args[argNumber];
        if (typeof arg === 'undefined' || arg === null) {
            arg = '';
        }
        if (arg.toFormattedString) {
            result += arg.toFormattedString(argFormat);
        }
        else if (useLocale && arg.localeFormat) {
            result += arg.localeFormat(argFormat);
        }
        else if (arg.format) {
            result += arg.format(argFormat);
        }
        else
            result += arg.toString();
        i = close + 1;
    }
    return result;
};
