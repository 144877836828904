var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-project-description-section gfu-project-description-section--emphasized",
      attrs: {
        "data-qa": `project-description-section:${_vm.projectDescriptionSectionId}`,
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl",
        },
        [
          _c(
            "h2",
            {
              ref: "anchor",
              staticClass: "gfu-hd gfu-hd--h2 gfu-heading--top-divider _mb-2",
              attrs: {
                "data-qa": `project-description-section-title:${_vm.header}`,
              },
            },
            [_vm._v(_vm._s(_vm.header))]
          ),
          _vm._v(" "),
          _vm.descriptionInfo
            ? _c(
                "div",
                {
                  staticClass:
                    "gfu-project-description-section__description gfu-richtext gfu-richtext--article",
                  attrs: {
                    "data-qa": `project-description-section-title:${_vm.descriptionInfo.header}`,
                  },
                },
                [
                  _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mb-2" }, [
                    _vm._v(_vm._s(_vm.descriptionInfo.header)),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    attrs: { "data-qa": "stable-pledge-learn-more" },
                    domProps: {
                      innerHTML: _vm._s(_vm.descriptionInfo.content),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            ref: "content",
            staticClass: "_mt-2 gfu-richtext gfu-richtext--article",
            domProps: { innerHTML: _vm._s(_vm.contentUpdated) },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }