var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDccVisible
    ? _c(
        "div",
        [
          _c("dynamic-currency-conversion", {
            attrs: {
              "project-id": _vm.projectId,
              "order-id": _vm.orderId,
              "order-code": _vm.orderCode,
              reason: _vm.reason,
              "handle-tax-without-shipping": _vm.handleTaxWithoutShipping,
              "can-be-paid-in-installments": _vm.canBePaidInInstallments,
              "payment-currency-strategy": _vm.paymentCurrencyStrategy,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }