import { orderItemStatuses, orderItemPaymentStatuses, orderItemTypes } from '@/Models';
import Formatter from '@/Utils/Formatter';

function isLocked(item) {
    return item.orderItemStatus !== orderItemStatuses.active;
}

function isNewItem(item, createDate) {
    return createDate !== null
        && item.paymentDate === null
        && item.paymentStatus === orderItemPaymentStatuses.unpaid
        && item.createDate > createDate;
}

function canBeDeletedItem(item, canDeleteItems) {
    return canDeleteItems && item.canBeDeleted;
}

function formattedAmount(value, currencySymbol) {
    return Formatter.format(value, 'C2', currencySymbol);
}

function buildLockedReasonInformation(item) {
    if (isItemTypeTip(item)) {
        return 'You can\'t remove this item because it\'s a tip.';
    }
    else if (isItemTypeDiscount(item)) {
        return 'You can\'t remove this item because it\'s a discount.';
    }

    return `You can't remove this item because it's ${item.promoItemID ? 'a free gift' : 'paid'}.`;
}

function buildItemName(item) {
    if (isItemTypeDiscount(item)) {
        return item.orderItemTypeName;
    }

    return item.displayName;
}

export function isItemTypeTip(item) {
    return item.orderItemType === orderItemTypes.tip;
}

export function isItemTypeDiscount(item) {
    return item.orderItemType === orderItemTypes.discount;
}

export function buildItemProps(options) {
    const {
        item,
        createDate = null,
        canDeleteItems = false,
        showDeleteButton = false,
        currencySymbol,
    } = options;

    const hasValue = isItemTypeTip(item) || isItemTypeDiscount(item);

    return {
        orderItemID: item.orderItemID,
        lockedReason: buildLockedReasonInformation(item),
        name: buildItemName(item),
        canBeDeleted: canBeDeletedItem(item, canDeleteItems),
        isLocked: isLocked(item),
        isNew: isNewItem(item, createDate),
        showDeleteButton,
        formattedAmount: hasValue ? formattedAmount(item.netPriceConverted, currencySymbol) : `${item.quantity}x`,
    };
}