var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("floating-content", {
    attrs: {
      "is-processing": _vm.isProcessing,
      "is-content-visible": _vm.isContentVisible,
    },
    on: {
      "position-update": _vm.onPositionUpdate,
      close: _vm.onClose,
      mouseleave: _vm.onMouseLeave,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "trigger",
          fn: function () {
            return [
              _vm._t("trigger", null, null, {
                showBalloon: _vm.showBalloon,
                closeBalloon: _vm.closeBalloon,
              }),
            ]
          },
          proxy: true,
        },
        _vm.hasUrlName
          ? {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "click-outside",
                    { attrs: { call: _vm.onClickOutside } },
                    [
                      _c(
                        "base-balloon",
                        _vm._b(
                          {
                            class: [
                              "gfu-floating-balloon",
                              {
                                "gfu-floating-balloon--processing":
                                  _vm.isProcessing,
                              },
                            ],
                          },
                          "base-balloon",
                          _vm.balloonProps,
                          false
                        ),
                        [
                          _c(
                            "comment-user-profile-content",
                            _vm._b(
                              { on: { close: _vm.onClose } },
                              "comment-user-profile-content",
                              _vm.commentUserProfileContentProps,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }