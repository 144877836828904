import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
class RealTimeServiceInstance {
    static projectHubProxy;
    static subscriptions;
    static hubConnections;

    constructor() {
        if (!RealTimeServiceInstance.instance) {
            this.subscriptions = [];
            this.hubConnections = [];
            this.createHubConnection('projectHub');
        }

        return RealTimeServiceInstance.instance;
    }

    createHubConnection(name) {
        const connection = new HubConnectionBuilder()
            .withUrl(`/${ name }`)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();

        this.hubConnections.push(connection);
        return connection;
    }

    startHubsConnection() {
        this.hubConnections.forEach(hub => {
            hub.start().then(this.invokeSubscriptions.bind(this));
        });
    }

    invokeSubscriptions() {
        this.subscriptions.forEach(s => {
            this.hubConnections.forEach(h => h.invoke(s.action, s.model));
        });
    }

    storeSubscription(subscription) {
        // sometimes two subscription are in the same group. We don't want to subscribe twice to the same group so we do not store duplicates
        if (!this.subscriptions.some(s => s.action === subscription.subscribeAction && s.model === subscription.model)) {
            this.subscriptions.push({ action: subscription.subscribeAction, model: subscription.model });
        }
    }

    subscribe(subscription) {
        this.storeSubscription(subscription);
        this.hubConnections.forEach(hub => {
            hub.on(subscription.eventName, subscription.eventHandle);

            if (hub.state === HubConnectionState.Disconnected) {
                this.startHubsConnection();
            }
            else if (hub.state === HubConnectionState.Connected) {
                hub.invoke(subscription.subscribeAction, subscription.model);
            }
        });
    }

    // TODO handle unsubscribing from RT services properly. we're not using this method right now.
    unsubscribe(subscription) {
        this.hubConnections.forEach(hub => {
            hub.off(subscription.eventName, subscription.eventHandle);
            hub.invoke(subscription.unsubscribeAction, subscription.model);
        });
    }
}

const instance = new RealTimeServiceInstance();

export default instance;
