var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
    [
      _vm.hasMoreThanOneStep
        ? _c("steps-indicator", {
            attrs: { steps: _vm.stepsArray, "is-in-admin-mode": false },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h1",
        {
          class: [
            "gfu-heading gfu-heading--main",
            { "_mt-6": !_vm.hasMoreThanOneStep },
          ],
          attrs: { "data-qa": "page-title" },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Checkout.CheckoutHeader) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-subheading gfu-subheading--main" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.Checkout.CheckoutSubHeader) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "box",
        { staticClass: "_mt-3 _mb-6" },
        [
          _c(
            "payment-checkout-header",
            _vm._b(
              {},
              "payment-checkout-header",
              _vm.paymentCheckoutHeaderPropsModel,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "legacy-cart-table",
            _vm._b({}, "legacy-cart-table", _vm.cartTableModelFormatted, false)
          ),
          _vm._v(" "),
          _vm.shouldDisplayNotificator
            ? _c(
                "base-notificator",
                _vm._b(
                  { staticClass: "_mt-2" },
                  "base-notificator",
                  _vm.validationResult,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c("checkout-return-actions", {
            attrs: {
              "action-url": _vm.backUrl,
              qa: _vm.checkoutReturnActionsQa,
            },
          }),
          _vm._v(" "),
          _c("legacy-order-summary-list", {
            attrs: { "order-id": _vm.orderID },
          }),
          _vm._v(" "),
          _c(
            "order-payment-options",
            _vm._b({}, "order-payment-options", _vm.orderPaymentOptions, false)
          ),
          _vm._v(" "),
          _c("checkout-footer", {
            attrs: { "show-adyen-payment-info": false },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }