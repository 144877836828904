var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-banner", attrs: { id: "js-project-banner" } },
    [
      _c(
        "div",
        {
          staticClass: "gfu-project-banner__content-wrapper",
          style: _vm.contentWrapperStyle,
        },
        [
          _c("div", { staticClass: "gfu-project-banner__content" }, [
            _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
              _c(
                "div",
                {
                  staticClass:
                    "gfu-grid__cell gfu-1of1 gfu-1of6--m gfu-1of10--l",
                  attrs: { "data-qa": "project-banner-image" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "gfu-thumb _screen--none _screen--m",
                      attrs: {
                        href: _vm.projectHomeUrl,
                        "data-qa": "project-banner-image-url",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "gfu-embed gfu-embed--1x1 gfu-thumb__item",
                        },
                        [
                          _c("base-image", {
                            staticClass: "gfu-embed__item",
                            attrs: {
                              loading: "lazy",
                              src: _vm.projectImage,
                              alt: _vm.projectName,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "gfu-grid__cell gfu-1of1 gfu-5of6--m",
                  class: [_vm.hasCredits ? "gfu-6of10--l" : "gfu-9of10--l"],
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "gfu-project-banner__title _mb-1",
                      attrs: { "data-qa": "project-banner-title" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "gfu-link gfu-link--nofx",
                          attrs: {
                            href: _vm.projectHomeUrl,
                            "data-qa": "project-banner-title-url",
                          },
                        },
                        [_vm._v(_vm._s(_vm.projectName))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "gfu-project-banner__title-annotation",
                      class: [
                        _vm.creatorImage
                          ? "gfu-project-banner__title-annotation--has-image"
                          : "",
                      ],
                    },
                    [
                      _c(
                        "localize",
                        {
                          attrs: {
                            resource:
                              _vm.Resources.ProjectHome
                                .ProjectHeaderByCreatorTitle,
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link gfu-link--nofx",
                              attrs: {
                                "format-key": "0",
                                href: _vm.creatorHomeUrl,
                                "data-qa": "project-banner-creator-url",
                              },
                            },
                            [
                              _vm.creatorImage
                                ? _c("img", {
                                    staticClass:
                                      "gfu-embed gfu-embed--1x1 gfu-project-banner__title-annotation-image",
                                    attrs: {
                                      loading: "lazy",
                                      src: _vm.creatorImage,
                                      alt: _vm.creatorName,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gfu-project-banner__title-annotation-text",
                                  attrs: {
                                    "data-qa": "project-banner-creator",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.creatorName))]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gfu-project-banner__description",
                      attrs: { "data-qa": "project-banner-description" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.projectShortDescription) +
                            "\n                        "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.hasCredits
        ? [
            _c("div", { staticClass: "gfu-project-banner__box-wrapper" }, [
              _c(
                "div",
                { staticClass: "gfu-project-banner__box-wrapper-layout" },
                [
                  _c(
                    "div",
                    { staticClass: "gfu-grid gfu-grid--gutter-columns" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "gfu-grid__cell gfu-1of1 gfu-left-7of10--l gfu-3of10--l",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "gfu-project-banner__box",
                              attrs: { "data-qa": "project-banner:BackerInfo" },
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "gfu-heading gfu-project-banner-user-info__heading",
                                  attrs: {
                                    "data-qa": "project-banner:UserMsg",
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "gfu-heading__icon gfu-heading__icon--circle gfu-heading__icon--accent _fa _fa--trophy",
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .BackerInfoMessage
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                [
                                  _c(
                                    "localize",
                                    {
                                      attrs: {
                                        resource:
                                          _vm.Resources.ProjectHome
                                            .UnspentCreditsMessage,
                                      },
                                    },
                                    [
                                      _c(
                                        "em",
                                        { attrs: { "format-key": "0" } },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              attrs: {
                                                "data-qa":
                                                  "project-banner:UnspentCredits",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.userCreditBalanceFormatted
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }