var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "_tal" }, [
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _c(
          "div",
          { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--l" },
          [
            _c("text-field", {
              attrs: {
                name: "model.name",
                "display-name": "Your creator profile display name",
                disabled: _vm.readOnly,
                "data-qa": "create-creator-account:name",
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
              model: {
                value: _vm.model.name,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1 _cf",
            attrs: { "data-qa": "create-creator-account:url" },
          },
          [
            _c(
              "url-field",
              _vm._b(
                {
                  attrs: {
                    name: "model.urlName",
                    "display-name": "Your creator profile address",
                    "validator-layout": "default",
                    layout: "condensed",
                    "data-qa": "new-project-wizard:ProjectUrl",
                  },
                  on: {
                    "confirm-change": _vm.onConfirmUrlChange,
                    "start-editing": _vm.onStartUrlEditing,
                    "cancel-editing": _vm.onCancelUrlEditing,
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.model.name
                        ? {
                            key: "edit",
                            fn: function ({ onEditClick }) {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "gfu-link gfu-link--accent _ml-1 _lhh",
                                    attrs: {
                                      href: "",
                                      "data-qa":
                                        "new-project-wizard-button:PickUrl",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return onEditClick.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "_fa _fa--pen" }),
                                    _vm._v(" edit URL"),
                                  ]
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.model.urlName,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "urlName", $$v)
                    },
                    expression: "model.urlName",
                  },
                },
                "url-field",
                _vm.urlModel,
                false
              )
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1 gfu-1of4--l gfu-left-3of4--l",
          },
          [
            _c(
              "base-button",
              {
                staticClass: "_mt-3",
                attrs: {
                  type: "button",
                  width: "wide",
                  theme: "accent",
                  display: "block",
                  enabled: !_vm.readOnly,
                  "data-qa": "create-account-button:Continue",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onSubmit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Continue")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "gfu-progress-overlay gfu-progress-overlay--sticky",
      class: { "is-visible": _vm.isProcessing },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }