export const pluralize = (val, word, plural = `${ word }s`) => {
    const _pluralize = (num, word, plural = `${ word }s`) =>
        [1, -1].includes(Number(num)) ? word : plural;
    if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
    return _pluralize(val, word, plural);
};

export function pluralizeWithRules(val, str = { many: null, one: null, few: null, other: null, two: null, zero: null }, locale = 'en-US') {
    const pr = new Intl.PluralRules(locale);
    return str[pr.select(val)] || str.other;
}

/**
 * Capitalize a string.
 */
export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isNullOrWhitespace = (input) => {
    return !input || !input.trim();
};

export const formatFileSize = (sizeInKB, precision) => {
    if (sizeInKB === 0) return '0 KB';
    const multiplicand = 1024;
    const decimals = precision ?? 2;
    const units = ['KB', 'MB', 'GB', 'TB'];
    const index = Math.floor(Math.log(sizeInKB) / Math.log(multiplicand));
    return `${ parseFloat((sizeInKB / Math.pow(multiplicand, index)).toFixed(decimals)) } ${ units[index] }`;
};

export const formatShortNumber = (number) => {
    const units = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    const tier = Math.log10(Math.abs(number)) / 3 | 0;
    const scale = Math.pow(10, tier * 3);
    const scaled = number / scale;
    const fractionDigits = tier > 0 ? 1 : 0;
    return `${ scaled.toFixed(fractionDigits) }${ units[tier] }` ;
};
