/**
 * @typedef HttpResponseInfo - Contains information about a HTTP response.
 * @type {Object}
 * @property {Object} data - Response data.
 */

/**
 * @typedef {'get'|'post'|'delete'} HttpRequestVerb
 */

/**
 * @callback ErrorHandlerCallback
 * @param error - The error to handle
 */

class ServiceBase {
    /**
     * @param {Object} $http - The http communication object instance.
     * @param errorHandler {ErrorHandlerCallback=} - A callback function invoked when requests on this instance fail.
     */
    constructor($http, errorHandler = null) {

        /** @member {Object} */
        this.$http = $http;

        /** @member {ErrorHandlerCallback} */
        this._errorHandler = errorHandler;
    }

    /**
     * Makes an asynchronous request for a resources.
     * @param verb {HttpRequestVerb} - The verb to use.
     * @param path {string} - The path of the request. Full url is constructed using the base address set in configuration.
     * @param params {object} - The request params.
     * @param config {object} - The request config.
     * @returns {Promise<HttpResponseInfo>}
     */
    request(verb, path, params, config = null) {
        verb = verb.toLowerCase();

        let data = null;

        if (['get', 'delete'].includes(verb)) {
            if (params && params.params) {
                params = params.params;
            }
        }
        else {
            if (params) {
                data = params;
                params = null;
            }
        }

        const method = this.$http[verb];

        if (!method)
            throw `Invalid request verb '${ verb }'.`;

        const axiosConfig = {
            method: verb,
            url: path,
            params,
            data,
            ...config,
        };

        let result = this.$http(axiosConfig);

        if (this.canHandleErrors()) {
            result = result.catch(this.handleError.bind(this));
        }

        return result;
    }

    /**
     * Handles an error.
     * @param error
     */
    handleError(error) {
        this._errorHandler.apply(this, arguments);
    }

    /**
     * Determines whether this instance can handle errors. Handled errors don't populate upwards.
     * @returns {boolean}
     */
    canHandleErrors() {
        return this._errorHandler && true;
    }
}

export default ServiceBase;