var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "skeleton-wrapper",
    { staticClass: "_flexcol _gap-3" },
    [
      _c("skeleton-media", { attrs: { "media-lines": 3 } }),
      _vm._v(" "),
      _c("skeleton-paragraph", { attrs: { lines: 5 } }),
      _vm._v(" "),
      _c("skeleton-button"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }