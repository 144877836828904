import { ref } from 'vue';
import { getWindowWidth } from '@/Utils/WindowUtility.js';
import { breakpoint as defaultBreakpoints } from '@/Types/StyleTypes.js';

const getBreakpointValues = (userBreakpoints, windowWidth) => ({
    isMobile: windowWidth < userBreakpoints.m,
    isTablet: windowWidth < userBreakpoints.l,
    isLessThanM: windowWidth < userBreakpoints.m,
    isLessThanL: windowWidth < userBreakpoints.l,
    isLessThanXl: windowWidth < userBreakpoints.xl,
});

const handleResize = (userBreakpoints) => {
    const windowWidth = getWindowWidth();
    const breakpointValues = getBreakpointValues(userBreakpoints, windowWidth);
    if (JSON.stringify(breakpoints.value) !== JSON.stringify(breakpointValues)) {
        breakpoints.value = breakpointValues;
    }
};

const breakpoints = ref(getBreakpointValues(defaultBreakpoints, getWindowWidth()));

export default {
    install(app, customBreakpoints) {
        const userBreakpoints = { ...defaultBreakpoints, ...customBreakpoints };
        window.addEventListener('resize', () => handleResize(userBreakpoints), { passive: true });

        app.mixin({
            computed: {
                $breakpoint() {
                    return {
                        ...breakpoints.value,
                        ...userBreakpoints,
                    };
                },
            },
        });
    },
};