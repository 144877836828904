const boundaryDetectorStyle = {
    height: '0 !important',
    margin: '0 !important',
    padding: '0 !important',
    border: 'none !important',
    outline: 'none !important',
};

export default {
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        overflowClass: {
            type: String,
            default: 'is-overflowing',
        },
    },
    data() {
        return {
            topBoundaryVisible: true,
            bottomBoundaryVisible: true,
        };
    },
    render(createElement) {
        this.topBoundary = createElement('div', { style: boundaryDetectorStyle });
        this.bottomBoundary = createElement('div', { style: boundaryDetectorStyle });

        const data = {
            class: this.isOverflowing ? [this.overflowClass] : [],
        };

        return createElement(this.tag, data, [this.topBoundary, this.$slots.default, this.bottomBoundary]);
    },
    mounted() {
        const observerOptions = {
            root: this.$el,
            threshold: [0, 1],
        };

        const topBoundaryObserver = new IntersectionObserver(entries => {
            this.topBoundaryVisible = entries[0].intersectionRatio > 0;
        }, observerOptions);
        topBoundaryObserver.observe(this.topBoundary.elm);

        const bottomBoundaryObserver = new IntersectionObserver(entries => {
            this.bottomBoundaryVisible = entries[0].intersectionRatio > 0;
        }, observerOptions);
        bottomBoundaryObserver.observe(this.bottomBoundary.elm);
    },
    computed: {
        isOverflowing() {
            return !this.topBoundaryVisible || !this.bottomBoundaryVisible;
        },
    },
};