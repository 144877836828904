var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      { attrs: { "data-qa": `checkbox-group:${_vm.displayName}` } },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    _vm._l(_vm.options, function (val, name) {
      return _c(
        "div",
        { key: val },
        [
          _c("checkbox-field", {
            attrs: {
              value: _vm.value,
              "display-name": name,
              "checked-value": val,
              inline: true,
              condensed: true,
              type: _vm.type,
              "data-qa": `checkbox-group:${name}`,
            },
            on: { input: _vm.onInput },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }