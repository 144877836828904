var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-project-description-section gfu-project-description-section--emphasized",
      attrs: {
        "data-qa": `project-description-section:${_vm.projectDescriptionSectionId}`,
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl",
        },
        [
          _c(
            "h2",
            {
              ref: "header",
              staticClass: "gfu-hd gfu-hd--h2 gfu-heading--top-divider _mb-2",
              attrs: {
                "data-qa": `project-description-section-title:${_vm.header}`,
              },
            },
            [_vm._v("\n            " + _vm._s(_vm.header) + "\n        ")]
          ),
          _vm._v(" "),
          _vm.isLanguageSelectorVisible
            ? _c("project-section-story-available-languages", {
                attrs: { "available-languages": _vm.availableLanguages },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isStablePledgeVisible
            ? _c("project-section-story-stable-pledge", {
                staticClass: "_mb-3",
              })
            : _vm._e(),
          _vm._v(" "),
          _c("project-section-story-properties", {
            attrs: {
              "project-categories": _vm.projectCategories,
              "project-properties": _vm.projectProperties,
            },
          }),
          _vm._v(" "),
          _c("div", {
            ref: "content",
            staticClass: "_mt-2 gfu-richtext gfu-richtext--article",
            domProps: { innerHTML: _vm._s(_vm.contentUpdated) },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }