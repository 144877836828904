import InitialState from '@/InitialState';
import { defineStore } from 'pinia';
import { pledgeManagerAvailabilities } from '@/Models';

export const useProjectAdditionalSettingsStore = defineStore({
    id: 'projectAdditionalSettingsStore',
    state: () => ({
        projectAdditionalSettings: InitialState?.projectAdditionalSettings || {},
    }),
    getters: {
        isPledgeManagerAvailableForNewOrders() {
            const { pledgeManagerAvailability: availability, allowNewOrders, hasAccessToLimitedPledgeManager } = this.projectAdditionalSettings;
            return allowNewOrders && (availability === pledgeManagerAvailabilities.open
                || availability === pledgeManagerAvailabilities.openForSpecificUserGroups && hasAccessToLimitedPledgeManager);
        },
    },
});
