var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isContentString
        ? [_c("span", [_vm._v(_vm._s(_vm.content))])]
        : [
            _c(
              "div",
              { staticClass: "_flexcol _gap-1" },
              [
                _vm._l(_vm.content, function (value, key) {
                  return [
                    _c("span", { key: key }, [
                      _vm._v(_vm._s(key) + ": " + _vm._s(value)),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }