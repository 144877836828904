import ServiceBase from '@/Services/ServiceBase';

export default class NotificationPreferenceService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    confirmUnsubscribe(unsubscribeKey) {
        return this.request('post', 'notificationPreference/confirmUnsubscribe', { key: unsubscribeKey });
    }
}