var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        staticClass: "gfu-selector-btn__wrapper",
        attrs: { name: _vm.name, "read-only": _vm.readOnly },
      },
      "field",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.options, function (value, key, index) {
      return _c(
        "button",
        {
          key: value,
          class: [
            "gfu-selector-btn gfu-selector-btn--multi",
            {
              "gfu-selector-btn--transparent": _vm.transparent,
              "gfu-selector-btn--first": _vm.isFirstOption(index),
              "gfu-selector-btn--middle": _vm.isMiddleOption(index),
              "gfu-selector-btn--last": _vm.isLastOption(index),
              "gfu-selector-btn--active gfu-selector-btn--nofx":
                _vm.model === value,
              "gfu-selector-btn--light": _vm.light,
            },
          ],
          attrs: {
            name: _vm.name,
            type: "button",
            disabled: _vm.readOnly,
            "data-qa": `multi-selector-field:${key}`,
          },
          on: {
            click: function ($event) {
              return _vm.selectOption(value)
            },
          },
        },
        [_vm._v(_vm._s(key))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }