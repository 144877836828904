var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: [
        "gfu-badge",
        _vm.themeClass,
        "gfu-badge--compact",
        _vm.textTransformClass,
        { "gfu-badge--solid": _vm.isSolid },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }