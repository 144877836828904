var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "gfu-btn gfu-btn--primary gfu-btn--compact gfu-btn--soft",
      on: { click: _vm.revertImpersonation },
    },
    [
      _vm._t("default", function () {
        return [
          _vm._v(_vm._s(_vm.Resources.Layout.UserBoxRevertImpersonationButton)),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }