var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-close-btn _flexcntr _ai-c _cur-p _pos-r",
      attrs: { "data-qa": _vm.qa },
      on: { click: _vm.onClose },
    },
    [
      _c("div", {
        staticClass:
          "gfu-close-btn__icon _fa _fa--times _fa--tac _fa--fw-eb _fa--lighter",
      }),
      _vm._v(" "),
      _vm.duration
        ? _c("svg", { staticClass: "gfu-close-btn__circle" }, [
            _c("circle", {
              ref: "circel",
              style: _vm.circleStyles,
              attrs: { cx: "10", cy: "10", r: "10" },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }