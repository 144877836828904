var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_pos-r" },
    [
      _vm.isBannerVisible
        ? _c("div", { staticClass: "gfu-info-media" }, [
            _c(
              "div",
              { staticClass: "gfu-info-media__aside _flex _jc-c" },
              [
                _c("base-image", {
                  staticClass: "gfu-newsletter-banner-image",
                  attrs: {
                    src: "/content/images/Newsletter/newsletter-banner-image.svg",
                    alt: "Newsletter banner",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.shouldDisplaySubscribeAction
              ? _c("div", { staticClass: "gfu-media__body" }, [
                  _c("h2", { staticClass: "gfu-hd gfu-hd--h2 _mt-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess.NewsletterBannerHeader
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess.NewsletterBannerParagraph
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-newsletter-banner-action" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "_mt-3 _mb-3",
                          attrs: { theme: "accent" },
                          on: { click: _vm.signUpToNewsletters },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.PaymentSuccess
                                  .NewsletterBannerSubscribeButton
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "gfu-info-media__body" }, [
                  _c("h2", { staticClass: "gfu-hd gfu-hd--h2 _mt-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess
                          .NewsletterBannerConfirmationHeader
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess
                          .NewsletterBannerConfirmationParagraph
                      )
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }