var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("click-outside", { attrs: { call: _vm.onBalloonClose } }, [
    _c(
      "div",
      { staticClass: "gfu-project-engagement-card-actions" },
      [
        _vm.isInGroupedMode
          ? [
              _c(
                "base-button",
                {
                  attrs: {
                    shape: "circle",
                    theme: _vm.theme,
                    "data-qa": "circle-menu",
                  },
                  on: { click: _vm.onBalloonOpen },
                },
                [_c("base-icon", { attrs: { name: "ellipsis-v" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "gfu-project-engagement-card-actions__dropdown",
                },
                [
                  _vm.isBalloonVisible
                    ? _c(
                        "base-balloon",
                        _vm._b(
                          { staticClass: "_px-2 _py-2" },
                          "base-balloon",
                          _vm.balloonProps,
                          false
                        ),
                        [
                          _vm._l(_vm.items, function (item) {
                            return [
                              item.hasAction
                                ? _c(
                                    "a",
                                    {
                                      key: item.title,
                                      staticClass: "gfu-balloon__content-link",
                                      attrs: {
                                        "data-qa": `balloon-action:${item.qa}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return (() =>
                                            _vm.onAction(item.fn)).apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("base-icon", {
                                        staticClass:
                                          "gfu-balloon__content-icon",
                                        attrs: { name: item.icon },
                                      }),
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item.title) +
                                          "\n                        "
                                      ),
                                    ],
                                    1
                                  )
                                : item.to
                                ? _c(
                                    "router-link",
                                    {
                                      key: item.title,
                                      staticClass: "gfu-balloon__content-link",
                                      attrs: { to: item.to },
                                    },
                                    [
                                      _c("base-icon", {
                                        staticClass:
                                          "gfu-balloon__content-icon",
                                        attrs: { name: item.icon },
                                      }),
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item.title) +
                                          "\n                        "
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "a",
                                    {
                                      key: item.title,
                                      staticClass: "gfu-balloon__content-link",
                                      attrs: {
                                        href: item.url,
                                        "data-qa": `balloon-action:${item.qa}`,
                                      },
                                    },
                                    [
                                      _c("base-icon", {
                                        staticClass:
                                          "gfu-balloon__content-icon",
                                        attrs: { name: item.icon },
                                      }),
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item.title) +
                                          "\n                        "
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          : _vm.isInInlineMode
          ? [
              _c(
                "div",
                { staticClass: "_flex _gap-1" },
                _vm._l(_vm.items, function (action) {
                  return _c(
                    "base-button",
                    {
                      key: action.title,
                      attrs: {
                        shape: "circle",
                        theme: _vm.theme,
                        "data-qa": `action:${action.qa}`,
                      },
                      on: { click: () => _vm.onAction(action.fn) },
                    },
                    [_c("base-icon", { attrs: { name: action.icon } })],
                    1
                  )
                }),
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }