var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "notificator",
      class: _vm.notificatorClassList,
      attrs: { "data-qa": "notificator" },
    },
    [
      _vm.withTransition
        ? [
            _c(
              "transition-group",
              {
                attrs: {
                  name: "gfu-notification-slide-and-fade",
                  mode: "out-in",
                  tag: "div",
                },
              },
              _vm._l(_vm.notifications, function (notification) {
                return _c(
                  "base-notificator",
                  _vm._b(
                    {
                      key: notification.id,
                      class: _vm.createNotificationClass(notification),
                      attrs: { "can-close": _vm.canClose },
                      on: {
                        close: function ($event) {
                          return _vm.onClose(notification)
                        },
                      },
                    },
                    "base-notificator",
                    notification,
                    false
                  )
                )
              }),
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.notifications, function (notification) {
        return _c(
          "base-notificator",
          _vm._b(
            {
              key: notification.id,
              class: _vm.createNotificationClass(notification),
              attrs: { "can-close": _vm.canClose },
              on: {
                close: function ($event) {
                  return _vm.onClose(notification)
                },
              },
            },
            "base-notificator",
            notification,
            false
          )
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }