// This is a generated file. Any changes you make here will be lost once it's re-generated.
import ServiceBase from './ServiceBase';

export class ShippingServiceBase extends ServiceBase {
    constructor($http, errorHandler = null) {
        super($http, errorHandler);
    }

    // ---- ShippingModel ----

    createShippingModel(shippingModel) {
        const params = {
            ...shippingModel,
        };

        return this.request('post', 'shipping/createShippingModel', params);
    }

    deleteShippingModel(shippingModelID, projectID) {
        const params = {
            shippingModelID,
            projectID,
        };

        return this.request('post', 'shipping/deleteShippingModel', params);
    }

    getShippingModel(shippingModelID, projectID) {
        const params = {
            shippingModelID,
            projectID,
        };

        return this.request('get', 'shipping/getShippingModel', params);
    }

    searchShippingModels(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'shipping/searchShippingModels', params);
    }

    updateShippingModel(shippingModel) {
        const params = {
            ...shippingModel,
        };

        return this.request('post', 'shipping/updateShippingModel', params);
    }

    // ---- ShippingZone ----

    createShippingZone(shippingZone) {
        const params = {
            ...shippingZone,
        };

        return this.request('post', 'shipping/createShippingZone', params);
    }

    deleteShippingZone(shippingZoneID, projectID) {
        const params = {
            shippingZoneID,
            projectID,
        };

        return this.request('post', 'shipping/deleteShippingZone', params);
    }

    getShippingZone(shippingZoneID, projectID) {
        const params = {
            shippingZoneID,
            projectID,
        };

        return this.request('get', 'shipping/getShippingZone', params);
    }

    searchShippingZones(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'shipping/searchShippingZones', params);
    }

    updateShippingZone(shippingZone) {
        const params = {
            ...shippingZone,
        };

        return this.request('post', 'shipping/updateShippingZone', params);
    }

    // ---- ShippingWeightRange ----

    createShippingWeightRange(shippingWeightRange) {
        const params = {
            ...shippingWeightRange,
        };

        return this.request('post', 'shipping/createShippingWeightRange', params);
    }

    deleteShippingWeightRange(shippingWeightRangeID, projectID) {
        const params = {
            shippingWeightRangeID,
            projectID,
        };

        return this.request('post', 'shipping/deleteShippingWeightRange', params);
    }

    getShippingWeightRange(shippingWeightRangeID, projectID) {
        const params = {
            shippingWeightRangeID,
            projectID,
        };

        return this.request('get', 'shipping/getShippingWeightRange', params);
    }

    searchShippingWeightRanges(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'shipping/searchShippingWeightRanges', params);
    }

    updateShippingWeightRange(shippingWeightRange) {
        const params = {
            ...shippingWeightRange,
        };

        return this.request('post', 'shipping/updateShippingWeightRange', params);
    }

    // ---- ShippingClass ----

    createShippingClass(shippingClass) {
        const params = {
            ...shippingClass,
        };

        return this.request('post', 'shipping/createShippingClass', params);
    }

    deleteShippingClass(shippingClassID, projectID) {
        const params = {
            shippingClassID,
            projectID,
        };

        return this.request('post', 'shipping/deleteShippingClass', params);
    }

    getShippingClass(shippingClassID, projectID) {
        const params = {
            shippingClassID,
            projectID,
        };

        return this.request('get', 'shipping/getShippingClass', params);
    }

    searchShippingClasses(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'shipping/searchShippingClasses', params);
    }

    updateShippingClass(shippingClass) {
        const params = {
            ...shippingClass,
        };

        return this.request('post', 'shipping/updateShippingClass', params);
    }

    // ---- ShippingClassZoneCost ----

    createShippingClassZoneCost(shippingClassZoneCost) {
        const params = {
            ...shippingClassZoneCost,
        };

        return this.request('post', 'shipping/createShippingClassZoneCost', params);
    }

    deleteShippingClassZoneCost(shippingClassZoneCostID, projectID) {
        const params = {
            shippingClassZoneCostID,
            projectID,
        };

        return this.request('post', 'shipping/deleteShippingClassZoneCost', params);
    }

    getShippingClassZoneCost(shippingClassZoneCostID, projectID) {
        const params = {
            shippingClassZoneCostID,
            projectID,
        };

        return this.request('get', 'shipping/getShippingClassZoneCost', params);
    }

    searchShippingClassZoneCosts(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'shipping/searchShippingClassZoneCosts', params);
    }

    updateShippingClassZoneCost(shippingClassZoneCost) {
        const params = {
            ...shippingClassZoneCost,
        };

        return this.request('post', 'shipping/updateShippingClassZoneCost', params);
    }

    // ---- ShippingStage ----

    createShippingStage(shippingStage) {
        const params = {
            ...shippingStage,
        };

        return this.request('post', 'shipping/createShippingStage', params);
    }

    deleteShippingStage(shippingStageID, projectID) {
        const params = {
            shippingStageID,
            projectID,
        };

        return this.request('post', 'shipping/deleteShippingStage', params);
    }

    getShippingStage(shippingStageID, projectID) {
        const params = {
            shippingStageID,
            projectID,
        };

        return this.request('get', 'shipping/getShippingStage', params);
    }

    searchShippingStages(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'shipping/searchShippingStages', params);
    }

    updateShippingStage(shippingStage) {
        const params = {
            ...shippingStage,
        };

        return this.request('post', 'shipping/updateShippingStage', params);
    }

    // ---- ProductShippingStage ----

    createProductShippingStage(productShippingStage) {
        const params = {
            ...productShippingStage,
        };

        return this.request('post', 'shipping/createProductShippingStage', params);
    }

    deleteProductShippingStage(shippingStageID, productID, projectID) {
        const params = {
            shippingStageID, productID,
            projectID,
        };

        return this.request('post', 'shipping/deleteProductShippingStage', params);
    }

    getProductShippingStage(shippingStageID, productID, projectID) {
        const params = {
            shippingStageID, productID,
            projectID,
        };

        return this.request('get', 'shipping/getProductShippingStage', params);
    }

    searchProductShippingStages(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'shipping/searchProductShippingStages', params);
    }

    updateProductShippingStage(productShippingStage) {
        const params = {
            ...productShippingStage,
        };

        return this.request('post', 'shipping/updateProductShippingStage', params);
    }
}

export class LocationsServiceBase extends ServiceBase {
    constructor($http, errorHandler = null) {
        super($http, errorHandler);
    }

    // ---- SubLocation ----

    createSubLocation(subLocation) {
        const params = {
            ...subLocation,
        };

        return this.request('post', 'locations/createSubLocation', params);
    }

    deleteSubLocation(subLocationID) {
        const params = {
            subLocationID,

        };

        return this.request('post', 'locations/deleteSubLocation', params);
    }

    getSubLocation(subLocationID) {
        const params = {
            subLocationID,

        };

        return this.request('get', 'locations/getSubLocation', params);
    }

    searchSubLocations(filters = {}, pageSize = null, pageIndex = null) {
        const params = {

            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'locations/searchSubLocations', params);
    }

    updateSubLocation(subLocation) {
        const params = {
            ...subLocation,
        };

        return this.request('post', 'locations/updateSubLocation', params);
    }
}

export class ProductsServiceBase extends ServiceBase {
    constructor($http, errorHandler = null) {
        super($http, errorHandler);
    }

    // ---- Product ----

    createProduct(product) {
        const params = {
            ...product,
        };

        return this.request('post', 'products/createProduct', params);
    }

    deleteProduct(productID, projectID) {
        const params = {
            productID,
            projectID,
        };

        return this.request('post', 'products/deleteProduct', params);
    }

    getProduct(productID, projectID) {
        const params = {
            productID,
            projectID,
        };

        return this.request('get', 'products/getProduct', params);
    }

    searchProducts(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'products/searchProducts', params);
    }

    updateProduct(product) {
        const params = {
            ...product,
        };

        return this.request('post', 'products/updateProduct', params);
    }

    // ---- ProductGroup ----

    createProductGroup(productGroup) {
        const params = {
            ...productGroup,
        };

        return this.request('post', 'products/createProductGroup', params);
    }

    deleteProductGroup(productGroupID, projectID) {
        const params = {
            productGroupID,
            projectID,
        };

        return this.request('post', 'products/deleteProductGroup', params);
    }

    getProductGroup(productGroupID, projectID) {
        const params = {
            productGroupID,
            projectID,
        };

        return this.request('get', 'products/getProductGroup', params);
    }

    searchProductGroups(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'products/searchProductGroups', params);
    }

    updateProductGroup(productGroup) {
        const params = {
            ...productGroup,
        };

        return this.request('post', 'products/updateProductGroup', params);
    }

    // ---- ProductGroupProduct ----

    createProductGroupProduct(productGroupProduct) {
        const params = {
            ...productGroupProduct,
        };

        return this.request('post', 'products/createProductGroupProduct', params);
    }

    deleteProductGroupProduct(productGroupID, productID, projectID) {
        const params = {
            productGroupID, productID,
            projectID,
        };

        return this.request('post', 'products/deleteProductGroupProduct', params);
    }

    getProductGroupProduct(productGroupID, productID, projectID) {
        const params = {
            productGroupID, productID,
            projectID,
        };

        return this.request('get', 'products/getProductGroupProduct', params);
    }

    searchProductGroupProducts(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'products/searchProductGroupProducts', params);
    }

    updateProductGroupProduct(productGroupProduct) {
        const params = {
            ...productGroupProduct,
        };

        return this.request('post', 'products/updateProductGroupProduct', params);
    }

    // ---- ProductGroupUserGroup ----

    createProductGroupUserGroup(productGroupUserGroup) {
        const params = {
            ...productGroupUserGroup,
        };

        return this.request('post', 'products/createProductGroupUserGroup', params);
    }

    deleteProductGroupUserGroup(productGroupID, userGroupID, projectID) {
        const params = {
            productGroupID, userGroupID,
            projectID,
        };

        return this.request('post', 'products/deleteProductGroupUserGroup', params);
    }

    getProductGroupUserGroup(productGroupID, userGroupID, projectID) {
        const params = {
            productGroupID, userGroupID,
            projectID,
        };

        return this.request('get', 'products/getProductGroupUserGroup', params);
    }

    searchProductGroupUserGroups(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'products/searchProductGroupUserGroups', params);
    }

    updateProductGroupUserGroup(productGroupUserGroup) {
        const params = {
            ...productGroupUserGroup,
        };

        return this.request('post', 'products/updateProductGroupUserGroup', params);
    }

    // ---- ProductSetItem ----

    createProductSetItem(productSetItem) {
        const params = {
            ...productSetItem,
        };

        return this.request('post', 'products/createProductSetItem', params);
    }

    deleteProductSetItem(productSetItemID, projectID) {
        const params = {
            productSetItemID,
            projectID,
        };

        return this.request('post', 'products/deleteProductSetItem', params);
    }

    getProductSetItem(productSetItemID, projectID) {
        const params = {
            productSetItemID,
            projectID,
        };

        return this.request('get', 'products/getProductSetItem', params);
    }

    searchProductSetItems(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'products/searchProductSetItems', params);
    }

    updateProductSetItem(productSetItem) {
        const params = {
            ...productSetItem,
        };

        return this.request('post', 'products/updateProductSetItem', params);
    }
}

export class CategoriesServiceBase extends ServiceBase {
    constructor($http, errorHandler = null) {
        super($http, errorHandler);
    }

    // ---- Category ----

    createCategory(category) {
        const params = {
            ...category,
        };

        return this.request('post', 'categories/createCategory', params);
    }

    deleteCategory(categoryID, projectID) {
        const params = {
            categoryID,
            projectID,
        };

        return this.request('post', 'categories/deleteCategory', params);
    }

    getCategory(categoryID, projectID) {
        const params = {
            categoryID,
            projectID,
        };

        return this.request('get', 'categories/getCategory', params);
    }

    searchCategorys(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'categories/searchCategorys', params);
    }

    updateCategory(category) {
        const params = {
            ...category,
        };

        return this.request('post', 'categories/updateCategory', params);
    }
}
