var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isProgresBarVisible
    ? _c("div", { staticClass: "gfu-progress-bar__container" }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-progress-bar__item" }, [
      _c("div", { staticClass: "gfu-progress-bar__value" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }