export const languageTypes = {
    en: {
        value: 'English',
        icon: '/content/images/localization/en.svg',
        code: 'EN',
        englishName: 'English',
    },
    pl: {
        value: 'Polski',
        icon: '/content/images/localization/pl.svg',
        code: 'PL',
        englishName: 'Polish',
    },
    it: {
        value: 'Italiano',
        icon: '/content/images/localization/it.svg',
        code: 'IT',
        englishName: 'Italian',
    },
    fr: {
        value: 'Français',
        icon: '/content/images/localization/fr.svg',
        code: 'FR',
        englishName: 'French',
    },
    de: {
        value: 'Deutsch',
        icon: '/content/images/localization/de.svg',
        code: 'DE',
        englishName: 'German',
    },
    es: {
        value: 'Español',
        icon: '/content/images/localization/es.svg',
        code: 'ES',
        englishName: 'Spanish',
    },
    cs: {
        value: 'Čeština',
        icon: '/content/images/localization/cz.svg',
        code: 'CS',
        englishName: 'Czech',
    },
    pt: {
        value: 'Português',
        icon: '/content/images/localization/pt_br.svg',
        code: 'PT',
        englishName: 'Portuguese',
    },
};