var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-description-stable-pledge",
      attrs: {
        "data-qa": "project-description-section:LearnAboutStablePledge",
      },
    },
    [
      _c("div", { staticClass: "_flex _ai-c" }, [
        _c("span", {
          staticClass: "_flex _fa _fa--2x _fa--box-check _tc--accent _mr-2",
        }),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(_vm.Resources.ProjectHome.ProjectStoryStablePledgeText)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "_flex _ai-c gfu-link gfu-link--accent _ml-a",
          attrs: { href: _vm.refundAndCancellationPolicyUrl },
        },
        [
          _c(
            "span",
            { attrs: { "data-qa": "learn-about-stable-pledge-button" } },
            [
              _vm._v(
                _vm._s(_vm.Resources.ProjectHome.ProjectStoryStablePledgeLink)
              ),
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "_fr _ml-1 _fa _fa--angle-double-down" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }