var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper" }, [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
      _c("div", {
        staticClass: "gfu-grid__cell gfu-1of1 gfu-1of6--m gfu-1of3--l",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-grid__cell gfu-1of1 gfu-4of6--m gfu-1of3--l" },
        [
          _c(
            "div",
            {
              staticClass: "gfu-box gfu-box--thick _mt-6 _mb-3",
              attrs: { "data-qa": "authenticate-form" },
            },
            [
              _c(
                "h2",
                { staticClass: "gfu-heading gfu-heading--soft gfu-box__title" },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.Resources.Account.AuthenticatePageHeader) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  ref: "form",
                  attrs: {
                    action: _vm.actionUrl,
                    method: "post",
                    autocomplete: "off",
                  },
                },
                [
                  _c("checkbox-field", {
                    attrs: {
                      condensed: true,
                      "display-name": _vm.Resources.Account.LogInOption,
                      name: "mode",
                      type: "radio",
                      "validator-layout": "none",
                      "checked-value": _vm.authenticationModes.login,
                      "data-qa": "authenticate-btn:ReturningCustomer",
                    },
                    model: {
                      value: _vm.mode,
                      callback: function ($$v) {
                        _vm.mode = $$v
                      },
                      expression: "mode",
                    },
                  }),
                  _vm._v(" "),
                  _c("checkbox-field", {
                    attrs: {
                      condensed: true,
                      "display-name": _vm.Resources.Account.SignUpOption,
                      name: "mode",
                      type: "radio",
                      "validator-layout": "none",
                      "checked-value": _vm.authenticationModes.createAccount,
                      "data-qa": "authenticate-btn:NewCustomer",
                    },
                    model: {
                      value: _vm.mode,
                      callback: function ($$v) {
                        _vm.mode = $$v
                      },
                      expression: "mode",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("mode"),
                          expression: "errors.has('mode')",
                        },
                      ],
                      staticClass: "gfu-field has-error",
                    },
                    [
                      _c("p", { staticClass: "gfu-field__error" }, [
                        _vm._v(
                          _vm._s(_vm.Resources.Common.ValidationOptionRequired)
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-field _tac" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            type: "submit",
                            width: "wide",
                            theme: "accent",
                            "data-qa": "authenticate-btn:Continue",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.Resources.Common.ModalContinueButton)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }