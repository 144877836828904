var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-summary-clock",
      attrs: { "data-qa": "countdown-clock" },
    },
    [
      _c("div", { staticClass: "gfu-project-summary-clock__head" }, [
        _c(
          "strong",
          { staticClass: "gfu-project-summary-clock__title gfu-hd gfu-hd--h5" },
          [_vm._v(_vm._s(_vm.title))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-project-summary-clock__body" },
        [
          _vm.isClockVisible
            ? [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-value" },
                    [_vm._v(_vm._s(_vm.formatNumber(_vm.remainingDays)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-part" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.pluralize(
                            _vm.remainingDays,
                            _vm.Resources.ProjectHome.HumanizeDatePluralizedDay
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-value" },
                    [_vm._v(_vm._s(_vm.formatNumber(_vm.remainingHours)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-part" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.pluralize(
                            _vm.remainingHours,
                            _vm.Resources.ProjectHome.HumanizeDatePluralizedHour
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-value" },
                    [_vm._v(_vm._s(_vm.formatNumber(_vm.remainingMinutes)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-part" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.pluralize(
                            _vm.remainingMinutes,
                            _vm.Resources.ProjectHome
                              .HumanizeDatePluralizedMinuteShort
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-value" },
                    [_vm._v(_vm._s(_vm.formatNumber(_vm.remainingSeconds)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-project-summary-clock--time-part" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.pluralize(
                            _vm.remainingSeconds,
                            _vm.Resources.ProjectHome
                              .HumanizeDatePluralizedSecondShort
                          )
                        )
                      ),
                    ]
                  ),
                ]),
              ]
            : [
                _c(
                  "div",
                  {
                    staticClass: "gfu-grid__cell gfu-1of1",
                    attrs: { "data-qa": "countdown-clock-imminent" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "gfu-project-summary-clock--time-value" },
                      [_vm._v(_vm._s(_vm.elapsedMessage))]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "gfu-project-summary-clock--time-part",
                    }),
                  ]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }