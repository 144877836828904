var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-summary gfu-box gfu-box--flat gfu-box--fill" },
    [_c("progress-overlay", { attrs: { visible: true } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }