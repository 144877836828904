var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.componentName
    ? _c(
        _vm.componentName,
        _vm._b(
          { tag: "component", on: { close: _vm.close, submit: _vm.submit } },
          "component",
          _vm.componentModel,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }