var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: _vm.Resources.Comments.ReportAndHideCommentModalTitle,
        icon: "eye-slash",
        "is-processing": _vm.isProcessing,
        "is-visible": true,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent _ttl",
                  attrs: { href: "", "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: {
                    size: "default",
                    width: "compact",
                    "text-transform": "none",
                    wide: true,
                    bold: true,
                    theme: "accent",
                    enabled: !_vm.isProcessing,
                    "data-qa": "modal-button:Confirm",
                  },
                  on: { click: _vm.onReportAndHideProjectComment },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalConfirmButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c("p", { staticClass: "_mb-3" }, [
          _vm._v(
            _vm._s(_vm.Resources.Comments.ReportAndHideCommentModalParagraph)
          ),
        ]),
        _vm._v(" "),
        _c(
          "base-notificator",
          { staticClass: "_mb-2", attrs: { type: "info", "can-close": false } },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.Resources.Comments.ReportAndHideCommentModalInformation
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("option-field", {
          attrs: {
            name: "model.reason",
            "display-name":
              _vm.Resources.Comments.ReportCommentModalReasonFieldLabel,
            options: _vm.availableReportReasons,
            "no-option-capitalize": true,
            "data-qa": "modal-select:Reason",
          },
          model: {
            value: _vm.model.reason,
            callback: function ($$v) {
              _vm.$set(_vm.model, "reason", $$v)
            },
            expression: "model.reason",
          },
        }),
        _vm._v(" "),
        _c("text-area-field", {
          attrs: {
            name: "model.reasonDescription",
            "display-name":
              _vm.Resources.Comments
                .ReportCommentModalReasonDescriptionFieldLabel,
            "data-qa": "modal-field:ReportReasonDescription",
          },
          model: {
            value: _vm.model.reasonDescription,
            callback: function ($$v) {
              _vm.$set(_vm.model, "reasonDescription", $$v)
            },
            expression: "model.reasonDescription",
          },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }