var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        staticClass: "gfu-field-item",
        attrs: { name: _vm.name, "data-qa": `auto-complete-field:${_vm.name}` },
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _c(
        "div",
        { staticClass: "gfu-field-auto-complete__container" },
        [
          _c(
            "toggle-trigger",
            {
              ref: "toggleTrigger",
              attrs: {
                tag: "div",
                tabindex: "0",
                "active-class": "gfu-field-select__trigger--active",
                "handle-click": false,
                "focus-children": true,
              },
              on: { collapse: _vm.onCollapse },
            },
            [
              _c(
                "div",
                { staticClass: "gfu-field-auto-complete__search-box" },
                [
                  _vm.readOnly
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchTerm,
                            expression: "searchTerm",
                          },
                        ],
                        class: [
                          "gfu-field__input gfu-field__input--readonly",
                          { "gfu-field__input--small": _vm.small },
                        ],
                        attrs: {
                          type: "text",
                          readonly: "readonly",
                          autocomplete: "off",
                          name: _vm.name,
                          "data-qa": "auto-complete-field-readonly",
                        },
                        domProps: { value: _vm.searchTerm },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchTerm = $event.target.value
                          },
                        },
                      })
                    : [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchTerm,
                              expression: "searchTerm",
                            },
                          ],
                          ref: "searchField",
                          class: [
                            "gfu-field__input",
                            { "gfu-field__input--small": _vm.small },
                          ],
                          attrs: {
                            placeholder: _vm.placeholder,
                            maxlength: _vm.maxInputLength,
                            name: _vm.name,
                            autocomplete: "off",
                            "aria-autocomplete": "both",
                            "aria-haspopup": "false",
                            "data-qa": "auto-complete-field:Search",
                          },
                          domProps: { value: _vm.searchTerm },
                          on: {
                            focus: _vm.onInputFocus,
                            blur: _vm.onInputBlur,
                            keyup: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                )
                                  return null
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectNextItem(-1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp",
                                  ])
                                )
                                  return null
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectNextItem(-1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "esc",
                                    27,
                                    $event.key,
                                    ["Esc", "Escape"]
                                  )
                                )
                                  return null
                                return _vm.onCollapse.apply(null, arguments)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onEnter.apply(null, arguments)
                              },
                              _vm.onInput,
                            ],
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.searchTerm = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.searchTerm
                          ? _c("button", {
                              class: [
                                "gfu-field-auto-complete__clear gfu-btn _fa _fa--times",
                                {
                                  "gfu-field-auto-complete__clear--small":
                                    _vm.small,
                                },
                              ],
                              attrs: {
                                "data-qa": "auto-complete-field-btn:Clear",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.clear.apply(null, arguments)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "toggle-container",
            {
              staticClass: "gfu-field-select__content",
              attrs: {
                tag: "div",
                tabindex: "1",
                "active-class": "gfu-field-select__content--expanded",
                "data-qa": "select-content",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "gfu-field-select__content-wrapper gfu-field-auto-complete__suggestions",
                },
                [
                  _c(
                    "ul",
                    {
                      ref: "itemList",
                      attrs: { "data-qa": "auto-complete-field:Suggestions" },
                    },
                    [
                      _vm.allowCreate
                        ? [
                            _vm.allowDuplicate || _vm.isNewItem
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "_pt-1 _pb-1",
                                    attrs: { tabindex: 0 },
                                    on: {
                                      keydown: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          )
                                            return null
                                          $event.preventDefault()
                                          return _vm.selectNextItem(0)
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          )
                                            return null
                                          $event.preventDefault()
                                          return _vm.selectPreviousItem(0)
                                        },
                                      ],
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.createItem.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "base-badge",
                                      {
                                        staticClass: "_my-1",
                                        attrs: {
                                          theme: "suggestion",
                                          "data-qa":
                                            "auto-complete-field-btn:CreateNew",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.createNewLabel) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.searchTerm) +
                                        "\n                        "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "gfu-field-select__group-header gfu-field-auto-complete__suggestions-header",
                                attrs: {
                                  "data-qa": "auto-complete-field:NoMatches",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.filteredItems.length
                                        ? _vm.matchingItemsLabel
                                        : _vm.noMatchesLabel
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.filteredItems, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: "matching-item-" + index,
                            attrs: {
                              tabindex: _vm.getItemIndex(index),
                              "data-qa":
                                "auto-complete-field-item:" + item.name,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectItem(item)
                              },
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.selectItem(item)
                              },
                              keydown: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  _vm.selectNextItem(_vm.getItemIndex(index))
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "up",
                                      38,
                                      $event.key,
                                      ["Up", "ArrowUp"]
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  _vm.selectPreviousItem(
                                    _vm.getItemIndex(index)
                                  )
                                },
                              ],
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.name) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      !_vm.allowCreate && !_vm.filteredItems.length
                        ? _c("li", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.isProcessing
                                    ? "Loading..."
                                    : _vm.noMatchesLabel
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("progress-overlay", {
                    attrs: { visible: _vm.isProcessing },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }