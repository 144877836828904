import { googlePayConfig, externalScripts } from '@/Config';
import { cardBrands } from '@/Utils/AdyenUtil';
import { loadScript } from '@/Utils/ScriptUtility';

const { merchantId, isEnabled } = googlePayConfig;

const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
};

// https://developers.google.com/pay/api/web/reference/request-objects#CardParameters
const brandMap = {
    [cardBrands.MasterCard]: 'MASTERCARD',
    [cardBrands.Visa]: 'VISA',
    [cardBrands.Amex]: 'AMEX',
    [cardBrands.JCB]: 'JCB',
    [cardBrands.Discover]: 'DISCOVER',
    [cardBrands.Maestro]: 'MAESTRO',
};

const allowedCardBrands = [cardBrands.MasterCard, cardBrands.Visa, cardBrands.Amex, cardBrands.JCB, cardBrands.Discover];

const getPaymentMethod = (adyenConfig) => ({
    tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            'gateway': 'adyen',
            gatewayMerchantId: adyenConfig.gatewayMerchantId,
        },
    },
    type: 'CARD',
    parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: allowedCardBrands.map(brand => brandMap[brand]),
    },
});

const errorMessage = 'Google Pay Service has not been initialized!';

export default class GooglePayService {
    isInitialized = false;
    paymentsClient = null;
    adyenConfig = {
        merchantName: null,
        gatewayMerchantId: null,
        useLiveMode: false,
    };

    constructor(adyenConfig) {
        this.adyenConfig = adyenConfig;
    }

    async initAsync() {
        if (!isEnabled) {
            return false;
        }
        const result = await this.loadScriptAsync();

        if (result) {
            this.paymentsClient = new google.payments.api
                .PaymentsClient({ environment: this.adyenConfig.useLiveMode ? 'PRODUCTION' : 'TEST' });
            this.isInitialized = true;
            return true;
        }

        return false;
    }

    async loadScriptAsync() {
        return await loadScript(externalScripts.googlePayUrl);
    }

    async invokeAsync(currencyCode, amount) {
        if (!this.isInitialized) {
            throw new Error(errorMessage);
        }

        const paymentDataRequest = {
            ...baseRequest,
            allowedPaymentMethods: [getPaymentMethod(this.adyenConfig)],
            transactionInfo: {
                countryCode: 'PL',
                currencyCode,
                totalPriceStatus: 'FINAL',
                totalPrice: amount,
            },
            merchantInfo: {
                merchantName: this.adyenConfig.merchantName,
                merchantId,
            },
            emailRequired: true,
        };

        return await this.paymentsClient.loadPaymentData(paymentDataRequest);
    }

    async isAvailableAsync() {
        if (!isEnabled) {
            return false;
        }

        if (!this.isInitialized) {
            return false;
        }

        const request = {
            ...baseRequest,
            allowedPaymentMethods: [getPaymentMethod(this.adyenConfig)],
        };

        const isReadyToPayResponse = await this.paymentsClient.isReadyToPay(request);

        if (!isReadyToPayResponse.result) {
            return false;
        }

        return isReadyToPayResponse.paymentMethodPresent !== false;
    }
}