var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "modal-box",
        {
          attrs: {
            "is-visible": _vm.isVisible,
            title: "Change display currency",
            icon: "sync",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "gfu-link gfu-link--accent",
                        attrs: { href: "#", "data-qa": "modal-button:cancel" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onCancel.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--accent gfu-btn--compact gfu-btn--soft gfu-btn--wide _ml-3",
                        attrs: {
                          type: "button",
                          "data-qa": "modal-button:Continue",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onAccept.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n            Continue\n        ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2883591138
          ),
        },
        [
          _c("p", [
            _vm._v(
              "\n        Changing the display currency will allow you to see goal and pledge amounts converted into "
            ),
            _c("strong", [_vm._v(_vm._s(_vm.currency.symbol))]),
            _vm._v(".\n    "),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        NOTE: The displayed amounts are approximate currency conversions. Pledges are collected in the native currency of the project.\n    "
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }