var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_mt-a" },
    [
      _vm.phaseStartedAt
        ? _c("timer-component", { attrs: { "end-time": _vm.phaseStartedAt } })
        : _c("div", [
            _c(
              "div",
              { attrs: { "data-qa": "project-card:PreviewDetailsFollowText" } },
              [
                !_vm.isFollowing
                  ? [
                      _vm.hasPromoItem
                        ? _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.Homepage
                                    .ProjectCardFollowWithGiftTextPart1,
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "gfu-link _tc--accent",
                                  attrs: {
                                    "format-key": "0",
                                    "data-qa":
                                      "project-card:PreviewDetailsFollowLink",
                                  },
                                  on: { click: _vm.onClick },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.Homepage
                                        .ProjectCardFollowWithGiftTextPart2
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { attrs: { "format-key": "1" } }, [
                                _vm._v(_vm._s(_vm.promoTypeLabel)),
                              ]),
                            ]
                          )
                        : _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.Homepage
                                    .ProjectCardFollowWithNoGiftTextPart1,
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "gfu-link _tc--accent",
                                  attrs: {
                                    "format-key": "0",
                                    "data-qa":
                                      "project-card:PreviewDetailsFollowLink",
                                  },
                                  on: { click: _vm.onClick },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.Homepage
                                        .ProjectCardFollowWithNoGiftTextPart2
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ]
                  : _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.Resources.Homepage.ProjectCardIsFollowingText
                          ) +
                          "\n            "
                      ),
                    ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "gfu-bt gfu-bt--caption _tc--lighter",
                attrs: {
                  "data-qa": "project-card:PreviewDetailsFollowCaption",
                },
              },
              [_vm._v(_vm._s(_vm.caption))]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }