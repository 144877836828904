var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-view",
    { attrs: { "is-processing": _vm.isProcessing } },
    [
      _c("account-navigation", { attrs: { "page-identity": _vm.pageID } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
        [
          _c("search-title", {
            attrs: {
              title: _vm.Resources.SubscriptionManagement.PageHeader,
              "total-item-count": _vm.userSubscriptionCount,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "_mb-2" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.Resources.SubscriptionManagement.PageIntroParagraph
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("search-field", {
            attrs: { value: _vm.searchingPhase },
            on: { input: _vm.onInput },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-grid gfu-grid--gutter" },
            [
              _vm.showSystemNotificationFields
                ? _c(
                    "system-notification-management-settings",
                    _vm._b(
                      {
                        on: {
                          "system-notification-preference-change":
                            _vm.updateUserSubscription,
                        },
                      },
                      "system-notification-management-settings",
                      _vm.systemNotificationProps,
                      false
                    )
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.subscribedCreatorsToShow, function (creator) {
                return _c(
                  "subscribed-creator-management-settings",
                  _vm._b(
                    {
                      key: creator.creatorID,
                      on: {
                        "project-notification-preference-change":
                          _vm.updateUserSubscription,
                      },
                    },
                    "subscribed-creator-management-settings",
                    creator,
                    false
                  )
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.canLoadMoreRows
            ? _c("load-more-trigger", { on: { "load-more": _vm.loadMoreRows } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }