var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.forceShow || _vm.actionsList(_vm.$slots.default).length,
          expression: "forceShow || actionsList($slots.default).length",
        },
      ],
      staticClass: "gfu-balloon__wrapper",
    },
    [
      _vm.actionsList(_vm.$slots.default).length === 1
        ? [
            _c(
              "tooltip",
              {
                ref: "tooltip",
                staticClass: "_flex",
                attrs: {
                  text: _vm.tooltipText,
                  "data-qa": `action-button:${_vm.tooltipQa}`,
                },
              },
              [_vm._t("default")],
              2
            ),
          ]
        : [
            _c(
              "span",
              { ref: "balloon-trigger" },
              [
                _c("toggle-trigger", {
                  staticClass:
                    "gfu-btn--toggler gfu-btn gfu-btn--circle _fa _fa--ellipsis-v",
                  attrs: {
                    tag: "a",
                    href: "",
                    "inactive-class": "gfu-btn--light-alt",
                    "active-class": "gfu-btn--primary",
                    "collapse-on-scroll":
                      _vm.collapseOnScroll &&
                      _vm.actionsList(_vm.$slots.default).length > 1,
                    "before-active": _vm.beforeOpen,
                    "data-qa": "dropdown-group:CircleMenu",
                  },
                  on: { click: _vm.onTriggerClick },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "toggle-container",
              {
                ref: "balloon-content",
                staticClass:
                  "gfu-balloon gfu-balloon--fixed gfu-balloon--center gfu-balloon--up gfu-balloon--no-header is-hidden",
                class: _vm.resolveBallonClass(),
                attrs: {
                  tag: "div",
                  tabindex: "0",
                  "active-class": "is-open",
                  "data-qa": "dropdown-group:Content",
                },
                on: { "active-change": _vm.onActionsBalloonActiveChange },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "gfu-balloon__content",
                    on: { click: _vm.onContentClick },
                  },
                  [_vm._t("default")],
                  2
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }