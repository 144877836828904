import AddProductToCart from './AddProductToCart.vue';
import AddProductToCartModalManager from './AddProductToCartModalManager.vue';
import AddProductToCartSuccessModal from './AddProductToCartSuccessModal.vue';
import CartBox from './CartBox.vue';
import CartTable from './CartTable.vue';
import CartTableItemRow from './CartTableItemRow.vue';
import CartTableItemRowBase from './CartTableItemRowBase.vue';
import CartTableSetItemRow from './CartTableSetItemRow.vue';
import CartTableTotalRow from './CartTableTotalRow.vue';
import GoToCheckoutModal from './GoToCheckoutModal.vue';

export default {
    AddProductToCart,
    AddProductToCartModalManager,
    AddProductToCartSuccessModal,
    CartBox,
    CartTable,
    CartTableItemRow,
    CartTableItemRowBase,
    CartTableSetItemRow,
    CartTableTotalRow,
    GoToCheckoutModal,
};