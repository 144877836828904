import ServiceBase from './ServiceBase';

export default class ContactService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    sendContactNotification({
        userEmail,
        projectID,
        categoryKey,
        message,
        reCaptchaToken,
        reCaptchaAction,
    }) {
        return this.request('post', 'contact/sendContactNotification', {
            userEmail,
            projectID,
            categoryKey,
            message,
            reCaptchaToken,
            reCaptchaAction,
        });
    }
}