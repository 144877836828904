var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout__main gfu-layout__main--extranarrow" },
    [
      _vm.isProjectsVisible
        ? _c(
            "div",
            {
              staticClass: "_mt-6",
              attrs: { "data-qa": "user-profile:Projects" },
            },
            [
              _c("h2", { staticClass: "gfu-hd gfu-hd--h1" }, [
                _vm._v(_vm._s(_vm.Resources.Profile.SectionProjectsHeader)),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(_vm._s(_vm.Resources.Profile.SectionProjectsParagraph)),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "_tc--accent gfu-link-underline",
                  attrs: { to: { name: "user-profile-projects" } },
                },
                [
                  _c("span", { staticClass: "gfu-link-underline__content" }, [
                    _vm._v(_vm._s(_vm.Resources.Profile.SectionViewAllAction)),
                  ]),
                  _vm._v(" "),
                  _c("base-icon", {
                    attrs: { name: "angle-right", size: "sm" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasProjects
                ? _c(
                    "div",
                    {
                      staticClass: "gfu-card-list gfu-scrollable _mb-n3 _mt-2",
                    },
                    _vm._l(_vm.projectsItemsSliced, function (project) {
                      return _c(
                        "div",
                        {
                          key: project.projectID,
                          staticClass:
                            "gfu-card-list__item gfu-5of6 gfu-2of5--m gfu-1of3--l gfu-1of3--xl",
                        },
                        [
                          _c(
                            "project-card",
                            _vm._b(
                              { attrs: { "show-labels": true } },
                              "project-card",
                              project,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "box",
                    { staticClass: "_mt-5" },
                    [
                      _c(
                        "empty-state",
                        {
                          attrs: {
                            title:
                              _vm.Resources.Profile.EmptyStateProjectsTitle,
                            "data-qa": "user-profile:EmptyState",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "_mt-3",
                              attrs: {
                                "data-qa": "user-profile-projects:Title",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.Profile
                                      .EmptyStateProjectParagraphOwnProfile
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-button",
                            {
                              staticClass: "_mt-3",
                              attrs: {
                                tag: "a",
                                href: _vm.searchUrl,
                                theme: "accent",
                                "data-qa": "user-profile-projects:Explore",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.Profile
                                      .EmptyStateProjectsExploreAction
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFollowersVisible
        ? _c(
            "div",
            {
              staticClass: "_mt-6",
              attrs: { "data-qa": "user-profile:Followers" },
            },
            [
              _c("h3", { staticClass: "gfu-hd gfu-hd--h1" }, [
                _vm._v(_vm._s(_vm.Resources.Profile.SectionFollowersHeader)),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.Resources.format(
                      _vm.Resources.Profile.SectionFollowersParagraph,
                      _vm.nickname
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "_tc--accent gfu-link-underline",
                  attrs: { to: { name: "user-profile-followers" } },
                },
                [
                  _c("span", { staticClass: "gfu-link-underline__content" }, [
                    _vm._v(_vm._s(_vm.Resources.Profile.SectionViewAllAction)),
                  ]),
                  _vm._v(" "),
                  _c("base-icon", {
                    attrs: { name: "angle-right", size: "sm" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasFollowers
                ? _c(
                    "div",
                    {
                      staticClass: "gfu-card-list gfu-scrollable _mb-n3 _mt-2",
                    },
                    _vm._l(_vm.followersItems, function (follower) {
                      return _c(
                        "div",
                        {
                          key: follower.urlName,
                          staticClass:
                            "gfu-card-list__item gfu-5of6 gfu-2of5--m gfu-1of3--l gfu-1of3--xl",
                          attrs: {
                            "data-qa": `user-card-urlname:${follower.urlName}`,
                          },
                        },
                        [
                          _c(
                            "user-profile-card",
                            _vm._b({}, "user-profile-card", follower, false)
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "box",
                    { staticClass: "_mt-5" },
                    [
                      _c(
                        "empty-state",
                        {
                          attrs: {
                            title:
                              _vm.Resources.Profile.EmptyStateFollowersTitle,
                            "data-qa": "user-profile:EmptyState",
                          },
                        },
                        [
                          _c("div", { staticClass: "_mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.Profile
                                  .EmptyStateFollowersParagraph
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFollowedVisible
        ? _c(
            "div",
            {
              staticClass: "_mt-6",
              attrs: { "data-qa": "user-profile:Following" },
            },
            [
              _c("h3", { staticClass: "gfu-hd gfu-hd--h1" }, [
                _vm._v(_vm._s(_vm.Resources.Profile.SectionFollowingHeader)),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.Resources.format(
                      _vm.Resources.Profile.SectionFollowingParagraph,
                      _vm.nickname
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "_tc--accent gfu-link-underline",
                  attrs: { to: { name: "user-profile-following" } },
                },
                [
                  _c("span", { staticClass: "gfu-link-underline__content" }, [
                    _vm._v(_vm._s(_vm.Resources.Profile.SectionViewAllAction)),
                  ]),
                  _vm._v(" "),
                  _c("base-icon", {
                    attrs: { name: "angle-right", size: "sm" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasFollowed
                ? _c(
                    "div",
                    {
                      staticClass: "gfu-card-list gfu-scrollable _mb-n3 _mt-2",
                    },
                    _vm._l(_vm.followingItems, function (follower) {
                      return _c(
                        "div",
                        {
                          key: follower.urlName,
                          staticClass:
                            "gfu-card-list__item gfu-5of6 gfu-2of5--m gfu-1of3--l gfu-1of3--xl",
                          attrs: {
                            "data-qa": `user-card-urlname:${follower.urlName}`,
                          },
                        },
                        [
                          _c(
                            "user-profile-card",
                            _vm._b({}, "user-profile-card", follower, false)
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "box",
                    { staticClass: "_mt-5" },
                    [
                      _c(
                        "empty-state",
                        {
                          attrs: {
                            title:
                              _vm.Resources.Profile.EmptyStateFollowingTitle,
                            "data-qa": "user-profile:EmptyState",
                          },
                        },
                        [
                          _c("div", { staticClass: "_mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.Profile
                                  .EmptyStateFollowingParagraph
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCommentsVisible
        ? _c(
            "div",
            {
              staticClass: "_mt-6",
              attrs: { "data-qa": "user-profile:Comments" },
            },
            [
              _c("h3", { staticClass: "gfu-hd gfu-hd--h1" }, [
                _vm._v(_vm._s(_vm.Resources.Profile.SectionCommentsHeader)),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.Resources.format(
                      _vm.Resources.Profile.SectionCommentsParagraph,
                      _vm.nickname
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "_tc--accent gfu-link-underline",
                  attrs: { to: { name: "user-profile-comments" } },
                },
                [
                  _c("span", { staticClass: "gfu-link-underline__content" }, [
                    _vm._v(_vm._s(_vm.Resources.Profile.SectionViewAllAction)),
                  ]),
                  _vm._v(" "),
                  _c("base-icon", {
                    attrs: { name: "angle-right", size: "sm" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasComments
                ? _c(
                    "box",
                    { staticClass: "_mt-5" },
                    _vm._l(_vm.commentsItemsSliced, function (item) {
                      return _c(
                        "comment-item-preview",
                        _vm._b(
                          {
                            key: item.commentID,
                            staticClass: "_mb-4",
                            attrs: {
                              nickname: _vm.nickname,
                              "avatar-url": _vm.avatarUrl,
                              "user-url-name": _vm.userUrlName,
                              "user-id": _vm.userID,
                              "data-qa": `project-comment-ID:${item.commentID}`,
                            },
                          },
                          "comment-item-preview",
                          item,
                          false
                        )
                      )
                    }),
                    1
                  )
                : _c(
                    "box",
                    { staticClass: "_mt-5" },
                    [
                      _c(
                        "empty-state",
                        {
                          attrs: {
                            title:
                              _vm.Resources.Profile.EmptyStateCommentsTitle,
                            "data-qa": "user-profile:EmptyState",
                          },
                        },
                        [
                          _c("div", { staticClass: "_mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.Profile
                                  .EmptyStateCommentsParagraphOwnProfile
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isGuestEmptyStateVisible
        ? _c(
            "box",
            { staticClass: "_mt-6" },
            [
              _c(
                "empty-state",
                {
                  attrs: {
                    title: _vm.Resources.Profile.EmptyStateProfileViewTitle,
                    "data-qa": "user-profile:EmptyState",
                  },
                },
                [
                  _c("div", { staticClass: "_mt-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.format(
                          _vm.Resources.Profile.EmptyStateProfileViewParagraph,
                          _vm.nickname
                        )
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }