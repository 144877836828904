import Formatting from '@/Utils/Formatting';
import { pluralize } from '@/Utils/ResourceUtility';

const Resources = window.__RESOURCES__;

const module = {
    format() {
        return Formatting.String.format(...arguments);
    },
    pluralize(count, str) {
        return pluralize(count, str);
    },
    ...Resources,
};

export default module;