var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper" }, [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
      _c("div", {
        staticClass: "gfu-grid__cell gfu-1of3--l gfu-1of6--m gfu-1of1",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-grid__cell gfu-1of3--l gfu-4of6--m gfu-1of1" },
        [
          _c("div", { staticClass: "gfu-box gfu-box--thick _mt-6 _mb-3" }, [
            _c(
              "h1",
              { staticClass: "gfu-heading gfu-heading--soft gfu-box__title" },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.Resources.ResetPassword.ResetPasswordHeader) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.canChangePassword
              ? _c("div", [
                  _c("p", { staticClass: "_tac" }, [
                    _vm._v(_vm._s(_vm.getMessage)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("div", { staticClass: "gfu-fields" }, [
                        _c(
                          "fieldset",
                          [
                            _c(
                              "field",
                              {
                                attrs: {
                                  name: "model.newPassword",
                                  "display-name":
                                    _vm.Resources.ResetPassword
                                      .NewPasswordFieldLabel,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.newPassword,
                                      expression: "model.newPassword",
                                    },
                                  ],
                                  staticClass: "gfu-field__input",
                                  attrs: {
                                    type: "password",
                                    name: "newPassword",
                                  },
                                  domProps: { value: _vm.model.newPassword },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.model,
                                        "newPassword",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "field",
                              {
                                attrs: {
                                  name: "model.repeatNewPassword",
                                  "display-name":
                                    _vm.Resources.ResetPassword
                                      .RepeatPasswordFieldLabel,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.model.repeatNewPassword,
                                      expression: "model.repeatNewPassword",
                                    },
                                  ],
                                  staticClass: "gfu-field__input",
                                  attrs: {
                                    type: "password",
                                    name: "repeatNewPassword",
                                  },
                                  domProps: {
                                    value: _vm.model.repeatNewPassword,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.model,
                                        "repeatNewPassword",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "_tac _mt-6" },
                              [
                                _c(
                                  "field",
                                  [
                                    _c(
                                      "base-button",
                                      {
                                        attrs: {
                                          theme: "accent",
                                          display: "block",
                                          width: "wide",
                                        },
                                        on: { click: _vm.submit },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.Resources.ResetPassword
                                                .ResetPasswordSubmitButton
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("progress-overlay", {
                        attrs: { visible: _vm.isProcessing },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "gfu-box--error _tac" }, [
                  _c("p", [_vm._v(_vm._s(_vm.getMessage))]),
                ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "gfu-grid__cell gfu-1of3--l gfu-1of6--m gfu-1of1",
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }