var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.notificatorClassList, attrs: { "data-qa": _vm.qaKey } },
    [
      _vm.hasImage
        ? _c("div", { staticClass: "gfu-notificator-item-image" }, [
            _c("div", { staticClass: "gfu-notificator-item-image-box" }, [
              _c(
                "div",
                { staticClass: "gfu-notificator-item-image-box-inner" },
                [
                  _c("img", {
                    staticClass: "_fo",
                    attrs: { src: _vm.imageUrl, alt: _vm.title },
                  }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c("span", { staticClass: "gfu-notificator-item__title" }, [
            _c("span", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(_vm._s(_vm.titlePostfix) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldSplitMessageAndTitle ? _c("br") : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "gfu-notificator-item__message",
          attrs: { "data-qa": "notificator-message" },
        },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.message))]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.action
        ? [
            _vm.action.callback
              ? _c("span", [
                  _vm._v("("),
                  _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--emphasized",
                      attrs: {
                        href: "",
                        "data-qa": `notificator-button:${_vm.action.title}`,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.action.callback.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.action.title))]
                  ),
                  _vm._v(")"),
                ])
              : _vm.action.route
              ? _c(
                  "span",
                  [
                    _vm._v("("),
                    _c(
                      "router-link",
                      {
                        staticClass: "gfu-link gfu-link--emphasized",
                        attrs: { to: _vm.action.route },
                      },
                      [_vm._v(_vm._s(_vm.action.title))]
                    ),
                    _vm._v(")"),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.canClose
        ? _c("span", {
            staticClass:
              "gfu-notificator-item__close-button _fa _fa--window-close",
            attrs: { "data-qa": "notificator-close-btn" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClose.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }