var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "_mt-3 _ba-2 _rounded--common",
        _vm.value === _vm.checkedValue ? "_bc--primary" : "_bc--input",
      ],
      attrs: { "data-qa": `dcc-currency-section:${_vm.labelText}` },
      on: { click: _vm.handleClick },
    },
    [
      _c("div", { staticClass: "_pos-r _cur-p _pa-3" }, [
        _vm.labelText
          ? _c(
              "div",
              { staticClass: "gfu-dcc-wrapper__badges" },
              [
                _c(
                  "base-badge",
                  {
                    staticClass: "_mr-1 _my-1",
                    attrs: {
                      theme: _vm.theme,
                      "data-qa": `dcc-badge:${_vm.labelText}`,
                    },
                  },
                  [_vm._v(_vm._s(_vm.labelText))]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "_flex _ai-c" },
          [
            _c("checkbox-field", {
              staticClass: "_mt-0 _mr-2",
              attrs: {
                value: _vm.value,
                "checked-value": _vm.checkedValue,
                name: _vm.checkboxGroupName,
                condensed: true,
                type: "radio",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_flexcol", attrs: { "data-qa": "value" } },
              [_vm._t("default")],
              2
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }