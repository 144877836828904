var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tooltip",
    { attrs: { text: _vm.tooltipText } },
    [
      _c(
        "base-button",
        _vm._g(
          _vm._b(
            { attrs: { theme: "accent", shape: "soft", display: "block" } },
            "base-button",
            { ..._vm.$attrs },
            false
          ),
          _vm.$listeners
        ),
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }