import { defineStore } from 'pinia';
import InitialState from '@/InitialState';

export const useUserLocationStore = defineStore({
    id: 'userLocation',
    state: () => ({
        userPreferredLocation: InitialState.userAdditionalInfo.userPreferredLocation,
        isUserLocationModalVisible: false,
    }),
    actions: {
        showUserLocationModal() {
            this.isUserLocationModalVisible = true;
        },
        hideUserLocationModal() {
            this.isUserLocationModalVisible = false;
        },
    },
    getters: {
        userCurrentLocationName() {
            return this.userPreferredLocation.locationName;
        },
        userSavedLocationId() {
            return this.userPreferredLocation.systemLocationID;
        },
        userSavedSublocationId() {
            return this.userPreferredLocation.systemSubLocationID;
        },
    },
});