var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "gfu-project-rankings" }, [
        _c(
          "div",
          { staticClass: "gfu-project-rankings__title" },
          [_vm._t("title")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gfu-project-rankings-selectors" },
          [
            _c("option-field", {
              staticClass: "gfu-project-rankings-selectors__option",
              attrs: {
                options: _vm.timeSpanOptions,
                "data-qa": "time-span-options",
              },
              model: {
                value: _vm.model.period,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "period", $$v)
                },
                expression: "model.period",
              },
            }),
            _vm._v(" "),
            _vm.isTablet
              ? _c("option-field", {
                  staticClass: "gfu-project-rankings-selectors__option",
                  attrs: {
                    options: _vm.rankingTypeOptions,
                    "data-qa": "time-span-field",
                  },
                  model: {
                    value: _vm.model.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "type", $$v)
                    },
                    expression: "model.type",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isTablet
              ? _c("option-field", {
                  staticClass: "gfu-project-rankings-selectors__option",
                  attrs: {
                    options: _vm.categoryTypeOptions,
                    "show-placeholder": false,
                    "data-qa": "category-options",
                  },
                  model: {
                    value: _vm.model.projectCategoryID,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "projectCategoryID", $$v)
                    },
                    expression: "model.projectCategoryID",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "section-expander",
        [
          _vm.isInitialized
            ? [
                _vm.isTablet
                  ? _c(
                      "div",
                      { staticClass: "_grid _grid-cols-1" },
                      [
                        _c("rankings-list", {
                          attrs: {
                            items: _vm.projects,
                            title: _vm.mobileRankingTexts.title,
                            icon: _vm.mobileRankingTexts.icon,
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "_grid _grid-cols-2--l _gap-6" },
                      [
                        _c("rankings-list", {
                          attrs: {
                            items: _vm.fundingRankingsList,
                            title:
                              _vm.Resources.Homepage
                                .RankingsFundingCategoryOption,
                            icon: "money-bill-trend-up",
                            "data-qa": "ranking-list:Funding",
                          },
                        }),
                        _vm._v(" "),
                        _c("rankings-list", {
                          attrs: {
                            items: _vm.audienceRankingsList,
                            title:
                              _vm.Resources.Homepage
                                .RankingsAudienceCategoryOption,
                            icon: "users",
                            "data-qa": "ranking-list:Audience",
                          },
                        }),
                      ],
                      1
                    ),
              ]
            : [
                _vm.isTablet
                  ? _c(
                      "div",
                      { staticClass: "_grid _grid-cols-1" },
                      [_c("skeleton-ranking-list")],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "_grid _grid-cols-2--l _gap-6" },
                      [
                        _c("skeleton-ranking-list"),
                        _vm._v(" "),
                        _c("skeleton-ranking-list"),
                      ],
                      1
                    ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }