var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flexcol _gap-1" },
    _vm._l(_vm.itemsArray, function (item) {
      return _c(
        "div",
        {
          key: item,
          staticClass:
            "_flex _bxsh--base _ai-c _jc-sb _px-2 _py-2 _bgc--white _rounded--common _pos-r gfu-skeleton--has-animation",
        },
        [
          _c("skeleton-line", {
            staticClass: "_flex-basis-70",
            attrs: { height: 20 },
          }),
          _vm._v(" "),
          _c("skeleton-line", {
            staticClass: "_flex-basis-10",
            attrs: { height: 20 },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }