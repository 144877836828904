var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-credit-card-overview gfu-credit-card-overview--pledge",
      attrs: { "data-qa": "card-overview" },
    },
    [
      _c(
        "div",
        { staticClass: "_flex _gap-1 _ai-c" },
        [
          _c("base-image", {
            attrs: {
              src: _vm.paymentImageUrl,
              alt: _vm.altText,
              "data-qa": "card-image",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "gfu-credit-card-overview__label",
              attrs: { "data-qa": "payment-method-label" },
            },
            [_vm._v(_vm._s(_vm.paymentMethodLabel))]
          ),
          _vm._v(" "),
          _vm.externalPaymentDataSource
            ? _c("span", { staticClass: "_ml-1" }, [
                _vm._v("(" + _vm._s(_vm.externalPaymentDataSource) + ")"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }