var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: _vm.Resources.Comments.ReportCommentModalTitle,
        icon: "flag",
        "is-processing": _vm.isProcessing,
        "is-visible": _vm.isVisible,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: { href: "", "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "icon-base-button",
                {
                  attrs: {
                    size: "default",
                    width: "compact",
                    "text-transform": "none",
                    wide: true,
                    bold: true,
                    theme: "accent",
                    icon: "check",
                    enabled: !_vm.isProcessing,
                    "data-qa": "modal-button:Save",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalSaveButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c("p", { staticClass: "_mb-3" }, [
            _vm._v(_vm._s(_vm.Resources.Comments.ReportCommentModalParagraph)),
          ]),
          _vm._v(" "),
          _c("option-field", {
            attrs: {
              name: "model.reason",
              "display-name":
                _vm.Resources.Comments.ReportCommentModalReasonFieldLabel,
              options: _vm.availableReportReasons,
              "no-option-capitalize": true,
              "data-qa": "report-project-comment-model:Reason",
            },
            model: {
              value: _vm.model.reason,
              callback: function ($$v) {
                _vm.$set(_vm.model, "reason", $$v)
              },
              expression: "model.reason",
            },
          }),
          _vm._v(" "),
          _vm.shouldShowReasonDescription
            ? _c("text-area-field", {
                attrs: {
                  name: "model.reasonDescription",
                  "display-name":
                    _vm.Resources.Comments
                      .ReportCommentModalReasonDescriptionFieldLabel,
                  "data-qa": "modal-field:ReportReasonDescription",
                },
                model: {
                  value: _vm.model.reasonDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "reasonDescription", $$v)
                  },
                  expression: "model.reasonDescription",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }