
    import Vue from 'vue';
    import { capitalize } from '@/Utils/TextUtility.js';

    const Wrapper = Vue.extend({
        abstract: true,
        props: {
            vnodes: { type: Function, default: null },
        },
        data: vm => ({
            updatedNodes: vm.vnodes,
        }),
        render(h) {
            // VNodes from scoped slots function
            const nodes = this.updatedNodes && this.updatedNodes();
            if (!nodes) return h();
            return nodes.length < 2 && !nodes[0].text
                ? nodes
                : h('div', nodes);
        },
        destroyed() {
            this.$el.parentNode.removeChild(this.$el);
        },
    });

    export default {
        name: 'Teleport',
        props: {
            to: {
                type: [String, HTMLElement], default: '#teleport',
            },
            disabled: {
                type: Boolean, default: false,
            },
        },
        mounted() {
            if (!this.disabled) {
                if (!this.getTargetEl()) {
                    this.createTargetEl();
                }

                this.mount();
            }
        },
        updated() {
            if (this.container) {
                this.container.updatedNodes = this.$scopedSlots.default;
            }
        },
        watch: {
            disabled: {
                handler(disabled) {
                    if (disabled) {
                        this.unmount();
                    }
                },
            },
        },
        methods: {
            getTargetEl() {
                if (this.to instanceof HTMLElement) {
                    return this.to;
                }
                return document.querySelector(this.to);
            },
            createTargetEl() {
                const body = document.querySelector('body');
                const child = document.createElement('div');
                child.setAttribute('id', this.to.substring(1));
                body.appendChild(child);
            },
            mount() {
                const targetEl = this.getTargetEl();
                const el = document.createElement('div');
                targetEl.appendChild(el);
                this.container = new Wrapper({
                    name: `Teleport.${ capitalize(this.to.substring(1)) }`,
                    el,
                    parent: this,
                    propsData: {
                        vnodes: this.$scopedSlots.default,
                    },
                });
            },
            unmount() {
                if (this.container) {
                    this.container.$destroy();
                    delete this.container;
                }
            },
        },
        render(h) {
            if (this.disabled) {
                const nodes = this.$scopedSlots && this.$scopedSlots.default();
                if (!nodes) return h();
                return nodes.length < 2 && !nodes[0].text ? nodes : h('div', nodes);
            }
            return h();
        },
        beforeDestroy() {
            this.unmount();
        },
    };
