var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    _vm._g(
      {
        class: [
          "gfu-comment-streams__button",
          { "gfu-btn--border-thin": _vm.isThin },
        ],
        attrs: {
          weight: "thin",
          lowercase: true,
          size: "micro",
          shape: "soft",
          theme: "default",
          "data-qa": "comments-stream:FilterTag",
        },
      },
      _vm.$listeners
    ),
    [
      _vm._v("\n    " + _vm._s(_vm.name) + "\n    "),
      _vm.isSelected
        ? _c("base-icon", {
            staticClass:
              "gfu-btn__icon gfu-btn__icon--right gfu-btn__icon--light",
            attrs: { name: "times", "data-qa": "comment-filter-item:Remove" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }