var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("search-header", {
    attrs: { narrow: _vm.narrow },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ isStuck }) {
          return [
            _c(
              "div",
              { class: ["gfu-project-search-header", { "is-stuck": isStuck }] },
              [
                _c(
                  "div",
                  { staticClass: "gfu-project-search-header__content _mb-2" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "_ttr",
                        attrs: {
                          theme: "searchPrimary",
                          width: "compact",
                          weight: "thin",
                          shadow: "tight",
                          "data-qa": "filters-button",
                        },
                        on: { click: _vm.onFiltersClick },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "_fa _fa--sliders-simple _tc--accent _mr-1",
                        }),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.Resources.Search.SearchFiltersHeader)
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.selectedFilters.length > 0
                          ? [
                              _vm._v(
                                "\n                    (" +
                                  _vm._s(_vm.selectedFilters.length) +
                                  ")\n                "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "base-button",
                      {
                        staticClass: "_ttr",
                        attrs: {
                          theme: "searchPrimary",
                          width: "compact",
                          weight: "thin",
                          shadow: "tight",
                          "data-qa": "sorting-options-button",
                        },
                        on: { click: _vm.onSortClick },
                      },
                      [
                        _c("span", {
                          staticClass: "_fa _fa--bars-sort _tc--accent _mr-1",
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.Resources.Search.SearchFiltersSortLabel)
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "strong",
                          {
                            attrs: {
                              "data-qa":
                                "sorting-options-button-selected-sorting",
                            },
                          },
                          [_vm._v(_vm._s(_vm.sortDisplayName))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.filtersWithDisplayNames, function (filter) {
                      return _c(
                        "base-button",
                        {
                          key: filter.key,
                          staticClass: "_ttr",
                          attrs: {
                            width: "compact",
                            weight: "thin",
                            shadow: "tight",
                            theme: "searchSecondary",
                            "data-qa": "filter-button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDeleteClick(filter)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: {
                                "data-qa": `filter-group:${filter.group}`,
                              },
                            },
                            [_vm._v(_vm._s(filter.group) + ":")]
                          ),
                          _vm._v(" "),
                          _c(
                            "strong",
                            {
                              attrs: {
                                "data-qa": `filter-label:${filter.displayName}`,
                              },
                            },
                            [_vm._v(_vm._s(filter.displayName))]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass:
                              "_fa _fa--window-close _ml-1 _tc--lighter",
                            attrs: { "data-qa": "close-button" },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }