import { mapState } from 'pinia';
import { useUserContextStore } from '@/Stores/Web/userContextStore';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';

import { promoItemTypes, projectPhases } from '@/Models.js';
import { millisecondsInDay } from '@/Utils/DateUtility';
import FollowProjectHelpers from '@/Mixins/FollowProjectHelpers.js';
import { useProjectStore } from '@/Stores/Web/projectStore';
import Resources from '@/Resources.js';

export default {
    name: 'PromoItemProvider',
    mixins: [FollowProjectHelpers],
    computed: {
        isVisible() {
            if (this.projectContext.phase === projectPhases.crowdfunding) {
                return this.userIsFollowingProject;
            }
            return true;
        },
        discountAmountFormatted() {
            return this.$format(this.promoItem.discountAmount, 'C2');
        },
        tooltip() {
            const tooltip = {
                isAuthenticatedAndFollow: Resources.ProjectHome.ProjectPromoItemAuthenticatedAndFollowsTooltip,
                isAuthenticatedAndNotFollow: Resources.ProjectHome.ProjectPromoItemAuthenticatedAndDoesntFollowTooltip,
                isNotAuthenticatedAndFollow: Resources.ProjectHome.ProjectPromoItemNotAuthenticatedAndFollowsTooltip,
                isNotAuthenticatedAndNotFollow: Resources.ProjectHome.ProjectPromoItemNotAuthenticatedAndDoesntFollowTooltip,
            };

            if (!this.userContext.isAuthenticated) {
                return !this.userIsFollowingProject
                    ? tooltip.isNotAuthenticatedAndNotFollow : tooltip.isNotAuthenticatedAndFollow;
            }

            return !this.userIsFollowingProject
                ? tooltip.isAuthenticatedAndNotFollow : tooltip.isAuthenticatedAndFollow;
        },
        label() {
            if (this.projectContext.project.phase === projectPhases.crowdfunding) {
                return Resources.ProjectHome.ProjectPromoItemBackNowLabel;
            }
            else {
                if (this.userIsFollowingProject) {
                    return Resources.ProjectHome.ProjectPromoItemIsFollowingLabel;
                }
                return Resources.ProjectHome.ProjectPromoItemFollowNowLabel;
            }
        },
        countdown() {
            const currentDate = new Date();
            const showCountdown = (this.campaignEnd - currentDate) / millisecondsInDay <= 3 && this.campaignEnd - currentDate > 0;
            return showCountdown ? this.campaignEnd : null;
        },
        campaignEnd() {
            const date = this.projectContext.project.campaignEnd;
            return date ? new Date(date) : null;
        },
        model() {
            const models = {
                discount: this.getDiscountModel,
                product: this.getProductModel,
            };

            const isPromoItemTypeNone = promoItemTypes[this.promoItem.type].name === promoItemTypes[promoItemTypes.none].name;

            if (promoItemTypes[this.promoItem.type].name && !isPromoItemTypeNone) {
                return models[promoItemTypes[this.promoItem.type].name.toLowerCase()]();
            }
        },
        userIsFollowingProject() {
            return this.isUserFollowingProject(this.projectContext.projectID);
        },
        ...mapState(useProjectContextStore, ['projectContext']),
        ...mapState(useProjectStore, ['promoItem']),
        ...mapState(useUserContextStore, ['userContext']),
    },
    methods: {
        getProductModel() {
            const { tooltip, promoItem, countdown, label } = this;
            const { productImageUrl, productName } = promoItem;
            return {
                tooltip,
                promoItem,
                countdown,
                label,
                image: { imageUrl: productImageUrl, alt: productName },
                contextLink: this.getRouteToProps(this.promoItem),
                userIsFollowingProject: this.userIsFollowingProject,
            };
        },
        getDiscountModel() {
            const { tooltip, promoItem, countdown, label } = this;
            return {
                tooltip,
                promoItem,
                countdown,
                label,
                image: { imageUrl: '/content/images/promo-item/discount.svg', alt: 'Discount' },
                discountAmountFormatted: this.discountAmountFormatted,
                userIsFollowingProject: this.userIsFollowingProject,
            };
        },
        getRouteToProps({ productID, productName, productUrl, type }) {
            if (type === promoItemTypes.product)  {
                return {
                    to: { name: 'product', params: { id: productID } },
                    title: productName,
                    href: productUrl,
                    inContext: true,
                };
            }
            return null;
        },
    },
    render() {
        return this.$scopedSlots.default({
            promoItem: this.promoItem,
            model: this.model,
            project: this.projectContext.project,
            isVisible: true,
        });
    },
};