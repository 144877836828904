var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("project-summary-box", {
    attrs: { "data-qa": "project-summary-box:Draft" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("div", [
              _c("span", {
                staticClass: "_ga _fa--2x _ga--gamefound-outline _tc--accent",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "_mt-1" }, [
                _c(
                  "h4",
                  { staticClass: "gfu-hd gfu-hd--h3" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.Resources.ProjectHome.CrowdfundingDraftInfoTitle
                        ) +
                        "\n                    "
                    ),
                    _c(
                      "tooltip",
                      {
                        attrs: {
                          text: _vm.Resources.ProjectHome
                            .ProjectSummaryBoxDraftInfoTooltip,
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "_fa _fa--info gfu-countdown__tooltip",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.campaignStartDate
              ? _c("project-summary-clock", {
                  staticClass: "_mt-3",
                  attrs: {
                    "elapsed-message":
                      _vm.Resources.ProjectHome
                        .ProjectSummaryBoxDraftCountdownElapsed,
                    "target-date": _vm.campaignStartDate,
                    title:
                      _vm.Resources.ProjectHome
                        .ProjectSummaryBoxDraftCountdownTitle,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.promoItem
              ? _c("project-promo-item", {
                  staticClass: "gfu-project-summary-promo-item",
                  attrs: {
                    "data-qa": `project-summary-promo-item:${_vm.promoItem.type}`,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c("project-summary-follow"),
            _vm._v(" "),
            _c("social-media-actions", { staticClass: "_mt-3 _tac" }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }