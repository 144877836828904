export function isInternetExplorer() {
    return !!window.document.documentMode;
}

export function getUA() {
    if (typeof navigator !== 'undefined'
        && typeof navigator['userAgent'] === 'string') {
        return navigator['userAgent'];
    }
    else {
        return '';
    }
}

export function getBrowserName(userAgent = getUA()) {
    const ua = userAgent.toLowerCase();
    if (ua.includes('edge/')) {
        return 'Edge';
    }
    else if (isFirefox(ua)) {
        return 'Firefox';
    }
    else if (isSafari(ua)) {
        return 'Safari';
    }
    else if (ua.includes('SamsungBrowser')) {
        return 'Samsung Internet';
    }
    else if ((ua.includes('chrome/') || isChromeIOS(ua))
        && !ua.includes('edge/')) {
        return 'Chrome';
    }
    return 'Other';
}

export function isFirefox(ua = getUA()) {
    return /firefox\//i.test(ua);
}

export function isMobileSafari() {
    const isIOS = /iPad|iPhone|iPod/.test(getUA()) && !window.MSStream;

    return isIOS && isSafari;
}

export function isBrowserNotificationSupported() {
    return !isMobileSafari() && 'Notification' in window;
}

export function isInstalledPWA() {
    return window.matchMedia('(display-mode: standalone)').matches;
}

function isSafari(userAgent = getUA()) {
    const ua = userAgent.toLowerCase();
    return ua.includes('safari/')
        && !ua.includes('chrome/')
        && !ua.includes('crios/')
        && !ua.includes('android');
}
function isChromeIOS(ua = getUA()) {
    return /crios\//i.test(ua);
}