var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["gfu-search-header", { "is-stuck": _vm.isStuck }] },
    [
      _c(
        "div",
        { class: ["gfu-layout-wrapper", { "_px-0": _vm.narrow }] },
        [_vm._t("default", null, null, { isStuck: _vm.isStuck })],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }