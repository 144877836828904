var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-general-message-info gfu-project-navigation-archive",
      attrs: { "data-qa": "project-navigation-archive" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n        " + _vm._s(_vm.message) + " "),
        _c("a", { staticClass: "_tdu", attrs: { href: _vm.projectUrl } }, [
          _vm._v(_vm._s(_vm.linkMessage)),
        ]),
        _vm._v(".\n    "),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "gfu-general-message-info__icon" }, [
      _c("span", { staticClass: "_ga _ga--gamefound-outline _mr-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }