var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-project-stretch-goal-card gfu-card",
        {
          "gfu-project-stretch-goal-card--featured": _vm.stretchGoal.isFeatured,
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "gfu-project-stretch-goal-card__body" },
        [
          _c("div", { staticClass: "gfu-media gfu-media--middle _mb-1" }, [
            _c(
              "div",
              {
                class: [
                  "gfu-project-stretch-goal-icon _mr-1",
                  {
                    "gfu-project-stretch-goal-icon--success":
                      _vm.stretchGoal.isUnlocked,
                  },
                ],
              },
              [
                _c("span", {
                  class: _vm.stretchGoal.isUnlocked
                    ? "_fa _fa--check"
                    : "_fa _fa--lock",
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "gfu-media__body gfu-bt gfu-bt--caption _tc--light",
                attrs: { "data-qa": "project-stretch-goal:Target" },
              },
              [
                _vm.stretchGoal.targetType !==
                _vm.stretchGoalTargetTypes.backedFunds
                  ? [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.resolveStatusText(_vm.stretchGoal)) +
                          "\n                "
                      ),
                    ]
                  : _c("display-currency-value", {
                      attrs: { tag: "span" },
                      scopedSlots: _vm._u([
                        {
                          key: "tooltip-content",
                          fn: function () {
                            return [
                              _c(
                                "localize",
                                {
                                  attrs: {
                                    resource:
                                      _vm.Resources.ProjectHome
                                        .ProjectApproximateCurrencyConversionTooltip,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "_whs-nw",
                                      attrs: { "format-key": "0" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$format(
                                            _vm.stretchGoal.targetValue,
                                            "C0"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "content",
                          fn: function ({ displayCurrency }) {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.resolveStatusText(
                                      _vm.stretchGoal,
                                      displayCurrency.symbol,
                                      displayCurrency.relativeFactor
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.resolveStatusText(
                                      _vm.stretchGoal,
                                      _vm.currencySymbol
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                _vm._v(" "),
                _vm.hasDescription
                  ? _c(
                      "tooltip",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _vm.targetDescription
                                    ? _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.targetDescription
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1051823544
                        ),
                      },
                      [_c("span", { staticClass: "_fa _fa--info" })]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass:
                "gfu-project-stretch-goal-card__title gfu-hd gfu-hd--h3 gfu-hd--decorative _mb-1",
              attrs: { "data-qa": "project-stretch-goal-name" },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "gfu-link gfu-link--nofx",
                  attrs: { to: _vm.route },
                },
                [_vm._v(_vm._s(_vm.stretchGoal.name))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.stretchGoal.isUnlocked && _vm.stretchGoal.isAutomatic
            ? [
                _c(
                  "div",
                  {
                    staticClass: "gfu-bt gfu-bt--caption",
                    attrs: {
                      "data-qa": "project-stretch-goals:CurrentGoalProgress",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.resolveProgress(
                          _vm.stretchGoal,
                          _vm.currentCurrencySymbol,
                          _vm.currentCurrencyFactor
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("progress-bar", {
                  attrs: {
                    value: _vm.stretchGoal.value,
                    max: _vm.stretchGoal.targetValue,
                    "progression-class":
                      "gfu-progress-bar__progression--accent",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.displayImage
        ? _c(
            "router-link",
            {
              staticClass:
                "gfu-project-stretch-goal-card__media gfu-embed gfu-embed--1x1",
              attrs: { to: _vm.route },
            },
            [
              _c("base-image", {
                staticClass: "gfu-embed__item",
                attrs: {
                  src: _vm.stretchGoal.imageUrl,
                  alt: _vm.stretchGoal.name,
                  loading: "lazy",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }