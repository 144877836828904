var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
    _c(
      "div",
      {
        staticClass: "gfu-grid__cell gfu-1of1",
        attrs: { "data-qa": "order-details:shippingAddress" },
      },
      [
        _c(
          "h2",
          { staticClass: "gfu-heading gfu-heading--top-divider _mb-3" },
          [_vm._v(_vm._s(_vm.Resources.YourPledge.ShippingSectionHeader))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "_my-3" }, [
          _c("span", { staticClass: "_fa _fa--info" }),
          _vm._v(
            " " +
              _vm._s(
                _vm.Resources.YourPledge
                  .ShippingInfoPartiallyCollectedNotificationText
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _vm.shippingModel
          ? _c("div", { staticClass: "gfu-grid gfu-grid--small" }, [
              _c(
                "label",
                {
                  staticClass:
                    "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
                },
                [_vm._v(_vm._s(_vm.Resources.YourPledge.ShippingInfoLabel))]
              ),
              _vm._v(" "),
              _vm.shippingInfo
                ? _c(
                    "label",
                    {
                      staticClass:
                        "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
                      attrs: { "data-qa": "order-details:Shipping" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.shippingInfo) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("address-display", {
          attrs: { address: _vm.shippingAddress, "show-location-only": true },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.canChangeShippingAddress
      ? _c(
          "div",
          { staticClass: "gfu-grid__cell _fc" },
          [
            _c(
              "base-button",
              {
                attrs: {
                  display: "fixed",
                  shape: "default",
                  "data-qa": "order-details:ChangeShipping",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.changeShipping.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.Resources.YourPledge.ManageShippingButton))]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }