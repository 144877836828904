var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("skeleton-wrapper", { staticClass: "_flex _gap-3" }, [
    _c("div", { staticClass: "_flex-basis-30" }, [_c("skeleton-image")], 1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "_flexcol _flex-basis-70" },
      [
        _c("skeleton-paragraph", { attrs: { lines: 1 } }),
        _vm._v(" "),
        _c("circle-icon", {
          staticClass: "_mt-a _as-fe",
          attrs: { theme: "disabled", size: "large", icon: "plus" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }