var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.model
    ? _c(
        "modal-box",
        {
          attrs: {
            "is-visible": true,
            title: _vm.Resources.AccountSettings.ChangeEmailTitle,
            icon: "user-cog",
            width: "narrow",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "a",
                      {
                        staticClass:
                          "gfu-link gfu-link--accent gfu-link--inline _mb-1",
                        attrs: { href: "", "data-qa": "modal-button:Cancel" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.close.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--wide gfu-btn--compact _ml-3 _mt-1",
                        attrs: {
                          type: "button",
                          "data-qa": "modal-button:Confirm",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Common.ModalConfirmButton))]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            4115391939
          ),
        },
        [
          _c("p", { staticClass: "_mb-3" }, [
            _vm._v(_vm._s(_vm.Resources.AccountSettings.ChangeEmailInfoText)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("p", { staticClass: "_mb-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.Resources.AccountSettings.ChangeEmailGoogleFbWarningText
                  )
                ),
              ]),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  value: _vm.email,
                  "display-name":
                    _vm.Resources.AccountSettings
                      .ChangeEmailCurrentEmailFieldLabel,
                  "read-only": true,
                  "data-qa": "modal-field:CurrentEmail",
                },
              }),
              _vm._v(" "),
              _vm.pendingNewEmail
                ? _c("div", { staticClass: "gfu-field__readonly _mt-1" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.Resources.format(
                            _vm.Resources.AccountSettings
                              .ChangeEmailPendingChangeInfoText,
                            _vm.pendingNewEmail
                          )
                        ) +
                        "\n            "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "gfu-link gfu-link--accent _ml-1",
                        attrs: { href: "", "data-qa": "modal-button:Revert" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.revertEmailChange.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.AccountSettings
                              .ChangeEmailRevertButton
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  "display-name":
                    _vm.Resources.AccountSettings.ChangeEmailNewEmailFieldLabel,
                  name: "model.newEmail",
                  "data-qa": "modal-field:Email",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.submit.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.model.newEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "newEmail", $$v)
                  },
                  expression: "model.newEmail",
                },
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.Resources.AccountSettings.ChangeEmailPasswordInfoText
                  )
                ),
              ]),
              _vm._v(" "),
              _c("text-field", {
                attrs: {
                  "display-name":
                    _vm.Resources.AccountSettings.ChangeEmailPasswordFieldLabel,
                  type: "password",
                  name: "model.password",
                  "data-qa": "modal-field:Password",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.submit.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.model.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "password", $$v)
                  },
                  expression: "model.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }