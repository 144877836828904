var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h3",
    { staticClass: "gfu-become-creator-values-title gfu-hd gfu-hd--h2 _tac" },
    [
      _c("div", [
        _vm._v("\n        " + _vm._s(_vm.typerText)),
        _c("span", { staticClass: "gfu-animation-blink _tc--accent _fw-n" }, [
          _vm._v("|"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }