var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "floating-message",
        _vm._b(
          {
            attrs: { "data-qa": `cookie-policy-box:${_vm.isVisible}` },
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("span", {
                        staticClass:
                          "gfu-hd _tc--primary _fa _fa--base _fa--lg _ga--cookie-icon",
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "_mt-1" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--micro _ttu",
                            attrs: {
                              type: "button",
                              "data-qa": "cookie-policy-button:AcceptAll",
                            },
                            on: { click: _vm.acceptAll },
                          },
                          [_vm._v(_vm._s(_vm.Resources.Common.ModalOkButton))]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "gfu-link gfu-link--accent _fw-b _ttl",
                            attrs: {
                              href: _vm.privacySettingsUrl,
                              "data-qa": "cookie-policy-button:CookieSettings",
                            },
                          },
                          [_vm._v(_vm._s(_vm.Resources.Cookies.PageHeader))]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1669620433
            ),
          },
          "floating-message",
          _vm.floatingMessageConfig,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }