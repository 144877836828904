var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout__main gfu-layout__main--extranarrow _my-6" },
    [
      _c("div", { staticClass: "gfu-content-head" }, [
        _c(
          "h2",
          {
            staticClass: "gfu-hd gfu-hd--h1",
            attrs: { "data-qa": "page-title" },
          },
          [_vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gfu-box _mt-3 _mb-6",
          attrs: { "data-qa": "faq-page-box" },
        },
        [
          _c("issue-box", {
            attrs: {
              sections: _vm.faqSections,
              "project-name": _vm.projectName,
              "search-field-label": _vm.Resources.Faq.SearchFaqTopicsLabel,
              "search-field-placeholder":
                _vm.Resources.Faq.SearchFaqTopicsPlaceholder,
            },
            on: { "nagative-feedback-click": _vm.onNegativeFeedbackClick },
            scopedSlots: _vm._u([
              {
                key: "empty-result",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "gfu-issues-empty-result _tac",
                        attrs: { "data-qa": "faq-page-no-content" },
                      },
                      [
                        _c(
                          "empty-state",
                          {
                            attrs: {
                              title: _vm.Resources.Faq.FaqUserEmptyStateTitle,
                              description:
                                _vm.Resources.Faq.FaqUserEmptyStateParagraph,
                              "data-qa": "empty-faq-customer",
                            },
                          },
                          [
                            _vm.isNotCrowdfundingDraftPhase
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--wide",
                                    attrs: {
                                      href: _vm.topicSelectionContactUrl,
                                      "data-qa":
                                        "faq-page-button:AskTheCreator",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.Resources.Faq
                                          .FaqUserEmptyStateButton
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }