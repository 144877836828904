var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flexcol" },
    [
      _vm.isInitialized
        ? [
            _vm.hasBanners
              ? _c("announcement-box", { attrs: { banners: _vm.banners } })
              : _vm._e(),
          ]
        : [_c("skeleton-banner")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }