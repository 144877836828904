var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "show-action-close": true,
        "is-visible": _vm.isOpen,
        title: _vm.Resources.format(
          _vm.Resources.YourPledge.StageHistoryModalHeader,
          _vm.orderPublicId
        ),
        width: "wide",
      },
      on: { "action-close": _vm.onClose },
    },
    [
      _c("div", { staticClass: "gfu-table _mt-3" }, [
        _c("div", { staticClass: "gfu-table__head" }, [
          _c("div", { staticClass: "gfu-table__row" }, [
            _c(
              "div",
              { staticClass: "gfu-table__cell _tal _flexb30 _flexb20--l" },
              [_vm._v(_vm._s(_vm.Resources.Common.DateTableHeader))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "gfu-table__cell _tal _flexb30" }, [
              _vm._v(
                _vm._s(_vm.Resources.Common.StagePaymentStatusTableHeader)
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gfu-table__cell gfu-table__cell--grow" },
              [_vm._v(_vm._s(_vm.Resources.Common.NotesTableHeader))]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gfu-table__body", attrs: { tabindex: "1" } },
          _vm._l(_vm.displayedHistory, function (item) {
            return _c(
              "div",
              {
                key: `order-state-${item.itemId}`,
                staticClass: "gfu-table__row gfu-table__row--striped",
                attrs: { "data-qa": `order-state-element:${item.itemId}` },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "gfu-table__cell _tal _flexb30 _flexb20--l",
                    attrs: { "data-qa": "order-state-history:Date" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$format(item.date)) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "gfu-table__cell _tal _flexb30",
                    attrs: { "data-qa": "order-state-history:Status" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.status) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "gfu-table__cell gfu-table__cell--grow _pa-0",
                    attrs: { "data-qa": "order-state-history:Notes" },
                  },
                  [
                    _c("pre", { staticClass: "_ma-0" }, [
                      _vm._v(_vm._s(item.stateNotes)),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }