// -------------------------------------------------------------------------------------
// Number
// -------------------------------------------------------------------------------------

import CultureInfo from './CultureInfo';

const Number = {
};

export default Number;

Number.parseLocale = function parseLocale(value) {
    return Number.parse(value, CultureInfo.currentCulture);
};

Number.parseInvariant = function parseInvariant(value) {
    return Number.parse(value, CultureInfo.invariantCulture);
};

Number.parse = function parse(value, cultureInfo) {
    value = value.trim();

    if (value.match(/^[+-]?infinity$/i)) {
        return parseFloat(value);
    }
    if (value.match(/^0x[a-f0-9]+$/i)) {
        return parseInt(value);
    }
    const numFormat = cultureInfo.numberFormat;
    let signInfo = Number._parseNumberNegativePattern(value, numFormat, numFormat.numberNegativePattern);
    let sign = signInfo[0];
    let num = signInfo[1];

    if (sign === '' && numFormat.numberNegativePattern !== 1) {
        signInfo = Number._parseNumberNegativePattern(value, numFormat, 1);
        sign = signInfo[0];
        num = signInfo[1];
    }
    if (sign === '') sign = '+';

    let exponent;
    let intAndFraction;
    let exponentPos = num.indexOf('e');
    if (exponentPos < 0) exponentPos = num.indexOf('E');
    if (exponentPos < 0) {
        intAndFraction = num;
        exponent = null;
    }
    else {
        intAndFraction = num.substr(0, exponentPos);
        exponent = num.substr(exponentPos + 1);
    }

    let integer;
    let fraction;
    const decimalPos = intAndFraction.indexOf(numFormat.numberDecimalSeparator);
    if (decimalPos < 0) {
        integer = intAndFraction;
        fraction = null;
    }
    else {
        integer = intAndFraction.substr(0, decimalPos);
        fraction = intAndFraction.substr(decimalPos + numFormat.numberDecimalSeparator.length);
    }

    integer = integer.split(numFormat.numberGroupSeparator).join('');
    const altNumGroupSeparator = numFormat.numberGroupSeparator.replace(/\u00A0/g, ' ');
    if (numFormat.numberGroupSeparator !== altNumGroupSeparator) {
        integer = integer.split(altNumGroupSeparator).join('');
    }

    let p = sign + integer;
    if (fraction !== null) {
        p += `.${ fraction }`;
    }
    if (exponent !== null) {
        const expSignInfo = Number._parseNumberNegativePattern(exponent, numFormat, 1);
        if (expSignInfo[0] === '') {
            expSignInfo[0] = '+';
        }
        p += `e${ expSignInfo[0] }${ expSignInfo[1] }`;
    }
    if (p.match(/^[+-]?\d*\.?\d*(e[+-]?\d+)?$/)) {
        return parseFloat(p);
    }
    return Number.NaN;
};

Number._parseNumberNegativePattern = function _parseNumberNegativePattern(value, numFormat, numberNegativePattern) {
    let neg = numFormat.negativeSign;
    let pos = numFormat.positiveSign;
    switch (numberNegativePattern) {
    case 4:
        neg = ` ${ neg }`;
        pos = ` ${ pos }`;
        // fall through
    case 3:
        if (value.endsWith(neg)) {
            return ['-', value.substr(0, value.length - neg.length)];
        }
        else if (value.endsWith(pos)) {
            return ['+', value.substr(0, value.length - pos.length)];
        }
        break;
    case 2:
        neg += ' ';
        pos += ' ';
        // fall through
    case 1:
        if (value.startsWith(neg)) {
            return ['-', value.substr(neg.length)];
        }
        else if (value.startsWith(pos)) {
            return ['+', value.substr(pos.length)];
        }
        break;
    case 0:
        if (value.startsWith('(') && value.endsWith(')')) {
            return ['-', value.substr(1, value.length - 2)];
        }
        break;
    default:
        throw new Error('');
    }
    return ['', value];
};

Number.format = function format(inputNumber, format) {
    return Number.toFormattedString(inputNumber, format, CultureInfo.invariantCulture);
};

Number.localeFormat = function localeFormat(inputNumber, format) {
    return Number.toFormattedString(inputNumber, format, CultureInfo.currentCulture);
};

Number.toFormattedString = function toFormattedString(inputNumber, format, cultureInfo) {
    if (!format || format.length === 0 || format === 'i') {
        if (cultureInfo && cultureInfo.name.length > 0) {
            return inputNumber.toLocaleString();
        }
        else {
            return inputNumber.toString();
        }
    }

    const _percentPositivePattern = ['n %', 'n%', '%n'];
    const _percentNegativePattern = ['-n %', '-n%', '-%n'];
    const _numberNegativePattern = ['(n)', '-n', '- n', 'n-', 'n -'];
    const _currencyPositivePattern = ['$n', 'n$', '$ n', 'n $'];
    const _currencyNegativePattern = ['($n)', '-$n', '$-n', '$n-', '(n$)', '-n$', 'n-$', 'n$-', '-n $', '-$ n', 'n $-', '$ n-', '$ -n', 'n- $', '($ n)', '(n $)'];
    function zeroPad(str, count, left) {
        for (let l = str.length; l < count; l++) {
            str = left ? `0${ str }` : `${ str }0`;
        }
        return str;
    }

    function expandNumber(number, precision, groupSizes, sep, decimalChar) {
        if (groupSizes.length === 0)
            throw new Error('groupSizes must be an array of at least 1');

        let curSize = groupSizes[0];
        let curGroupIndex = 1;
        const factor = Math.pow(10, precision);
        let rounded = Math.round(number * factor) / factor;
        if (!isFinite(rounded)) {
            rounded = number;
        }
        number = rounded;

        let numberString = number.toString();
        let right = '';
        let exponent;

        let split = numberString.split(/e/i);
        numberString = split[0];
        exponent = split.length > 1 ? parseInt(split[1]) : 0;
        split = numberString.split('.');
        numberString = split[0];
        right = split.length > 1 ? split[1] : '';

        if (exponent > 0) {
            right = zeroPad(right, exponent, false);
            numberString += right.slice(0, exponent);
            right = right.substr(exponent);
        }
        else if (exponent < 0) {
            exponent = -exponent;
            numberString = zeroPad(numberString, exponent + 1, true);
            right = numberString.slice(-exponent, numberString.length) + right;
            numberString = numberString.slice(0, -exponent);
        }
        if (precision > 0) {
            if (right.length > precision) {
                right = right.slice(0, precision);
            }
            else {
                right = zeroPad(right, precision, false);
            }
            right = decimalChar + right;
        }
        else {
            right = '';
        }
        let stringIndex = numberString.length - 1;
        let ret = '';
        while (stringIndex >= 0) {
            if (curSize === 0 || curSize > stringIndex) {
                if (ret.length > 0)
                    return numberString.slice(0, stringIndex + 1) + sep + ret + right;
                else
                    return numberString.slice(0, stringIndex + 1) + right;
            }
            if (ret.length > 0)
                ret = numberString.slice(stringIndex - curSize + 1, stringIndex + 1) + sep + ret;
            else
                ret = numberString.slice(stringIndex - curSize + 1, stringIndex + 1);
            stringIndex -= curSize;
            if (curGroupIndex < groupSizes.length) {
                curSize = groupSizes[curGroupIndex];
                curGroupIndex++;
            }
        }
        return numberString.slice(0, stringIndex + 1) + sep + ret + right;
    }
    const nf = cultureInfo.numberFormat;
    let number = Math.abs(inputNumber);
    if (!format)
        format = 'D';
    let precision = -1;
    if (format.length > 1) precision = parseInt(format.slice(1), 10);
    let pattern;
    switch (format.charAt(0)) {
    case 'd':
    case 'D':
        pattern = 'n';
        if (precision !== -1) {
            number = zeroPad(`${ number }`, precision, true);
        }
        if (inputNumber < 0) number = -number;
        break;
    case 'c':
    case 'C':
        if (inputNumber < 0) pattern = _currencyNegativePattern[nf.currencyNegativePattern];
        else pattern = _currencyPositivePattern[nf.currencyPositivePattern];
        if (precision === -1) precision = nf.currencyDecimalDigits;
        number = expandNumber(Math.abs(inputNumber), precision, nf.currencyGroupSizes, nf.currencyGroupSeparator, nf.currencyDecimalSeparator);
        break;
    case 'n':
    case 'N':
        if (inputNumber < 0) pattern = _numberNegativePattern[nf.numberNegativePattern];
        else pattern = 'n';
        if (precision === -1) precision = nf.numberDecimalDigits;
        number = expandNumber(Math.abs(inputNumber), precision, nf.numberGroupSizes, nf.numberGroupSeparator, nf.numberDecimalSeparator);
        break;
    case 'p':
    case 'P':
        if (inputNumber < 0) pattern = _percentNegativePattern[nf.percentNegativePattern];
        else pattern = _percentPositivePattern[nf.percentPositivePattern];
        if (precision === -1) precision = nf.percentDecimalDigits;
        number = expandNumber(Math.abs(inputNumber) * 100, precision, nf.percentGroupSizes, nf.percentGroupSeparator, nf.percentDecimalSeparator);
        break;
    default:
        throw new Error('Bad format specifier');
    }
    const regex = /n|\$|-|%/g;
    let ret = '';
    for (; ;) {
        const index = regex.lastIndex;
        const ar = regex.exec(pattern);
        ret += pattern.slice(index, ar ? ar.index : pattern.length);
        if (!ar)
            break;
        switch (ar[0]) {
        case 'n':
            ret += number;
            break;
        case '$':
            ret += nf.currencySymbol;
            break;
        case '-':
            if (/[1-9]/.test(number)) {
                ret += nf.negativeSign;
            }
            break;
        case '%':
            ret += nf.percentSymbol;
            break;
        default:
            throw new Error('Invalid number format pattern');
        }
    }
    return ret;
};
