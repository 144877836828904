class EventEmitter {
    constructor() {
        this.subscriptions = [];
    }

    on(eventName, callback) {
        this.subscriptions.push({ eventName, callback });
    }

    off(eventName, callback) {
        this.subscriptions = this.subscriptions
            .filter(s =>
                !(s.eventName === eventName
                    && s.callback === callback));
    }

    emit(eventName, payload = null) {
        this.subscriptions
            .filter((s) => s.eventName === eventName)
            .forEach((s) => s.callback.apply(this, [payload]));
    }
}

export default EventEmitter;