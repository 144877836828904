var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stretchGoals.length > 0
    ? _c(
        "div",
        { staticClass: "gfu-project-stretch-goal-grid" },
        _vm._l(_vm.stretchGoals, function (stretchGoal) {
          return _c("project-stretch-goal-card", {
            key: stretchGoal.projectStretchGoalID,
            staticClass: "gfu-project-stretch-goal-grid__item",
            attrs: {
              "stretch-goal": stretchGoal,
              "currency-symbol": _vm.currencySymbol,
              "data-qa": `project-stretch-goals-card:${stretchGoal.projectStretchGoalID}`,
            },
          })
        }),
        1
      )
    : _c("div", { staticClass: "gfu-project-stretch-goal-placeholder" }, [
        _c("span", [_vm._v(_vm._s(_vm.placeholderText))]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }