var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-summary gfu-box gfu-box--flat gfu-box--fill" },
    [
      _c(
        "div",
        {
          staticClass: "gfu-project-summary-completed",
          attrs: { "data-qa": "project-summary-box:Completed" },
        },
        [
          _c(
            "project-summary-completed-stats",
            _vm._b(
              { staticClass: "gfu-project-summary-completed__main" },
              "project-summary-completed-stats",
              _vm.statsModel,
              false
            )
          ),
          _vm._v(" "),
          _vm.projectLastUpdate
            ? _c(
                "project-summary-update",
                _vm._b(
                  { staticClass: "gfu-project-summary-completed__aside" },
                  "project-summary-update",
                  _vm.lastUpdateModel,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }