var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", { staticClass: "_mr-1", attrs: { "data-qa": "days-left" } }, [
      _vm._v(_vm._s(_vm.timeLeft.totalDays)),
    ]),
    _c("span", { staticClass: "gfu-promo-banner__countdown-unit _mr-2" }, [
      _vm._v("days"),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "_mr-1", attrs: { "data-qa": "hours-left" } }, [
      _vm._v(_vm._s(_vm.timeLeft.hours)),
    ]),
    _c("span", { staticClass: "gfu-promo-banner__countdown-unit _mr-2" }, [
      _vm._v("hours"),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "_mr-1", attrs: { "data-qa": "minutes-left" } }, [
      _vm._v(_vm._s(_vm.timeLeft.minutes)),
    ]),
    _c("span", { staticClass: "gfu-promo-banner__countdown-unit _mr-2" }, [
      _vm._v("minutes"),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "_mr-1", attrs: { "data-qa": "seconds-left" } }, [
      _vm._v(_vm._s(_vm.timeLeft.seconds)),
    ]),
    _c("span", { staticClass: "gfu-promo-banner__countdown-unit _mr-2" }, [
      _vm._v("seconds"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }