export const slugify = text => text.toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Normalize diacritics characters from text
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const toPascalCase = (str) => {
    return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join('');
};

export function toHash(str) {
    let hash = 5381;

    for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i); hash = (hash << 5) + hash + charCode; // hash * 33 + charCode
    }
    return hash >>> 0; // Convert to an unsigned 32-bit integer
}

export function splitStringWithPlaceholders(str, components) {
    const regex = /{([^{}]+)}/g;
    const parts = str.split(regex);

    return parts.reduce((result, part) => {
        const placeholder = part.trim();
        if (components[placeholder]) {
            result.push({ placeholder });
        }
        else {
            result.push(part);
        }
        return result;
    }, []);
}

export function lastIndexOfPunctuationMark(str, characters = ['.', ',', ';', '!', '?', ')', ']', '}']) {
    for (let i = str.length - 1; i >= 0; i--) {
        if (characters.includes(str[i])) {
            return i;
        }
    }
    return 0;
}

export function isWhitespaceOrEmpty(text) {
    return !text || text.length === 0 || /^\s*$/.test(text);
}

export function camelCaseToKebabCase(input) {
    // Replace uppercase letters with hyphen followed by the lowercase equivalent
    return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

export function formatStringToRefValue(input) {
    // Convert the string to lowercase
    const lowercased = input.toLowerCase();
    // Replace spaces with hyphens
    const formatted = lowercased.replace(/\s+/g, '-');
    return formatted;
}