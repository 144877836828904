var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("comment-user-balloon", {
    attrs: { author: _vm.author },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function ({ showBalloon }) {
          return [
            _c(
              "a",
              {
                staticClass: "gfu-link--nofx _pos-r _flex",
                attrs: { href: _vm.author.profileUrl },
                on: {
                  mouseover: function ($event) {
                    return _vm.onMouseover(showBalloon)
                  },
                },
              },
              [
                _c(
                  "base-avatar",
                  {
                    class: { "_cur-p": _vm.hasUrlName },
                    attrs: {
                      "avatar-url": _vm.author.avatarUrl,
                      alt: _vm.author.nickname,
                      clean: true,
                    },
                  },
                  [
                    _c("user-follow", {
                      attrs: {
                        nickname: _vm.author.nickname,
                        "user-url-name": _vm.author.urlName,
                        "user-id": _vm.author.userID,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ isFollowing }) {
                              return [
                                isFollowing
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-avatar--followed _flex _jc-c _ai-c _bgc--accent _tc--white",
                                      },
                                      [
                                        _c("base-icon", {
                                          attrs: {
                                            "icon-namespace": "ga",
                                            name: "heart-solid-icon",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }