import ServiceBase from './ServiceBase';

export default class CartsService extends ServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    addTipToCart(model) {
        return this.request('post', 'carts/addTipToCart', model);
    }

    addToCart(model) {
        return this.request('post', 'carts/addToCart', model);
    }

    checkCartItemsIntegrity(projectID, orderCode) {
        return this.request('get', 'carts/checkCartItemsIntegrity', { params: { projectID, orderCode } });
    }

    enablePledgeEdit(projectID) {
        return this.request('post', 'carts/enablePledgeEdit', { projectID });
    }

    getCartSummary(projectID) {
        if (!projectID) {
            return this.request('get', 'carts/getCartSummaries');
        }

        return this.request('get', 'carts/getCartSummary', { params: { projectID } });
    }

    getCartByActivationKey(projectID, activationKey) {
        return this.request('get', 'carts/getCartByActivationKey', { params: { projectID, activationKey } });
    }

    getCartDetails(model) {
        return this.request('get', 'carts/getCartDetails', { params: model });
    }

    getOrderAwaitingPayment(projectID) {
        return this.request('get', 'carts/getOrderAwaitingPayment', { projectID });
    }

    revertChanges(projectID) {
        return this.request('post', 'carts/revertChanges', { projectID });
    }

    removeOrderItem(orderItemID, projectID, quantity) {
        return this.request('post', 'carts/removeOrderItem', { orderItemID, projectID, quantity });
    }

    startPledgeFromScratch(projectID) {
        return this.request('post', 'carts/startPledgeFromScratch', { projectID });
    }

    updateCartItem(model) {
        return this.request('post', 'carts/updateCartItem', model);
    }
}
