import ServiceBase from './ServiceBase';
export default class UserProfileService extends ServiceBase {

    getProfile(urlName, projectID = null) {
        return this.request('get', 'userProfile/getProfile', {
            params: { urlName, projectID },
        });
    }

    getProfileComments({ urlName, pageIndex, pageSize }) {
        return this.request('get', 'userProfile/getProfileComments', {
            params: { urlName, pageIndex, pageSize },
        });
    }

    getProfileFollowers({ urlName, pageIndex, pageSize, sortType }) {
        return this.request('get', 'userProfile/getProfileFollowers', {
            params: { urlName, pageIndex, pageSize, sortType },
        });
    }

    getProfileFollowing({ urlName, pageIndex, pageSize, sortType }) {
        return this.request('get', 'userProfile/getProfileFollowing', {
            params: { urlName, pageIndex, pageSize, sortType },
        });
    }

    getProfileProjects({ urlName, pageIndex, pageSize }) {
        return this.request('get', 'userProfile/getProfileProjects', {
            params: { urlName, pageIndex, pageSize },
        });
    }

    setUserFollow({ profileUrlName, isUnfollowing }) {
        return this.request('post', 'userProfile/setUserFollow', { profileUrlName, isUnfollowing });
    }
}
