var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("comment-user-balloon", {
    attrs: { author: _vm.author },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function ({ showBalloon }) {
          return [
            _c(
              "a",
              {
                staticClass: "gfu-link--nofx",
                attrs: { href: _vm.author.profileUrl },
                on: {
                  mouseover: function ($event) {
                    return _vm.onMouseover(showBalloon)
                  },
                },
              },
              [
                _c(
                  "h5",
                  {
                    class: _vm.triggerClasses,
                    attrs: {
                      "data-qa": `project-comment-author-nickname:${_vm.author.userID}`,
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.author.nickname) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }