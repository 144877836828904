var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-view",
    [
      _c("footer-navigation", {
        attrs: { "current-page": _vm.Resources.Layout.FeesLink },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-info-hero gfu-info-hero--fees _jc-c" }, [
        _c("div", { staticClass: "gfu-info-hero__cover" }),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-info-hero__content" }, [
          _c(
            "h2",
            {
              staticClass:
                "gfu-hd gfu-hd--d2 gfu-hd--decorative _tc--accent-inverse _mb-4",
            },
            [_vm._v("Fees")]
          ),
          _vm._v(
            "\n            Easily fund and manage your campaign. Exclusive features at no extra cost.\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isExternalPMV2Enabled
        ? _c(
            "main",
            {
              staticClass:
                "gfu-layout-wrapper gfu-layout-wrapper--narrow _py-6",
            },
            [
              _c(
                "box",
                {
                  staticClass: "_tac _mb-6",
                  attrs: { main: true, huge: true },
                },
                [
                  _c("h2", { staticClass: "gfu-hd gfu-hd--h2" }, [
                    _vm._v("Exclusive benefits "),
                    _c("span", { staticClass: "_fw-n" }, [
                      _vm._v("for your project."),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "_flexwrap _my-6" }, [
                    _c(
                      "div",
                      { staticClass: "gfu-1of2 gfu-1of4--l _flexcol _pa-2" },
                      [
                        _c(
                          "tooltip",
                          {
                            attrs: {
                              text: "Widest selection of payment options on the crowdfunding market",
                            },
                          },
                          [
                            _c("base-icon", {
                              staticClass: "_tc--accent",
                              attrs: { name: "money-bill-wave", size: "2x" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-2" }, [
                          _vm._v("Various payment "),
                          _c("br"),
                          _vm._v("methods"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-1of2 gfu-1of4--l _flexcol _pa-2" },
                      [
                        _c(
                          "tooltip",
                          {
                            attrs: {
                              text: "Free installments available only on Gamefound",
                            },
                          },
                          [
                            _c("base-image", {
                              staticClass: "_mx-a",
                              attrs: {
                                src: "/content/images/static/stretch-pay/stretch-pay-icon.svg",
                                height: "42",
                                width: "42",
                                alt: "stretchPay",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-2" }, [
                          _vm._v("Stretch Pay"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-1of2 gfu-1of4--l _flexcol _pa-2" },
                      [
                        _c(
                          "tooltip",
                          {
                            attrs: {
                              text: "Free promotion of your project on our site: main page, newsletter, and more",
                            },
                          },
                          [
                            _c("base-icon", {
                              staticClass: "_tc--accent",
                              attrs: { name: "users", size: "2x" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-2" }, [
                          _vm._v("Huge community"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-1of2 gfu-1of4--l _flexcol _pa-2" },
                      [
                        _c(
                          "tooltip",
                          {
                            attrs: {
                              text: "If you are based outside of the EU, we will handle tax settlement for the B2C orders placed by EU backers for you ",
                            },
                          },
                          [
                            _c("base-icon", {
                              staticClass: "_tc--accent",
                              attrs: { name: "scale-balanced", size: "2x" },
                            }),
                            _vm._v(" "),
                            _c(
                              "h4",
                              { staticClass: "gfu-hd gfu-hd--h4 _mt-2" },
                              [_vm._v("VAT handling")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    { attrs: { tag: "a", href: _vm.becomeACreatorUrl } },
                    [_vm._v("Learn more")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "gfu-hd gfu-hd--h2" }, [
                _vm._v("Our offer"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-bt gfu-bt--b1" }, [
                _vm._v(
                  "Complex crowdfunding and pledge manager services depending on your needs. No hidden fees."
                ),
              ]),
              _vm._v(" "),
              _c(
                "box",
                { staticClass: "_my-6", attrs: { main: true, huge: true } },
                [
                  _vm._l(_vm.items, function (item) {
                    return _c("div", { key: item.title }, [
                      _c("hr", { staticClass: "gfu-hr gfu-hr--light" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "_flexwrap _ai-c" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "gfu-1of1 gfu-3of4--m _pa-3 _tac _tal--l",
                          },
                          [
                            _c("h4", { staticClass: "gfu-hd gfu-hd--h3" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "gfu-bt gfu-bt--b1",
                              domProps: { innerHTML: _vm._s(item.description) },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "gfu-1of1 gfu-1of4--m _tac _pa-3" },
                          [
                            _c(
                              "h4",
                              { staticClass: "gfu-hd gfu-hd--h2 _tc--accent" },
                              [_vm._v(_vm._s(item.value))]
                            ),
                            _vm._v(" "),
                            item.valueDescription
                              ? _c("div", [
                                  _vm._v(_vm._s(item.valueDescription)),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _c("hr", { staticClass: "gfu-hr gfu-hr--light _mb-6" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "_tac" },
                    [
                      _c(
                        "base-button",
                        { attrs: { tag: "a", href: _vm.launchYourProjectUrl } },
                        [_vm._v("Launch your project")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "main",
            {
              staticClass:
                "gfu-layout-wrapper gfu-layout-wrapper--narrow _py-6",
            },
            [
              _c("h2", { staticClass: "gfu-hd gfu-hd--h2" }, [
                _vm._v("Competitive pricing"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-bt gfu-bt--b1" }, [
                _vm._v(
                  "Gamefound offers complex crowdfunding and pledge manager services depending on your needs."
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-box gfu-box--flat gfu-box--huge _my-6" },
                [
                  _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mb-3" }, [
                    _vm._v("Crowdfunding"),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "gfu-hr gfu-hr--light" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "_flexwrap _ai-c" }, [
                    _c("div", { staticClass: "gfu-1of1 gfu-3of4--m _pa-3" }, [
                      _c("h4", { staticClass: "gfu-hd gfu-hd--h3" }, [
                        _vm._v("Platform fee"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "gfu-bt gfu-bt--b1" }, [
                        _vm._v(
                          "\n                        Our commission - how much we take from the raised amount (only if your project is funded).\n                        Pledge managers for campaigns run on Gamefound are free.\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-1of1 gfu-1of4--m _tac _pa-3" },
                      [
                        _c("div", { staticClass: "_mb-3" }, [
                          _c("h4", { staticClass: "gfu-hd gfu-hd--h2" }, [
                            _vm._v("5%"),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("in crowdfunding and late pledge"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("h4", { staticClass: "gfu-hd gfu-hd--h2" }, [
                            _vm._v("0%"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("in pledge manager")]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "gfu-hr gfu-hr--light" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "_flexwrap _ai-c" }, [
                    _c("div", { staticClass: "gfu-1of1 gfu-3of4--m _pa-3" }, [
                      _c("h4", { staticClass: "gfu-hd gfu-hd--h3" }, [
                        _vm._v("Payment processing"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "gfu-bt gfu-bt--b1" }, [
                        _vm._v(
                          "\n                        The fee applies to every transaction + fixed fee in the project currency\n                        (e.g. $0.2 when $ is the project currency).\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-1of1 gfu-1of4--m _tac _pa-3" },
                      [
                        _c("div", { staticClass: "_mb-3" }, [
                          _c("h4", { staticClass: "gfu-hd gfu-hd--h2" }, [
                            _vm._v("3%"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("+0.2 per transaction")]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "gfu-hr gfu-hr--light" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-box gfu-box--flat gfu-box--huge" },
                [
                  _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mb-3" }, [
                    _vm._v("Pledge manager for external platforms"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "gfu-bt gfu-bt--b1" }, [
                    _vm._v(
                      "\n                Managing pledges (changing rewards, collecting shipping address, late pledges, etc.) is free.\n                Payment processing fee depends on your individual agreement with the payment operator (Stripe/PayPal).\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "gfu-hr gfu-hr--light" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "gfu-bt gfu-bt--b1" }, [
                    _vm._v(
                      "\n                Additionally, we'll appreciate sending us 1-2 copies of your game. We're working on our board game collection!\n            "
                    ),
                  ]),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }