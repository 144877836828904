var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-description",
      attrs: { "data-qa": "store-project-home:Contents" },
    },
    [
      _c("table-of-contents"),
      _vm._v(" "),
      _c("mini-wizard-manager", { attrs: { "is-mini-wizard-enabled": true } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-layout__main gfu-layout__main--extranarrow _pt-6" },
        [
          _c("store-product-categories-list", {
            attrs: { "product-categories": _vm.productCategories },
          }),
          _vm._v(" "),
          _c("legal-entity-information", {
            attrs: { fill: true, emphasized: false },
          }),
          _vm._v(" "),
          _c("project-recommendations", {
            staticClass: "_mt-4",
            attrs: {
              quantity: 12,
              "exclude-project-ids": [_vm.projectId],
              source: "projectHome",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }