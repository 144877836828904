import { defineStore } from 'pinia';

export const useOffsetHelperStore = defineStore({
    id: 'offsetHelper',
    state: () => ({
        scrollToTopOffset: 0,
    }),
    actions: {
        setScrollToTopOffset(offset) {
            this.scrollToTopOffset = offset;
        },
    },
});