import CookiesUtility, { cookieNames } from '@/Utils/CookiesUtility';
import { isInstalledPWA } from '@/Utils/Browser';
import { millisecondsInDay } from '@/Utils/DateUtility';
import { appPlatformNameTypes, appPlatformTypes } from '@/Models';

const iOSApp = 'iOS App Store';

export const displayModeTypes = {
    web: 'web',
    pwa: 'pwa',
};

export function isIOSApp() {
    const appPlatformCookie = CookiesUtility.get(cookieNames.appPlatform);

    return appPlatformCookie === iOSApp;
}

export function isWebApp() {
    return !isInstalledPWA();
}

export async function isAndroidApp() {
    // Safari and firefox dosn't support getInstalledRelatedApps
    if (navigator.getInstalledRelatedApps) {
        const relatedApps = await navigator.getInstalledRelatedApps();

        return relatedApps.length > 0;
    }

    return false;
}

export async function setAppPlatformCookie() {
    const expirationDate = new Date(Date.now() +  millisecondsInDay * 30);
    const hostname = window.location.hostname;
    const platform = {
        platformName: null,
        platformType: appPlatformTypes.web,
    };

    if (isIOSApp()) {
        platform.platformName = appPlatformNameTypes.ios;
        platform.platformType = appPlatformTypes.pwa;
    }
    else if (await isAndroidApp()) {
        platform.platformName = appPlatformNameTypes.android;
        platform.platformType = appPlatformTypes.pwa;
    }
    else if (isInstalledPWA()) {
        platform.platformType = appPlatformTypes.pwa;
    }

    CookiesUtility.set(cookieNames.appPlatformCookieName, JSON.stringify(platform), expirationDate, '/', hostname);
}

export function getAppPlatformCookie() {
    const { platformName, platformType } = CookiesUtility.get(cookieNames.appPlatformCookieName) || {};
    return {
        platformName,
        platformType: platformType ? platformType : appPlatformTypes.web,
    };
}