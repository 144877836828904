var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-issue__option-wrapper" }, [
    _c(
      "div",
      { staticClass: "gfu-issue__option", on: { click: _vm.onHeaderClick } },
      [
        _vm.imageUrl
          ? _c("div", { staticClass: "gfu-issue__option-icon-box" }, [
              _c(
                "div",
                { staticClass: "gfu-issue__option-icon-box-icon-box-inner" },
                [_c("img", { attrs: { src: _vm.imageUrl, alt: _vm.header } })]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-issue__option-header" }, [
          _c("span", {
            staticClass: "gfu-issue__option-title",
            domProps: { innerHTML: _vm._s(_vm.formattedHeader) },
          }),
          _vm._v(" "),
          _vm.subtitle
            ? _c("span", { staticClass: "gfu-issue__option-subtitle" }, [
                _vm._v(_vm._s(_vm.subtitle)),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gfu-issue__option-navigation-box" }, [
      _c("span", {
        staticClass:
          "_fa _fa--chevron-right gfu-issue__option-navigation-box-icon",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }