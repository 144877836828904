var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasProducts
    ? _c(
        "div",
        { attrs: { "data-qa": "store-categories-list" } },
        _vm._l(_vm.categories, function (category) {
          return _c(
            "div",
            { key: category.categoryID },
            [
              _c(
                category.element.component,
                {
                  ref: category.element.ref,
                  refInFor: true,
                  tag: "component",
                  class: category.element.class,
                  attrs: {
                    id: category.element.id,
                    "data-qa": category.element.dataQa,
                  },
                },
                [
                  category.isChildCategory
                    ? _c("span", [
                        _vm._v(
                          "\n                " +
                            _vm._s(category.parentName) +
                            "\n                "
                        ),
                        _c("span", { staticClass: "_tc--accent _fw-l" }, [
                          _vm._v(" » "),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(category.name))]),
                      ])
                    : _c("span", [_vm._v(_vm._s(category.name))]),
                  _vm._v(" "),
                  category.infoText
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "gfu-heading__icon gfu-heading__icon--primary gfu-heading__icon--small",
                        },
                        [
                          _c("tooltip", {
                            attrs: {
                              text: category.infoText,
                              "icon-name": "info",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "gfu-richtext gfu-richtext--article _mt-2 _mb-1",
                domProps: { innerHTML: _vm._s(category.description) },
              }),
              _vm._v(" "),
              _c("store-product-card-list", {
                attrs: { products: category.cardModels },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }