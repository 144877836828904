var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-product-details-info",
        { "gfu-product-details-info--single-item": _vm.isSingleItem },
      ],
      attrs: { "data-qa": "product-info" },
    },
    [
      _c("base-thumb", {
        attrs: {
          size: "medium",
          "image-url": _vm.productData.imageUrl,
          alt: _vm.productName,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-hd--h2 _fw-eb _tc--dark _grow-1" },
        [
          _c("div", { staticClass: "_mb-1" }, [
            _c(
              "span",
              { staticClass: "_fw-n", attrs: { "data-qa": "quantity" } },
              [_vm._v(_vm._s(_vm.quantity) + "x")]
            ),
            _vm._v(" "),
            _c("span", { attrs: { "data-qa": "product-name" } }, [
              _vm._v(_vm._s(_vm.productName)),
            ]),
          ]),
          _vm._v(" "),
          _c("display-currency-price", {
            attrs: {
              "effective-price": _vm.effectivePrice,
              price: _vm.price,
              "is-discounted": _vm.isDiscounted,
              "data-qa": "price",
            },
            scopedSlots: _vm._u([
              {
                key: "effective-price",
                fn: function ({ effectivePrice }) {
                  return [
                    _c(
                      "span",
                      { staticClass: "gfu-price gfu-price--text-base" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(effectivePrice) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "price",
                fn: function ({ price }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass:
                          "gfu-price gfu-price--old gfu-hd--h4 _fw-n _ml-1",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(price) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }