var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-table__row gfu-table__row--short",
        { "gfu-table__row--total-highlighted": _vm.isHighlighted },
        _vm.themeClass,
      ],
      attrs: { "data-qa": `cart-table-total-row:${_vm.displayText}` },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell gfu-table__cell--short gfu-table__cell--title _flexb50 _flexb75--s _flexb60--m _flexb60--xl",
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.displayText) +
              _vm._s(_vm.useNetValue ? " (net)" : "") +
              _vm._s(_vm.displayText ? ":" : "") +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell gfu-table__cell--short _tac _flexb15 _flexb10--s gfu-table__cell--actions _screen--none _screen--s",
          attrs: { "data-qa": "cart-table-quantity" },
        },
        [_vm._v("\n        " + _vm._s(_vm.quantity) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-table__cell gfu-table__cell--short _tar _flexb50 _flexb25--s _flexb15--m _flexb15--l",
          attrs: { "data-qa": "cart-table-total-row:Price" },
        },
        [
          _vm._t("default", function () {
            return [
              _vm._v(
                _vm._s(
                  _vm.$format(
                    _vm.useNetValue ? _vm.netValue : _vm.grossValue,
                    _vm.format,
                    _vm.currencySymbol
                  )
                )
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.allowEditing
        ? _c("div", {
            staticClass:
              "gfu-table__cell gfu-table__cell--short _flexb5 _screen--none _screen--s",
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }