var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["gfu-progress-overlay", _vm.classes],
      attrs: { "data-qa": "progress-overlay" },
    },
    [
      _c("div", { staticClass: "gfu-progress-overlay__message" }, [
        _vm._v(_vm._s(_vm.message)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }