var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-benefits-grid" },
    [
      _c("span", { staticClass: "gfu-benefits-grid__stamp" }),
      _vm._v(" "),
      _vm._l(_vm.marketingServicesBenefits, function (benefit) {
        return _c(
          "benefit-item",
          _vm._b(
            { key: benefit.creatorBenefitID },
            "benefit-item",
            benefit,
            false
          )
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }