// -------------------------------------------------------------------------------------
// StringBuilder
// -------------------------------------------------------------------------------------

export default class StringBuilder {
    constructor(initialText) {
        this._parts = typeof initialText !== 'undefined' && initialText !== null && initialText !== ''
            ? [initialText.toString()] : [];
        this._value = {};
        this._len = 0;
    }

    append(text) {
        this._parts[this._parts.length] = text;
    }
    appendLine(text) {
        this._parts[this._parts.length] = typeof text === 'undefined' || text === null || text === ''
            ? '\r\n' : `${ text }\r\n`;
    }
    clear() {
        this._parts = [];
        this._value = {};
        this._len = 0;
    }
    isEmpty() {
        if (this._parts.length === 0) return true;
        return this.toString() === '';
    }
    toString(separator) {
        separator = separator || '';
        const parts = this._parts;
        if (this._len !== parts.length) {
            this._value = {};
            this._len = parts.length;
        }
        const val = this._value;
        if (typeof val[separator] === 'undefined') {
            if (separator !== '') {
                for (let i = 0; i < parts.length;) {
                    if (typeof parts[i] === 'undefined' || parts[i] === '' || parts[i] === null) {
                        parts.splice(i, 1);
                    }
                    else {
                        i++;
                    }
                }
            }
            val[separator] = this._parts.join(separator);
        }
        return val[separator];
    }
}
