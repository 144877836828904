var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      ref: "trigger",
      tag: "component",
      attrs: { "data-value": _vm.guid, "data-qa": "tooltip-trigger" },
      on: {
        mouseenter: function ($event) {
          $event.stopPropagation()
          return _vm.onMouseOver.apply(null, arguments)
        },
        mouseleave: function ($event) {
          $event.stopPropagation()
          return _vm.onMouseOut.apply(null, arguments)
        },
        click: _vm.onClick,
      },
    },
    [
      _vm.hasIcon
        ? _c("base-icon", { attrs: { name: _vm.iconName } })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.enabled
        ? _c(
            "teleport",
            { attrs: { to: "#tooltip" } },
            [
              _c(
                "transition",
                {
                  attrs: { name: "gfu-tooltip-fade" },
                  on: { enter: _vm.onEnter },
                },
                [
                  _vm.isVisible
                    ? _c(
                        "div",
                        {
                          ref: "content",
                          staticClass: "gfu-tooltip",
                          attrs: { tabindex: "0" },
                          on: { click: _vm.closeContentOnClick },
                        },
                        [
                          _c(
                            "base-balloon",
                            _vm._b(
                              {
                                staticClass: "gfu-tooltip__balloon",
                                attrs: {
                                  "data-value": _vm.guid,
                                  "data-qa": "tooltip-text",
                                },
                              },
                              "base-balloon",
                              _vm.balloonProps,
                              false
                            ),
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.text) +
                                  "\n                    "
                              ),
                              _vm._t("content", function () {
                                return [_vm._v(_vm._s(_vm.infoText))]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }