var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "show-action-close": true,
        "is-visible": _vm.isUserLocationModalVisible,
        title: _vm.Resources.Layout.UserLocationModalTitle,
        "is-processing": _vm.isProcessing,
      },
      on: { "action-close": _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link _ttl _tc--lighter",
                  attrs: { href: "", "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onClose.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Layout.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: {
                    disabled: _vm.isProcessing,
                    size: "default",
                    width: "compact",
                    "text-transform": "none",
                    bold: true,
                    theme: "accent",
                    enabled: !_vm.isProcessing,
                    "data-qa": "modal-button:Save",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onSave.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Layout.ModalSaveButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm.Resources.Layout.UserLocationModalInformation) +
          "\n    "
      ),
      _c(
        "fieldset",
        { staticClass: "_mt-3" },
        [
          _c("h3", { staticClass: "gfu-hd gfu-hd--h3" }, [
            _vm._v(
              _vm._s(_vm.Resources.Layout.UserLocationModalFieldsetHeading)
            ),
          ]),
          _vm._v(" "),
          _c("auto-complete-field", {
            attrs: {
              name: "model.locationID",
              items: _vm.locations,
              "min-chars": 0,
              "item-name-finder": (item) => item.name,
              "item-value-finder": (item) => item.locationID,
              "data-qa": "delivered-to:Location",
            },
            on: { input: _vm.setLocationID },
            model: {
              value: _vm.model.locationID,
              callback: function ($$v) {
                _vm.$set(_vm.model, "locationID", $$v)
              },
              expression: "model.locationID",
            },
          }),
          _vm._v(" "),
          _vm.hasSublocations
            ? _c("auto-complete-field", {
                attrs: {
                  name: "model.subLocationID",
                  items: _vm.sublocations,
                  "min-chars": 0,
                  "item-name-finder": (item) => item.name,
                  "item-value-finder": (item) => item.subLocationID,
                  "data-qa": "delivered-to:Sublocation",
                },
                on: { input: _vm.setSublocationID },
                model: {
                  value: _vm.model.subLocationID,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "subLocationID", $$v)
                  },
                  expression: "model.subLocationID",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }