var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "_mt-0 _mb-0 _ml-0" },
    _vm._l(_vm.benefits, function (benefit) {
      return _c(
        "li",
        {
          key: benefit,
          staticClass:
            "gfu-bullet-list__item gfu-bullet-list__item--check gfu-bullet-list__item--secondary _tc--gray",
        },
        [_vm._v(_vm._s(benefit))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }