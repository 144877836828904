var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "checkbox-field",
    _vm._b(
      { attrs: { condensed: true }, on: { input: _vm.onInput } },
      "checkbox-field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _vm._v(
        "\n    " + _vm._s(_vm.checkboxVatPayerConfirmationMessage) + " \n    "
      ),
      _c(
        "tooltip",
        {
          attrs: {
            text: _vm.checkboxVatPayerConfirmationTooltipText,
            position: "right",
          },
        },
        [_c("em", [_c("base-icon", { attrs: { name: "info" } })], 1)]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }