const isNativeSmoothScrollEnabledOn = function (elem) {
    return elem && 'getComputedStyle' in window
        && window.getComputedStyle(elem)['scroll-behavior'] === 'smooth';
};

function makeScroller(container, defaultDuration, edgeOffset) {

    // Use defaults if not provided
    defaultDuration = defaultDuration || 200; // ms
    if (!edgeOffset && edgeOffset !== 0) {
        // When scrolling, this amount of distance is kept from the edges of the container:
        edgeOffset = 9; // px
    }

    // Handling the life-cycle of the scroller
    let scrollTimeoutId;
    const setScrollTimeoutId = function (newValue) {
        scrollTimeoutId = newValue;
    };

    /**
     * Stop the current smooth scroll operation immediately
     */
    const stopScroll = function () {
        clearTimeout(scrollTimeoutId);
        setScrollTimeoutId(0);
    };

    const getTopWithEdgeOffset = function (elem) {
        return Math.max(0, container.getTopOf(elem) - edgeOffset);
    };

    /**
     * Scrolls to a specific horizontal position in the document.
     *
     * @param {targetX} The horizontal position within the document.
     * @param {duration} Optionally the duration of the scroll operation.
     *        If not provided the default duration is used.
     * @param {onDone} An optional callback function to be invoked once the scroll finished.
     */
    const scrollToX = function (targetX, duration, onDone) {
        stopScroll();
        if (duration === 0 || duration && duration < 0 || isNativeSmoothScrollEnabledOn(container.body)) {
            container.toX(targetX);
            if (onDone) {
                onDone();
            }
        }
        else {
            const startX = container.getX();
            const distance = Math.max(0, targetX) - startX;
            const startTime = new Date().getTime();
            duration = duration || Math.min(Math.abs(distance), defaultDuration);

            (function loopScroll() {
                setScrollTimeoutId(setTimeout(function () {
                    // Calculate percentage:
                    const p = Math.min(1, (new Date().getTime() - startTime) / duration);
                    // Calculate the absolute horizontal position:
                    const x = Math.max(0, Math.floor(startX + distance * (p < 0.5 ? 2 * p * p : p * (4 - p * 2) - 1)));
                    container.toX(x);
                    if (p < 1 && container.getWidth() + x < container.body.scrollWidth) {
                        loopScroll();
                    }
                    else {
                        setTimeout(stopScroll, 99); // with cooldown time
                        if (onDone) {
                            onDone();
                        }
                    }
                }, 9));
            })();
        }
    };

    /**
     * Scrolls to a specific vertical position in the document.
     *
     * @param {targetY} The vertical position within the document.
     * @param {duration} Optionally the duration of the scroll operation.
     *        If not provided the default duration is used.
     * @param {onDone} An optional callback function to be invoked once the scroll finished.
     */
    const scrollToY = function (targetY, duration, onDone) {
        stopScroll();
        if (duration === 0 || duration && duration < 0 || isNativeSmoothScrollEnabledOn(container.body)) {
            container.toY(targetY);
            if (onDone) {
                onDone();
            }
        }
        else {
            const startY = container.getY();
            const distance = Math.max(0, targetY) - startY;
            const startTime = new Date().getTime();
            duration = duration || Math.min(Math.abs(distance), defaultDuration);
            (function loopScroll() {
                setScrollTimeoutId(setTimeout(function () {
                    // Calculate percentage:
                    const p = Math.min(1, (new Date().getTime() - startTime) / duration);
                    // Calculate the absolute vertical position:
                    const y = Math.max(0, Math.floor(startY + distance * (p < 0.5 ? 2 * p * p : p * (4 - p * 2) - 1)));
                    container.toY(y);
                    if (p < 1 && container.getHeight() + y < container.body.scrollHeight) {
                        loopScroll();
                    }
                    else {
                        setTimeout(stopScroll, 99); // with cooldown time
                        if (onDone) {
                            onDone();
                        }
                    }
                }, 9));
            })();
        }
    };

    /**
     * Scrolls to the top of a specific element.
     *
     * @param {elem} The element to scroll to.
     * @param {duration} Optionally the duration of the scroll operation.
     * @param {onDone} An optional callback function to be invoked once the scroll finished.
     */
    const scrollToElem = function (elem, duration, onDone) {
        scrollToY(getTopWithEdgeOffset(elem), duration, onDone);
    };

    /**
     * Scrolls an element into view if necessary.
     *
     * @param {elem} The element.
     * @param {duration} Optionally the duration of the scroll operation.
     * @param {onDone} An optional callback function to be invoked once the scroll finished.
     */
    const scrollIntoView = function (elem, duration, onDone) {
        const elemHeight = elem.getBoundingClientRect().height;
        const elemBottom = container.getTopOf(elem) + elemHeight;
        const containerHeight = container.getHeight();
        const y = container.getY();
        const containerBottom = y + containerHeight;
        if (getTopWithEdgeOffset(elem) < y || elemHeight + edgeOffset > containerHeight) {
            // Element is clipped at top or is higher than screen.
            scrollToElem(elem, duration, onDone);
        }
        else if (elemBottom + edgeOffset > containerBottom) {
            // Element is clipped at the bottom.
            scrollToY(elemBottom - containerHeight + edgeOffset, duration, onDone);
        }
        else if (onDone) {
            onDone();
        }
    };

    /**
     * Scrolls to the center of an element.
     *
     * @param {elem} The element.
     * @param {duration} Optionally the duration of the scroll operation.
     * @param {offset} Optionally the offset of the top of the element from the center of the screen.
     *        A value of 0 is ignored.
     * @param {onDone} An optional callback function to be invoked once the scroll finished.
     */
    const scrollToCenterOf = function (elem, duration, offset, onDone) {
        scrollToY(Math.max(0, container.getTopOf(elem) - container.getHeight() / 2 + (offset || elem.getBoundingClientRect().height / 2)), duration, onDone);
    };

    /**
     * Changes default settings for this scroller.
     *
     * @param {newDefaultDuration} Optionally a new value for default duration, used for each scroll method by default.
     *        Ignored if null or undefined.
     * @param {newEdgeOffset} Optionally a new value for the edge offset, used by each scroll method by default. Ignored if null or undefined.
     * @returns An object with the current values.
     */
    const setup = function (newDefaultDuration, newEdgeOffset) {
        if (newDefaultDuration === 0 || newDefaultDuration) {
            defaultDuration = newDefaultDuration;
        }
        if (newEdgeOffset === 0 || newEdgeOffset) {
            edgeOffset = newEdgeOffset;
        }
        return {
            defaultDuration,
            edgeOffset,
        };
    };

    return {
        setup,
        to: scrollToElem,
        toX: scrollToX,
        toY: scrollToY,
        intoView: scrollIntoView,
        center: scrollToCenterOf,
        stop: stopScroll,
        moving: () => !!scrollTimeoutId,
        getX: container.getX,
        getY: container.getY,
        getTopOf: container.getTopOf,
    };
}

const docElem = document.documentElement;
const getDocX = () => window.scrollX || docElem.scrollLeft;
const getDocY = () => window.scrollY || docElem.scrollTop;

// Create a scroller for the document:
const Scroller = makeScroller({
    body: document.scrollingElement || document.body,
    toX: coord => {
        window.scrollTo(coord.x, coord.y);
    },
    toY: y => {
        window.scrollTo(0, y);
    },
    getX: getDocX,
    getY: getDocY,
    getHeight: () => window.innerHeight || docElem.clientHeight,
    getWidth: () => window.innerWidth || docElem.clientWidth,
    getTopOf: elem => elem.getBoundingClientRect().top + getDocY() - docElem.offsetTop,
});

/**
 * Creates a scroller from the provided container element (e.g., a DIV)
 *
 * @param {scrollContainer} The vertical position within the document.
 * @param {defaultDuration} Optionally a value for default duration, used for each scroll method by default.
 *        Ignored if 0 or null or undefined.
 * @param {edgeOffset} Optionally a value for the edge offset, used by each scroll method by default.
 *        Ignored if null or undefined.
 * @returns A scroller object, similar to `Scroller` but controlling the provided element.
 */
Scroller.createScroller = function (scrollContainer, defaultDuration, edgeOffset) {
    return makeScroller({
        body: scrollContainer,
        toX: x => {
            scrollContainer.scrollLeft = x;
        },
        toY: y => {
            scrollContainer.scrollTop = y;
        },
        getX: () => scrollContainer.scrollLeft,
        getY: () => scrollContainer.scrollTop,
        getHeight: () => Math.min(scrollContainer.clientHeight, window.innerHeight || docElem.clientHeight),
        getWidth: () => Math.min(scrollContainer.clientWidth, window.innerWidth || docElem.clientWidth),
        getTopOf: elem => elem.offsetTop,
    }, defaultDuration, edgeOffset);
};

export default Scroller;