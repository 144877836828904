var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["gfu-benefit-item", _vm.benefitClass] }, [
    _c("h3", { staticClass: "gfu-benefit-item__title" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "gfu-bt gfu-bt--b2 _tc--gray" }, [
      _vm._v(_vm._s(_vm.description)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }