import Formatter from '@/Utils/Formatter';

export function setChartXAxisDateCategories(chartOptions, categories) {
    return {
        ...chartOptions,
        ...{
            xaxis: {
                ...chartOptions.xaxis,
                categories: categories.map(d => new Date(d).getTime()),
            },
            yaxis: {
                ...chartOptions.yaxis,
                labels: {
                    ...chartOptions.labels,
                    formatter: (value) => {
                        if (value % 1 !== 0) return Formatter.format(value, 'N1');
                        return Formatter.format(value, 'N0');
                    },
                },
            },
        },
    };
}

export function setChartTooltipCurrencyFormat(chartOptions, currencySymbol) {
    return {
        ...chartOptions,
        ...{
            tooltip: {
                ...chartOptions.tooltip,
                y: {
                    ...chartOptions.tooltip && chartOptions.tooltip.y,
                    formatter (value) {
                        return Formatter.format(value, 'N2') + currencySymbol;
                    },
                },
            },
        },
    };
}

const defaultFontFamily = 'Nunito Sans, Helvetica, Arial, sans-serif';

export const defaultChartOptions = {
    chart: {
        fontFamily: defaultFontFamily,
        toolbar: {
            show: true,
            tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: '<span class="_fa fa-lg _fa--plus-circle gfua-chart__icon"></span>',
                zoomout: '<span class="_fa fa-lg  _fa--minus-circle gfua-chart__icon"></span>',
                // Disabling pan icon also disabled the functionality, instead we do display: none
                pan: true,
                reset: '<span class="_fa _fa--undo gfua-chart__icon"></span>',
            },
            autoSelected: 'pan',
        },
    },
    xaxis: {
        labels: {
            hideOverlappingLabels: true,
            trim: true,
            showDuplicates: false,
            datetimeFormatter: {
                year: 'yyyy',
                month: 'dd/MM',
                day: 'dd/MM',
                hour: 'HH:mm',
                minute: 'HH:mm:ss',
            },
            style: {
                cssClass: 'gfua-chart--text-light',
            },
        },
    },
    yaxis: {
        labels: {
            style: {
                cssClass: 'gfua-chart--text-light',
            },
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
        followCursor: true,
    },
};
