var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "gfu-fade" } }, [
    _vm.isVisible
      ? _c(
          "a",
          {
            class: [
              "gfu-project-return-to-navigation",
              "gfu-project-return-to-navigation--right",
              {
                "gfu-project-return-to-navigation--expanded": _vm.isBarExpanded,
              },
            ],
            style: _vm.elementStyle,
            attrs: { href: `#${_vm.elementId}` },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClick.apply(null, arguments)
              },
            },
          },
          [_c("span", { staticClass: "_fa _fa--chevron-up" })]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }