import Config from '@/Config';
import Log from '@/Utils/Log';
import { pluralizeWithRules } from '@/Utils/TextUtility';
import { languageTypes } from '@/Types/LanguageTypes.js';

export const defaultLanguage = 'en';
export const labelRepresentationTypes = {
    isoCode: 'isoCode',
    fullName: 'fullName',
};

export function pluralize(count, str) {
    const parts = str.split('|').map(i => i.replace('{n}', count).trim());
    const lang = Config.language.currentLanguageIsoCode || defaultLanguage;
    const warnMessage = `Pluralization string (${ lang }) '${ str }' does not have enough parts.`;

    const pluralCategories = {
        many: null,
        one: null,
        few: null,
        other: null,
        two: null,
        zero: null,
    };

    switch (parts.length) {
    case 1:
        pluralCategories.one = parts[0];
        pluralCategories.other = parts[0];
        Log.warn(warnMessage);
        break;
    case 2:
        pluralCategories.one = parts[0];
        pluralCategories.other = parts[1];
        break;
    case 3:
        pluralCategories.one = parts[0];
        pluralCategories.few = parts[1];
        pluralCategories.many = parts[2];
        break;
    case 4:
        pluralCategories.one = parts[0];
        pluralCategories.few = parts[1];
        pluralCategories.many = parts[2];
        pluralCategories.other = parts[3];
        break;
    case 5:
        pluralCategories.one = parts[0];
        pluralCategories.few = parts[1];
        pluralCategories.many = parts[2];
        pluralCategories.other = parts[3];
        Log.warn(warnMessage);
        break;
    default:
        pluralCategories.zero = parts[0];
        pluralCategories.one = parts[1];
        pluralCategories.two = parts[2];
        pluralCategories.few = parts[3];
        pluralCategories.many = parts[4];
        pluralCategories.other = parts[5];
        break;
    }

    return pluralizeWithRules(count, pluralCategories, lang);
}

export function getSupportedLanguages(labelRepresentation = labelRepresentationTypes.fullName) {
    return Object.keys(languageTypes)
        .map(lang =>
            ({ code: lang, isDefault: lang === defaultLanguage }),
        )
        .sort((lang1, lang2) =>
            lang2.isDefault - lang1.isDefault || lang1.code.localeCompare(lang2.code),
        )
        .reduce((languages, language) => {
            let key = languageTypes[language.code].value;

            if (labelRepresentation === labelRepresentationTypes.isoCode ) {
                key = languageTypes[language.code].code;
            }
            else if (language.isDefault) {
                key = `${key} (default)`;
            }
            languages[key] = languageTypes[language.code].code;

            return languages;
        }, {});
}