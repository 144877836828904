import { getWindowWidth } from '@/Utils/WindowUtility.js';

export default {
    data: () => ({
        screenWidth: 0,
    }),
    methods: {
        setScreenSize() {
            this.screenWidth = getWindowWidth();
        },
    },
    mounted() {
        this.setScreenSize();
        window.addEventListener('resize', this.setScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setScreenSize);
    },
};