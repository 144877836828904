var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flexcol _gap-2" },
    _vm._l(_vm.lines, function (line) {
      return _c("skeleton-line", { key: line })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }