var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-summary-progress" },
    [
      _c(
        "h5",
        {
          staticClass: "gfu-hd gfu-hd--h4 gfu-hd--light _ttu _mb-1",
          attrs: { "data-qa": "project-summary:Funded" },
        },
        [
          _c(
            "localize",
            {
              key: _vm.percentageOfGoal,
              attrs: {
                resource:
                  _vm.Resources.ProjectHome.ProjectSummaryProgressGoalHeader,
              },
            },
            [
              _c("display-currency-value", {
                attrs: {
                  "format-key": "0",
                  "display-tooltip": false,
                  tag: "span",
                },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function ({ displayCurrency }) {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$format(
                                _vm.campaignGoal /
                                  displayCurrency.relativeFactor,
                                "C0",
                                displayCurrency.symbol
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$format(_vm.campaignGoal, "C0")) +
                            "\n                "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _vm._v(" "),
              _c("animated-number", {
                attrs: {
                  "format-key": "1",
                  number: _vm.percentageOfGoal,
                  format: "P0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("display-currency-value", {
        staticClass: "gfu-bt gfu-bt--b1 _flex _ai-c",
        attrs: { tag: "div", "data-qa": "project-summary:Gathered" },
        scopedSlots: _vm._u([
          {
            key: "tooltip-content",
            fn: function () {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.Resources.format(
                        _vm.Resources.ProjectHome
                          .ProjectSummaryProgressCurrencyConversionTooltip,
                        _vm.$format(_vm.fundsGathered, "C2"),
                        _vm.$format(_vm.campaignGoal, "C0")
                      )
                    ) +
                    "\n        "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function ({ displayCurrency }) {
              return [
                _c("animated-number", {
                  staticClass: "gfu-hd gfu-hd--d1 _mr-1",
                  attrs: {
                    tag: "h3",
                    number: _vm.roundToIntegers(
                      _vm.fundsGathered / displayCurrency.relativeFactor
                    ),
                    format: "C0",
                    "currency-symbol": displayCurrency.symbol,
                  },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _c("animated-number", {
                  staticClass: "gfu-hd gfu-hd--d1 _mr-1",
                  attrs: {
                    tag: "h3",
                    number: _vm.roundToIntegers(_vm.fundsGathered),
                    format: "C0",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_mb-3" },
        [
          _c("progress-bar", {
            attrs: {
              value: _vm.fundsGathered,
              max: _vm.campaignGoal,
              "can-go-over": true,
              "progression-over-class":
                "gfu-progress-bar__progression-over gfu-progress-bar__progression-over--animated",
              "progression-class":
                "gfu-progress-bar__progression--accent gfu-progress-bar--animated",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "_flexbtwn" }, [
        _c(
          "h4",
          {
            staticClass: "gfu-hd gfu-hd--h4 _whs-nw",
            attrs: { "data-qa": "project-summary:BackersCount" },
          },
          [
            _c("span", { staticClass: "_fa _fa--user _mr-1" }),
            _vm._v(" "),
            _c("animated-number", {
              attrs: { number: _vm.backersCount, format: "N0" },
            }),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.Resources.pluralize(
                    _vm.backersCount,
                    _vm.Resources.ProjectHome.AnimatedNumberPluralizedBacker
                  )
                ) +
                "\n        "
            ),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.hideClock
          ? _c(
              "h4",
              {
                staticClass: "gfu-hd gfu-hd--h4 _whs-nw",
                attrs: { "data-qa": "project-summary:DaysLeft" },
              },
              [_c("time-left", { attrs: { timestamp: _vm.myDate } })],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }