var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    _vm._b(
      {
        class: [_vm.visibilityClass],
        attrs: {
          theme: "primary",
          width: "wide",
          shape: "soft",
          "data-qa": "login-button:signup",
        },
        on: { click: _vm.onClick },
      },
      "base-button",
      _vm.$attrs,
      false
    ),
    [_vm._v(_vm._s(_vm.Resources.Account.SignUpCallToActionButton))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }