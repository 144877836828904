var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("account-navigation", { attrs: { "page-identity": _vm.pageID } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
        [
          _c("h2", { staticClass: "gfu-heading gfu-heading--main _mt-6" }, [
            _vm._v(
              _vm._s(_vm.Resources.AccountSettings.AccountDetailsSectionTitle)
            ),
          ]),
          _vm._v(" "),
          _c("box", { staticClass: "_mt-6" }, [
            _vm.user
              ? _c("div", { staticClass: "_flexcol _gap-4 _fd-r--l" }, [
                  _c(
                    "div",
                    { staticClass: "gfu-1of1 gfu-1of2--l" },
                    [
                      _c(
                        "h2",
                        { staticClass: "gfu-heading gfu-heading--text" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.AccountSettings
                                .AccountDetailsSectionTitle
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            name: "model.fullName",
                            "display-name":
                              _vm.Resources.AccountSettings.FullNameFieldLabel,
                            description:
                              _vm.Resources.AccountSettings
                                .FullNameFieldDescription,
                            "data-qa": "account-settings:FullName",
                          },
                          model: {
                            value: _vm.model.fullName,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "fullName", $$v)
                            },
                            expression: "model.fullName",
                          },
                        },
                        [
                          _c("field-edit", {
                            staticClass: "_ai-c _jc-st",
                            attrs: { name: "model.fullName" },
                            on: {
                              edit: _vm.onEdit,
                              save: _vm.onSave,
                              cancel: _vm.onCancel,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    onSave,
                                    onCancel,
                                    isEditing,
                                  }) {
                                    return [
                                      _c("text-field", {
                                        ref: "fullName",
                                        staticClass: "_mt-0 _mr-1 _grow-1",
                                        attrs: {
                                          placeholder:
                                            _vm.Resources.AccountSettings
                                              .FullNameFieldPlaceholder,
                                          "show-label": false,
                                          disabled: !isEditing,
                                        },
                                        on: {
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return onSave.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "esc",
                                                  27,
                                                  $event.key,
                                                  ["Esc", "Escape"]
                                                )
                                              )
                                                return null
                                              return onCancel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.model.fullName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, "fullName", $$v)
                                          },
                                          expression: "model.fullName",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1601772496
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name": "Email",
                            "data-qa": "account-settings:Email",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "_flex _jc-st _ai-c" },
                            [
                              _c("text-field", {
                                staticClass: "_mt-0 _mr-1 _grow-1",
                                attrs: {
                                  "display-name":
                                    _vm.Resources.AccountSettings
                                      .EmailFieldLabel,
                                  disabled: true,
                                  "show-label": false,
                                  placeholder:
                                    _vm.Resources.AccountSettings
                                      .EmailFieldPlaceholder,
                                },
                                model: {
                                  value: _vm.user.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "email", $$v)
                                  },
                                  expression: "user.email",
                                },
                              }),
                              _vm._v(" "),
                              _c("icon-base-button", {
                                attrs: {
                                  theme: "lightAlt",
                                  shape: "circle",
                                  icon: "pen",
                                  "only-icon": true,
                                  "data-qa": "field-action:Edit",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onChangeEmail.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name":
                              _vm.Resources.AccountSettings.PasswordFieldLabel,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "_flex _jc-st _ai-c" },
                            [
                              _c("text-field", {
                                staticClass: "_mt-0 _mr-1 _grow-1",
                                attrs: {
                                  value: "**********",
                                  "display-name":
                                    _vm.Resources.AccountSettings
                                      .PasswordFieldLabel,
                                  conceal: true,
                                  type: "password",
                                  disabled: true,
                                  "show-label": false,
                                },
                              }),
                              _vm._v(" "),
                              _c("icon-base-button", {
                                attrs: {
                                  theme: "lightAlt",
                                  shape: "circle",
                                  icon: "pen",
                                  "only-icon": true,
                                  "data-qa":
                                    "account-settings-button:ChangePassword",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onChangePassword.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("saved-credit-cards", {
                        staticClass: "_my-6",
                        attrs: { "saved-cards": _vm.savedCards },
                        on: { "card-deleted": _vm.onCardDeleted },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-1of1 gfu-1of2--l" },
                    [
                      _c(
                        "h2",
                        { staticClass: "gfu-heading gfu-heading--text" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.AccountSettings
                                .ProfileCustomizationSectionTitle
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name":
                              _vm.Resources.AccountSettings.NicknameFieldLabel,
                            name: "model.nickname",
                            description:
                              _vm.Resources.AccountSettings
                                .NicknameFieldDescription,
                            "data-qa": "account-settings:Nickname",
                          },
                        },
                        [
                          _c("field-edit", {
                            staticClass: "_ai-c _jc-st",
                            attrs: { name: "model.nickname" },
                            on: {
                              edit: _vm.onEdit,
                              save: _vm.onSave,
                              cancel: _vm.onCancel,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    onSave,
                                    onCancel,
                                    isEditing,
                                  }) {
                                    return [
                                      _c("text-field", {
                                        ref: "nickname",
                                        staticClass: "_mt-0 _mr-1 _grow-1",
                                        attrs: {
                                          placeholder:
                                            _vm.Resources.AccountSettings
                                              .NicknameFieldPlaceholder,
                                          "show-label": false,
                                          disabled: !isEditing,
                                        },
                                        on: {
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return onSave.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "esc",
                                                  27,
                                                  $event.key,
                                                  ["Esc", "Escape"]
                                                )
                                              )
                                                return null
                                              return onCancel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.model.nickname,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, "nickname", $$v)
                                          },
                                          expression: "model.nickname",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              581098764
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "_mt-2" },
                        [
                          _c("image-field", {
                            attrs: {
                              "display-name":
                                _vm.Resources.AccountSettings.AvatarFieldLabel,
                              name: "avatarImageUrl",
                              "image-class": "gfu-avatar",
                              "upload-api-action": "images/uploadAvatarImage",
                              "thumb-url": _vm.model.avatarImageUrl,
                              "data-qa": "account-settings:AvatarImage",
                            },
                            on: {
                              "image-uploaded": _vm.onImageUploaded,
                              error: _vm.handleError,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "_mt-2" },
                        [
                          _c("switch-field", {
                            staticClass: "_mt-3",
                            attrs: {
                              name: "isPublic",
                              "show-label": false,
                              "on-label":
                                _vm.Resources.AccountSettings
                                  .IsPublicFieldOnLabel,
                              "off-label":
                                _vm.Resources.AccountSettings
                                  .IsPublicFieldOffLabel,
                              "display-name":
                                _vm.Resources.AccountSettings
                                  .IsPublicFieldLabel,
                              "data-qa": "account-settings:IsPublic",
                            },
                            on: { input: _vm.onChangeIsPublic },
                            model: {
                              value: _vm.model.isPublicField,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "isPublicField", $$v)
                              },
                              expression: "model.isPublicField",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          staticClass: "_mt-6",
                          attrs: {
                            "display-name":
                              _vm.Resources.AccountSettings.BioFieldLabel,
                            description:
                              _vm.Resources.AccountSettings.BioFieldDescription,
                          },
                        },
                        [
                          _c("field-edit", {
                            staticClass: "_jc-st",
                            attrs: {
                              name: "model.bio",
                              "data-qa": "account-settings:Bio",
                            },
                            on: {
                              edit: _vm.onEdit,
                              save: _vm.onSave,
                              cancel: _vm.onCancel,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    onSave,
                                    onCancel,
                                    isEditing,
                                  }) {
                                    return [
                                      _c("text-area-field", {
                                        ref: "bio",
                                        staticClass: "_mt-0 _mr-1 _grow-1",
                                        attrs: {
                                          name: "model.bio",
                                          rows: "6",
                                          "display-name":
                                            _vm.Resources.AccountSettings
                                              .BioFieldLabel,
                                          "show-label": false,
                                          disabled: !isEditing,
                                          maxlength: 300,
                                        },
                                        on: {
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return onSave.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "esc",
                                                  27,
                                                  $event.key,
                                                  ["Esc", "Escape"]
                                                )
                                              )
                                                return null
                                              return onCancel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.model.bio,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, "bio", $$v)
                                          },
                                          expression: "model.bio",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              942473324
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name":
                              _vm.Resources.AccountSettings.InstagramFieldLabel,
                            description:
                              _vm.Resources.AccountSettings
                                .InstagramFieldDescription,
                            name: "model.instagramUrl",
                            "data-qa": "account-settings:Instagram",
                          },
                        },
                        [
                          _c("field-edit", {
                            staticClass: "_ai-c _jc-st",
                            attrs: { name: "model.instagramUrl" },
                            on: {
                              edit: _vm.onEdit,
                              save: _vm.onSave,
                              cancel: _vm.onCancel,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    onSave,
                                    onCancel,
                                    isEditing,
                                  }) {
                                    return [
                                      _c("text-field", {
                                        ref: "instagramUrl",
                                        staticClass: "_mt-0 _mr-1 _grow-1",
                                        attrs: {
                                          placeholder:
                                            _vm.Resources.AccountSettings
                                              .InstagramFieldPlaceholder,
                                          "show-label": false,
                                          disabled: !isEditing,
                                        },
                                        on: {
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return onSave.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "esc",
                                                  27,
                                                  $event.key,
                                                  ["Esc", "Escape"]
                                                )
                                              )
                                                return null
                                              return onCancel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.model.instagramUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "instagramUrl",
                                              $$v
                                            )
                                          },
                                          expression: "model.instagramUrl",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1453658268
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name":
                              _vm.Resources.AccountSettings.FacebookFieldLabel,
                            description:
                              _vm.Resources.AccountSettings
                                .FacebookFieldDescription,
                            name: "model.facebookUrl",
                            "data-qa": "account-settings:Facebook",
                          },
                        },
                        [
                          _c("field-edit", {
                            staticClass: "_ai-c _jc-st",
                            attrs: { name: "model.facebookUrl" },
                            on: {
                              edit: _vm.onEdit,
                              save: _vm.onSave,
                              cancel: _vm.onCancel,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    onSave,
                                    onCancel,
                                    isEditing,
                                  }) {
                                    return [
                                      _c("text-field", {
                                        ref: "facebook",
                                        staticClass: "_mt-0 _mr-1 _grow-1",
                                        attrs: {
                                          placeholder:
                                            _vm.Resources.AccountSettings
                                              .FacebookFieldPlaceholder,
                                          "show-label": false,
                                          disabled: !isEditing,
                                        },
                                        on: {
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return onSave.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "esc",
                                                  27,
                                                  $event.key,
                                                  ["Esc", "Escape"]
                                                )
                                              )
                                                return null
                                              return onCancel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.model.facebookUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "facebookUrl",
                                              $$v
                                            )
                                          },
                                          expression: "model.facebookUrl",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1430487175
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name":
                              _vm.Resources.AccountSettings.TwitterFieldLabel,
                            description:
                              _vm.Resources.AccountSettings
                                .TwitterFieldDescription,
                            name: "model.twitterUrl",
                            "data-qa": "account-settings:Twitter",
                          },
                        },
                        [
                          _c("field-edit", {
                            staticClass: "_ai-c _jc-st",
                            attrs: { name: "model.twitterUrl" },
                            on: {
                              edit: _vm.onEdit,
                              save: _vm.onSave,
                              cancel: _vm.onCancel,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    onSave,
                                    onCancel,
                                    isEditing,
                                  }) {
                                    return [
                                      _c("text-field", {
                                        ref: "twitter",
                                        staticClass: "_mt-0 _mr-1 _grow-1",
                                        attrs: {
                                          placeholder:
                                            _vm.Resources.AccountSettings
                                              .TwitterFieldPlaceholder,
                                          "show-label": false,
                                          disabled: !isEditing,
                                        },
                                        on: {
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return onSave.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "esc",
                                                  27,
                                                  $event.key,
                                                  ["Esc", "Escape"]
                                                )
                                              )
                                                return null
                                              return onCancel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.model.twitterUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "twitterUrl",
                                              $$v
                                            )
                                          },
                                          expression: "model.twitterUrl",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3618475794
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "field",
                        {
                          attrs: {
                            "display-name":
                              _vm.Resources.AccountSettings.YoutubeFieldLabel,
                            name: "model.youtubeUrl",
                            description:
                              _vm.Resources.AccountSettings
                                .YoutubeFieldDescription,
                            "data-qa": "account-settings:Youtube",
                          },
                        },
                        [
                          _c("field-edit", {
                            staticClass: "_ai-c _jc-st",
                            attrs: { name: "model.youtubeUrl" },
                            on: {
                              edit: _vm.onEdit,
                              save: _vm.onSave,
                              cancel: _vm.onCancel,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    onSave,
                                    onCancel,
                                    isEditing,
                                  }) {
                                    return [
                                      _c("text-field", {
                                        ref: "youtube",
                                        staticClass: "_mt-0 _mr-1 _grow-1",
                                        attrs: {
                                          placeholder:
                                            _vm.Resources.AccountSettings
                                              .YoutubeFieldPlaceholder,
                                          "show-label": false,
                                          disabled: !isEditing,
                                        },
                                        on: {
                                          keyup: [
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return onSave.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "esc",
                                                  27,
                                                  $event.key,
                                                  ["Esc", "Escape"]
                                                )
                                              )
                                                return null
                                              return onCancel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.model.youtubeUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "youtubeUrl",
                                              $$v
                                            )
                                          },
                                          expression: "model.youtubeUrl",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3146362474
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("box", { staticClass: "_mt-6" }, [
            _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
              _vm._v(
                _vm._s(_vm.Resources.AccountSettings.DeleteAccountSectionTitle)
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(_vm.Resources.AccountSettings.DeleteAccountSectionText) +
                  "\n                "
              ),
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    href: "",
                    "data-qa": "account-settings-button:DeleteAccount",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onDeleteAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.Resources.AccountSettings.DeleteAccountLink)
                  ),
                ]
              ),
              _vm._v(".\n            "),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("account-delete-confirmation", {
        attrs: { "is-visible": _vm.isDeleteAccountModalVisible },
        on: { close: _vm.onDeleteModalClose },
      }),
      _vm._v(" "),
      _vm.isChangePasswordModalVisible
        ? _c("change-password-modal", {
            attrs: { "user-id": _vm.user.userID },
            on: {
              "password-changed": _vm.onPasswordChanged,
              close: _vm.onChangePasswordModalClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isChangeEmailModalVisible
        ? _c("change-email-modal", {
            attrs: {
              email: _vm.user.email,
              "pending-new-email": _vm.user.newEmail,
              "user-id": _vm.user.userID,
            },
            on: {
              "email-changed": _vm.onEmailChanged,
              close: _vm.onChangeEmailModalClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }