var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "scrollable-wrapper",
    {
      class: _vm.scrollClassList,
      attrs: { "scroll-direction": _vm.scrollDirection },
    },
    [
      _vm.isInitialized
        ? [
            _c(
              "div",
              { class: ["_flex _fd-c--l", _vm.wrapperClassList] },
              [
                _vm._l(_vm.notifications, function (notification, index) {
                  return _c(
                    "notification-tile",
                    _vm._b(
                      {
                        key: `notification-${index}`,
                        staticClass: "gfu-scrollable-item",
                        attrs: { "data-qa": `spotlight-notification:${index}` },
                      },
                      "notification-tile",
                      notification,
                      false
                    )
                  )
                }),
                _vm._v(" "),
                _c("load-more-trigger", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canLoadMoreEvents,
                      expression: "canLoadMoreEvents",
                    },
                  ],
                  staticClass: "_pl-1",
                  attrs: { threshold: 0.5, "root-margin": 400 },
                  on: { "load-more": _vm.getNotifications },
                }),
              ],
              2
            ),
          ]
        : [
            _c(
              "div",
              { class: ["_flex _fd-c--l", _vm.wrapperClassList] },
              _vm._l(_vm.placeholders, function (placeholder) {
                return _c("skeleton-notification", {
                  key: placeholder,
                  class: _vm.skeletonClassList,
                })
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }