var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flex _ai-c _gap-2 _ai-fs" },
    [
      _c("skeleton-avatar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_flex-col _gap-2 _grow-1" },
        [_c("skeleton-paragraph", { attrs: { lines: _vm.mediaLinesCount } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }