export default {
    data: () => ({
        availableOrder: {},
        firstItemNumber: 1,
        lastItemNumber: 0,
        order: 0,
        pageIndex: 0,
        pageSize: 50,
        pagedItems: [],
        totalItemCount: 0,
        totalPageCount: 0,
    }),
    methods: {
        setPaginationData(data, reset = false) {
            this.availableOrder = data.availableOrder;
            this.firstItemNumber = data.firstItemNumber;
            this.lastItemNumber = data.lastItemNumber;
            this.order = data.order;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.pagedItems = reset ? data.pagedItems : [
                ...this.pagedItems,
                ...data.pagedItems,
            ],
            this.totalItemCount = data.totalItemCount;
            this.totalPageCount = data.totalPageCount;
        },
        resetPaginationData() {
            this.availableOrder = {};
            this.firstItemNumber = 1;
            this.lastItemNumber = 0;
            this.order = 0;
            this.pageIndex = 0;
            this.pageSize = 50;
            this.pagedItems = [];
            this.totalItemCount = 0;
            this.totalPageCount = 0;
        },
    },
    computed: {
        hasPagedItems() {
            return this.pagedItems.length > 0;
        },
        hasMorePagedItems() {
            return this.pageIndex < this.totalPageCount - 1;
        },
    },
};
