// -------------------------------------------------------------------------------------
// Date
// -------------------------------------------------------------------------------------

import CultureInfo from './CultureInfo';
import StringBuilder from './StringBuilder';

const Date = {};

export default Date;

Date._appendPreOrPostMatch = function _appendPreOrPostMatch(preMatch, strBuilder) {
    let quoteCount = 0;
    let escaped = false;
    for (let i = 0, il = preMatch.length; i < il; i++) {
        const c = preMatch.charAt(i);
        switch (c) {
        case '\'':
            if (escaped) strBuilder.append('\'');
            else quoteCount++;
            escaped = false;
            break;
        case '\\':
            if (escaped) strBuilder.append('\\');
            escaped = !escaped;
            break;
        default:
            strBuilder.append(c);
            escaped = false;
            break;
        }
    }
    return quoteCount;
};

Date._expandFormat = function _expandFormat(dtf, format) {
    if (!format) {
        format = 'F';
    }
    const len = format.length;
    if (len === 1) {
        switch (format) {
        case 'd':
            return dtf.shortDatePattern;
        case 'D':
            return dtf.longDatePattern;
        case 't':
            return dtf.shortTimePattern;
        case 'T':
            return dtf.longTimePattern;
        case 'g':
            return `${ dtf.shortDatePattern } ${ dtf.shortTimePattern }`;
        case 'f':
            return `${ dtf.longDatePattern } ${ dtf.shortTimePattern }`;
        case 'F':
            return dtf.fullDateTimePattern;
        case 'M': case 'm':
            return dtf.monthDayPattern;
        case 's':
            return dtf.sortableDateTimePattern;
        case 'Y': case 'y':
            return dtf.yearMonthPattern;
        case 'O': case 'o':
            return 'yyyy-MM-dd\'T\'HH:mm:ss.fffffffzzz';
        default:
            throw new Error('Invalid format string');
        }
    }
    else if (len === 2 && format.charAt(0) === '%') {
        format = format.charAt(1);
    }
    return format;
};

Date._expandYear = function _expandYear(dtf, year) {
    const now = new Date();
    const era = Date._getEra(now);
    if (year < 100) {
        const curr = Date._getEraYear(now, dtf, era);
        year += curr - curr % 100;
        if (year > dtf.calendar.twoDigitYearMax) {
            year -= 100;
        }
    }
    return year;
};

Date._getEra = function _getEra(date, eras) {
    if (!eras) return 0;
    let start; const ticks = date.getTime();
    for (let i = 0, l = eras.length; i < l; i += 4) {
        start = eras[i + 2];
        if (start === null || ticks >= start) {
            return i;
        }
    }
    return 0;
};

Date._getEraYear = function _getEraYear(date, dtf, era, sortable) {
    let year = date.getFullYear();
    if (!sortable && dtf.eras) {
        year -= dtf.eras[era + 3];
    }
    return year;
};

Date._getParseRegExp = function _getParseRegExp(dtf, format) {
    if (!dtf._parseRegExp) {
        dtf._parseRegExp = {};
    }
    else if (dtf._parseRegExp[format]) {
        return dtf._parseRegExp[format];
    }
    let expFormat = Date._expandFormat(dtf, format);
    expFormat = expFormat.replace(/([\^\$\.\*\+\?\|\[\]\(\)\{\}])/g, '\\\\$1');
    const regexp = new StringBuilder('^');
    const groups = [];
    let index = 0;
    let quoteCount = 0;
    const tokenRegExp = Date._getTokenRegExp();
    let match;
    while ((match = tokenRegExp.exec(expFormat)) !== null) {
        const preMatch = expFormat.slice(index, match.index);
        index = tokenRegExp.lastIndex;
        quoteCount += Date._appendPreOrPostMatch(preMatch, regexp);
        if (quoteCount % 2 === 1) {
            regexp.append(match[0]);
            continue;
        }
        switch (match[0]) {
        case 'dddd': case 'ddd':
        case 'MMMM': case 'MMM':
        case 'gg': case 'g':
            regexp.append('(\\D+)');
            break;
        case 'tt': case 't':
            regexp.append('(\\D*)');
            break;
        case 'yyyy':
            regexp.append('(\\d{4})');
            break;
        case 'fff':
            regexp.append('(\\d{3})');
            break;
        case 'ff':
            regexp.append('(\\d{2})');
            break;
        case 'f':
            regexp.append('(\\d)');
            break;
        case 'dd': case 'd':
        case 'MM': case 'M':
        case 'yy': case 'y':
        case 'HH': case 'H':
        case 'hh': case 'h':
        case 'mm': case 'm':
        case 'ss': case 's':
            regexp.append('(\\d\\d?)');
            break;
        case 'zzz':
            regexp.append('([+-]?\\d\\d?:\\d{2})');
            break;
        case 'zz': case 'z':
            regexp.append('([+-]?\\d\\d?)');
            break;
        case '/':
            regexp.append(`(\\${ dtf.dateSeparator })`);
            break;
        default:
            throw new Error('Invalid date format pattern');
        }
        Array.add(groups, match[0]);
    }
    Date._appendPreOrPostMatch(expFormat.slice(index), regexp);
    regexp.append('$');
    const regexpStr = regexp.toString().replace(/\s+/g, '\\s+');
    const parseRegExp = { 'regExp': regexpStr, groups };
    dtf._parseRegExp[format] = parseRegExp;
    return parseRegExp;
};

Date._getTokenRegExp = function _getTokenRegExp() {
    return /\/|dddd|ddd|dd|d|MMMM|MMM|MM|M|yyyy|yy|y|hh|h|HH|H|mm|m|ss|s|tt|t|fff|ff|f|zzz|zz|z|gg|g/g;
};

Date.parseLocale = function parseLocale(value, formats) {
    // / <summary locid='M:J#Date.parseLocale' />
    // / <param name='value' type='String'></param>
    // / <param name='formats' parameterArray='true' optional='true' mayBeNull='true'></param>
    // / <returns type='Date'></returns>
    return Date._parse(value, CultureInfo.currentCulture, arguments);
};

Date.parseInvariant = function parseInvariant(value, formats) {
    // / <summary locid='M:J#Date.parseInvariant' />
    // / <param name='value' type='String'></param>
    // / <param name='formats' parameterArray='true' optional='true' mayBeNull='true'></param>
    // / <returns type='Date'></returns>
    return Date._parse(value, CultureInfo.invariantCulture, arguments);
};

Date._parse = function _parse(value, cultureInfo, args) {
    let i; let l; let date; let format; let formats; let custom = false;
    for (i = 1, l = args.length; i < l; i++) {
        format = args[i];
        if (format) {
            custom = true;
            date = Date._parseExact(value, format, cultureInfo);
            if (date) return date;
        }
    }
    if (!custom) {
        formats = cultureInfo._getDateTimeFormats();
        for (i = 0, l = formats.length; i < l; i++) {
            date = Date._parseExact(value, formats[i], cultureInfo);
            if (date) return date;
        }
    }
    return null;
};

Date._parseExact = function _parseExact(value, format, cultureInfo) {
    value = value.trim();
    const dtf = cultureInfo.dateTimeFormat;
    const parseInfo = Date._getParseRegExp(dtf, format);
    const match = new RegExp(parseInfo.regExp).exec(value);
    if (match === null) return null;

    const groups = parseInfo.groups;
    let era = null; let year = null; let month = null; let date = null; let weekDay = null;
    let hour = 0; let hourOffset; let min = 0; let sec = 0; let msec = 0; let tzMinOffset = null;
    let pmHour = false;
    for (let j = 0, jl = groups.length; j < jl; j++) {
        const matchGroup = match[j + 1];
        if (matchGroup) {
            switch (groups[j]) {
            case 'dd': case 'd':
                date = parseInt(matchGroup, 10);
                if (date < 1 || date > 31) return null;
                break;
            case 'MMMM':
                month = cultureInfo._getMonthIndex(matchGroup);
                if (month < 0 || month > 11) return null;
                break;
            case 'MMM':
                month = cultureInfo._getAbbrMonthIndex(matchGroup);
                if (month < 0 || month > 11) return null;
                break;
            case 'M': case 'MM':
                month = parseInt(matchGroup, 10) - 1;
                if (month < 0 || month > 11) return null;
                break;
            case 'y': case 'yy':
                year = Date._expandYear(dtf, parseInt(matchGroup, 10));
                if (year < 0 || year > 9999) return null;
                break;
            case 'yyyy':
                year = parseInt(matchGroup, 10);
                if (year < 0 || year > 9999) return null;
                break;
            case 'h': case 'hh':
                hour = parseInt(matchGroup, 10);
                if (hour === 12) hour = 0;
                if (hour < 0 || hour > 11) return null;
                break;
            case 'H': case 'HH':
                hour = parseInt(matchGroup, 10);
                if (hour < 0 || hour > 23) return null;
                break;
            case 'm': case 'mm':
                min = parseInt(matchGroup, 10);
                if (min < 0 || min > 59) return null;
                break;
            case 's': case 'ss':
                sec = parseInt(matchGroup, 10);
                if (sec < 0 || sec > 59) return null;
                break;
            case 'tt': case 't':
                var upperToken = matchGroup.toUpperCase();
                pmHour = upperToken === dtf.pmDesignator.toUpperCase();
                if (!pmHour && upperToken !== dtf.amDesignator.toUpperCase()) return null;
                break;
            case 'f':
                msec = parseInt(matchGroup, 10) * 100;
                if (msec < 0 || msec > 999) return null;
                break;
            case 'ff':
                msec = parseInt(matchGroup, 10) * 10;
                if (msec < 0 || msec > 999) return null;
                break;
            case 'fff':
                msec = parseInt(matchGroup, 10);
                if (msec < 0 || msec > 999) return null;
                break;
            case 'dddd':
                weekDay = cultureInfo._getDayIndex(matchGroup);
                if (weekDay < 0 || weekDay > 6) return null;
                break;
            case 'ddd':
                weekDay = cultureInfo._getAbbrDayIndex(matchGroup);
                if (weekDay < 0 || weekDay > 6) return null;
                break;
            case 'zzz':
                var offsets = matchGroup.split(/:/);
                if (offsets.length !== 2) return null;
                hourOffset = parseInt(offsets[0], 10);
                if (hourOffset < -12 || hourOffset > 13) return null;
                var minOffset = parseInt(offsets[1], 10);
                if (minOffset < 0 || minOffset > 59) return null;
                tzMinOffset = hourOffset * 60 + (matchGroup.startsWith('-') ? -minOffset : minOffset);
                break;
            case 'z': case 'zz':
                hourOffset = parseInt(matchGroup, 10);
                if (hourOffset < -12 || hourOffset > 13) return null;
                tzMinOffset = hourOffset * 60;
                break;
            case 'g': case 'gg':
                var eraName = matchGroup;
                if (!eraName || !dtf.eras) return null;
                eraName = eraName.toLowerCase().trim();
                for (let i = 0, l = dtf.eras.length; i < l; i += 4) {
                    if (eraName === dtf.eras[i + 1].toLowerCase()) {
                        era = i;
                        break;
                    }
                }
                if (era === null) return null;
                break;
            }
        }
    }
    let result = new Date(); let defaults; const convert = dtf.calendar.convert;
    if (convert) {
        defaults = convert.fromGregorian(result);
    }
    if (!convert) {
        defaults = [result.getFullYear(), result.getMonth(), result.getDate()];
    }
    if (year === null) {
        year = defaults[0];
    }
    else if (dtf.eras) {
        year += dtf.eras[(era || 0) + 3];
    }
    if (month === null) {
        month = defaults[1];
    }
    if (date === null) {
        date = defaults[2];
    }
    if (convert) {
        result = convert.toGregorian(year, month, date);
        if (result === null) return null;
    }
    else {
        result.setFullYear(year, month, date);
        if (result.getDate() !== date) return null;
        if (weekDay !== null && result.getDay() !== weekDay) {
            return null;
        }
    }
    if (pmHour && hour < 12) {
        hour += 12;
    }
    result.setHours(hour, min, sec, msec);
    if (tzMinOffset !== null) {
        const adjustedMin = result.getMinutes() - (tzMinOffset + result.getTimezoneOffset());
        result.setHours(result.getHours() + parseInt(adjustedMin / 60, 10), adjustedMin % 60);
    }
    return result;
};

Date.format = function format(inputDate, format) {
    return Date.toFormattedString(inputDate, format, CultureInfo.invariantCulture);
};

Date.localeFormat = function localeFormat(inputDate, format) {
    return Date.toFormattedString(inputDate, format, CultureInfo.currentCulture);
};

Date.toFormattedString = function toFormattedString(inputDate, format, cultureInfo) {
    const dtf = cultureInfo.dateTimeFormat;
    const convert = dtf.calendar.convert;
    if (!format || !format.length || format === 'i') {
        if (cultureInfo && cultureInfo.name.length) {
            if (convert) {
                return inputDate.toFormattedString(dtf.fullDateTimePattern, cultureInfo);
            }
            else {
                const eraDate = new Date(inputDate.getTime());
                const era = Date._getEra(inputDate, dtf.eras);
                eraDate.setFullYear(Date._getEraYear(inputDate, dtf, era));
                return eraDate.toLocaleString();
            }
        }
        else {
            return inputDate.toString();
        }
    }
    const eras = dtf.eras;
    const sortable = format === 's';
    format = Date._expandFormat(dtf, format);
    const ret = new StringBuilder();
    let hour;
    function addLeadingZero(num) {
        if (num < 10) {
            return `0${ num }`;
        }
        return num.toString();
    }
    function addLeadingZeros(num) {
        if (num < 10) {
            return `00${ num }`;
        }
        if (num < 100) {
            return `0${ num }`;
        }
        return num.toString();
    }
    function padYear(year) {
        if (year < 10) {
            return `000${ year }`;
        }
        else if (year < 100) {
            return `00${ year }`;
        }
        else if (year < 1000) {
            return `0${ year }`;
        }
        return year.toString();
    }

    let foundDay; let checkedDay; const dayPartRegExp = /([^d]|^)(d|dd)([^d]|$)/g;
    function hasDay() {
        if (foundDay || checkedDay) {
            return foundDay;
        }
        foundDay = dayPartRegExp.test(format);
        checkedDay = true;
        return foundDay;
    }

    let quoteCount = 0;
    const tokenRegExp = Date._getTokenRegExp();
    let converted;
    if (!sortable && convert) {
        converted = convert.fromGregorian(inputDate);
    }

    function getPart(date, part) {
        if (converted) {
            return converted[part];
        }
        switch (part) {
        case 0: return date.getFullYear();
        case 1: return date.getMonth();
        case 2: return date.getDate();
        }
    }

    for (; ;) {
        const index = tokenRegExp.lastIndex;
        const ar = tokenRegExp.exec(format);
        const preMatch = format.slice(index, ar ? ar.index : format.length);
        quoteCount += Date._appendPreOrPostMatch(preMatch, ret);
        if (!ar) break;
        if (quoteCount % 2 === 1) {
            ret.append(ar[0]);
            continue;
        }

        switch (ar[0]) {
        case 'dddd':
            ret.append(dtf.dayNames[inputDate.getDay()]);
            break;
        case 'ddd':
            ret.append(dtf.abbreviatedDayNames[inputDate.getDay()]);
            break;
        case 'dd':
            foundDay = true;
            ret.append(addLeadingZero(getPart(inputDate, 2)));
            break;
        case 'd':
            foundDay = true;
            ret.append(getPart(inputDate, 2));
            break;
        case 'MMMM':
            ret.append(dtf.monthGenitiveNames && hasDay()
                ? dtf.monthGenitiveNames[getPart(inputDate, 1)]
                : dtf.monthNames[getPart(inputDate, 1)]);
            break;
        case 'MMM':
            ret.append(dtf.abbreviatedMonthGenitiveNames && hasDay()
                ? dtf.abbreviatedMonthGenitiveNames[getPart(inputDate, 1)]
                : dtf.abbreviatedMonthNames[getPart(inputDate, 1)]);
            break;
        case 'MM':
            ret.append(addLeadingZero(getPart(inputDate, 1) + 1));
            break;
        case 'M':
            ret.append(getPart(inputDate, 1) + 1);
            break;
        case 'yyyy':
            ret.append(padYear(converted ? converted[0] : Date._getEraYear(inputDate, dtf, Date._getEra(inputDate, eras), sortable)));
            break;
        case 'yy':
            ret.append(addLeadingZero((converted ? converted[0] : Date._getEraYear(inputDate, dtf, Date._getEra(inputDate, eras), sortable)) % 100));
            break;
        case 'y':
            ret.append((converted ? converted[0] : Date._getEraYear(inputDate, dtf, Date._getEra(inputDate, eras), sortable)) % 100);
            break;
        case 'hh':
            hour = inputDate.getHours() % 12;
            if (hour === 0) hour = 12;
            ret.append(addLeadingZero(hour));
            break;
        case 'h':
            hour = inputDate.getHours() % 12;
            if (hour === 0) hour = 12;
            ret.append(hour);
            break;
        case 'HH':
            ret.append(addLeadingZero(inputDate.getHours()));
            break;
        case 'H':
            ret.append(inputDate.getHours());
            break;
        case 'mm':
            ret.append(addLeadingZero(inputDate.getMinutes()));
            break;
        case 'm':
            ret.append(inputDate.getMinutes());
            break;
        case 'ss':
            ret.append(addLeadingZero(inputDate.getSeconds()));
            break;
        case 's':
            ret.append(inputDate.getSeconds());
            break;
        case 'tt':
            ret.append(inputDate.getHours() < 12 ? dtf.amDesignator : dtf.pmDesignator);
            break;
        case 't':
            ret.append((inputDate.getHours() < 12 ? dtf.amDesignator : dtf.pmDesignator).charAt(0));
            break;
        case 'f':
            ret.append(addLeadingZeros(inputDate.getMilliseconds()).charAt(0));
            break;
        case 'ff':
            ret.append(addLeadingZeros(inputDate.getMilliseconds()).substr(0, 2));
            break;
        case 'fff':
            ret.append(addLeadingZeros(inputDate.getMilliseconds()));
            break;
        case 'z':
            hour = inputDate.getTimezoneOffset() / 60;
            ret.append((hour <= 0 ? '+' : '-') + Math.floor(Math.abs(hour)));
            break;
        case 'zz':
            hour = inputDate.getTimezoneOffset() / 60;
            ret.append((hour <= 0 ? '+' : '-') + addLeadingZero(Math.floor(Math.abs(hour))));
            break;
        case 'zzz':
            hour = inputDate.getTimezoneOffset() / 60;
            ret.append(`${ (hour <= 0 ? '+' : '-') + addLeadingZero(Math.floor(Math.abs(hour)))
            }:${ addLeadingZero(Math.abs(inputDate.getTimezoneOffset() % 60)) }`);
            break;
        case 'g':
        case 'gg':
            if (dtf.eras) {
                ret.append(dtf.eras[Date._getEra(inputDate, eras) + 1]);
            }
            break;
        case '/':
            ret.append(dtf.dateSeparator);
            break;
        default:
            throw new Error('Invalid date format pattern');
        }
    }
    return ret.toString();
};
