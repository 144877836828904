var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.classList] },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "base-button",
        {
          class: [
            "gfu-section-expander__button",
            "_bxsh--base",
            { "gfu-section-expander__button--expanded": _vm.isExpanded },
          ],
          attrs: { theme: "lightFlat", shape: "common" },
          on: { click: _vm.changeState },
        },
        [
          _c("span", { staticClass: "_tc--gray" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.buttonConfig.text) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("base-icon", {
            staticClass: "_tc--accent",
            attrs: { name: _vm.buttonConfig.icon, size: "lg" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }