var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isProjectV1
    ? _c("div", { staticClass: "_mt-a" }, [
        _vm.showCrowdfundingOnlyFundsGathered
          ? _c("div", { staticClass: "_flex _ai-b _flexwrap" }, [
              _c(
                "h3",
                {
                  class: [
                    "gfu-hd gfu-hd--h3 _mr-1",
                    { "_tc--inverse": _vm.scheme === "inverse" },
                  ],
                  attrs: { "data-qa": "project-card:FundsGathered" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$format(_vm.fundsGathered, "C0", _vm.currencySymbol)
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.showFundedAt && _vm.fundedAtText
                ? _c(
                    "span",
                    {
                      staticClass: "gfu-bt gfu-bt--caption _tc--lighter",
                      attrs: { "data-qa": "project-card:FundedAtText" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.Resources.format(
                              _vm.Resources.Homepage
                                .ProjectCardFundingGoalReachedInCaption,
                              _vm.fundedAtText
                            )
                          ) +
                          "\n            "
                      ),
                      _c(
                        "tooltip",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.Resources.format(
                                            _vm.Resources.Homepage
                                              .ProjectCardFundingGoalPercentageTooltip,
                                            _vm.fundsGatheredPercentage
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2882344171
                          ),
                        },
                        [_c("span", { staticClass: "_fa _fa--info" })]
                      ),
                    ],
                    1
                  )
                : _c(
                    "span",
                    { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.Resources.Homepage
                              .ProjectCardFundingAmountPledgedCaption
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
            ])
          : _vm.showEverythingFundsGathered
          ? _c("div", { staticClass: "_flex _flexwrap _ai-b" }, [
              _c(
                "h3",
                {
                  class: [
                    "gfu-hd gfu-hd--h3 _mr-1",
                    { "_tc--inverse": _vm.scheme === "inverse" },
                  ],
                  attrs: { "data-qa": "project-card:FundsGathered" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$format(
                          _vm.totalFundsGathered,
                          "C0",
                          _vm.currencySymbol
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.Resources.Homepage.ProjectCardFundingTotalCaption
                      ) +
                      "\n            "
                  ),
                  _c(
                    "tooltip",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.Resources.Homepage
                                        .ProjectCardFundingTotalTooltip
                                    ) +
                                    "\n                "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3945823635
                      ),
                    },
                    [_c("span", { staticClass: "_fa _fa--info" })]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }