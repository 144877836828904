import ServiceBase from './ServiceBase';

export default class EspagoService extends ServiceBase {
    constructor($http, useLiveApi) {
        super($http);
        this.baseUrl = useLiveApi ? 'https://secure.espago.com/api' : 'https://sandbox.espago.com/api';
    }

    createToken(publicKey, cardInfo) {
        if (!this.baseUrl)
            throw 'Invalid Espago base url.';

        const params = { card: cardInfo };

        const config = {
            withCredentials: false,
            auth: {
                username: publicKey,
                password: '',
            },
        };

        return this.$http.post(`${ this.baseUrl }/tokens`, params, config);
    }
}