var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isOpenForOrders
        ? _c(
            "base-notificator",
            {
              staticClass: "_my-4",
              attrs: {
                type: "info",
                "can-close": false,
                "data-qa": "notificator-item-info:ProjectDoesNotAcceptPledges",
              },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.Resources.YourPledge.NoNewOrdersInfoToast)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.skeletonItems.length
        ? _c(
            "div",
            {
              staticClass: "_mb-3",
              attrs: { "data-qa": "cart-table-item-list:box" },
            },
            _vm._l(_vm.skeletonItems, function (i) {
              return _c("skeleton-media-object", { key: i })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.groupedOrderItems, function (group) {
        return _c(
          "div",
          {
            key: group.title,
            staticClass: "_mb-3",
            attrs: { "data-qa": "cart-table-item-list:box" },
          },
          [
            _vm.groupedOrderItems.length > 1
              ? _c(
                  "span",
                  { staticClass: "gfu-bt--b2 _tc--light _ttu _fw-n _lts-2" },
                  [_vm._v(_vm._s(group.title))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(group.productOrderItems, function (item) {
              return _c(
                "cart-table-product-order-item",
                _vm._b(
                  {
                    key: item.orderItemID,
                    attrs: {
                      "data-qa": `cart-table-order-item:${item.orderItemId}`,
                    },
                    on: {
                      edit: _vm.onEdit,
                      remove: function ($event) {
                        return _vm.onRemove(item.orderItemId)
                      },
                    },
                  },
                  "cart-table-product-order-item",
                  item,
                  false
                )
              )
            }),
            _vm._v(" "),
            _vm._l(group.customOrderItems, function (item) {
              return _c(
                "cart-table-custom-order-item",
                _vm._b(
                  {
                    key: item.orderItemID,
                    attrs: {
                      "data-qa": `cart-table-order-item:${item.orderItemId}`,
                    },
                    on: {
                      remove: function ($event) {
                        return _vm.onRemove(item.orderItemId)
                      },
                    },
                  },
                  "cart-table-custom-order-item",
                  item,
                  false
                )
              )
            }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }