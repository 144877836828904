var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("option-field", {
    class: ["gfu-field-select--primary", _vm.fieldClass],
    attrs: {
      "display-name": _vm.option.text,
      options: _vm.getOptions(_vm.option),
      "show-placeholder": true,
      name: "model",
      "show-label": _vm.showLabel,
      inline: _vm.inline,
      small: _vm.small,
      "no-option-capitalize": true,
      disabled: _vm.disabled,
      "data-qa": "product-grouped-option-field:" + _vm.option.text,
    },
    on: { change: _vm.onChange },
    scopedSlots: _vm._u(
      [
        _vm.showLabel
          ? {
              key: "label",
              fn: function () {
                return [
                  _c(
                    "field-label",
                    {
                      staticClass: "_mb-1",
                      class: _vm.labelClass,
                      attrs: { "is-required": _vm.isRequired },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "description",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.tooltipLabel) +
                                    "\n                "
                                ),
                                _c(
                                  "tooltip",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "content",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "_px-1 _pt-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.tooltipDescription
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                _vm._l(
                                                  _vm.setItemNames,
                                                  function (name) {
                                                    return _c(
                                                      "li",
                                                      { key: name },
                                                      [_vm._v(_vm._s(name))]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      359186836
                                    ),
                                  },
                                  [
                                    _c("base-icon", {
                                      attrs: { name: "info" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2602920575
                      ),
                    },
                    [
                      _c(
                        "span",
                        { attrs: { "data-qa": "product-grouped-option-text" } },
                        [_vm._v(_vm._s(_vm.option.text))]
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }