import { stretchGoalTargetTypes } from '@/Models';
import { calculatePercentage } from '@/Utils/MathUtility';
import Resources from '@/Resources.js';

export default {
    methods: {
        resolveStatusText(stretchGoal, currencySymbol = null, currencyFactor = 1) {
            switch (stretchGoal.targetType) {
            case stretchGoalTargetTypes.backersCount:
                return Resources.format(Resources.ProjectHome.ProjectStretchGoalUnlocksByBackerCount, this.$format(stretchGoal.targetValue));
            case stretchGoalTargetTypes.backedFunds:
                return Resources.format(Resources.ProjectHome.ProjectStretchGoalUnlocksByPledgeValue, this.$format(stretchGoal.targetValue / currencyFactor, 'C0', currencySymbol));
            case stretchGoalTargetTypes.campaignDay:
                return Resources.format(Resources.ProjectHome.ProjectStretchGoalUnlocksByDay, this.$format(stretchGoal.targetValue));
            default:
                return `${ this.$format(stretchGoal.targetValue) } ${ stretchGoal.targetTypeName }`;
            }
        },
        resolveValueFormatter(stretchGoal, currencySymbol = null) {
            switch (stretchGoal.targetType) {
            case stretchGoalTargetTypes.backersCount:
                return (num) => this.$format(num);
            case stretchGoalTargetTypes.backedFunds:
                return (num) => this.$format(num, 'C0', currencySymbol);
            default:
                throw new Error('Not supported');
            }
        },
        resolveProgress(stretchGoal, currencySymbol = null, currencyFactor = 1) {
            const targetValueRemaining = stretchGoal.targetValue - stretchGoal.value;
            switch (stretchGoal.targetType) {
            case stretchGoalTargetTypes.backersCount:
                return Resources.format(Resources.ProjectHome.ProjectStretchGoalProgressBackerCount, Resources.pluralize(targetValueRemaining, Resources.ProjectHome.ProjectSummaryProgressPluralizedBacker));
            case stretchGoalTargetTypes.backedFunds:
                return Resources.format(Resources.ProjectHome.ProjectStretchGoalProgressPledgeValue, this.$format(targetValueRemaining / currencyFactor, 'C0', currencySymbol));
            case stretchGoalTargetTypes.campaignDay:
                return Resources.format(Resources.ProjectHome.ProjectStretchGoalProgressCampaignDay, targetValueRemaining, Resources.pluralize(targetValueRemaining, Resources.ProjectHome.HumanizeDatePluralizedDay));
            default:
                return `${ Math.floor(calculatePercentage(stretchGoal.value, stretchGoal.targetValue)) }% completed`;
            }
        },
        resolveDescription(stretchGoal, currencySymbol = null, currencyFactor = 1) {
            switch (stretchGoal.targetType) {
            case stretchGoalTargetTypes.backersCount:
                return stretchGoal.isUnlocked
                    ? Resources.format(Resources.ProjectHome.ProjectStretchGoalBackersCountUnlockedDescription, Resources.pluralize(stretchGoal.targetValue, Resources.ProjectHome.ProjectSummaryProgressPluralizedBacker))
                    : Resources.format(Resources.ProjectHome.ProjectStretchGoalBackersCountNotUnlockedDescription, Resources.pluralize(stretchGoal.targetValue, Resources.ProjectHome.ProjectSummaryProgressPluralizedBacker));
            case stretchGoalTargetTypes.backedFunds:
                return stretchGoal.isUnlocked
                    ? Resources.format(Resources.ProjectHome.ProjectStretchGoalPledgeValueUnlockedDescription, this.$format(stretchGoal.targetValue / currencyFactor, 'C0', currencySymbol))
                    : Resources.format(Resources.ProjectHome.ProjectStretchGoalPledgeValueNotUnlockedDescription, this.$format(stretchGoal.targetValue / currencyFactor, 'C0', currencySymbol));
            case stretchGoalTargetTypes.campaignDay:
                return stretchGoal.isUnlocked
                    ? Resources.format(Resources.ProjectHome.ProjectStretchGoalCampaignDayUnlockedDescription, stretchGoal.targetValue)
                    : Resources.format(Resources.ProjectHome.ProjectStretchGoalCampaignDayNotUnlockedDescription, stretchGoal.targetValue);
            default:
                return stretchGoal.targetDescription;
            }
        },
    },
};