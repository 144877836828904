var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-mini-wizard-wrapper" },
    [
      _c(
        "box",
        { staticClass: "gfu-project-mini-wizard _fo", attrs: { main: true } },
        [
          _vm._t("header"),
          _vm._v(" "),
          _c(
            "detect-overflow",
            {
              staticClass: "gfu-project-mini-wizard__content",
              attrs: { "data-qa": "mini-wizard-content" },
            },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _vm._t("footer"),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }