var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.model
    ? _c(
        "modal-box",
        {
          attrs: {
            "is-visible": true,
            title: _vm.Resources.AccountSettings.ChangePasswordTitle,
            icon: "user-cog",
            width: "narrow",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "a",
                      {
                        staticClass:
                          "gfu-link gfu-link--accent gfu-link--inline _mb-1",
                        attrs: { href: "", "data-qa": "modal-button:Cancel" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.close.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--wide gfu-btn--compact _ml-3 _mt-1",
                        attrs: {
                          type: "button",
                          "data-qa": "modal-button:Confirm",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Common.ModalConfirmButton))]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            4115391939
          ),
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(_vm.Resources.AccountSettings.ChangePasswordInfoText)
            ),
          ]),
          _vm._v(" "),
          _c("text-field", {
            attrs: {
              "display-name":
                _vm.Resources.AccountSettings
                  .ChangePasswordNewPasswordFieldLabel,
              type: "password",
              name: "model.newPassword",
              "data-qa": "modal-field:NewPassword",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.submit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.newPassword,
              callback: function ($$v) {
                _vm.$set(_vm.model, "newPassword", $$v)
              },
              expression: "model.newPassword",
            },
          }),
          _vm._v(" "),
          _c("text-field", {
            attrs: {
              "display-name":
                _vm.Resources.AccountSettings
                  .ChangePasswordConfirmNewPasswordFieldLabel,
              type: "password",
              name: "model.repeatNewPassword",
              "data-qa": "modal-field:RepeatPassword",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.submit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.repeatNewPassword,
              callback: function ($$v) {
                _vm.$set(_vm.model, "repeatNewPassword", $$v)
              },
              expression: "model.repeatNewPassword",
            },
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.Resources.AccountSettings.ChangePasswordCurrentPasswordText
              )
            ),
          ]),
          _vm._v(" "),
          _c("text-field", {
            attrs: {
              "display-name":
                _vm.Resources.AccountSettings
                  .ChangePasswordCurrentPasswordFieldLabel,
              type: "password",
              name: "model.oldPassword",
              "data-qa": "modal-field:Password",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.submit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.oldPassword,
              callback: function ($$v) {
                _vm.$set(_vm.model, "oldPassword", $$v)
              },
              expression: "model.oldPassword",
            },
          }),
          _vm._v(" "),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }