var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_py-3 _bdb--thick _bc--disabled" }, [
    _c(
      "div",
      { staticClass: "_flex _gap-3 _pos-r" },
      [
        _c("base-image", {
          staticClass: "_rounded--common",
          attrs: {
            src: _vm.imageUrl,
            alt: _vm.name,
            loading: "lazy",
            width: "120",
            height: "120",
          },
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "h4",
            {
              class: ["gfu-hd", _vm.headerClasses],
              attrs: { "data-qa": "cart-table:ProductName" },
            },
            [_vm._v(_vm._s(_vm.name))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["gfu-hd", _vm.headerClasses],
              attrs: { "data-qa": "cart-table:ProductPrice" },
            },
            [_vm._v(_vm._s(_vm.priceFormatted))]
          ),
          _vm._v(" "),
          _vm.handleTax
            ? _c(
                "div",
                { class: ["gfu-bt", _vm.descriptionClasses, "_tc--light"] },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "localize",
                        {
                          key: _vm.taxFormatted,
                          attrs: {
                            resource:
                              _vm.Resources.YourPledge
                                .CartTableOrderItemTaxValueLabel,
                          },
                        },
                        [
                          _c("span", { attrs: { "format-key": "0" } }, [
                            _vm._v(_vm._s(_vm.taxFormatted)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.hasActions
          ? _c("balloon-actions", {
              class: ["_right-0", "_top-a", _vm.balloonActionClasses],
              attrs: {
                actions: _vm.balloonActions,
                mode: _vm.balloonActionMode,
                theme: "white",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }