import ServiceBase from './ServiceBase';

export default class SystemStatusService extends ServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    getSystemHealthInfo() {
        return this.request('get', 'systemStatus/getSystemHealthInfo');
    }
}