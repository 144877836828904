import InitialState from '@/InitialState';
import { defineStore } from 'pinia';
import CookiesUtility, { cookieNames } from '@/Utils/CookiesUtility.js';
import { defaultLanguage } from '@/Utils/ResourceUtility.js';

export const useLanguageStore = defineStore({
    id: 'language',
    state: () => ({
        selectedLanguage: CookiesUtility.hasCookie(cookieNames.localization)
            ? CookiesUtility.get(cookieNames.localization).toLowerCase()
            : defaultLanguage,
        isLanguageSelectorModalVisible: false,
        availableLanguages: InitialState.availableLanguages,
    }),
    actions: {
        setCurrentLanguage(languageCode) {
            languageCode = languageCode.toLowerCase();
            const isSelectedLanguageAvailable = this.availableLanguages.find(({ languageIsoCode }) => languageIsoCode === languageCode);

            if (!isSelectedLanguageAvailable) return;

            this.selectedLanguage = languageCode;
            window.location.href = this.redirectLink;
        },
        showLanguageSelectorModal() {
            this.isLanguageSelectorModalVisible = true;
        },
        hideLanguageSelectorModal() {
            this.isLanguageSelectorModalVisible = false;
        },
    },
    getters: {
        redirectLink(state) {
            return state.availableLanguages.find(({ languageIsoCode }) => languageIsoCode === state.selectedLanguage)?.languageUrl;
        },
    },
});