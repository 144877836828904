var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _vm.readOnly
        ? _c(
            "span",
            {
              class: [
                "gfu-field__input gfu-field__input--faux gfu-field__input--readonly",
                { "gfu-field__input--small": _vm.small },
              ],
            },
            [_vm._v("\n        " + _vm._s(_vm.placeholder) + "\n    ")]
          )
        : _c(
            "div",
            {
              class: [
                "gfu-field-select",
                { "gfu-field-select--small": _vm.small },
              ],
            },
            [
              _c(
                "toggle-trigger",
                {
                  staticClass: "gfu-field-select__trigger _tc--light",
                  attrs: {
                    tag: "div",
                    tabindex: "0",
                    "active-class": "gfu-field-select__trigger--active",
                    "data-qa": `select:${_vm.name}`,
                  },
                },
                [_vm._v(_vm._s(_vm.placeholder))]
              ),
              _vm._v(" "),
              _c(
                "toggle-container",
                {
                  staticClass: "gfu-field-select__content",
                  attrs: {
                    tag: "div",
                    tabindex: "1",
                    "active-class": "gfu-field-select__content--expanded",
                    name: _vm.name,
                    "data-qa": "select-content",
                  },
                  on: { "active-change": _vm.onActiveChange },
                },
                [
                  _c(
                    "div",
                    { staticClass: "gfu-field-select__content-wrapper" },
                    [_vm._t("default")],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }