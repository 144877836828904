var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    { staticClass: "gfu-become-creator-values gfu-box--huge" },
    [
      _c("become-a-creator-values-title"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_flexwrap _tac _jc-c" },
        _vm._l(_vm.benefits, function (value) {
          return _c(
            "values-section-item",
            _vm._b(
              { key: value.valueID, staticClass: "gfu-1of2 gfu-1of4--m _pa-4" },
              "values-section-item",
              value,
              false
            )
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "_py-4 _tac" }, [
        _c(
          "div",
          { attrs: { "data-qa": "create-account-button:BecomeCreator" } },
          [
            _c(
              "a",
              {
                staticClass:
                  "gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--wide _ttu",
                attrs: { href: _vm.createCreatorAccountUrl },
              },
              [_vm._v("\n                Launch your project\n            ")]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }