var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("option-field", {
    class: ["gfu-field-select--primary", _vm.fieldClass],
    attrs: {
      "display-name": _vm.option.text,
      options: _vm.getOptions(_vm.option),
      "show-placeholder": true,
      name: "model",
      "show-label": _vm.showLabel,
      "label-class": _vm.labelClass,
      inline: _vm.inline,
      small: _vm.small,
      "no-option-capitalize": true,
      disabled: _vm.disabled,
      "data-qa": "product-option-field:" + _vm.option.text,
    },
    on: { change: _vm.onChange },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }