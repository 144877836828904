var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": `info-banner-id:${_vm.slide.bannerId}` } },
    [
      _c(
        "div",
        {
          class: [
            "gfu-announcement-content",
            { "gfu-announcement-content--no-image": !_vm.slide.imageUrl },
          ],
          style: _vm.imageStyle(_vm.slide.backgroundImageUrl),
          attrs: { "data-qa": "info-banner:BackgroundImage" },
        },
        [
          _vm.slide.imageUrl
            ? _c(
                "base-link",
                {
                  staticClass: "gfu-announcement-content__packshot-wrapper",
                  attrs: { url: _vm.actionUrl, "data-qa": "info-banner:Image" },
                },
                [
                  _c("div", {
                    staticClass: "gfu-announcement-content__packshot",
                    style: _vm.imageStyle(_vm.slide.imageUrl),
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "gfu-announcement-content__description _tac",
                {
                  "gfu-announcement-content__description--no-image":
                    !_vm.slide.imageUrl,
                },
              ],
              style: `color: ${_vm.slide.contentStyle}`,
            },
            [
              _c("h2", { staticClass: "gfu-announcement-content__header" }, [
                _vm._v(_vm._s(_vm.slide.header)),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "_mt-0",
                domProps: { innerHTML: _vm._s(_vm.slide.content) },
              }),
              _vm._v(" "),
              _vm.actionUrl
                ? _c(
                    "base-link",
                    { attrs: { url: _vm.actionUrl } },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "_mt-2",
                          style: _vm.buttonStyles(
                            _vm.slide.contentStyle,
                            _vm.slide.actionLabelStyle
                          ),
                          attrs: {
                            theme: "default",
                            "data-qa": "info-banner:CtaButton",
                          },
                        },
                        [_vm._v(_vm._s(_vm.slide.actionLabel))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }