export function contentTransformations(content) {
    if (content) {
        let text = content;
        const regex = /<video[^>]*?(?:\b(preload)\s*=\s*"(metadata)"[^>]*?)(?:\bdata-video-(width|height)\s*=\s*"(\d+)"[^>]*?)(?:\bdata-video-(width|height)\s*=\s*"(\d+)"[^>]*?)>.*?<\/video>/g;
        const match = content.match(regex);
        if (match) {
            // https://dev.to/shivampawar/fix-replaceall-is-not-a-function-in-javascript-3klp
            text = text.replace(/'preload="none"'/g, 'preload="metadata"');
            match.forEach((element) => {
                const newElement = element.replace(/'preload="metadata"'/g, 'preload="none"');
                text = text.replace(element, newElement);
            });
        }
        return text;
    }
    return null;
}