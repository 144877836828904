var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fieldset", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
    _c(
      "div",
      { staticClass: "gfu-grid__cell gfu-1of1 gfu-6of12--m" },
      [
        _c("text-field", {
          staticClass: "_mt-0",
          attrs: { name: "email", "display-name": "Email" },
          model: {
            value: _vm.email,
            callback: function ($$v) {
              _vm.email = $$v
            },
            expression: "email",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }