var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isUnpublish
    ? _c(
        "div",
        { staticClass: "gfu-dropdown" },
        [
          _c(
            "tooltip",
            {
              attrs: {
                text: "This blog post is not visible to any user because it hasn't been published.",
              },
            },
            [
              _c(
                "div",
                { staticClass: "gfu-btn gfu-btn--accent gfu-btn--circle" },
                [_c("span", { staticClass: "_fa _fa--exclamation-triangle" })]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }