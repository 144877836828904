var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.slides.length > 0
    ? _c("base-slider", {
        staticClass: "gfu-project-slider gfu-embed gfu-embed--16x9",
        attrs: { autoplay: true, "max-indicators": 9 },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ onAction }) {
                return _vm._l(_vm.slides, function (slide, index) {
                  return _c(
                    "div",
                    {
                      key: slide.galleryItemID,
                      class: [
                        "gfu-project-slider__slide gfu-embed__item ",
                        { "is-active": _vm.isCurrent(index) },
                      ],
                      style: `background-image: url(${slide.imageUrl})`,
                      attrs: {
                        "data-qa": `gallery-image:${slide.galleryItemID}`,
                      },
                    },
                    [
                      slide.videoUrl
                        ? _c("video-frame", {
                            attrs: {
                              url: slide.videoUrl,
                              "is-watching": slide.isWatching,
                              "data-qa": `gallery-video:${slide.galleryItemID}`,
                            },
                            on: {
                              play: function ($event) {
                                return _vm.onPlay(onAction)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                })
              },
            },
            {
              key: "nav",
              fn: function ({ onNext, onPrev }) {
                return [
                  _c(
                    "a",
                    {
                      staticClass:
                        "gfu-project-slider-nav gfu-project-slider-nav--prev",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return onPrev.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", { staticClass: "_fa _fa--angle-left" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "gfu-project-slider-nav gfu-project-slider-nav--next",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return onNext.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", { staticClass: "_fa _fa--angle-right" })]
                  ),
                ]
              },
            },
            {
              key: "indicators",
              fn: function ({ onSetCurrentIndex, indicators }) {
                return [
                  _c(
                    "transition-group",
                    {
                      staticClass: "gfu-project-slider-indicators",
                      attrs: {
                        name: "gfu-project-slider-indicators",
                        tag: "div",
                      },
                    },
                    _vm._l(indicators, function (indicator) {
                      return _c("a", {
                        key: `i${indicator}`,
                        class: [
                          "gfu-project-slider-indicators__indicator",
                          { "is-active": _vm.isCurrent(indicator) },
                        ],
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return onSetCurrentIndex(indicator)
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]
              },
            },
          ],
          null,
          false,
          3883192815
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }