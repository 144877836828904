var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tooltip",
    { attrs: { enabled: _vm.hasTooltip, text: _vm.tooltipText } },
    [
      _c(
        "base-label",
        _vm._b(
          { class: [{ "gfu-label--achievement": !_vm.onlyIcon }] },
          "base-label",
          { theme: _vm.theme, shape: _vm.shape },
          false
        ),
        [
          _vm.hasIcon ? _c("span", { class: _vm.iconClasses }) : _vm._e(),
          _vm._v("\n        " + _vm._s(_vm.name) + "\n    "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }