var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": _vm.isOpen,
        "is-processing": _vm.isProcessing,
        title: _vm.Resources.YourPledge.RemainingInstallmentsModalHeader,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link",
                  attrs: { href: "", "data-qa": "modal-button:cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onClose.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: {
                    width: "wide",
                    theme: "accent",
                    "data-qa": "modal-button:Yes",
                  },
                  on: { click: _vm.onPayRemainingInstallments },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalYesButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", { staticClass: "_mb-0" }, [
        _vm._v(_vm._s(_vm.Resources.YourPledge.RemainingInstallmentsModalText)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "_mb-3 _mt-0" }, [
        _vm._v(_vm._s(_vm.payRemainingInfo)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }