var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("user-follow", {
    attrs: {
      nickname: _vm.nickname,
      "user-url-name": _vm.userUrlName,
      "user-id": _vm.userId,
    },
    on: { action: _vm.onAction },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ isFollowing, onFollow, onUnfollow }) {
          return [
            isFollowing
              ? _c(
                  "div",
                  {
                    staticClass:
                      "_flex _rounded--large _bgc--lighter-10 _py-h _px-3 _ai-c _tc--inherit",
                  },
                  [
                    _c("base-icon", {
                      staticClass: "_mr-2 _tc--accent",
                      attrs: { name: "heart" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "_flexcol _jc-fs _ai-fs _lhc _py-h" },
                      [
                        _c("div", { staticClass: "_fw-b" }, [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Profile.YouAreFollowingThisUser
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "_tc--accent _ttl",
                            attrs: {
                              href: "#",
                              "data-qa": "user-profile:UnfollowButton",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return onUnfollow.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.Resources.Profile.UserUnfollowAction)
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "base-button",
                  {
                    staticClass: "_flex gfu-1of1 _jc-c _ai-fs",
                    attrs: { theme: "accent" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return onFollow.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("base-icon", {
                      attrs: {
                        "icon-namespace": "ga",
                        name: "heart-solid-icon",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "gfu-bt gfu-bt--b2 _ml-2" }, [
                      _vm._v(_vm._s(_vm.Resources.Profile.UserFollowAction)),
                    ]),
                  ],
                  1
                ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }