var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
      _c(
        "div",
        {
          staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
          attrs: { "data-qa": "order-details:shippingAddress" },
        },
        [
          _c(
            "h2",
            { staticClass: "gfu-heading gfu-heading--top-divider _pb-3" },
            [_vm._v(_vm._s(_vm.Resources.YourPledge.ShippingAddressHeader))]
          ),
          _vm._v(" "),
          _c("address-display", { attrs: { address: _vm.shippingAddress } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
          attrs: { "data-qa": "order-details:billingAddress" },
        },
        [
          _c(
            "h2",
            { staticClass: "gfu-heading gfu-heading--top-divider _pb-3" },
            [_vm._v(_vm._s(_vm.Resources.YourPledge.BillingAddressHeader))]
          ),
          _vm._v(" "),
          _c("address-display", { attrs: { address: _vm.billingAddress } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.canChangeShippingAddress
        ? _c(
            "div",
            { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--s _fc" },
            [
              _c(
                "a",
                {
                  staticClass: "gfu-btn gfu-btn--primary gfu-btn--fixed",
                  attrs: { "data-qa": "order-details:ChangeShipping" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeShipping.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.YourPledge.ManageShippingButton))]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }