var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: [
        "gfu-project-card-stamp",
        "gfu-project-card-follow",
        _vm.buttonClasses,
      ],
      attrs: {
        disabled: _vm.isFollowRequestProcessing,
        type: "button",
        "data-qa": "project-card:FollowButton",
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c("span", {
        class: [
          "gfu-project-card-follow__icon _ga--heart-solid-icon",
          { "gfu-project-card-follow__icon--followed": _vm.isFollower },
        ],
        attrs: { "data-qa": `is-follower:${_vm.isFollower}` },
      }),
      _vm._v(" "),
      _vm.followersFormatted
        ? _c(
            "span",
            {
              staticClass: "_ml-1",
              attrs: { "data-qa": "project-card:NumberOfFollowers" },
            },
            [_vm._v(_vm._s(_vm.followersFormatted))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }