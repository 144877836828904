var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-view",
    {
      staticClass: "gfu-layout__main gfu-layout__main--narrow",
      attrs: {
        "is-processing": _vm.isProcessing,
        "data-qa": "begin-order-form",
      },
    },
    [
      _vm.isStepIndicatorVisible
        ? _c("steps-indicator", { attrs: { steps: _vm.steps } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h1",
        {
          class: [
            "gfu-heading gfu-heading--main",
            { "_mt-6": !_vm.isStepIndicatorVisible },
          ],
          attrs: { "data-qa": "page-title" },
        },
        [_vm._v(_vm._s(_vm.Resources.Checkout.ShippingPageHeader))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-subheading gfu-subheading--main" }, [
        _vm._v("\n        " + _vm._s(_vm.subheaderText) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "box",
        { staticClass: "_mt-3 _mb-6" },
        [
          _c(
            "payment-checkout-header",
            _vm._b(
              {},
              "payment-checkout-header",
              _vm.paymentCheckoutHeaderPropsModel,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "legacy-cart-table",
            _vm._b({}, "legacy-cart-table", _vm.cartTableModelFormatted, false)
          ),
          _vm._v(" "),
          _c(
            "checkout-return-actions",
            _vm._b(
              {},
              "checkout-return-actions",
              _vm.checkoutReturnActionsModel,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.isNotificationVisible
                ? _c(
                    "div",
                    {
                      staticClass: "gfu-notificator _mt-3",
                      attrs: {
                        id: "notify",
                        "data-qa":
                          "notificator-item-info:ChangeAddressNotAllowed",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "gfu-notificator-item gfu-notificator-item--permanent gfu-notificator-item--has-actions",
                          attrs: { "data-qa": "notificator-message" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.Resources.Checkout
                                    .ShippingFormNotificatorParagraph
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("legacy-begin-order-form", {
                attrs: {
                  "phone-number-required": _vm.phoneNumberRequired,
                  "force-same-billing-location-id":
                    _vm.forceSameBillingLocationId,
                  "show-address-line2": _vm.showAddressLine2,
                  "show-address-line3": _vm.showAddressLine3,
                  "handle-tax": _vm.handleTax,
                  "customer-information-required":
                    _vm.customerInformationRequired,
                  "enable-invoicing": _vm.enableInvoicing,
                  "check-items-integrity": _vm.checkItemsIntegrity,
                  "is-order-amended": _vm.isOrderAmended,
                  "is-order-with-early-taxes": _vm.isOrderWithEarlyTaxes,
                  "disable-address-change": _vm.disableAddressChange,
                  "order-code": _vm.orderCode,
                  "url-back-to-cart": _vm.urlBackToCart,
                  "url-contact-form": _vm.urlContactForm,
                  "project-is-digital-only": _vm.projectIsDigitalOnly,
                  "order-id": _vm.orderId,
                },
                on: { continue: _vm.onContinue },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("checkout-footer"),
        ],
        1
      ),
      _vm._v(" "),
      _c("project-context-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }