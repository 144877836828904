const directions = { up: 'up', down: 'down' };
const positions = { left: 'left', center: 'center', right: 'right' };

export function getVerticalPosition(containerHeight, triggerBounding, contentBounding, verticalPosition = null) {
    const verticalOffset = 2; // offset position from element to stop blinking
    const top = triggerBounding.top + triggerBounding.height + verticalOffset;
    const bottom = containerHeight - triggerBounding.top + verticalOffset;
    const topBoundary = bottom <= containerHeight - contentBounding.height;
    const bottomBoundary = top + contentBounding.height <= containerHeight;

    const direction = topBoundary
        ? bottomBoundary
            ? verticalPosition : directions.up : directions.down;

    const positionValue = { down: { top }, up: { bottom } };

    return { style: positionValue[direction], direction };
}

export function getHorizontalPosition(containerWidth, triggerBounding, contentBounding, position = null) {
    const horizontalOffset = 28; // Balloon anchor position from edge
    const triggerWidthHalf = triggerBounding.width / 2;
    const triggerCenterAnchor = triggerBounding.left + triggerWidthHalf;
    const contentCenterAnchor = triggerCenterAnchor - contentBounding.width / 2;
    const leftTriggerAnchor = triggerCenterAnchor - horizontalOffset;
    const rightTriggerAnchor =  triggerBounding.right - triggerWidthHalf - contentBounding.width + horizontalOffset;

    const leftCenterBoundary = contentCenterAnchor > 0;
    const rightCenterBoundary = contentCenterAnchor + contentBounding.width + horizontalOffset <= containerWidth;

    const leftBoundary = rightTriggerAnchor > 0;
    const rightBoundary = contentCenterAnchor + contentBounding.width + horizontalOffset <= containerWidth;

    let anchor = position;

    switch (position) {
    case positions.center:
        anchor = !rightCenterBoundary || !leftCenterBoundary
            ? leftCenterBoundary
                ? positions.right : positions.left : position;
        break;
    case positions.left:
        anchor = !rightBoundary
            ? rightCenterBoundary
                ? positions.center : positions.right : positions.left;
        break;
    case positions.right:
        anchor = !leftBoundary
            ? leftCenterBoundary
                ? positions.center : positions.left : positions.right;
        break;
    }

    const positionValue = {
        left: leftTriggerAnchor,
        right: rightTriggerAnchor,
        center: contentCenterAnchor,
    };

    return { style: { left: positionValue[anchor] }, anchor };
}