import MessageBox from '@/Utils/MessageBox';
import { projectPhases } from '@/Models';
import Resources from '@/Resources.js';

export function cancelPledge(phase, url) {
    const question = phase === projectPhases.crowdfunding
        ? Resources.YourPledge.CancelPledgeCFModalParagraph
        : phase === projectPhases.store
            ? Resources.YourPledge.CancelOrderModalParagraph
            : Resources.YourPledge.CancelPledgeModalParagraph;

    const header = phase === projectPhases.store ? Resources.YourPledge.CancelOrderModalHeader : Resources.YourPledge.CancelPledgeModalHeader;

    return MessageBox.question(question, header, () => {
        this.startProcessing();
        // @TODO: move those MVC redirects to API calls in #6521
        window.location = url;
    });
}