var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper" }, [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
      _c("div", { staticClass: "gfu-grid__cell gfu-1of1 gfu-4of6--l" }, [
        _c(
          "h1",
          {
            staticClass: "gfu-heading gfu-heading--main _mt-3",
            attrs: { "data-qa": "page-title" },
          },
          [_vm._v("New order")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-box _mt-6 _mb-6" }, [
          _c("p", [
            _vm._v(
              "\n                    It appears you already have another pledge in this project. What do you want to do?\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { "data-qa": "select-order-form" } },
            [
              _c(
                "fieldset",
                { staticClass: "_mb-6" },
                [
                  _vm.allowPlaceNewOrder
                    ? _c("checkbox-field", {
                        attrs: {
                          condensed: true,
                          type: "radio",
                          "display-name": "Make a new pledge.",
                          "checked-value": true,
                          "data-qa": "select-order-form:NewOrder",
                        },
                        model: {
                          value: _vm.model.createNewOrder,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "createNewOrder", $$v)
                          },
                          expression: "model.createNewOrder",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("checkbox-field", {
                    attrs: {
                      condensed: true,
                      type: "radio",
                      "display-name":
                        "Add items to one of your previous pledges.",
                      "checked-value": false,
                      "data-qa": "select-order-form:PreviousOrder",
                    },
                    model: {
                      value: _vm.model.createNewOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "createNewOrder", $$v)
                      },
                      expression: "model.createNewOrder",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.model.createNewOrder
                    ? _c(
                        "fieldset",
                        { staticClass: "_ml-6" },
                        [
                          _vm._l(_vm.model.previousOrders, function (order) {
                            return _c(
                              "checkbox-field",
                              {
                                key: `previous-order-${order.orderID}`,
                                attrs: {
                                  condensed: true,
                                  type: "radio",
                                  "checked-value": order.orderCode,
                                  "data-qa":
                                    "select-order-form-order:" +
                                    order.orderCode,
                                },
                                model: {
                                  value: _vm.model.selectedPreviousOrderCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "selectedPreviousOrderCode",
                                      $$v
                                    )
                                  },
                                  expression: "model.selectedPreviousOrderCode",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(order.itemsCount) +
                                    " " +
                                    _vm._s(
                                      _vm.pluralize(order.itemsCount, "item")
                                    ) +
                                    " for\n                                " +
                                    _vm._s(
                                      _vm.$format(
                                        order.totalDiscountedValueConverted,
                                        "C2",
                                        order.currencySymbol
                                      )
                                    ) +
                                    " placed on\n                                " +
                                    _vm._s(_vm.$format(order.createDate)) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          !_vm.isProcessing && !_vm.model.previousOrders.length
                            ? _c("p", [
                                _vm._v(
                                  "You don't have any paid orders that could be\n                                modified"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has(
                                    "model.selectedPreviousOrderCode"
                                  ),
                                  expression:
                                    "errors.has('model.selectedPreviousOrderCode')",
                                },
                              ],
                              staticClass: "gfu-field has-error _mt-0",
                            },
                            [
                              _c("p", { staticClass: "gfu-field__error" }, [
                                _vm._v("Please choose one of the options."),
                              ]),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-grid" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--xl _pr-1",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "gfu-btn gfu-btn--light gfu-btn--block",
                        attrs: {
                          type: "button",
                          "data-qa": "select-order-form-btn:Cancel",
                        },
                        on: { click: _vm.cancel },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Checkout.BackToCartButton))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--xl _pa-3 _screen--none _screen--xl",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--xl _tar _pl-1",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "gfu-btn gfu-btn--accent gfu-btn--block",
                        attrs: {
                          type: "button",
                          "data-qa": "select-order-form-btn:Continue",
                        },
                        on: { click: _vm.submit },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Common.ModalContinueButton))]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("progress-overlay", {
                attrs: { visible: _vm.isProcessing, sticky: true },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }