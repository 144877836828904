var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-media-description",
        "_mb-6",
        { "gfu-media-description--reverse": _vm.isReversed },
      ],
    },
    [
      _c("div", { staticClass: "gfu-media-description__body" }, [
        _c(
          "h3",
          {
            staticClass: "gfu-hd gfu-hd--h2 gfu-heading--top-divider",
            attrs: { id: "campaign-launch" },
          },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "_mt-3" }, [_vm._v(_vm._s(_vm.description))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-media-description__aside" }, [
        _c("img", { attrs: { src: _vm.imageUrl, alt: _vm.title } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }