var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "project-comment-item",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "has-children": _vm.thread.children.length > 0,
            "has-pin-comment-action": _vm.hasPinCommentAction,
            "has-report-and-hide-action": _vm.hasReportAndHideAction,
            "allow-creator-hiding-comments": _vm.allowCreatorHidingComments,
            "is-breakpoint-large": _vm.isBreakpointLarge,
            "data-qa": `project-comment:${_vm.thread.commentID}`,
          },
          on: {
            reply: _vm.onReply,
            "nickname-required": _vm.onNicknameRequired,
          },
        },
        "project-comment-item",
        _vm.thread,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("transition", { attrs: { name: "gfu-fade" } }, [
        !!_vm.countLoadMoreComments
          ? _c("div", { staticClass: "_tc--accent _mt-3" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "",
                    "data-qa": "project-comment-action:LoadMore",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onloadMoreChildren(false)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "_fa _fa--ellipsis-v _mr-1" }),
                  _vm._v(" "),
                  _c(
                    "localize",
                    {
                      key: _vm.countLoadMoreComments,
                      attrs: {
                        resource:
                          _vm.Resources.Comments.LoadMoreChildCommentsButton,
                      },
                    },
                    [
                      _c("span", { attrs: { "format-key": "0" } }, [
                        _vm._v(_vm._s(_vm.countLoadMoreComments)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._l(_vm.children, function (childrenComment) {
        return _c(
          "project-comment-item",
          _vm._g(
            _vm._b(
              {
                key: childrenComment.commentID,
                staticClass: "_mt-3",
                attrs: {
                  "is-breakpoint-large": _vm.isBreakpointLarge,
                  "has-report-and-hide-action": _vm.hasReportAndHideAction,
                  "allow-creator-hiding-comments":
                    _vm.allowCreatorHidingComments,
                  "data-qa": `project-comment:${childrenComment.commentID}`,
                },
                on: { reply: _vm.onReply },
              },
              "project-comment-item",
              childrenComment,
              false
            ),
            _vm.$listeners
          )
        )
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "gfu-fade" } }, [
        !!_vm.thread.newerChildrenCount
          ? _c("div", { staticClass: "_tc--accent _mt-3" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "",
                    "data-qa": "project-comment-action:LoadMore",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onloadMoreChildren(true)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "_fa _fa--ellipsis-v _mr-1" }),
                  _vm._v(" "),
                  _c(
                    "localize",
                    {
                      key: _vm.thread.newerChildrenCount,
                      attrs: {
                        resource:
                          _vm.Resources.Comments.LoadMoreChildCommentsButton,
                      },
                    },
                    [
                      _c("span", { attrs: { "format-key": "0" } }, [
                        _vm._v(_vm._s(_vm.thread.newerChildrenCount)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "gfu-fade" } },
        [
          _vm.thread.newRepliesCount > 0
            ? _c(
                "base-button",
                {
                  staticClass: "_mt-3 _mb-3",
                  attrs: {
                    theme: "lightAlt",
                    shape: "hard",
                    display: "block",
                    shadow: "tight",
                    width: "tight",
                    "data-qa": "project-comment-button:LoadMore",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onloadMoreChildren(true)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "gfu-bt gfu-bt--caption" },
                    [
                      _c(
                        "localize",
                        {
                          key: _vm.thread.newRepliesCount,
                          attrs: {
                            resource:
                              _vm.Resources.Comments.LoadNewMessagesButton,
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "gfu-comment-badge",
                              attrs: { "format-key": "0" },
                            },
                            [_vm._v(_vm._s(_vm.thread.newRepliesCount))]
                          ),
                          _vm._v(" "),
                          _c("span", { attrs: { "format-key": "1" } }, [
                            _vm._v(_vm._s(_vm.newRepliesCountLabel)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "gfu-fade" } },
        [
          _vm.showReply
            ? _c("project-comment-field", {
                ref: "reply",
                staticClass: "_mt-3",
                attrs: { mode: "reply" },
                on: {
                  "nickname-required": _vm.onNicknameRequired,
                  submit: _vm.onReplySubmit,
                  cancel: _vm.onCancel,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }