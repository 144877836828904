export default {
    getResponseErrorMessage(error) {
        let message = error.message;

        // Handle 'Too Many Requests' status code
        if (error.response && error.response.status === 429)
            message = this.getRetryAfterMessage(error);
        else if (error.response && error.response.data && error.response.data.title) {
            message = error.response.data.title;
        }
        else if (error.response && error.response.data && error.response.data.message)
            message = error.response.data.message;
        else if (error.response && error.response.statusText)
            message = error.response.statusText;

        return message || `${ error }`;
    },

    getRetryAfterMessage(error) {
        let message = 'You are doing this too often. ';
        const retryAfter = error.response.headers['retry-after'];
        if (retryAfter && Number.isInteger(parseInt(retryAfter))) {
            if (retryAfter <= 60) {
                message += 'Try again in a few seconds.';
            }
            else {
                // realistically we would never have to show hours so this should be enough
                message += `Try again in ${ Math.ceil(retryAfter / 60) } minutes.`;
            }

            return message;
        }
        // We don't have time to display so instead use generic message.
        // Theoretically this can't ever happen because Throttling Filter always includes 'retry after' header.
        return `${ message }Try again later.`;
    },
};
