import ServiceBase from './ServiceBase';

export default class ProjectRankingsService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    getProjectRankingCategories() {
        return this.request('get', 'projectRankings/getProjectRankingCategories');
    }

    getAggregatedProjectRankings(params) {
        return this.request('get', 'projectRankings/getAggregatedProjectRankings', params);
    }
}
