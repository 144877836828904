var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    _vm._b(
      {
        class: _vm.linkClass,
        attrs: { href: "", disabled: !_vm.enabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.onClick.apply(null, arguments)
          },
        },
      },
      "a",
      _vm.$attrs,
      false
    ),
    [
      _vm.icon
        ? _c("span", { staticClass: "_fl" }, [
            _c("span", { class: _vm.iconClass }),
            _vm._v(" "),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }