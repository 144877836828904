var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("field-description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _vm._v(" "),
      !_vm.readOnly
        ? _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            ref: "input",
            class: [
              "gfu-field__textarea",
              `gfu-field__textarea--rows-${_vm.rows}`,
              { "gfu-field__textarea--no-resize": _vm.isNoResizeable },
              { "gfu-field__textarea--readonly": _vm.readOnly },
            ],
            attrs: {
              type: "text",
              rows: _vm.rows,
              lang: _vm.langCode,
              maxlength: _vm.maxlength,
              name: _vm.name,
              disabled: _vm.isDisabled,
              placeholder: _vm.placeholder,
              "data-qa": "text-area:" + _vm.name,
            },
            domProps: { value: _vm.model },
            on: {
              keyup: _vm.onKeyUp,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.model = $event.target.value
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.readOnly
        ? _c(
            "span",
            {
              staticClass:
                "gfu-field__textarea gfu-field__readonly gfu-field__textarea--readonly",
              attrs: { "data-qa": "text-area-field" },
            },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.model))]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }