var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section-expander",
    { staticClass: "_h-full", attrs: { height: "tall" } },
    [
      _vm.isInitialized
        ? [
            _c(
              "div",
              { class: _vm.wrapperClassList },
              [
                _c("info-tile-list", {
                  attrs: { items: _vm.categories.projectTypeCategories },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_flexcol _gap-1" },
                  [
                    _c(
                      "div",
                      [
                        _c("base-icon", { attrs: { name: "smile" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Homepage
                                .ProjectListPopularCategoriesTitle
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("label-tile-list", {
                      attrs: { items: _vm.categories.projectPopularCategories },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.globalCategoriesClassList },
                  [
                    _c(
                      "div",
                      [
                        _c("base-icon", { attrs: { name: "shape" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Homepage
                                .ProjectListGlobalCategoriesTitle
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "scrollable-wrapper",
                      {
                        attrs: {
                          "scroll-direction": _vm.scrollDirections.vertical,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "_flexcol _gap-1" },
                          [
                            _c("box-list", {
                              attrs: {
                                items: _vm.categories.projectGlobalCategories,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "_flexcol _gap-6" },
              [
                _c("skeleton-tile", { attrs: { "items-count": 4 } }),
                _vm._v(" "),
                _c("skeleton-tile", { attrs: { "items-count": 2 } }),
                _vm._v(" "),
                _c("skeleton-box-list", { attrs: { "items-count": 20 } }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }