var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "gfu-media gfu-link gfu-link--nofx gfu-link--block",
      attrs: { href: _vm.url },
    },
    [
      _c("base-image", {
        staticClass: "_mr-2",
        attrs: {
          src: _vm.thumbImageUrl,
          alt: _vm.title,
          width: "48",
          height: "48",
          loading: "lazy",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-media__body _pt-1" }, [
        _c(
          "h3",
          {
            staticClass: "gfu-hd gfu-hd--h4 _ttu",
            attrs: { "data-qa": `search-form-results-list-item:${_vm.title}` },
          },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--light" }, [
          _vm._v(_vm._s(_vm.subtitle)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }