var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.model
    ? _c("div", { staticClass: "gfu-product gfu-product--modal" }, [
        _c("div", { staticClass: "gfu-product__row" }, [
          _c(
            "div",
            { staticClass: "gfu-product__gallery gfu-1of1 gfu-1of2--m" },
            [
              _c(
                "div",
                { staticClass: "gfu-embed gfu-embed--1x1" },
                [
                  _c("base-image", {
                    staticClass: "gfu-embed__item",
                    attrs: {
                      src: _vm.model.imageUrl,
                      alt: _vm.model.name,
                      loading: "lazy",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-product__info gfu-1of1 gfu-1of2--m" }, [
            _c("div", { staticClass: "gfu-product-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "gfu-product-status gfu-media gfu-media--middle _mb-3",
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "gfu-project-stretch-goal-icon _mr-1",
                        {
                          "gfu-project-stretch-goal-icon--success":
                            _vm.model.isUnlocked,
                        },
                      ],
                    },
                    [
                      _c("span", {
                        class: `_fa ${
                          _vm.model.isUnlocked ? "_fa--check" : "_fa--lock"
                        }`,
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-media__body" },
                    [
                      _c(
                        "h4",
                        { staticClass: "gfu-hd gfu-hd--h4 gfu-hd--light" },
                        [
                          _vm.isBackedFunds
                            ? _c("display-currency-value", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tooltip-content",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.Resources.format(
                                                  _vm.Resources.ProjectHome
                                                    .ProjectApproximateCurrencyConversionTooltip,
                                                  _vm.$format(
                                                    _vm.model.targetValue,
                                                    "C0"
                                                  )
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "content",
                                      fn: function ({ displayCurrency }) {
                                        return [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.resolveStatusText(
                                                  _vm.model,
                                                  displayCurrency.symbol,
                                                  displayCurrency.relativeFactor
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.resolveStatusText(
                                                  _vm.model,
                                                  _vm.currencySymbol
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2411682321
                                ),
                              })
                            : [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.resolveStatusText(_vm.model)) +
                                    "\n                            "
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      !_vm.model.isUnlocked && _vm.model.isAutomatic
                        ? _c("progress-bar", {
                            staticClass: "_mt-1",
                            attrs: {
                              value: _vm.model.value,
                              max: _vm.model.targetValue,
                              "progression-class":
                                "gfu-progress-bar__progression--accent",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "h1",
                { staticClass: "gfu-hd gfu-hd--d1 _mb-3 gfu-hd--decorative" },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.model.name) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "_mb-3" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.model.abstract) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.resolveDescription(
                      _vm.model,
                      _vm.currencySymbol,
                      _vm.currencyFactor
                    )
                  ),
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.model.description
          ? _c("div", { staticClass: "gfu-product-content _mt-6" }, [
              _c("h2", { staticClass: "gfu-hd gfu-hd--h2 _tc--base _mb-2" }, [
                _vm._v(_vm._s(_vm.Resources.Common.MoreInfoHeader)),
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "gfu-richtext gfu-richtext--article",
                domProps: { innerHTML: _vm._s(_vm.model.description) },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.next || _vm.prev
          ? _c(
              "div",
              { staticClass: "gfu-product__row gfu-product-content _mt-4" },
              [
                _vm.prev
                  ? _c(
                      "router-link",
                      {
                        staticClass: "_tc--accent _flexaicntr",
                        attrs: { to: _vm.prev, rel: "prev" },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "_fa _fa--angle-left _fl _mr-1 gfu-icon gfu-icon--big _fl",
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .ProjectSummaryStretchGoalPreviousLink
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.next
                  ? _c(
                      "router-link",
                      {
                        staticClass: "_tc--accent _flexaicntr _ml-a",
                        attrs: { to: _vm.next, rel: "next" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .ProjectSummaryStretchGoalNextLink
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "_fa _fa--angle-right _fr _ml-1 gfu-icon gfu-icon--big _fr",
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }