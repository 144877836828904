var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_mb-3" }, [
    _c(
      "span",
      {
        staticClass: "gfu-link gfu-link--accent",
        on: { click: _vm.testModeAction },
      },
      [_vm._v(" " + _vm._s(_vm.testModeActionText) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }