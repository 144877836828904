var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "gfu-contact-form__back-navigation",
      attrs: { "data-qa": "contact-form-btn:GoBack" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("span", { staticClass: "_fa _fa--chevron-left _mr-1" }),
      _vm._v(_vm._s(_vm.Resources.Common.GoBackButton) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }