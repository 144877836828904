var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper _pb-6" }, [
    _c("h2", { staticClass: "gfu-hd gfu-hd--h1 gfu-heading--top-divider" }, [
      _vm._v(_vm._s(_vm.Resources.Homepage.BrowseProjectsSectionTitle)),
    ]),
    _vm._v(" "),
    _c("div", [
      _vm._v(_vm._s(_vm.Resources.Homepage.BrowseProjectsSectionSubtitle)),
    ]),
    _vm._v(" "),
    _c(
      "nav",
      { staticClass: "gfu-card-list gfu-card-list--wrap _mt-2" },
      _vm._l(_vm.featuredCategories, function (category) {
        return _c(
          "div",
          {
            key: category.projectCategoryID,
            staticClass: "gfu-1of1 gfu-1of2--m gfu-1of3--l gfu-card-list__item",
          },
          [
            _c("base-card", {
              staticClass: "gfu-category-card",
              scopedSlots: _vm._u(
                [
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "gfu-card-title" }, [
                          _c("a", { attrs: { href: category.url } }, [
                            _vm._v(_vm._s(category.name)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link gfu-link--accent",
                              attrs: { href: category.url },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.Resources.format(
                                      _vm.Resources.Homepage
                                        .BrowseProjectsProjectCount,
                                      category.openProjectsCount
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "_mt-3 gfu-category-card-projects" },
                          _vm._l(category.featuredProjects, function (project) {
                            return _c(
                              "a",
                              {
                                key: project.projectID,
                                attrs: {
                                  href: project.url,
                                  title: project.name,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: project.imageUrl,
                                    alt: project.name,
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "nav",
      {
        staticClass:
          "gfu-card-list gfu-card-list--wrap _screen--none _screen--flex-m _mt-1",
      },
      _vm._l(_vm.categories, function (category) {
        return _c(
          "div",
          {
            key: category.projectCategoryID,
            staticClass: "gfu-card-list__item gfu-1of4 gfu-1of6--l _pa-2",
          },
          [
            _c(
              "a",
              {
                staticClass: "gfu-bt gfu-bt--b1",
                attrs: { href: category.url },
              },
              [_vm._v(_vm._s(category.name))]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "_tar _mt-2" }, [
      _c(
        "a",
        {
          staticClass: "gfu-link gfu-link--accent",
          attrs: { href: _vm.seeMoreUrl },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Homepage.BrowseProjectsViewAllLink)
          ),
          _c("span", { staticClass: "_fr _ml-1 _fa _fa--chevron-right" }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }