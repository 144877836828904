var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": _vm.isVisible,
        title: _vm.Resources.AccountSettings.DeleteAccountConfirmationTitle,
        icon: "user-cog",
        width: "narrow",
        "is-processing": _vm.isProcessing,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: { href: "", "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "gfu-btn gfu-btn--accent gfu-btn--soft gfu-btn--wide gfu-btn--compact",
                  attrs: {
                    type: "button",
                    disabled: _vm.isProcessing,
                    "data-qa": "modal-button:Delete",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.Resources.AccountSettings.DeleteAccountConfirmButton
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          _vm._s(_vm.Resources.AccountSettings.DeleteAccountConfirmationInfo)
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.Resources.AccountSettings.DeleteAccountPasswordInfo)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c("text-field", {
            attrs: {
              "display-name":
                _vm.Resources.AccountSettings.DeleteAccountPasswordFieldLabel,
              type: "password",
              name: "model.password",
              "data-qa": "modal-field:Password",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.submit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.password,
              callback: function ($$v) {
                _vm.$set(_vm.model, "password", $$v)
              },
              expression: "model.password",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }