var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("box", { staticClass: "gfu-box gfu-box--huge" }, [
    _c("div", { staticClass: "gfu-box__title--success" }),
    _vm._v(" "),
    _c(
      "h2",
      {
        staticClass: "gfu-heading gfu-hd gfu-hd--h1 gfu-box__title",
        attrs: { "data-qa": "unsubscribe-successful" },
      },
      [_vm._v("Unsubscribe successful!")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "_tac _mt-4" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "_mb-1" },
          [
            _vm._v(
              "\n                You will no longer receive\n                "
            ),
            _c("span", { staticClass: "_fw-b" }, [
              _vm._v(_vm._s(_vm.notificationTopicDisplayText)),
            ]),
            _vm._v("\n                emails"),
            _vm.contextName
              ? [
                  _vm._v("\n                    about\n                    "),
                  _c("span", { staticClass: "_fw-b" }, [
                    _vm._v(_vm._s(_vm.contextName)),
                  ]),
                ]
              : _vm._e(),
            _vm._v(".\n            "),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", [
          _vm._v("\n                Visit your "),
          _c(
            "a",
            {
              staticClass: "gfu-link gfu-link--accent",
              attrs: { href: _vm.subscriptionManagementUrl },
            },
            [_vm._v("communication preferences")]
          ),
          _vm._v(" for more notification settings.\n            "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }