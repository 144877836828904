var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "gfu-hd gfu-hd--h2" }, [
        _vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader)),
      ]),
      _vm._v(" "),
      _vm.isCrowdfunding
        ? [
            _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
              _vm._v(
                _vm._s(_vm.Resources.PaymentSuccess.FaqSectionHeaderItem1)
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.paragraphText))]),
            _vm._v(" "),
            _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
              _vm._v(
                _vm._s(_vm.Resources.PaymentSuccess.FaqSectionHeaderItem2)
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.shippingInfoText))]),
            _vm._v(" "),
            _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
              _vm._v(
                _vm._s(_vm.Resources.PaymentSuccess.FaqSectionHeaderItem3)
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(_vm.Resources.PaymentSuccess.FaqSectionParagraphItem3)
              ),
            ]),
          ]
        : [
            _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
              _vm._v(
                _vm._s(_vm.Resources.PaymentSuccess.FaqSectionHeaderItem5)
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              [
                _c(
                  "localize",
                  {
                    attrs: {
                      resource:
                        _vm.Resources.PaymentSuccess.FaqSectionParagraphItem5,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "gfu-link gfu-link--accent",
                        attrs: {
                          "format-key": "0",
                          href: _vm.yourPledgeUrl,
                          "data-qa": "pledge-success-link:YourPledge",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.PaymentSuccess.YourPledgePageLink
                          )
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.projectIsDigitalOnly
              ? [
                  _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
                    _vm._v(
                      _vm._s(_vm.Resources.PaymentSuccess.FaqSectionHeaderItem6)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess.FaqSectionParagraphItem6
                      )
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.canAddItemsToPledge
              ? [
                  _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
                    _vm._v(
                      _vm._s(_vm.Resources.PaymentSuccess.FaqSectionHeaderItem7)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess.FaqSectionParagraphItem7
                      )
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
      _vm._v(" "),
      _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
        _vm._v(_vm._s(_vm.Resources.PaymentSuccess.FaqSectionHeaderItem4)),
      ]),
      _vm._v(" "),
      _c(
        "p",
        [
          _c(
            "localize",
            {
              attrs: {
                resource: _vm.Resources.PaymentSuccess.FaqSectionParagraphItem4,
              },
            },
            [
              _vm.faqEnabled
                ? _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: {
                        "format-key": "0",
                        href: _vm.links.faqUrl,
                        "data-qa": "pledge-success-link:Faq",
                      },
                    },
                    [_vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader))]
                  )
                : _c("span", { attrs: { "format-key": "0" } }, [
                    _vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader)),
                  ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    "format-key": "1",
                    href: _vm.links.contactUrl,
                    "data-qa": "pledge-success-link:ContactForm",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.PaymentSuccess.FaqSectionContactFormLink
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.updatesEnabled
                ? _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: {
                        "format-key": "2",
                        href: _vm.links.updatesUrl,
                        "data-qa": "pledge-success-link:Updates",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.PaymentSuccess
                            .FaqSectionProjectUpdatesLink
                        )
                      ),
                    ]
                  )
                : _c("span", { attrs: { "format-key": "2" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess
                          .FaqSectionProjectUpdatesLink
                      )
                    ),
                  ]),
              _vm._v(" "),
              _vm.commentsEnabled
                ? _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: {
                        "format-key": "3",
                        href: _vm.links.commentsUrl,
                        "data-qa": "pledge-success-link:Comments",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.PaymentSuccess.FaqSectionCommentsLink
                        )
                      ),
                    ]
                  )
                : _c("span", { attrs: { "format-key": "3" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.PaymentSuccess.FaqSectionCommentsLink
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }