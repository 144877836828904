var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", [
    _c(
      "div",
      {
        staticClass: "gfu-navbar gfu-navbar--lighter gfu-navbar--elevate _jc-c",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "gfu-navbar-nav gfu-navbar-nav--scrollable gfu-profile-navbar-nav",
          },
          [
            _c(
              "div",
              {
                staticClass: "gfu-navbar-nav__wrapper",
                attrs: { "data-qa": "profile-navbar" },
              },
              _vm._l(_vm.navigation, function (item) {
                return _c(
                  "router-link",
                  {
                    key: item.label,
                    staticClass: "gfu-navbar-item gfu-link-underline",
                    attrs: {
                      to: item.route,
                      "active-class": "is-active",
                      "data-qa": `profile-nav:${item.label}`,
                    },
                  },
                  [
                    _c("div", { staticClass: "gfu-navbar-link" }, [
                      _c(
                        "span",
                        {
                          staticClass: "gfu-link-underline__content",
                          attrs: { "data-qa": "profile-nav-title" },
                        },
                        [_vm._v(_vm._s(item.label))]
                      ),
                      _vm._v(" "),
                      item.isBadgeVisible
                        ? _c(
                            "span",
                            {
                              staticClass: "gfu-navbar-badge _ml-1",
                              attrs: { "data-qa": "profile-nav-count" },
                            },
                            [_vm._v(_vm._s(item.number))]
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              1
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gfu-profile-view" },
      [
        _vm.isPublic
          ? _c("router-view", { attrs: { name: "profile" } })
          : _c(
              "div",
              { staticClass: "gfu-layout__main gfu-layout__main--extranarrow" },
              [
                _c(
                  "box",
                  { staticClass: "_mt-6" },
                  [
                    _vm.isViewingOwnProfile
                      ? _c(
                          "empty-state",
                          {
                            attrs: {
                              title:
                                _vm.Resources.Profile
                                  .EmptyStateProfileStatusPrivateTitle,
                              "data-qa": "user-profile:PrivateState",
                            },
                          },
                          [
                            _c("div", { staticClass: "_mt-3" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.Resources.Profile
                                      .EmptyStateProfileStatusPrivateOwnProfileParagraph
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "base-button",
                              {
                                staticClass: "_mt-3",
                                attrs: {
                                  tag: "a",
                                  href: _vm.accountSettingsUrl,
                                  theme: "accent",
                                  "format-key": "0",
                                  "data-qa": "user-profile:ManagePrivacyButton",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.Profile
                                      .EmptyStateProfilePrivateStatusOwnProfileAction
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "empty-state",
                          {
                            attrs: {
                              title:
                                _vm.Resources.Profile
                                  .EmptyStateProfileStatusPrivateTitle,
                            },
                          },
                          [
                            _c("div", { staticClass: "_mt-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.Profile
                                    .EmptyStateProfileStatusPrivateParagraph
                                )
                              ),
                            ]),
                          ]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }