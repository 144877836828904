var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-poll gfu-box gfu-box--flat" },
    [
      _vm.ready
        ? [
            _c(
              "h3",
              {
                staticClass: "gfu-poll__title gfu-hd gfu-hd-h3",
                attrs: { "data-qa": "project-update-poll:Title" },
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _vm._l(_vm.options, function (option) {
              return _c(
                "poll-option",
                _vm._b(
                  {
                    key: option.pollOptionID,
                    staticClass: "_mb-2",
                    attrs: {
                      "data-qa": `project-update-poll-option:${option.pollOptionID}`,
                    },
                    on: { selected: _vm.onVoted },
                  },
                  "poll-option",
                  { ...option, ..._vm.pollOptionProps },
                  false
                )
              )
            }),
            _vm._v(" "),
            _vm.restrictionPollMessage
              ? _c("div", { staticClass: "gfu-media _mt-3" }, [
                  _c("span", { staticClass: "_fa _fa--info _mr-2" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gfu-media__body",
                      attrs: {
                        "data-qa": "project-update-poll:CannotVoteReason",
                      },
                    },
                    [_vm._v(_vm._s(_vm.restrictionPollMessage))]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gfu-box__tags",
                attrs: { "data-qa": "project-update-poll-badge" },
              },
              [
                _c("base-badge", { attrs: { theme: _vm.label.theme } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.label.text) +
                      "\n            "
                  ),
                ]),
              ],
              1
            ),
          ]
        : _c("progress-overlay", { attrs: { visible: true } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }