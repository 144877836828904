import { userBadges } from '@/Models';
import Resources from '@/Resources.js';

const badUserStatusNames = ['disabled'];
const goodUserStatusNames = ['active'];

function getUserAchievedLabelTypeModels() {
    return {
        [userBadges.pledgeMaster]: {
            name: Resources.Comments.PledgeMasterBadge,
            icon: 'gem',
            tooltipText: Resources.Comments.PledgeMasterBadgeTooltip,
            theme: 'primaryLight',
            iconSize: 'lg',
            dataQa: 'badge:pledgeMaster',
            order: 0,
        },
        [userBadges.backer]: {
            name: Resources.Comments.BackerBadge,
            iconNamespace: 'ga',
            icon: 'gamefound-outline',
            tooltipText: Resources.Comments.BackerBadgeTooltip,
            theme: 'accentLight',
            iconSize: 'lg',
            dataQa: 'badge:backer',
            order: 0,
        },
        [userBadges.creatorBacker]: {
            name: Resources.Comments.CreatorBackerBadge,
            iconNamespace: 'ga',
            icon: 'gamefound-outline',
            tooltipText: Resources.Comments.CreatorBackerBadgeTooltip,
            theme: 'accentLight',
            iconSize: 'lg',
            dataQa: 'badge:priorBacker',
            order: 0,
        },
        [userBadges.pathfinder]: {
            name: 'pathfinder',
            icon: 'atom-simple',
            tooltipText: Resources.Comments.PathfinderBadgeTooltip,
            theme: 'labelDark',
            iconSize: 'lg',
            dataQa: 'badge:pathfinder',
            order: 5,
        },
    };
}

function getUserAchievedLabel(labelModel, supplemental = null) {
    const modifiedName = supplemental !== null ? `${ labelModel.name } ${ supplemental }` : labelModel.name;

    return { ...labelModel, name: modifiedName };
}

export function getUserAchievedLabelTypeModel(userBadge) {
    return getUserAchievedLabelTypeModels()[userBadge];
}

export function getUserStatusEvaluation(userStatus) {
    if (userStatus) {
        if (badUserStatusNames.includes(userStatus))
            return 'bad';
        if (goodUserStatusNames.includes(userStatus))
            return 'good';
    }
    return null;
}

export function getUserAchievedLabels(userAchievedLabelsType) {
    const models = getUserAchievedLabelTypeModels();

    const sortedByOrder = (labels) => labels.sort((a, b) => a.order - b.order);

    const result = userAchievedLabelsType.reduce((labels, label) => models[label.badgeType]
        ? [...labels, getUserAchievedLabel(models[label.badgeType], label.supplemental)]
        : [...labels], []);

    return sortedByOrder(result).map(({ order, ...props }) => props);
}

export function getVerifiedLabelModel(verifiedAccountCaption) {
    return {
        theme: 'accent',
        icon: 'check',
        tooltipText: verifiedAccountCaption,
        shape: 'badge',
        dataQa: 'badge:verified',
    };
}