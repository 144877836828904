var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "show-action-close": true,
        "is-visible": _vm.isVisible,
        title: _vm.modalTitle,
      },
      on: { "action-close": _vm.close, open: _vm.onOpen },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link _ttl _tc--lighter",
                  attrs: { href: "", "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Layout.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: {
                    disabled: !_vm.languageCandidate,
                    size: "default",
                    width: "compact",
                    "text-transform": "none",
                    bold: true,
                    theme: "accent",
                    "data-qa": "modal-button:Save",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onLanguageSet.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Layout.ModalSaveButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("base-notificator", { attrs: { "can-close": false } }, [
        _vm._v(_vm._s(_vm.info)),
      ]),
      _vm._v(" "),
      _c("option-field", {
        attrs: {
          "display-name": _vm.label,
          options: _vm.supportedLanguages,
          layout: "condensed",
          description: _vm.description,
          "data-qa": "project-language-selector:PlatformLanguage",
        },
        model: {
          value: _vm.selectedLanguage,
          callback: function ($$v) {
            _vm.selectedLanguage = $$v
          },
          expression: "selectedLanguage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }