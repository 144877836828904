var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("notificator", { ref: "notify", attrs: { singular: true } }),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _c(
          "div",
          { staticClass: "gfu-grid__cell gfu-1of1 _mt-4" },
          [
            _c("h2", { staticClass: "gfu-heading gfu-heading--top-divider" }, [
              _vm._v(_vm._s(_vm.formTitle)),
            ]),
            _vm._v(" "),
            _vm.isLocationsLoaded
              ? _c("address-data-form", {
                  key: "shippingAddressDataForm",
                  ref: "shippingAddressDataForm",
                  attrs: {
                    "location-specific-field-rules":
                      _vm.locationSpecificFieldRules,
                    "phone-number-required": _vm.phoneNumberRequired,
                    "show-address-line2": _vm.showAddressLine2,
                    "show-address-line3": _vm.showAddressLine3,
                    "read-only": _vm.disableAddressChange,
                    "show-notes": true,
                    "show-vat-id": true,
                    "is-registered-vat-payer-checkbox-visible":
                      _vm.isRegisteredVatPayerCheckboxVisible,
                    "project-locations": _vm.projectLocations,
                    "allow-location-change":
                      _vm.shouldAllowShippingLocationChange,
                    "allow-sub-location-change":
                      _vm.shouldAllowShippingSubLocationChange,
                    "data-qa": "begin-order-form:ShippingAddress",
                  },
                  on: {
                    "location-change": _vm.onShippingLocationChange,
                    "sublocation-change": _vm.onShippingSublocationChange,
                  },
                  model: {
                    value: _vm.shippingAddressData,
                    callback: function ($$v) {
                      _vm.shippingAddressData = $$v
                    },
                    expression: "shippingAddressData",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("checkbox-field", {
              staticClass: "_mt-3",
              attrs: {
                "display-name":
                  _vm.Resources.Checkout.DifferentBillingAddressLabel,
                condensed: true,
                disabled: _vm.isBillingInformationDifferentCheckboxDisabled,
                "data-qa": "begin-order-form:differentBilling",
              },
              model: {
                value: _vm.myBillingInformationIsDifferentFromShipping,
                callback: function ($$v) {
                  _vm.myBillingInformationIsDifferentFromShipping = $$v
                },
                expression: "myBillingInformationIsDifferentFromShipping",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.myBillingInformationIsDifferentFromShipping,
                expression: "myBillingInformationIsDifferentFromShipping",
              },
            ],
            staticClass: "gfu-grid__cell gfu-1of1 _mt-4",
          },
          [
            _c("h2", { staticClass: "gfu-heading gfu-heading--top-divider" }, [
              _vm._v(_vm._s(_vm.Resources.Checkout.BillingAddressTitle)),
            ]),
            _vm._v(" "),
            _vm.isLocationsLoaded
              ? _c("address-data-form", {
                  key: "customerAddressDataForm",
                  ref: "customerAddressDataForm",
                  attrs: {
                    "phone-number-required": _vm.phoneNumberRequired,
                    "show-vat-id": false,
                    "project-locations": _vm.projectLocations,
                    "validate-vat-id":
                      _vm.hasReverseVatEnabled && _vm.model.vatInvoice === true,
                    "show-address-line2": _vm.showAddressLine2,
                    "show-address-line3": _vm.showAddressLine3,
                    "read-only":
                      _vm.disableAddressChange ||
                      _vm.isOrderAmended ||
                      !_vm.model.specificCustomerAddress,
                    "show-notes": true,
                    "allow-location-change":
                      _vm.shouldAllowBillingAddressLocationChange,
                    "allow-sub-location-change":
                      _vm.shouldAllowBillingAddressLocationChange,
                    "data-qa": "begin-order-form:BillingAddress",
                  },
                  model: {
                    value: _vm.customerAddressData,
                    callback: function ($$v) {
                      _vm.customerAddressData = $$v
                    },
                    expression: "customerAddressData",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns _mt-4" }, [
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1",
            attrs: { "data-qa": "shipping-method-section" },
          },
          [
            _c(
              "fieldset",
              [
                _c(
                  "h2",
                  { staticClass: "gfu-heading gfu-heading--top-divider" },
                  [
                    _vm._v(
                      _vm._s(_vm.Resources.Checkout.ShippingMethodSectionTitle)
                    ),
                    _c("em", [_vm._v("*")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "field",
                  {
                    staticClass: "_mt-0",
                    attrs: {
                      "base-class": "",
                      name: "model.shippingModelID",
                      "show-label": false,
                    },
                  },
                  _vm._l(_vm.shippingModels, function (shippingModel) {
                    return _c(
                      "div",
                      {
                        key: `shipping-method-${shippingModel.shippingModelID}`,
                        attrs: {
                          "data-qa":
                            "begin-order-form-shipping-method:" +
                            shippingModel.shippingModelID,
                        },
                      },
                      [
                        _c(
                          "checkbox-field",
                          {
                            attrs: {
                              "checked-value": shippingModel.shippingModelID,
                              condensed: true,
                              type: "radio",
                            },
                            model: {
                              value: _vm.model.shippingModelID,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "shippingModelID", $$v)
                              },
                              expression: "model.shippingModelID",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(shippingModel.name) +
                                "\n                            "
                            ),
                            shippingModel.shippingCostDifferenceConverted !==
                            null
                              ? _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-qa": `shipping-cost:${shippingModel.shippingCostDifferenceConverted}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                (" +
                                        _vm._s(
                                          _vm.getShippingInfo(shippingModel)
                                        ) +
                                        ")\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            shippingModel.description
                              ? _c(
                                  "tooltip",
                                  {
                                    attrs: { text: shippingModel.description },
                                  },
                                  [
                                    _c("em", [
                                      _c("span", {
                                        staticClass: "_fa _fa--info",
                                      }),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "gfu-grid__cell gfu-1of1 _mt-3" }, [
              _c(
                "div",
                { staticClass: "gfu-1of1 _flexwrap-reverse _jc-sb _ai-c" },
                [
                  _c(
                    "div",
                    { staticClass: "gfu-1of1 gfu-1of2--m gfu-1of3--l" },
                    [
                      _c(
                        "base-link",
                        {
                          staticClass: "_tc--accent _flex _ai-c",
                          attrs: { url: _vm.projectUrl },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onGoBack.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("base-icon", {
                            attrs: {
                              name: "chevron-left _mr-1",
                              "data-qa": "begin-order-button:Back",
                            },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.Resources.Common.GoBackButton) +
                              "\n                        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-1of1 gfu-1of2--m gfu-1of3--l" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass:
                            "gfu-btn gfu-btn--accent gfu-btn--block _mb-1",
                          attrs: {
                            type: "button",
                            disabled: !_vm.canSubmit,
                            "data-qa": "begin-order-form-button:Continue",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.Resources.Common.ModalContinueButton) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }