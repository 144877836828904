var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "installment-state-box",
    {
      attrs: {
        "payment-date": _vm.paymentDate,
        "data-qa": "StretchPay:overdueInstallment",
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _vm._v(
                _vm._s(_vm.Resources.YourPledge.InstallmentProgressOverdueTitle)
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "value",
          fn: function () {
            return [_vm._v(_vm._s(_vm.installmentValue))]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c("p", { staticClass: "_tc--error _mt-0" }, [
        _vm._v(
          _vm._s(_vm.Resources.YourPledge.InstallmentProgressOverdueDescription)
        ),
      ]),
      _vm._v(" "),
      _vm.isRetryPaymentButtonVisible
        ? _c(
            "processing-button",
            {
              attrs: {
                width: "wide",
                theme: "accent",
                display: "block",
                "is-processing": _vm.isProcessing,
                "is-action-in-progress": _vm.isChargingInstallments,
                status: _vm.actionStatus,
                "data-qa": "StretchPay:retryPayment",
              },
              on: { click: _vm.onRetryInstallmentPayment },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.Resources.YourPledge.RetryInstallmentButton) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }