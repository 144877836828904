var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "product-card-list-provider",
    _vm._b(
      {
        attrs: { "edit-route-name": "product-summary-focus" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({
              productList,
              onItemAdded,
              getAddProductToCartModel,
            }) {
              return [
                _c(
                  "ul",
                  { staticClass: "gfu-card-list gfu-scrollable" },
                  _vm._l(productList, function (product) {
                    return _c(
                      "li",
                      {
                        key: product.productId,
                        staticClass:
                          "gfu-card-list__item gfu-3of4 gfu-5of12--s gfu-1of3--m",
                      },
                      [
                        _c(
                          "product-card",
                          _vm._b(
                            {
                              attrs: {
                                context: "modal",
                                "show-abstract": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "action",
                                    fn: function () {
                                      return [
                                        _c(
                                          "add-product-to-cart",
                                          _vm._b(
                                            {
                                              on: { "item-added": onItemAdded },
                                            },
                                            "add-product-to-cart",
                                            getAddProductToCartModel(product),
                                            false
                                          )
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            "product-card",
                            product,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      },
      "product-card-list-provider",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }