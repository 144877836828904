import Config from '@/Config.js';
import { EventBus } from '@/Utils/EventBus.js';

class GlobalEventBus extends EventBus {
    constructor(name, debug) {
        super(name, debug);

        try {
            this.Topics = {
                Web: {
                    Request: {
                        ShowMessageBox: this.createTopic('Web.Request.ShowMessageBox'),
                        ShowGoToCheckoutModal: this.createTopic('Web.Request.ShowGoToCheckoutModal'),
                    },
                    Notify: {
                        AddProductToCart: this.createTopic('Web.Notify.AddProductToCart'),
                        CartEmpty: this.createTopic('Web.Notify.CartEmpty'),
                        CartEmptyRedirect: this.createTopic('Web.Notify.CartEmptyRedirect'),
                        CartModified: this.createTopic('Web.Notify.CartModified'),
                        CartStatusChanged: this.createTopic('Web.Notify.CartStatusChanged'),
                        CrowdfundingNotifyShow: this.createTopic('Web.Notify.CrowdfundingNotifyShow'),
                        CrowdfundingNotifyHide: this.createTopic('Web.Notify.CrowdfundingNotifyHide'),
                        ProjectStretchGoalsUnlockedCountChanged: this.createTopic('Web.Notify.ProjectStretchGoalsUnlockedCountChanged'),
                        CartDetailsStoreInitialized: this.createTopic('Web.Notify.CartDetailsStoreInitialized'),
                        OnValidate: this.createTopic('Web.Notify.OnValidate'),
                        LocationChanged: this.createTopic('Web.Notify.LocationChanged'),
                    },
                },
                Admin: {
                    Notify: {
                        ViewChanged: this.createTopic('Admin.Notify.ViewChanged'),
                        ProductCategoryChanged: this.createTopic('Admin.Notify.ProductCategoryChanged'),
                        ProductCreated: this.createTopic('Admin.Notify.ProductCreated'),
                        ProductDeleted: this.createTopic('Admin.Notify.ProductDeleted'),
                    },
                },
            };

        }
        catch (error) {
            if (typeof window.Rollbar !== 'undefined') {
                window.Rollbar.error(error, this);
            }

            throw error;
        }
    }
}

export default new GlobalEventBus('Global', Config.debug);
