var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_flex _flexcntr _flexaicntr" }, [
    _vm._v("\n    Could not load component. Please refresh the page.\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }