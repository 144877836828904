var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_pa-3 _tac" },
    [
      _c("p", [_vm._v(_vm._s(_vm.Resources.Layout.WebPushBlockedParagraph))]),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "_mt-3",
          attrs: {
            tag: "a",
            theme: "accent",
            target: "_blank",
            href: _vm.url,
            "data-qa": "notification-box-button:ReadInstructions",
          },
          on: { click: _vm.onButtonClick },
        },
        [_vm._v(_vm._s(_vm.Resources.Layout.WebPushHowToUnblockLink))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }