var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-flip-card" }, [
    _c("div", { class: _vm.flipCardInnerClasses }, [
      _c(
        "div",
        {
          staticClass: "gfu-flip-card__side gfu-flip-card__side--face",
          attrs: { "data-qa": "card-face" },
        },
        [_vm._t("face")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.flipCardBackClasses,
          attrs: { "data-qa": "card-reverse" },
        },
        [_vm._t("reverse")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }