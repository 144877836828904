var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("default", null, null, {
        isFollowing: _vm.isFollowing,
        onFollow: _vm.onFollow,
        onUnfollow: _vm.onUnfollow,
        canFollow: _vm.canFollow,
        followRestrictionReason: _vm.followRestrictionReason,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }