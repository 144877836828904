
export function getApiOperationResultData(result) {
    return result?.data?.data || result?.data;
}

export function handleServerValidationError(error, validator, fieldNamePrefix = '') {
    const data = error?.response?.data;

    if (!data) {
        return false;
    }

    let result = false;

    // Handle ApiErrorResult
    if (data.fields?.length > 0) {
        for (const field of data.fields) {
            result = result || assignErrorToField(fieldNamePrefix, field.fieldName, field.message, validator);
        }
    }
    // Handle FluentValidation errors
    else if (data.errors && Object.keys(data.errors).length !== 0) {
        for (const key in result.data.errors) {
            // only use first error because we don't support multiple errors on fields
            result = result || assignErrorToField(fieldNamePrefix, key, result.data.errors[key][0], validator);
        }
    }

    return result;
}

export function assignErrorToField(fieldNamePrefix, fieldName, fieldErrorMessage, validator) {
    const key = fieldNamePrefix + fieldName;
    const valField = validator.fields.find({ name: key });

    if (valField) {
        validator.errors.add({
            field: key,
            msg: fieldErrorMessage || 'Field value is invalid.',
            id: valField ? valField.id : null,
        });

        return true;
    }

    return false;
}