var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "_flexbtwn _ai-c _tc--inverse" }, [
      _c(
        "a",
        {
          staticClass: "gfu-bt--caption _ttu",
          attrs: { href: _vm.currentPledge.cartDetailsUrl },
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.orderDetailsLabel) + "\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "strong",
        {
          staticClass: "gfu-bt gfu-bt--b1 _ml-a",
          attrs: { "data-qa": "mini-wizard-header:AlreadyPledged" },
        },
        [_vm._v("\n            " + _vm._s(_vm.alreadyPledged) + "\n        ")]
      ),
    ]),
    _vm._v(" "),
    _vm.hasTaxHandlingEnabled
      ? _c(
          "div",
          {
            staticClass: "gfu-bt gfu-bt--caption _tc--inverse _tar",
            attrs: { "data-qa": "mini-wizard-item-warning:Tax" },
          },
          [_vm._v("\n        " + _vm._s(_vm.taxesSummaryLabel) + "\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }