import { defineStore } from 'pinia';
import { useCartStore } from '@/Stores/Web/cartStore.js';
import { useCartSummaryStore } from '@/Stores/Web/cartSummaryStore';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';

export const useRootStore = defineStore({
    id: 'root',
    state: () => ({
        initializePromise: null,
    }),
    actions: {
        initialize() {
            if (this.initializePromise !== null) {
                return this.initializePromise;
            }

            const cartSummaryStore = useCartSummaryStore();
            const { preventCartLoading } = useCartStore();
            const { projectContext } = useProjectContextStore();

            this.initializePromise = Promise.all([
                cartSummaryStore.loadCartSummary(projectContext.projectID, preventCartLoading),
            ]);

            return this.initializePromise;
        },
    },
});