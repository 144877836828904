export const adyenCardPaymentName = {
    'amex': 'American Express',
    'amex_applepay': 'Apple Pay - American Express',
    'bcmc': 'Bancontact',
    'cartebancaire': 'Cartes Bancaires',
    'diners': 'Diners',
    'discover': 'Discover',
    'discover_applepay': 'Apple Pay - Discover',
    'jcb': 'JCB',
    'jcb_applepay': 'Apple Pay - JCB credit card',
    'mc': 'Mastercard',
    'mc_applepay': 'Apple Pay - Mastercard',
    'visa': 'Visa',
    'visa_applepay': 'Apple Pay - Visa',
};