var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mt-5" }, [
        _vm._v(_vm._s(_vm.Resources.YourPledge.StretchPaySectionHeader)),
      ]),
      _vm._v(" "),
      _vm._l(_vm.paymentTypes, function (paymentType) {
        return [
          _c(
            paymentType.name,
            _vm._b(
              {
                key: paymentType.name,
                tag: "component",
                on: { "payment-type-changed": _vm.onPaymentTypeChanged },
              },
              "component",
              paymentType.props,
              false
            )
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }