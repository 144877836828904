var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": true,
        "body-class-list": ["gfu-zoomable-gallery-modal"],
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-zoomable-gallery-modal__nav _flexbtwn _ai-c _px-3 _py-3",
        },
        [
          _c("span", { staticClass: "_tc--gray" }, [
            _vm._v(_vm._s(_vm.galleryNavigationText)),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "_flexaicntr _tc--accent _ml-a",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onGalleryClose.apply(null, arguments)
                },
              },
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.Resources.Common.ModalCloseButton)),
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "modal-navigation__icon _fa _fa--times _ml-1 _fr",
                attrs: { "data-qa": "modal-button:Close" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-zoomable-gallery" },
        [
          _c("progress-overlay", { attrs: { visible: !_vm.isImageLoaded } }),
          _vm._v(" "),
          _c("base-slider", {
            staticClass: "gfu-zoomable-gallery__slider",
            attrs: {
              current: _vm.currentDisplayedImage,
              "can-swipe": _vm.canSwipe,
            },
            on: { change: _vm.onChange },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return _vm._l(_vm.images, function (image, index) {
                    return _c(
                      "div",
                      {
                        key: image.imageUrl + index,
                        class: [
                          "gfu-zoomable-gallery__slide",
                          { "is-active": _vm.currentDisplayedImage === index },
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "imageContainer",
                            refInFor: true,
                            staticClass:
                              "gfu-zoomable-gallery__image _flexcntr _ai-c",
                          },
                          [
                            _c("base-image", {
                              ref: "image",
                              refInFor: true,
                              class: _vm.imageClass,
                              attrs: {
                                draggable: "false",
                                src: image.imageUrl,
                              },
                              on: { load: _vm.onImageLoaded },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  })
                },
                proxy: true,
              },
              {
                key: "controls",
                fn: function ({ onNext, onPrev }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-zoomable-gallery__controls _flexbtwn _ai-c _px-4",
                      },
                      [
                        _vm.hasMoreThanOneSlide
                          ? _c("base-slider-navigation", {
                              staticClass: "gfu-zoomable-gallery-nav-item",
                              attrs: { type: "prev", icon: "angle-left" },
                              on: { click: onPrev },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "_flex _jc-c _gap-1 _grow-1" },
                          [
                            _c("base-slider-navigation", {
                              ref: "zoomOutButton",
                              staticClass: "gfu-zoomable-gallery-nav-item",
                              attrs: { icon: "minus" },
                            }),
                            _vm._v(" "),
                            _c("base-slider-navigation", {
                              ref: "zoomInButton",
                              staticClass: "gfu-zoomable-gallery-nav-item",
                              attrs: { icon: "plus" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.hasMoreThanOneSlide
                          ? _c("base-slider-navigation", {
                              staticClass: "gfu-zoomable-gallery-nav-item",
                              attrs: { type: "prev", icon: "angle-right" },
                              on: { click: onNext },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }