var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-toast",
      attrs: { "data-qa": _vm.qaKey },
      on: {
        mouseenter: _vm.onToggleHoverState,
        mouseleave: _vm.onToggleHoverState,
      },
    },
    [
      _c(
        "div",
        { staticClass: "gfu-toast__icon" },
        [_c("circle-icon", { attrs: { icon: _vm.icon, theme: _vm.type } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "_wbbw _flexcol _grow-1 _jc-c" }, [
        _c(
          "span",
          { staticClass: "_fw-eb", attrs: { "data-qa": "toast-title" } },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "gfu-bt--caption _tc--gray _my-0",
            attrs: { "data-qa": "toast-message" },
          },
          [_vm._v(_vm._s(_vm.description))]
        ),
        _vm._v(" "),
        _vm.hasActions
          ? _c(
              "div",
              { staticClass: "gfu-bt--caption _flexaicntr _flexwrap _mt-1" },
              _vm._l(_vm.actionsMapped, function (action) {
                return _c(
                  "a",
                  {
                    key: action.title,
                    class: action.classes,
                    attrs: {
                      href: action.href,
                      "data-qa": `toast-button:${action.title}`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onAction($event, action)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(action.title) +
                        "\n            "
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-toast__close" },
        [
          _vm.canClose
            ? _c("close-button", {
                attrs: {
                  duration: _vm.duration,
                  countdown: _vm.shouldCountdown,
                  qa: "toast-close-btn",
                },
                on: { close: _vm.onClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }