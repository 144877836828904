var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-comment-login" },
    [
      _c(
        "div",
        [
          _c("h4", { staticClass: "gfu-hd gfu-hd--h3" }, [
            _vm._v(_vm._s(_vm.Resources.Comments.LogInToCommentHeader)),
          ]),
          _vm._v(" "),
          _c(
            "localize",
            {
              attrs: {
                resource: _vm.Resources.Comments.LogInToCommentParagraph,
              },
            },
            [
              _c("em", { attrs: { "format-key": "0" } }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.createAccountUrlWithReturn,
                      "data-qa": "project-comments-button:CreateAccount",
                    },
                  },
                  [_vm._v(_vm._s(_vm.Resources.Comments.SignUpLink))]
                ),
              ]),
              _vm._v(".\n        "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "gfu-comment-login__button",
          attrs: {
            tag: "a",
            type: null,
            theme: "accent",
            "data-qa": "project-comments-button:LogIn",
          },
          on: { click: _vm.onClick },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Comments.LogInCallToActionButton) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }