var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "gfu-project-mini-wizard__collapse",
      attrs: { "data-qa": "mini-wizard-button:ShowHide" },
      on: { click: _vm.toggleCollapsed },
    },
    [
      _c("div", {
        class: [
          "gfu-project-mini-wizard__collapse-icon",
          {
            "gfu-project-mini-wizard__collapse-icon--expanded":
              !_vm.isCollapsed,
          },
        ],
      }),
      _vm._v(" "),
      _vm.isCollapsed
        ? _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.Resources.ProjectHome.MiniWizardCollapsibleShowItemsButton
                ) +
                "\n    "
            ),
          ])
        : _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.Resources.ProjectHome.MiniWizardCollapsibleHideItemsButton
                ) +
                "\n    "
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }