var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_mt-1 _tac _mb-3" }, [
    _c("h4", { staticClass: "gfu-hd gfu-hd--h3" }, [_vm._t("header")], 2),
    _vm._v(" "),
    _c("p", { staticClass: "_mt-1 _mb-0" }, [_vm._t("subheader")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }