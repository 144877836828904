var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flex _ai-c _flexwrap _gap-1 _mt-3" },
    [
      _c("icon-base-label", {
        staticClass: "_flex",
        attrs: {
          theme: "accent",
          icon: "check",
          shape: "badge",
          "icon-size": "lg",
          "data-qa": "stretch-pay-success-tick-mark",
        },
      }),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "_mt-0 _tc--base" },
        [
          _c(
            "localize",
            {
              attrs: {
                resource: _vm.Resources.ProjectHome.StretchPayAvailableInfo,
              },
            },
            [
              _c("base-image", {
                attrs: {
                  "format-key": "0",
                  src: "/content/images/installments-logo.svg",
                  alt: "stretchPay",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }