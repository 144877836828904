var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.localItems.length > 0
    ? _c("base-slider", {
        attrs: { autoplay: true },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({}) {
                return _vm._l(_vm.images, function (image) {
                  return _c("div", {
                    key: image.key,
                    class: [
                      "gfu-creator-banner__slide",
                      { "is-active": image.isActive },
                    ],
                    style: `background-image: url(${image.imageUrl});`,
                    attrs: {
                      "data-qa": `creator-banner-slider-image:${image.key}`,
                    },
                  })
                })
              },
            },
            {
              key: "nav",
              fn: function () {
                return [_c("div")]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2554740354
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }