var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_mt-a" }, [
    _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" }, [
      _vm._v("Last update:"),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { "data-qa": "project-card:LastUpdate" } }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }