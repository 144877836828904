var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "gfu-heading _mb-3" }, [
        _vm._v(_vm._s(_vm.Resources.Cookies.SettingsBoxHeader)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-subheading gfu-subheading--wizard _mb-6" },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Cookies.SettingsBoxParagraph) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_mb-6 gfu-box gfu-box--flat gfu-box--nested" },
        [
          _c(
            "toggle-field",
            {
              attrs: {
                type: "switch",
                disabled: true,
                "data-qa": _vm.technicalCookieName,
              },
              model: {
                value: _vm.model.technicalCookiesAccepted,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "technicalCookiesAccepted", $$v)
                },
                expression: "model.technicalCookiesAccepted",
              },
            },
            [
              _c("span", { staticClass: "gfu-heading" }, [
                _vm._v(_vm._s(_vm.Resources.Cookies.TechnicalCookiesHeader)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "_ml-6" }, [
            _vm._v(_vm._s(_vm.Resources.Cookies.TechnicalCookiesParagraph)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-box gfu-box--flat gfu-box--nested" },
        [
          _c(
            "toggle-field",
            {
              attrs: {
                type: "switch",
                "data-qa": "AnalyticCookiePolicyAccepted",
              },
              on: { change: _vm.updateAnalyticsCookie },
              model: {
                value: _vm.model.analyticCookiesAccepted,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "analyticCookiesAccepted", $$v)
                },
                expression: "model.analyticCookiesAccepted",
              },
            },
            [
              _c("span", { staticClass: "gfu-heading" }, [
                _vm._v(_vm._s(_vm.Resources.Cookies.AnalyticCookiesHeader)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "_ml-6" }, [
            _vm._v(_vm._s(_vm.Resources.Cookies.AnalyticCookiesParagraph)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }