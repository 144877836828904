var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "project-explorer",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "prefix",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "project-explorer",
      _vm.explorerProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }