import { mapState } from 'pinia';
import Config from '@/Config';
import Resources from '@/Resources';
import { apiClient } from '@/Clients/ApiClient.js';
import { useCartSummaryStore } from '@/Stores/Web/cartSummaryStore';
import { useUserContextStore } from '@/Stores/Web/userContextStore';
import { useProjectUserContextStore } from '@/Stores/Web/projectUserContextStore';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
import { orderStates, projectPermissions } from '@/Models';
import { userHasProjectPermission } from '@/Utils/RoleUtility';
import AnalyticsTracking from '@/Plugins/AnalyticsTracking';
import ProductsService from '@/Services/ProductsService';

export default {
    name: 'ProductCardListProvider',
    props: {
        products: { type: Array, required: true },
    },
    data: () => ({
        stockLimits: [],
    }),
    computed: {
        currentPledge() {
            return this.getCurrentPledge(this.projectContext.project.version, this.orderAwaitingPayment);
        },
        isEditor() {
            return userHasProjectPermission(this.userContext.user, projectPermissions.editProjectSettings, this.projectContext.creatorID, this.projectContext.projectID);
        },
        canEdit() {
            return this.isEditor;
        },
        productList() {
            const products = [...this.products];
            return products.map(product => {
                if (Array.isArray(this.stockLimits)) {
                    product.stockLimits = this.stockLimits.find(sl => sl.productID === product.productID)?.stockLimit;
                }
                return product;
            });
        },
        pledge() {
            return this.currentPledge.cart || {
                orderState: orderStates.unfinished,
                parentOrder: null,
            };
        },
        productsIDList() {
            return this.productList.map(p => p.productID);
        },
        isUnfinishedOrder() {
            return this.pledge.orderState === orderStates.unfinished;
        },
        isPledgePlaced() {
            return !this.isUnfinishedOrder
                || this.pledge.parentOrder !== null;
        },
        checkoutUrl() {
            return Config.projectNavigation.checkoutUrl;
        },
        ...mapState(useProjectContextStore, ['projectContext']),
        ...mapState(useUserContextStore, ['userContext']),
        ...mapState(useCartSummaryStore, ['getCurrentPledge']),
        ...mapState(useProjectUserContextStore, ['orderAwaitingPayment']),
    },
    methods: {
        onItemAdded(orderItem) {
            const tracking = new AnalyticsTracking();
            tracking.addToCartTracking(orderItem);
        },
        getAddProductToCartModel(product) {
            const { checkoutUrl } = this;
            const {
                productID,
                projectID,
                productCanBePurchasedDescription,
                productCanBePurchased,
                remainingStockLimit,
                deliveryDateRemarks,
                estimatedDeliveryAt,
            } = product;

            return {
                buttonText: Resources.Product.RewardCardAddToPledgeButton, // 'Add to pledge',
                productId: productID,
                projectId: projectID,
                checkoutUrl,
                purchaseLimit: remainingStockLimit,
                readOnly: !productCanBePurchased,
                tooltipText: productCanBePurchasedDescription,
                shape: 'hard',
                iconPosition: 'right',
                textAlign: 'left',
                contentWidth: 'normal',
                accented: true,
                fixedTooltip: true,
                deliveryDateRemarks,
                estimatedDeliveryAt,
                productName: product.name,
                productImageUrl: product.imageUrl,
                stockLimits: product.stockLimits,
            };
        },
        async getProductStockLimits() {
            try {
                if (!this.productsIDList.length) {
                    return;
                }
                const response = await this._productsService.getProductStockLimits(this.productsIDList, this.projectContext.projectID);
                if (response.data) {
                    this.stockLimits = response.data;
                }

            }
            catch (e) {
                this.handleError(e);
            }
        },
        handleError(error) {
            this.$notify.popupServerError(error);
        },
    },
    created() {
        this._productsService = new ProductsService(apiClient);
    },
    render() {
        return this.$scopedSlots.default({
            productList: this.productList,
            onItemAdded: this.onItemAdded,
            getAddProductToCartModel: this.getAddProductToCartModel,
        });
    },
    async mounted() {
        await this.getProductStockLimits();
    },
};