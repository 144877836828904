import AsyncHelpers from '@/Mixins/AsyncHelpers';
import analytics from '@/Analytics';
import { getPermission, isBrowserSupported, iOSPushPermissionRequest, getIOSPushToken, states } from '@/Utils/WebPushUtility';
import { apiClient } from '@/Clients/ApiClient';
import PushNotificationService from '@/Services/PushNotificationsService';
import { mapState } from 'pinia';
import { useUserContextStore } from '@/Stores/Web/userContextStore.js';
import { iOSPushCapability } from '@/Utils/WebPushUtility';

export default {
    data: () => ({
        shouldActivateIOSPush: false,
    }),
    mixins: [AsyncHelpers],
    computed: {
        isIOSPushCapability() {
            return iOSPushCapability();
        },
        ...mapState(useUserContextStore, ['userId']),
    },
    methods: {
        onEnablePush() {
            if (this.isIOSPushCapability) {
                this.shouldActivateIOSPush = true;
                iOSPushPermissionRequest();
            }
            else {
                this.enableWebPushNotificationsAsync();
            }
        },
        onIOSPushPermissionRequest(event) {
            if (event && event.detail) {
                switch (event.detail) {
                case 'granted':
                    getIOSPushToken();
                    break;
                default:
                    // permission denied
                    break;
                }
            }
        },
        getIOSToken(event) {
            if (event && event.detail && this.shouldActivateIOSPush) {
                const token = event.detail;
                this.handleNotificationEnableSuccess(token);
            }
        },
        handleNotificationEnableSuccess(token) {
            this.enablePushNotifications(token);
            this.$notify.popupSuccess(this.Resources.Layout.WebPushConfirmationSuccessMessage);
            this.$emit('push-notification-enabled', states.granted);
            analytics.track('enable-push', {
                section: 'push-notification-activator',
                pushEnabled: true,
                userId: this.userId,
            });
        },
        async enableWebPushNotificationsAsync() {
            try {
                this.isProcessing = true;
                const isSupported = await isBrowserSupported();
                if (isSupported) {
                    const token = await getPermission();
                    switch (token) {
                    case undefined:
                        return;
                    case false:
                        this.$emit('push-notification-rejected', states.denied);
                        return;
                    default:
                        this.handleNotificationEnableSuccess(token);
                    }
                }
                else {
                    throw new Error(this.Resources.Layout.WebPushNotSupportedErrorMessage);
                }
            }
            catch (error) {
                this.handleError(error);
                analytics.track('enable-push', {
                    section: 'push-notification-activator',
                    pushEnabled: false,
                    userId: this.userId,
                });
            }
            finally {
                this.isProcessing = false;
            }
        },
        handleError(error) {
            this.$notify.popupServerError(error);
        },
    },
    created() {
        const pushNotificationService = new PushNotificationService(apiClient);
        this.enablePushNotifications = this.withProcessing(pushNotificationService
            .enablePushNotifications.bind(pushNotificationService));
    },
    mounted() {
        window.addEventListener('push-permission-request', this.onIOSPushPermissionRequest);
        window.addEventListener('push-token', this.getIOSToken);
    },
    beforeDestroy() {
        window.removeEventListener('push-permission-request', this.onIOSPushPermissionRequest);
        window.removeEventListener('push-token', this.getIOSToken);
    },
};