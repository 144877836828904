var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    [
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
            attrs: { "data-qa": "address-data-form:FullName" },
          },
          [
            _c("text-field", {
              attrs: {
                name: "model.fullName",
                "display-name": _vm.Resources.Checkout.UserFullNameLabel,
                disabled: _vm.isFullAddressDisabled,
              },
              model: {
                value: _vm.model.fullName,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "fullName", $$v)
                },
                expression: "model.fullName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
            attrs: { "data-qa": "address-data-form:Country" },
          },
          [
            _c("option-field", {
              attrs: {
                name: "model.projectLocationID",
                "display-name": _vm.Resources.Checkout.UserCountryLabel,
                options: _vm.availableProjectLocationsOptions,
                disabled: _vm.isProjectLocationDisabled,
              },
              on: { input: _vm.onLocationChange },
              model: {
                value: _vm.model.projectLocationID,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "projectLocationID", $$v)
                },
                expression: "model.projectLocationID",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
            attrs: { "data-qa": "address-data-form:City" },
          },
          [
            _c("text-field", {
              attrs: {
                name: "model.city",
                "display-name": _vm.Resources.Checkout.UserCityLabel,
                disabled: _vm.isFullAddressDisabled,
              },
              model: {
                value: _vm.model.city,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "city", $$v)
                },
                expression: "model.city",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.projectSubLocations.length === 0
          ? _c(
              "div",
              {
                key: "stateOrProvince",
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:StateOrProvince" },
              },
              [
                _c("text-field", {
                  attrs: {
                    name: "model.stateOrProvince",
                    "display-name":
                      _vm.Resources.Checkout.UserStateOrProvinceLabel,
                    disabled: _vm.isFullAddressDisabled,
                  },
                  model: {
                    value: _vm.model.stateOrProvince,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "stateOrProvince", $$v)
                    },
                    expression: "model.stateOrProvince",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              {
                key: "subLocationID",
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:SubLocation" },
              },
              [
                _c("option-field", {
                  attrs: {
                    name: "model.projectSubLocationID",
                    "display-name":
                      _vm.Resources.Checkout.UserStateOrProvinceLabel,
                    options: _vm.availableProjectSubLocationsOptions,
                    disabled: _vm.isProjectSubLocationDisabled,
                  },
                  on: { input: _vm.onSublocationChange },
                  model: {
                    value: _vm.model.projectSubLocationID,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "projectSubLocationID", $$v)
                    },
                    expression: "model.projectSubLocationID",
                  },
                }),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
            attrs: { "data-qa": "address-data-form:PostalCode" },
          },
          [
            _c("text-field", {
              attrs: {
                name: "model.postalCode",
                "display-name": _vm.Resources.Checkout.UserPostalCodeLabel,
                disabled: _vm.isFullAddressDisabled,
              },
              model: {
                value: _vm.model.postalCode,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "postalCode", $$v)
                },
                expression: "model.postalCode",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
            attrs: { "data-qa": "address-data-form:AddressLine1" },
          },
          [
            _c("text-field", {
              attrs: {
                name: "model.addressLine1",
                "display-name": _vm.addressLabel,
                disabled: _vm.isFullAddressDisabled,
              },
              model: {
                value: _vm.model.addressLine1,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "addressLine1", $$v)
                },
                expression: "model.addressLine1",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _vm.effectiveShowAddressLine2
          ? _c(
              "div",
              {
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:AddressLine2" },
              },
              [
                _c("text-field", {
                  attrs: {
                    name: "model.addressLine2",
                    "display-name":
                      _vm.Resources.Checkout.UserSecondAddressLabel,
                    disabled: _vm.isFullAddressDisabled,
                  },
                  model: {
                    value: _vm.model.addressLine2,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "addressLine2", $$v)
                    },
                    expression: "model.addressLine2",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.effectiveShowAddressLine3
          ? _c(
              "div",
              {
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:AddressLine3" },
              },
              [
                _c("text-field", {
                  attrs: {
                    name: "model.addressLine3",
                    "display-name":
                      _vm.Resources.Checkout.UserThirdAddressLabel,
                    disabled: _vm.isFullAddressDisabled,
                  },
                  model: {
                    value: _vm.model.addressLine3,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "addressLine3", $$v)
                    },
                    expression: "model.addressLine3",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.effectiveShowAddressLine2 && !_vm.effectiveShowAddressLine3
          ? _c(
              "div",
              {
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:PhoneNumber" },
              },
              [
                _c("text-field", {
                  attrs: {
                    name: "model.phoneNumber",
                    "display-name": _vm.Resources.Checkout.UserPhoneNumberLabel,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.model.phoneNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "phoneNumber", $$v)
                    },
                    expression: "model.phoneNumber",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        !(_vm.effectiveShowAddressLine2 && !_vm.effectiveShowAddressLine3)
          ? _c(
              "div",
              {
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:PhoneNumber" },
              },
              [
                _c("text-field", {
                  attrs: {
                    name: "model.phoneNumber",
                    "display-name": _vm.Resources.Checkout.UserPhoneNumberLabel,
                    disabled: _vm.readOnly,
                  },
                  model: {
                    value: _vm.model.phoneNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "phoneNumber", $$v)
                    },
                    expression: "model.phoneNumber",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showVatID
          ? _c(
              "div",
              {
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:VatNumber" },
              },
              [
                _c("text-field", {
                  attrs: {
                    name: "model.vatNumber",
                    "display-name": _vm.customerVatNumberFieldName,
                    "tooltip-text": _vm.customerVatNumberFieldRules.tooltip,
                    disabled: _vm.isVatPayerFieldsDisabled,
                  },
                  model: {
                    value: _vm.model.vatNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "vatNumber", $$v)
                    },
                    expression: "model.vatNumber",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _c(
          "div",
          { staticClass: "gfu-grid__cell gfu-1of1" },
          [
            _vm.isRegisteredVatPayerCheckboxVisible
              ? _c("vat-reverse-checkbox", {
                  staticClass: "_mt-2",
                  attrs: {
                    "tax-regulation": _vm.projectLocation.taxRegulation,
                    disabled: _vm.isVatPayerFieldsDisabled,
                    "data-qa": "begin-order-form:validateVat",
                  },
                  model: {
                    value: _vm.model.isRegisteredVatPayer,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "isRegisteredVatPayer", $$v)
                    },
                    expression: "model.isRegisteredVatPayer",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
        _vm.hasShippingLocalizedAddressRules
          ? _c(
              "div",
              {
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:LocalizedAddress" },
              },
              [
                _c("text-area-field", {
                  attrs: {
                    name: "model.shippingLocalizedAddress",
                    "display-name":
                      _vm.Resources.Checkout.LocalizedAddressLabel,
                    description: "",
                    "tooltip-text": _vm.shippingLocalizedAddressRules.tooltip,
                    disabled: _vm.readOnly,
                    rows: "4",
                  },
                  model: {
                    value: _vm.model.shippingLocalizedAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "shippingLocalizedAddress", $$v)
                    },
                    expression: "model.shippingLocalizedAddress",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showNotes
          ? _c(
              "div",
              {
                staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m",
                attrs: { "data-qa": "address-data-form:Notes" },
              },
              [
                _c("text-area-field", {
                  attrs: {
                    name: "model.notes",
                    "display-name": _vm.Resources.Checkout.UserNotesLabel,
                    description: "",
                    disabled: _vm.readOnly,
                    rows: "4",
                  },
                  model: {
                    value: _vm.model.notes,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "notes", $$v)
                    },
                    expression: "model.notes",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }