var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "show-action-close": true,
        "is-visible": _vm.isVisible,
        width: "narrow",
        title: _vm.Resources.Account.LogInModalTitle,
        icon: "right-to-bracket",
        subtitle: _vm.subtitle,
      },
      on: { "action-close": _vm.close, open: _vm.onOpen },
    },
    [
      _c("external-login-actions", {
        staticClass: "_mb-3",
        attrs: { "return-url": _vm.returnUrl },
        on: { success: _vm.onSuccess },
      }),
      _vm._v(" "),
      _c("login-form", {
        attrs: { "return-url": _vm.returnUrl },
        on: { success: _vm.onSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }