var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_rounded--common _pos-r" },
    [
      _c(
        "transition",
        { attrs: { name: "gfu-fade", mode: "out-in" } },
        [
          _vm.isProcessing
            ? [
                _c("skeleton-comment-user-profile-card", {
                  attrs: { "data-qa": "user-profile-content:skeleton" },
                }),
              ]
            : _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      class: [
                        "gfu-media _gap-1",
                        { "_ai-c": !_vm.hasPledgeMasterBadge },
                      ],
                    },
                    [
                      _c(
                        "a",
                        { attrs: { href: _vm.profileUrl } },
                        [
                          _c("base-avatar", {
                            staticClass: "_mr-1",
                            attrs: {
                              "avatar-url": _vm.avatarImageUrl,
                              alt: _vm.nickname,
                              size: "sm",
                              clean: true,
                              "data-qa": "user-profile-content:avatar-image",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "gfu-comment-user-profile-card-info _fo _flexcol",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-link--nofx",
                              attrs: { href: _vm.profileUrl },
                            },
                            [
                              _c(
                                "h2",
                                {
                                  class: [
                                    "gfu-hd gfu-hd--h1 _tc--base _toe",
                                    { "_mt-1": !_vm.isPublic },
                                  ],
                                  attrs: {
                                    "data-qa": "user-profile-content:nickname",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.nickname))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isPublic
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "gfu-comment-user-profile-card-info__followers",
                                  attrs: {
                                    "data-qa":
                                      "user-profile-content:followers-label",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.formattedFollowersLabel))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.labels, function (label) {
                            return _c(
                              "icon-base-label",
                              _vm._b(
                                {
                                  key: label.name,
                                  staticClass: "_mt-1",
                                  attrs: {
                                    "data-qa": `user-profile-content:${label.dataQa}`,
                                  },
                                },
                                "icon-base-label",
                                label,
                                false
                              )
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isPublic
                    ? [
                        _c(
                          "p",
                          {
                            staticClass: "_tc--lighter",
                            attrs: { "data-qa": "user-profile-content:bio" },
                          },
                          [_vm._v(_vm._s(_vm.bio))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "_flexcol _mt-2 _gap-3" },
                          [
                            _vm.isFollowingButtonVisible
                              ? _c("user-follow-button", {
                                  attrs: {
                                    nickname: _vm.nickname,
                                    "user-url-name": _vm.urlName,
                                    "user-id": _vm.userID,
                                    "data-qa":
                                      "user-profile-content:follow-button",
                                  },
                                  on: { action: _vm.onUserFollowAction },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "gfu-link gfu-link-underline gfu-link--accent _tac",
                                attrs: {
                                  href: _vm.profileUrl,
                                  "data-qa":
                                    "user-profile-content:view-profile-link",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "gfu-link-underline__content",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.Resources.Profile.ViewProfile)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-qa":
                                "user-profile-content:private-profile-text",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.Profile
                                  .CommentUserProfilePrivateStateParagraph
                              )
                            ),
                          ]
                        ),
                      ],
                ],
                2
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }