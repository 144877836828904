var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasContent
    ? _c(
        "div",
        [
          _vm._t("header"),
          _vm._v(" "),
          _vm.isRichText
            ? _c("span", {
                class: [
                  "gfu-richtext",
                  { "gfu-richtext--article": _vm.isArticle },
                ],
                domProps: { innerHTML: _vm._s(_vm.content) },
              })
            : [_vm._v(_vm._s(_vm.content))],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }