import ServiceBase from './ServiceBase';

export default class PaymentsService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    createApplePaySession() {
        return this.request('post', 'payments/createApplePaySession');
    }

    getCompletedOrderPayments(orderID) {
        const params = {
            orderID,
        };

        return this.request('get', 'payments/getCompletedOrderPayments', params);
    }
}