import ServiceBase from './ServiceBase';

export default class ProjectRecommendationsService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    // TODO: Remove includeRefs in #20854
    getProjectRecommendations(quantity, excludeProjectIDs, source, includeRefs) {
        return this.request('post', 'projectRecommendations/getProjectRecommendations', { quantity, excludeProjectIDs, source, includeRefs });
    }
}