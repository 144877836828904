import StatisticsService from '@/Services/StatisticsService.js';
import { apiClient } from '@/Clients/ApiClient.js';
const statisticsService = new StatisticsService(apiClient);

export default {
    data() {
        return {
            creatorStatistics: {
                usersCount: 0,
                projectsCount: 0,
                ordersValue: 0,
                creatorsCount: 0,
                ordersCount: 0,
            },
        };
    },
    methods: {
        async getStatistics() {
            try {
                const { data } = await statisticsService.getGlobalApplicationStatistics();
                this.creatorStatistics = data;
            }
            catch (error) {
                this.$notify.popupServerError(error);
            }
        },
    },
};
