import { loadScript } from '@/Utils/ScriptUtility';
import { externalScripts, userIdentityProviders } from '@/Config';
import { ExternalSignInErrorTypes } from '@/Types/ExternalSignInErrorTypes';
import { ExternalSignInError } from '@/Utils/ExternalSignInUtility';

const appleSignInUrl = externalScripts.appleSignInUrl;

export default class AppleSignIn {
    async initAsync() {
        try {
            const result = await loadScript(appleSignInUrl);

            if (result) {
                this.loadAppleApi();
                return this;
            }
        }
        catch (error) {
            throw new Error(error);
        }
    }

    loadAppleApi () {
        window.AppleID.auth.init({
            clientId: userIdentityProviders.apple.clientID,
            redirectURI: userIdentityProviders.apple.redirectUrl,
            scope: 'name email',
            usePopup: true,
        });
    }

    async signInAsync() {
        try {
            const data = await window.AppleID.auth.signIn();

            // The user's object is returned only during account creation
            return { token: data.authorization.code, user: data?.user?.name };
        }
        catch (error) {
            if (error.error === 'popup_closed_by_user') {
                throw new ExternalSignInError(ExternalSignInErrorTypes.popupClosed);
            }
            throw new ExternalSignInError(ExternalSignInErrorTypes.error);
        }

    }
}