
function analyticsManager(config = {}) {
    const plugins = config.plugins;

    const instance = {
        track: (eventName, payload, projectContext) => {
            plugins.forEach(plugin => {
                plugin.track({ event: eventName, properties: payload, projectContext });
            });
        },
    };

    return instance;
}

export { analyticsManager };