var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-header__actions gfu-navbar-nav",
      attrs: { "data-qa": "navbar-header-actions" },
    },
    [
      _vm.isCurrencySelectorVisible
        ? _c(
            "div",
            {
              staticClass: "gfu-navbar-item _screen--none _screen--flex-m",
              attrs: { "data-qa": "header-actions:CurrencySelector" },
            },
            [
              _c("currency-selector", {
                staticClass: "gfu-navbar-btn gfu-navbar-btn--narrow",
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLanguageSelectorVisible ? _c("language-selector") : _vm._e(),
      _vm._v(" "),
      _vm.isCartBoxVisible ? _c("cart-box") : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "gfu-navbar-item gfu-navbar-link--no-fx _cur-p _tdn",
          attrs: {
            href: _vm.myProjectUrl,
            title: _vm.Resources.MyProjects.PageHeader,
            "data-qa": "header-actions:MyProjects",
          },
        },
        [
          _c("base-icon", {
            staticClass: "gfu-navbar-btn gfu-navbar-btn--narrow",
            attrs: { name: "books-icon", size: "lg", "icon-namespace": "ga" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPushNotificationActive ? _c("push-notification-manager") : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile ? _c("user-box") : _vm._e(),
      _vm._v(" "),
      _vm.isMobile ? _c("mobile-menu") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }