var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-box" },
    [
      _c("contact-back", {
        staticClass: "_mb-3",
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _vm._v(" "),
      _c(
        "contact-current-group-header",
        _vm._b(
          {},
          "contact-current-group-header",
          {
            currentCategoryGroup: _vm.currentCategoryGroup,
            currentProject: _vm.currentProject,
          },
          false
        )
      ),
      _vm._v(" "),
      _c(
        "h2",
        {
          staticClass: "gfu-hd gfu-hd--h1 _mb-4",
          attrs: { "data-qa": "contact-form-title" },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Contact.TopicsSectionHeader) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showReminder
        ? _c(
            "base-notificator",
            { attrs: { type: "info", "can-close": false } },
            [
              _c(
                "localize",
                {
                  attrs: {
                    resource:
                      _vm.Resources.Contact.ContactSelectProjectNotification,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent-plain",
                      attrs: { "format-key": "0", href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$emit("back")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.Resources.Contact.ContactSelectProjectLink)
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("issue-box", {
        attrs: {
          sections: _vm.issuesToDisplay,
          "project-name": _vm.currentProjectName,
          "allow-search": _vm.allowSearch,
          "show-icon": false,
          "search-field-label":
            _vm.Resources.Contact.ContactSearchTopicsFormName,
          "search-field-placeholder":
            _vm.Resources.Contact.ContactSearchTopicsFormPlaceholder,
        },
        on: {
          "section-click": _vm.onIssueSectionClick,
          "nagative-feedback-click": _vm.onIssueNegativeFeedbackClick,
        },
      }),
      _vm._v(" "),
      _c("contact-back", {
        staticClass: "_mt-3",
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { sticky: true, visible: _vm.isProcessing },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }