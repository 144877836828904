var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": _vm.isModalVisible,
        title: "Warning",
        icon: "cart-plus",
        "data-qa": "go-to-checkout-modal",
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    href: "",
                    "data-qa": "modal-button:ReviewYourPledge",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onAlternativeAction.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("review your pledge")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "gfu-btn gfu-btn--accent gfu-btn--compact gfu-btn--soft gfu-btn--wide _ml-3",
                  attrs: {
                    type: "button",
                    "data-qa": "modal-button:ProceedWithOrder",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onContinueAction.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            Go to checkout\n        ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _vm._v("\n        You added items with different\n        "),
          _vm._l(
            _vm.cartIntegrity.optionsWithMixedValues,
            function (option, index) {
              return _c(
                "span",
                {
                  key: `selection-option-${option}`,
                  attrs: { "data-qa": `selection-option-${option}` },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        index === 0
                          ? ""
                          : index + 1 === _vm.nonUniqueItemOptions.Count
                          ? " and "
                          : ", "
                      )
                  ),
                  _c("strong", [_vm._v(_vm._s(option))]),
                ]
              )
            }
          ),
          _vm._v(
            " options to your pledge. Do you want to proceed to checkout?\n    "
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }