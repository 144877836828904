import { projectLabelTypes, profileInvolvementTypes } from '@/Models.js';

export function buildProjectLabelTheme(labelType) {
    const themes = {
        [projectLabelTypes.endingSoon]: 'error',
        [projectLabelTypes.launchingSoon]: 'accent',
    };

    return themes[labelType] || 'light';
}

export function buildProfileInvolvementLabelTheme(labelType) {
    const themes = {
        [profileInvolvementTypes.backer]: 'accent',
        [profileInvolvementTypes.follower]: 'default',
    };

    return themes[labelType];
}

export function buildProfileInvolvementLabel(labelType, data) {
    const label = {
        name: profileInvolvementTypes[labelType].displayName,
        theme: buildProfileInvolvementLabelTheme(labelType),
    };

    if (labelType === profileInvolvementTypes.backer && data) {
        label.name = `${ label.name } #${ data }`;
    }

    return label;
}
