var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "slider-container",
    { attrs: { "arrow-style": "circle", centered: false } },
    [
      _vm._l(_vm.itemCount, function (n) {
        return _c(
          "div",
          {
            key: n,
            class: [
              _vm.slideWidthClasses,
              "gfu-product-recommendation__item _shrink-0 _grow-0",
            ],
          },
          [
            _c("skeleton-product-recommendation-item", {
              staticClass: "_shrink-0 _grow-0",
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }