var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        _vm.currentComponent.component,
        _vm._b(
          { tag: "components" },
          "components",
          _vm.currentComponent.props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }