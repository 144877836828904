var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-installment-state-box" },
    [
      _c(
        "p",
        {
          class: [
            "gfu-installment-state-box__header",
            { "_mb-1": _vm.hasValue },
          ],
        },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-installment-details" }, [
        _c(
          "span",
          { staticClass: "gfu-installment-details__value" },
          [_vm._t("value")],
          2
        ),
        _vm._v(" "),
        _vm.paymentDate
          ? _c("span", [
              _vm._v(
                "\n            (" + _vm._s(_vm.formattedDate) + ")\n        "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }