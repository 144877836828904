var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isPledgeEmpty
        ? [
            _c(
              "div",
              { staticClass: "_flexcol _gap-1" },
              [
                _c(
                  "base-heading",
                  {
                    attrs: {
                      tag: "h6",
                      variant: "light",
                      "data-qa": "pledge-not-empty",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.ProjectHome.MiniWizardCartItemsHeader
                        ) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "mini-wizard-item",
                    _vm._b(
                      {
                        key: item.orderItemID,
                        on: {
                          delete: function ($event) {
                            return _vm.deleteMethod(item)
                          },
                        },
                      },
                      "mini-wizard-item",
                      item,
                      false
                    )
                  )
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showEmptyRewardsInfo
        ? [
            _vm.showRewardsHeader
              ? _c(
                  "div",
                  {
                    class: [
                      "gfu-bt gfu-bt--caption _tc--light _mb-2 _tac",
                      { "_mt-2": !_vm.isPledgeEmpty },
                    ],
                    attrs: { "data-qa": "mini-wizard-element:NoRewardsYet" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.Resources.ProjectHome.MiniWizardNoCartItemsHeader
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("chooseRewardsButton", function () {
              return [
                _c(
                  "base-button",
                  {
                    attrs: {
                      theme: "primary",
                      display: "block",
                      width: "narrow",
                      to: {
                        name: "page-section",
                        params: { section: _vm.rewardsSection.urlName },
                      },
                      "data-qa": "mini-wizard-button:ChooseReward",
                    },
                  },
                  [
                    _c("base-icon", {
                      staticClass: "_fl",
                      attrs: { name: "plus" },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.ProjectHome
                            .MiniWizardChooseRewardsButton
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showEmptyAddonsInfo
        ? [
            _c(
              "div",
              {
                staticClass: "gfu-bt gfu-bt--caption _tc--light _my-2 _tac",
                attrs: { "data-qa": "mini-wizard-element:NoAddonsYet" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.Resources.ProjectHome.MiniWizardNoAddonsSubheader
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _vm._t("chooseAddonsButton", function () {
              return [
                _c(
                  "base-button",
                  {
                    staticClass: "_mt-2",
                    attrs: {
                      theme: "primary",
                      display: "block",
                      width: "narrow",
                      to: {
                        name: "page-section",
                        params: { section: _vm.addonsSection.urlName },
                      },
                      "data-qa": "mini-wizard-button:PickAddons",
                    },
                  },
                  [
                    _c("base-icon", {
                      staticClass: "_fl",
                      attrs: { name: "plus" },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.ProjectHome.MiniWizardChooseAddonsButton
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddMoreItems
        ? [
            _vm._t("addMoreItemsButton", function () {
              return [
                _c(
                  "base-button",
                  {
                    staticClass: "_mt-2",
                    attrs: {
                      theme: "primary",
                      display: "block",
                      width: "narrow",
                      to: {
                        name: "page-section",
                        params: { section: _vm.rewardsSection.urlName },
                      },
                      "data-qa": "mini-wizard-button:AddMoreItems",
                    },
                  },
                  [
                    _c("base-icon", {
                      staticClass: "_fl",
                      attrs: { name: "plus" },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.Resources.ProjectHome.MiniWizardAddMoreItemsButton
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ]
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }