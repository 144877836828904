var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-project-summary-stats",
        {
          "gfu-project-summary-stats--funded": _vm.wasCampaignSuccessful,
          "gfu-project-summary-stats--update": _vm.hasUpdate,
        },
      ],
      attrs: {
        "data-qa": `project-summary-stats:${_vm.wasCampaignSuccessful}`,
      },
    },
    [
      _c(
        "div",
        { staticClass: "gfu-project-summary-stats-bg" },
        _vm._l(_vm.icons, function (icon) {
          return _c("span", { key: icon, class: icon })
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-project-summary-stats__title" }, [
        _c(
          "h6",
          {
            staticClass: "gfu-hd gfu-hd--h4 _tc--inherit _ttu",
            attrs: { "data-qa": "project-summary-stats:ProjectOutcome" },
          },
          [_vm._v(_vm._s(_vm.title))]
        ),
      ]),
      _vm._v(" "),
      _vm.wasCampaignSuccessful
        ? _c(
            "project-summary-completed-stats-funded",
            _vm._b(
              {},
              "project-summary-completed-stats-funded",
              _vm.projectSummaryStatsFundedProps,
              false
            )
          )
        : [
            _c("div", { staticClass: "gfu-project-summary-stats__content" }, [
              _c("div", { staticClass: "_mt-3" }, [_vm._v(_vm._s(_vm.reason))]),
              _vm._v(" "),
              _c("div", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.ProjectHome
                        .ProjectSummaryStatisticsNoFundsCollectedText
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "_mt-4" }, [
                _vm._v(
                  _vm._s(
                    _vm.Resources.format(
                      _vm.Resources.ProjectHome
                        .ProjectSummaryStatisticsEndDateText,
                      _vm.campaignEndedAt
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "_mt-4",
                  attrs: { "data-qa": "project-summary-stats:BackersCount" },
                },
                [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource:
                          _vm.Resources.ProjectHome
                            .ProjectSummaryStatisticsBackerCountText,
                      },
                    },
                    [
                      _c("span", { attrs: { "format-key": "0" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.pluralize(
                              _vm.backersCount,
                              _vm.Resources.ProjectHome
                                .ProjectSummaryProgressPluralizedBacker
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("display-currency-value", {
                        attrs: {
                          "format-key": "1",
                          "display-tooltip": false,
                          tag: "span",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function ({ displayCurrency }) {
                              return [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$format(
                                        _vm.fundsGathered /
                                          displayCurrency.relativeFactor,
                                        "C0",
                                        displayCurrency.symbol
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$format(_vm.fundsGathered, "C0")
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  attrs: {
                    "data-qa": "project-summary-stats:PercentageOfGoal",
                  },
                },
                [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource:
                          _vm.Resources.ProjectHome
                            .ProjectSummaryStatisticsPercentageOfGoalText,
                      },
                    },
                    [
                      _c("span", { attrs: { "format-key": "0" } }, [
                        _vm._v(_vm._s(_vm.percentageOfGoal)),
                      ]),
                      _vm._v(" "),
                      _c("display-currency-value", {
                        attrs: {
                          "format-key": "1",
                          "display-tooltip": false,
                          tag: "strong",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function ({ displayCurrency }) {
                              return [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$format(
                                        _vm.campaignGoal /
                                          displayCurrency.relativeFactor,
                                        "C0",
                                        displayCurrency.symbol
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$format(_vm.campaignGoal, "C0")
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("project-summary-follow", {
              staticClass: "_mt-4",
              attrs: { theme: "inverse", "button-theme": "transparentInverse" },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }