function findScriptElement(src) {
    const selector = `script[src="${ src }"]`;
    return document.querySelector(selector);
}

export function loadScript(src) {
    return new Promise((resolve, reject) => {
        let element = findScriptElement(src);

        if (element) {
            resolve(element);
            return;
        }

        element = document.createElement('script');
        element.async = true;
        element.src = src;

        element.addEventListener('error', reject);
        element.addEventListener('abort', reject);
        element.addEventListener('load', () => resolve(element));

        document.head.appendChild(element);
    });
}

export function unloadScript(src) {
    return new Promise((resolve, reject) => {
        const element = findScriptElement(src);

        if (element) {
            document.head.removeChild(element);
            resolve(true);
            return;
        }

        reject(false);
    });
}
