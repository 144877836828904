var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "gfu-hd gfu-hd--h2" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.ProjectHome.PledgeWithoutRewardHeader) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.ProjectHome.PledgeWithoutRewardDescription) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-project-without-reward gfu-box _pa-0 gfu-box gfu-box--bulky _mt-3",
          attrs: { "data-qa": "tip-section" },
        },
        [
          _c("unit-field", {
            staticClass: "gfu-project-without-reward__currency-field _mt-0",
            attrs: {
              "display-name":
                _vm.Resources.ProjectHome.PledgeWithoutRewardAmountFieldName,
              "show-label": false,
              name: "model.amount",
              symbol: _vm.currentCheckoutCurrency.shortName,
              placeholder:
                _vm.Resources.ProjectHome
                  .PledgeWithoutRewardAmountFieldPlaceholder,
              "text-align": "left",
              "validator-layout": "tooltip",
              "data-qa": "tip-section-field:TipAmount",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.submit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.amount,
              callback: function ($$v) {
                _vm.$set(_vm.model, "amount", $$v)
              },
              expression: "model.amount",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-project-without-reward__action" }, [
            _c(
              "button",
              {
                staticClass:
                  "gfu-btn gfu-btn--block gfu-btn--hard _tal gfu-btn--accent gfu-project-without-reward__button",
                attrs: { "data-qa": "tip-section-button:AddTip" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "gfu-btn__text" }, [
                  _vm._v(
                    _vm._s(_vm.Resources.Product.RewardCardAddToPledgeButton)
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "_ga _ga--plus-bold-icon _fr" }),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }