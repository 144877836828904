var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-navbar-item gfu-navbar-dropdown",
      attrs: { "data-qa": "header-actions:CartBox" },
    },
    [
      _c(
        "toggle-trigger",
        {
          staticClass:
            "gfu-navbar-btn gfu-navbar-btn--narrow gfu-navbar-dropdown-pointer",
          attrs: {
            tag: "button",
            "active-class": "is-visible",
            "container-full-screen-width": _vm.isMobile,
            "data-qa": "header-actions:CartBoxButton",
          },
        },
        [
          _c(
            "base-icon",
            {
              class: ["_pos-r", { "gfu-header-shake": _vm.isAddingToCart }],
              attrs: {
                size: "lg",
                name: "shopping-cart",
                "data-qa": "header-actions:ShoppingCart",
              },
            },
            [
              _vm.totalQuantity > 0
                ? _c(
                    "span",
                    {
                      class: [
                        "gfu-cart-badge",
                        { "gfu-cart-badge--big": _vm.isAddingToCart },
                      ],
                      attrs: { "data-qa": "cart-box:QuantityBadge" },
                    },
                    [_vm._v(_vm._s(_vm.itemsCount))]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "toggle-container",
        {
          staticClass: "gfu-navbar-dropdown__content",
          attrs: { "active-class": "is-visible", tabindex: "0" },
        },
        [
          _vm.isCartAvailable
            ? _c("base-balloon", {
                key: "single",
                attrs: {
                  width: "wide",
                  position: "right",
                  "is-pointer-visible": false,
                  "data-qa": "header-actions:CartBoxBalloon",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "gfu-balloon__top-title",
                              attrs: { "data-qa": "cart-box:ProjectName" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.model.cart.project.name) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "_flex _jc-sb" }, [
                            _c(
                              "span",
                              { attrs: { "data-qa": "cart-box:Quantity" } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.Resources.format(
                                        _vm.Resources.Layout
                                          .CartBoxTotalItemsQuantity,
                                        _vm.totalQuantity
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$format(
                                    _vm.totalPriceConverted,
                                    "C2",
                                    _vm.model.cart.currencySymbol
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "content",
                      fn: function () {
                        return [
                          !_vm.pledgeIsEmpty
                            ? [
                                _vm.model.cart.parentOrder
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-balloon__actions _mb-3 _pt-0",
                                        attrs: {
                                          "data-qa": "cart-box:ModifyText",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.Resources.format(
                                                _vm.Resources.Layout
                                                  .CartBoxModifyText,
                                                _vm.orderPublicID
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.unpaidItems, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.orderItemID,
                                      staticClass: "_flex _gap-3",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "_flex",
                                          attrs: {
                                            href: _vm.model.cartDetailsUrl,
                                          },
                                        },
                                        [
                                          _c("base-image", {
                                            attrs: {
                                              src: item.imageUrl,
                                              width: "48",
                                              height: "48",
                                              loading: "lazy",
                                              alt: item.displayName,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "_py-1" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "gfu-balloon__content-item-title",
                                            attrs: {
                                              href: _vm.model.cartDetailsUrl,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(item.displayName) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-balloon__content-item-desc",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.Resources.format(
                                                    _vm.Resources.Layout
                                                      .CartBoxItemQuantity,
                                                    item.quantity
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                            item.options.length > 0
                                              ? _c(
                                                  "span",
                                                  _vm._l(
                                                    item.options,
                                                    function (option, index) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: option.productOptionID,
                                                        },
                                                        [
                                                          index === 0
                                                            ? _c("span", [
                                                                _vm._v("("),
                                                              ])
                                                            : _vm._e(),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.valueText
                                                              )
                                                            ),
                                                          ]),
                                                          index + 1 <
                                                          item.options.length
                                                            ? _c("span", [
                                                                _vm._v(", "),
                                                              ])
                                                            : _vm._e(),
                                                          index + 1 ===
                                                          item.options.length
                                                            ? _c("span", [
                                                                _vm._v(")"),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _vm.model.productOrderItems.length > 5
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-balloon__content-item _tac",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "gfu-link gfu-link--accent",
                                            attrs: {
                                              href: _vm.model.cartDetailsUrl,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.Layout
                                                  .CartBoxViewAllButton
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.model.productOrderItems.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "gfu-balloon__actions" },
                                      [
                                        _c(
                                          "base-button",
                                          {
                                            attrs: {
                                              tag: "a",
                                              href: _vm.model.cartDetailsUrl,
                                              theme: "primary",
                                              width: "compact",
                                              shape: "soft",
                                              "data-qa":
                                                "header-actions:ViewCart",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.Resources.Layout
                                                    .CartBoxViewCartButton
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("go-to-checkout", {
                                          attrs: {
                                            "continue-url":
                                              _vm.model.checkoutUrl,
                                            "order-code": _vm.parentOrderCode,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ onInvoke }) {
                                                  return [
                                                    _c(
                                                      "base-button",
                                                      {
                                                        attrs: {
                                                          tag: "a",
                                                          href: _vm.model
                                                            .checkoutUrl,
                                                          theme: "accent",
                                                          width: "compact",
                                                          shape: "soft",
                                                          "data-qa":
                                                            "header-actions:CheckOut",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return onInvoke.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    " +
                                                            _vm._s(
                                                              _vm.Resources
                                                                .Layout
                                                                .CartBoxCheckoutButton
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4182580652
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _c(
                                "div",
                                {
                                  staticClass: "_tac",
                                  attrs: { "data-qa": "cart-box:EmptyText" },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.Resources.Layout.CartBoxEmptyPledge
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2659010533
                ),
              })
            : _vm.hasMultipleCarts
            ? _c("base-balloon", {
                key: "multiple",
                attrs: { "is-pointer-visible": false, position: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "gfu-balloon__top-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.Layout.CartBoxMultiplePledgesTitle
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.format(
                                  _vm.Resources.Layout
                                    .CartBoxMultiplePledgesText,
                                  _vm.model.projectsCount
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              tag: "a",
                              href: _vm.model.cartDetailsUrl,
                              theme: "primary",
                              width: "compact",
                              shape: "soft",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.Resources.Layout.CartBoxViewPledgesButton
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              })
            : _c("base-balloon", {
                key: "empty",
                attrs: { "is-pointer-visible": false, position: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "gfu-balloon__top-title" }, [
                          _vm._v(
                            _vm._s(_vm.Resources.Layout.CartBoxNoPledgesTitle)
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { "data-qa": "cart-box:ItemsQuantity" } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.Resources.format(
                                    _vm.Resources.Layout
                                      .CartBoxTotalItemsQuantity,
                                    0
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "_tac",
                            attrs: { "data-qa": "cart-box:EmptyText" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.Resources.Layout.CartBoxEmptyPledge
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }