var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
    [
      _c("div", { staticClass: "gfu-heading__wrapper" }, [
        _c("h1", { staticClass: "gfu-heading gfu-heading--main _mt-6" }, [
          _vm._v("\n            System health\n        "),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "gfu-heading__actions" },
          [
            _c("toggle-field", {
              staticClass: "_mt-1",
              attrs: {
                "display-name": "Auto-refresh",
                type: "switch",
                inline: true,
                enabled: !_vm.isProcessing,
              },
              model: {
                value: _vm.autoRefresh,
                callback: function ($$v) {
                  _vm.autoRefresh = $$v
                },
                expression: "autoRefresh",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "_ml-3 gfu-btn gfu-btn--nano gfu-btn--compact gfu-btn--accent",
                attrs: { disabled: _vm.isProcessing },
                on: { click: _vm.loadSystemHealthInfo },
              },
              [_vm._v("Refresh")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.model
        ? _c(
            "div",
            { staticClass: "gfu-box gfu-box--thick _mt-6 _mb-6" },
            [
              _c(
                "h1",
                {
                  staticClass:
                    "gfu-heading gfu-heading--main gfu-heading--inline",
                },
                [_vm._v("Misc")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Total memory allocated (reported by GC)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$format(
                            _vm.model.totalConsumedMemoryBytes / 1000000,
                            "N2"
                          )
                        ) + "Mb"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Operating system threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(_vm._s(_vm.model.operatingSystemThreads)),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "h1",
                {
                  staticClass:
                    "gfu-heading gfu-heading--main gfu-heading--inline _mt-6",
                },
                [_vm._v("Worker threads")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Active worker threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(_vm._s(_vm.model.activeThreadPoolWorkerThreads)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Min worker threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(_vm._s(_vm.model.minThreadPoolWorkerThreads)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Max workrer threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(_vm._s(_vm.model.maxThreadPoolWorkerThreads)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Max reported worker threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(_vm._s(_vm.model.maximumReportedWorkerThreads)),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "h1",
                {
                  staticClass:
                    "gfu-heading gfu-heading--main gfu-heading--inline _mt-6",
                },
                [_vm._v("Completion port threads")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Active completion port threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(
                        _vm._s(_vm.model.activeThreadPoolCompletionPortThreads)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Min completion port threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(
                        _vm._s(_vm.model.minThreadPoolCompletionPortThreads)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Max completion port threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(
                        _vm._s(_vm.model.maxThreadPoolCompletionPortThreads)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" },
                  [
                    _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
                      _vm._v("Max reported completion port threads"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gfu-stats-table__value" }, [
                      _vm._v(
                        _vm._s(_vm.model.maximumReportedCompletionPortThreads)
                      ),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("apexchart", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.model.activeWorkerThreadsReadings.length > 0,
            expression: "model.activeWorkerThreadsReadings.length > 0",
          },
        ],
        attrs: {
          height: "250",
          type: "line",
          options: _vm.activeWorkersChartOptions,
          series: _vm.activeWorkersChartSeries,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }