export function getFormattedPrice(handleTax, netPrice, price, quantity) {
    return (handleTax ? netPrice  : price) * quantity;
}

export function getFormattedTax(netPrice, price, quantity) {
    return Math.abs(price - netPrice) * quantity;
}

export const defaultCartModel = {
    'allowEditing': false,
    'cart': {
        'currencyShortName': null,
        'currencySymbol': null,
        'customerProjectLocationID': null,
        'customerProjectLocationIsoCode': null,
        'customerProjectSubLocationID': null,
        'customerVatNumber': null,
        'effectiveOutstandingAmountAfterCreditDiscount': 0,
        'effectiveOutstandingCreditPaymentAmount': 0,
        'effectivePaidAmount': 0,
        'effectivePayableAmount': 0,
        'effectiveRefundedAmount': 0,
        'effectiveTotalDiscount': 0,
        'effectiveTotalNetDiscount': 0,
        'handleTax': false,
        'hasParent': false,
        'isVatRefundable': false,
        'orderCode': null,
        'orderID': 0,
        'orderPaymentStatus': 0,
        'orderPublicID': null,
        'orderStage': 0,
        'orderState': 0,
        'orderStateNotes': null,
        'paymentMethod': 0,
        'scheduledAmount': 0,
        'shippingCostConverted': 0,
        'shippingModelID': null,
        'shippingNetCostConverted': 0,
        'shippingProjectLocationIsoCode': null,
        'taxInfos': null,
        'taxName': null,
        'taxRate': null,
        'totalDiscountedValueConverted': 0,
        'totalNetValueConverted': 0,
        'totalTax': null,
        'totalTaxConverted': null,
        'totalValueConverted': 0,
        'totalVatDeductionConverted': 0,
        'vatInvoice': null,
    },
    'customOrderItems': [],
    'enableVatPayerFields': false,
    'hasSameItemsAsParent': false,
    'isEligibleForInstallments': false,
    'productOrderItems': [],
    'totalQuantity': 0,
};

export const defaultOrderModel = {
    'customOrderItems': [],
    'order': {
        'currencyShortName': null,
        'currencySymbol': null,
        'customerProjectLocationID': null,
        'customerProjectLocationIsoCode': null,
        'customerProjectSubLocationID': null,
        'customerVatNumber': null,
        'effectiveOutstandingAmountAfterCreditDiscount': 0,
        'effectiveOutstandingCreditPaymentAmount': 0,
        'effectivePaidAmount': 0,
        'effectivePayableAmount': 0,
        'effectiveRefundedAmount': 0,
        'effectiveTotalDiscount': 0,
        'effectiveTotalNetDiscount': 0,
        'handleTax': false,
        'isVatRefundable': false,
        'orderCode': null,
        'orderID': 0,
        'orderPaymentStatus': 0,
        'orderPublicID': null,
        'orderStage': 0,
        'orderState': 0,
        'orderStateNotes': null,
        'paymentMethod': 0,
        'scheduledAmount': 0,
        'shippingCostConverted': 0,
        'shippingModelID': null,
        'shippingNetCostConverted': 0,
        'shippingProjectLocationIsoCode': null,
        'taxInfos': null,
        'taxName': null,
        'taxRate': null,
        'totalDiscountedValueConverted': 0,
        'totalNetValueConverted': 0,
        'totalTax': null,
        'totalTaxConverted': null,
        'totalValueConverted': 0,
        'totalVatDeductionConverted': 0,
        'vatInvoice': null,
    },
    'productOrderItems': [
        {
            'canBeDeleted': false,
            'canBeEdited': false,
            'canChangeOptions': false,
            'canChangeQuantity': false,
            'displayName': null,
            'netPriceConverted': 0,
            'options': [
                {
                    'optionText': null,
                    'orderItemID': 0,
                    'orderItemOptionID': 0,
                    'priceModifier': null,
                    'productOptionID': 0,
                    'productOptionValueID': 0,
                    'translatedOptionText': null,
                    'translatedValueText': null,
                    'valueText': null,
                },
            ],
            'orderItemID': 0,
            'orderItemSource': 0,
            'orderItemStatus': 0,
            'orderItemType': 0,
            'parentOrderItemID': 0,
            'paymentStatus': 0,
            'priceConverted': 0,
            'product': {
                'copiesAvailable': null,
                'displayName': null,
                'imageUrl': null,
                'perUserCopiesAvailable': null,
                'price': 0,
                'productID': 0,
                'type': 0,
            },
            'productID': 0,
            'quantity': 0,
            'reason': null,
            'taxConverted': 0,
            'validationResult': {
                'isValid': true,
                'message': null,
            },
        },
    ],
    'totalQuantity': 1,
};