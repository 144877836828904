var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm.showHeader
        ? _c(
            "div",
            {
              staticClass: "gfu-box-header",
              attrs: { "data-qa": `box-title:${_vm.title}` },
            },
            [
              _vm.title
                ? _c(
                    "h2",
                    {
                      staticClass:
                        "gfu-heading gfu-heading--text gfu-box-header__title",
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots["subtitle"]
                ? _c(
                    "div",
                    { staticClass: "gfu-box-header__subtitle" },
                    [_vm._t("subtitle")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots["actions"]
                ? _c(
                    "div",
                    { staticClass: "gfu-box-header__actions" },
                    [_vm._t("actions")],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm._t("content"),
      _vm._v(" "),
      _vm.$slots["footer"]
        ? _c(
            "div",
            {
              staticClass: "gfu-box__footer gfu-form__footer",
              attrs: { "data-qa": "box-footer" },
            },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }