var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout__main gfu-layout__main--extranarrow" },
    [
      _c("h1", { staticClass: "gfu-hd gfu-hd--h1 _mt-6 _mb-5" }, [
        _vm._v(_vm._s(_vm.Resources.Profile.SectionCommentsHeader)),
      ]),
      _vm._v(" "),
      _c(
        "box",
        [
          _vm._l(_vm.pagedItems, function (item) {
            return _c(
              "comment-item-preview",
              _vm._b(
                {
                  key: item.commentID,
                  staticClass: "_mb-4",
                  attrs: {
                    nickname: _vm.nickname,
                    "user-url-name": _vm.userUrlName,
                    "avatar-url": _vm.avatarUrl,
                    "user-id": _vm.userID,
                  },
                },
                "comment-item-preview",
                item,
                false
              )
            )
          }),
          _vm._v(" "),
          !_vm.hasPagedItems
            ? _c(
                "empty-state",
                {
                  attrs: {
                    title: _vm.Resources.Profile.EmptyStateCommentsTitle,
                    "data-qa": "user-profile:EmptyState",
                  },
                },
                [
                  _c("div", { staticClass: "_mt-3" }, [
                    _vm._v(_vm._s(_vm.emptyStateMessage)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("load-more-trigger", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasMorePagedItems,
                expression: "hasMorePagedItems",
              },
            ],
            on: { "load-more": _vm.onLoadMore },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }