var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-qa": "mini-wizard-featured-reward" } }, [
    _c(
      "div",
      { staticClass: "_pos-r" },
      [
        _c(
          "router-link",
          {
            staticClass: "gfu-embed gfu-embed--1x1 _fo",
            attrs: { to: _vm.route },
          },
          [
            _c("base-image", {
              staticClass: "gfu-embed__item",
              attrs: {
                src: _vm.featuredReward.imageUrl,
                alt: _vm.featuredReward.name,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gfu-labels gfu-labels--bottom _ml-3" },
          [
            _c(
              "base-badge",
              {
                attrs: {
                  "data-qa": "mini-wizard-featured-reward:FeaturedBadge",
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.Resources.ProjectHome.MiniWizardFeaturedRewardBadge
                    ) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "_pa-3" },
      [
        _c(
          "h2",
          {
            staticClass: "gfu-hd gfu-hd--h3 gfu-hd--decorative _line-clamp-2",
            attrs: { "data-qa": "mini-wizard-featured-reward:Name" },
          },
          [
            _c("router-link", { attrs: { to: _vm.route } }, [
              _vm._v(_vm._s(_vm.featuredReward.name)),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("display-currency-price", {
          attrs: {
            "effective-price": _vm.featuredReward.effectivePrice,
            price: _vm.featuredReward.price,
            "is-discounted": _vm.featuredReward.isDiscounted,
          },
          scopedSlots: _vm._u([
            {
              key: "effective-price",
              fn: function ({ effectivePrice }) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "gfu-price gfu-bt--b2 gfu-price--text-base",
                      attrs: {
                        "data-qa":
                          "mini-wizard-featured-reward-price:Effective",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(effectivePrice) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "price",
              fn: function ({ price }) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "gfu-price gfu-price--old _fw-n",
                      attrs: {
                        "data-qa": "mini-wizard-featured-reward-price:Old",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(price) +
                          "\n                "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("product-card-list-provider", {
          attrs: {
            "edit-route-name": "rewards-general",
            products: [_vm.featuredReward],
            "checkout-url": _vm.currentPledge.checkoutUrl,
            "cart-url": _vm.currentPledge.cartDetailsUrl,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ onItemAdded, getAddProductToCartModel }) {
                return [
                  _c(
                    "add-product-to-cart",
                    _vm._b(
                      {
                        staticClass: "_mt-3",
                        attrs: {
                          "data-qa": `mini-wizard-button:SelectThisReward`,
                        },
                        on: { "item-added": onItemAdded },
                      },
                      "add-product-to-cart",
                      _vm.getAddToCartModel(
                        getAddProductToCartModel(_vm.featuredReward)
                      ),
                      false
                    )
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }