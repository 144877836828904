var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-scrollable-wrapper" }, [
    _c("div", { class: _vm.scrollContentClassList }, [
      _c(
        "div",
        { ref: "scrollableElement", class: _vm.directionClassList },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }