var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "gfu-btn gfu-btn--narrow" },
    [
      _c("copy-to-clipboard", {
        class: ["gfu-icon", _vm.getSizeClassName(_vm.size)],
        attrs: { "text-to-copy": _vm.url, "vertical-position": "down" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }