var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-layout__main",
      attrs: { "data-qa": "creator-projects" },
    },
    [
      _c(
        "h1",
        { staticClass: "gfu-hd gfu-hd--h1 _mt-6 _mb-3" },
        [
          _c(
            "localize",
            { attrs: { resource: _vm.Resources.Layout.CreatorProjectsHeader } },
            [
              _c("span", { attrs: { "format-key": "0" } }, [
                _vm._v(_vm._s(_vm.creatorProjectsPluralized)),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-card-list gfu-card-list--wrap" },
        _vm._l(_vm.projectItems, function (item) {
          return _c(
            "div",
            {
              key: item.props.projectID,
              staticClass:
                "gfu-card-list__item gfu-1of1 gfu-1of3--m gfu-1of4--l",
            },
            [
              _c(
                item.component,
                _vm._b({ tag: "component" }, "component", item.props, false)
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.isInitialized
        ? _c("project-recommendations", {
            staticClass: "_mt-6",
            attrs: {
              quantity: 8,
              "slides-to-show": 4,
              source: "creatorPage",
              "exclude-project-ids": _vm.projectIDs,
              "data-qa": "Recommendations",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }