import { flatten, parsePath, debounce, each, isEqual, filter } from '@/Utils';
import RJSON from 'relaxed-json';

const elementTypeRE = /@([\w-]+)/;
const extraOptionsRE = /{(([\w_-]+:((['"][^'"]+['"])|([0-9 \.]+)),?)+)}/;

export default {
    methods: {
        clearValidation() {
            this.$validator.pause();
            this.$validator.reset().then(this.$validator.resume);
            // setTimeout(() => {
            //     this.$validator.resume();
            // }, 400);
        },
        initializeValidator() {
            const options = this.$options;
            const validationRules
                = flatten(typeof options.validate === 'function' ? options.validate.apply(this) : options.validate) || {};

            const dependencyCache = {};

            Object.keys(validationRules).forEach(key => {
                const rule = validationRules[key] || '';

                // Create and attach a validator field
                const field = this._attachValidatorField(key, rule);

                // This function reacts to changes on the field value and automatically triggers validation
                const fieldValueValidationTrigger = (value, oldValue) => {
                    this.$validator.validate(key, value);

                    if (dependencyCache[field.name]) {
                        each(dependencyCache[field.name], dependentField => {
                            this.$validator.validate(dependentField.name, dependentField.value);
                        });
                    }
                };

                // Attatch the validation trigger function via watch. Take delay option into account.
                field.$unwatch = this.$watch(key,
                    field.delay.input > 0 ? debounce(fieldValueValidationTrigger, field.delay.input) : fieldValueValidationTrigger);

                // In case the rule is a function, it means the rule can change. In this case we need to watch it for changes.
                if (typeof rule === 'function') {
                    this.$watch(rule, (newRule, oldRule) => {
                        // In case it "really" changes
                        if (!isEqual(newRule, oldRule)) {
                            // Update the field
                            this._updateValidatorField(key, newRule);

                            // Unhook the old validation trigger function (in case the delay value changed)
                            field.$unwatch();
                            field.$unwatch = this.$watch(key,
                                field.delay.input > 0 ? debounce(fieldValueValidationTrigger, field.delay.input) : fieldValueValidationTrigger);

                            // Update field dependencies
                            this._updateValidatorFieldDependencies(field);
                            this._updateValidatorFieldDependencyCache(field, dependencyCache);
                        }
                    });
                }
            });

            each(this.$validator.fields.items, this._updateValidatorFieldDependencies);

            each(this.$validator.fields.items, field => {
                this._updateValidatorFieldDependencyCache(field, dependencyCache);
            });
        },
        findParentByValidatorFieldName(component, fieldName) {
            if (!component.$parent || !fieldName)
                return component;

            if (component.$parent.$validator) {
                const field = component.$parent.$validator.fields.items.find((f) => f.name === this.name);

                if (typeof field !== 'undefined') {
                    return component.$parent;
                }
            }

            return this.findParentByValidatorFieldName(component.$parent, fieldName);
        },
        _attachValidatorField(name, rule, alias) {
            let ruleValue = (typeof rule === 'function' ? rule.apply(this) : rule) || '';
            let fieldElement = null;

            let extraOptions = {};

            if (typeof ruleValue === 'object') {
                ruleValue = Object.assign({}, ruleValue);

                if (ruleValue['element']) {
                    fieldElement = { type: ruleValue['element'] };
                    delete ruleValue['element'];
                }

                if (ruleValue['options']) {
                    extraOptions = ruleValue['options'];
                    delete ruleValue['options'];
                }
            }
            else {
                if (elementTypeRE.test(ruleValue)) {
                    const elementType = elementTypeRE.exec(ruleValue)[1];
                    ruleValue = ruleValue.replace(elementTypeRE, '');

                    fieldElement = { type: elementType };
                }

                if (extraOptionsRE.test(ruleValue)) {
                    const extraOptionsString = extraOptionsRE.exec(ruleValue)[1];
                    ruleValue = ruleValue.replace(extraOptionsRE, '');

                    extraOptions = { ...extraOptions, ...RJSON.parse(`{${ extraOptionsString }}`) };
                }
            }

            if (alias !== null) {
                extraOptions['alias'] = alias;
            }

            const field = this.$validator.attach({ name, rules: ruleValue, getter: () => parsePath(name)(this), ...extraOptions });

            field.flags.required = field.isRequired;
            field.el = fieldElement;
            field.vm = this;

            return field;
        },
        _detachValidatorField(name) {
            const field = this.$validator.fields.items.find(field => field.name === name);
            this.$validator.detach(name);
            return field;
        },
        _updateValidatorField(name, rule) {
            const field = this.$validator.fields.items.find(field => field.name === name);
            let ruleValue = (typeof rule === 'function' ? rule.apply(this) : rule) || '';
            let fieldElement = null;

            let extraOptions = {};

            if (typeof ruleValue === 'object') {
                ruleValue = Object.assign({}, ruleValue);

                if (ruleValue['element']) {
                    fieldElement = { type: ruleValue['element'] };
                    delete ruleValue['element'];
                }

                if (ruleValue['options']) {
                    extraOptions = ruleValue['options'];
                    delete ruleValue['options'];
                }
            }
            else {
                if (elementTypeRE.test(ruleValue)) {
                    const elementType = elementTypeRE.exec(ruleValue)[1];
                    ruleValue = ruleValue.replace(elementTypeRE, '');

                    fieldElement = { type: elementType };
                }

                if (extraOptionsRE.test(ruleValue)) {
                    const extraOptionsString = extraOptionsRE.exec(ruleValue)[1];
                    ruleValue = ruleValue.replace(extraOptionsRE, '');

                    extraOptions = { ...extraOptions, ...RJSON.parse(`{${ extraOptionsString }}`) };
                }
            }

            field.update({ rules: ruleValue, ...extraOptions });
            field.el = fieldElement;

            return field;
        },
        _updateValidatorFieldDependencies(field) {
            each(field.rules, (ruleParams, ruleName) => {
                if (/confirmed|after|before/.test(ruleName)) {
                    const otherFieldName = ruleParams[0];

                    if (!otherFieldName)
                        return;

                    const otherField = this.$validator.fields.items.find(f => f.name === otherFieldName);

                    field.dependencies.push({ name: ruleName, field: otherField });
                }
            });
        },
        _updateValidatorFieldDependencyCache(field, cacheBag) {
            cacheBag[field.name] = filter(this.$validator.fields.items, f => f.dependencies.find(d => d.field === field));
        },
    },
    computed: {
        parentErrors() {
            return this.parentValidator.errors;
        },
        hasError() {
            if (!this.name)
                return false;

            return this.parentErrors.has(this.name);
        },
        isRequired() {
            return this.validatorField && this.validatorField.flags.required || false;
        },
        validatorField: {
            get() {
                if (this.name !== null) {
                    return this.parentValidator.fields.items.find((f) => f.name === this.name);
                }
            },
            set(val) {
                this.validatorField = val;
            },
        },
        parentValidator() {
            return this.compositeParent.$validator;
        },
        compositeParent() {
            return this.findParentByValidatorFieldName(this, this.name);
        },
        isSilentInvalid() {
            return Object.entries(this.$validator.flags)
                .some(([key, value]) => value.invalid);
        },
    },
};
