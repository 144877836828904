import { projectPhases, projectVersions, orderStates } from '@/Models';

export function isPledgeImplicitEditMode(project, orderState) {

    if (project.version === projectVersions.v2 && project.phase > projectPhases.latePledge) {
        return orderState === orderStates.pledgedAndPaid
            || orderState === orderStates.pledgedWithInstallments;
    }

    return false;
}