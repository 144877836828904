var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      key: _vm.$route.fullPath,
      attrs: {
        width: _vm.modalWidth,
        "is-visible": _vm.isVisible,
        "has-backdrop": true,
        "show-action-close": true,
        "show-action-back": _vm.showActionBack(),
        "is-processing": false,
      },
      on: {
        "action-back": _vm.onActionBack,
        "action-close": _vm.onActionClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "gfu-product-modal" },
        [
          _c("router-view", {
            attrs: { name: "projectContextModal", "data-qa": _vm.qa },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }