var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    { tag: "component" },
    [
      _vm.currentDisplayCurrency
        ? [
            _vm.reverseTooltipPosition
              ? _vm._t("tooltip", function () {
                  return [
                    _vm.displayTooltip
                      ? _c("tooltip", {
                          staticClass:
                            "_fa _fa--sync _tc--accent _fa--sm _fa--base",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [_vm._t("tooltip-content")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ]
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._t("content", null, {
              displayCurrency: _vm.currentDisplayCurrency,
            }),
            _vm._v(" "),
            !_vm.reverseTooltipPosition
              ? _vm._t("tooltip", function () {
                  return [
                    _vm.displayTooltip
                      ? _c("tooltip", {
                          staticClass:
                            "_fa _fa--sync _tc--accent _fa--sm _fa--base",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [_vm._t("tooltip-content")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ]
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._t("content-alt", null, {
              displayCurrency: _vm.currentDisplayCurrency,
            }),
          ]
        : [_vm._t("default")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }