var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-subscription-management-settings _mt-4",
      attrs: { "data-qa": `projectID:${_vm.projectID}` },
    },
    [
      _vm.hasBadges
        ? _c(
            "div",
            {
              staticClass:
                "gfu-project-subscription-management-settings__badges",
              attrs: { "data-qa": "project-badges" },
            },
            _vm._l(_vm.badges, function ({ key, theme, text, dataQa }) {
              return _c(
                "base-badge",
                { key: key, attrs: { theme: theme, "data-qa": dataQa } },
                [_vm._v(_vm._s(text))]
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "gfu-bt gfu-bt--caption _tc--light _mt-0 _ttu" },
          [
            _vm._v(
              _vm._s(
                _vm.Resources.SubscriptionManagement
                  .ProjectNotificationsSubsectionHeader
              )
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "gfu-project-subscription-management-settings__title",
          },
          [_vm._v(_vm._s(_vm.projectName))]
        ),
      ]),
      _vm._v(" "),
      _c("option-field", {
        staticClass: "_mt-0",
        attrs: {
          "show-placeholder": false,
          "first-column-width": 8,
          options: _vm.generalNotificationPreferenceOptions,
          "display-name":
            _vm.Resources.SubscriptionManagement
              .GeneralProjectNotificationsFieldLabel,
          name: "model.projectLifecycleNotificationType",
          layout: "columns",
          description:
            _vm.Resources.SubscriptionManagement
              .GeneralProjectNotificationsFieldDescription,
          "data-qa": "project-notifications:General",
        },
        on: { input: _vm.onProjectLifecycleNotificationPreferenceChange },
        model: {
          value: _vm.model.projectLifecycleNotificationType,
          callback: function ($$v) {
            _vm.$set(_vm.model, "projectLifecycleNotificationType", $$v)
          },
          expression: "model.projectLifecycleNotificationType",
        },
      }),
      _vm._v(" "),
      _c("option-field", {
        staticClass: "_mt-2",
        attrs: {
          "show-placeholder": false,
          "first-column-width": 8,
          options: _vm.notificationPreferenceOptions,
          "display-name":
            _vm.Resources.SubscriptionManagement
              .UpdatesProjectNotificationsFieldLabel,
          name: "model.projectUpdatesNotificationType",
          layout: "columns",
          description:
            _vm.Resources.SubscriptionManagement
              .UpdatesProjectNotificationsFieldDescription,
          "data-qa": "project-notifications:Updates",
        },
        on: { input: _vm.onProjectUpdatesNotificationPreferenceChange },
        model: {
          value: _vm.model.projectUpdatesNotificationType,
          callback: function ($$v) {
            _vm.$set(_vm.model, "projectUpdatesNotificationType", $$v)
          },
          expression: "model.projectUpdatesNotificationType",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }