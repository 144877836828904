var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h3",
    { staticClass: "gfu-hd gfu-hd--h2 _flex _gap-1 _ai-c" },
    [
      _vm.iconName
        ? _c("base-icon", {
            attrs: { name: _vm.iconName, "icon-namespace": _vm.iconNamespace },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }