import OrderPaymentOptions from '@/Components/Payments/OrderPaymentOptions.vue';
import AdyenOrderPaymentOptions from '@/Components/Payments/Adyen/AdyenOrderPaymentOptions';
import AdyenOrderPaymentForm from '@/Components/Payments/Adyen/AdyenOrderPaymentForm';
import DynamicCurrencyConversionWrapper from '@/Components/Payments/DCC/DynamicCurrencyConversionWrapper';
import CheckoutFooter from '../Payments/CheckoutFooter';

export default {
    OrderPaymentOptions,
    AdyenOrderPaymentOptions,
    AdyenOrderPaymentForm,
    DynamicCurrencyConversionWrapper,
    CheckoutFooter,
};