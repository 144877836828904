import { defineStore } from 'pinia';
import { apiClient } from '@/Clients/ApiClient.js';
import WebStorage, { storageTypes } from '@/Utils/WebStorage.js';
import UsersService from '@/Services/UsersService';
import UserProfileService from '@/Services/UserProfileService';
import InitialState from '@/InitialState';

export const deferredFollowUserStorage = new WebStorage('deferredFollowUser', storageTypes.sessionStorage);

export const useUserFactsStore = defineStore({
    id: 'userFacts',
    state: () => ({
        projectUrlToFollow: null,
        actionType: null,
        isUserLoginVisible: false,
        isUserFollowSettingsModalVisible: false,
        tempUserToFollow: null,
        followedProjectsIDs: InitialState.userAdditionalInfo?.followedProjectsIDs || [],
        followedUsersIDs: InitialState.userAdditionalInfo?.followedUsersIDs || [],
        backedProjectIDs: InitialState.userAdditionalInfo?.backedProjectsIDs || [],
    }),
    actions: {
        async followProject(projectId, section) {
            const userService = new UsersService(apiClient);
            const response = await userService.followProject(projectId, section);

            if (response.data.success)
                this.followedProjectsIDs.push(projectId);

            return response;
        },
        async unfollowProject(projectId) {
            const userService = new UsersService(apiClient);
            const response = await userService.unfollowProject(projectId);
            const followedProjectsIDs = this.followedProjectsIDs;

            if (response.data.success) {
                this.followedProjectsIDs = followedProjectsIDs
                    .filter(pid => pid !== projectId);
            }

            return response;
        },
        async followUser(profileUrlName, userID) {
            const userProfileService = new UserProfileService(apiClient);
            const response = await userProfileService.setUserFollow({ profileUrlName, isUnfollowing: false });
            if (response.data.success) {
                this.followedUsersIDs.push(userID);
            }
            return response;
        },
        async unfollowUser(profileUrlName, userID) {
            const userProfileService = new UserProfileService(apiClient);
            const response = await userProfileService.setUserFollow({ profileUrlName, isUnfollowing: true });
            if (response.data.success) {
                this.followedUsersIDs = this.followedUsersIDs
                    .filter(u => u !== userID);
            }

            return response;
        },
        deferFollowUserAction(profileUrlName, userID, nickname) {
            deferredFollowUserStorage.set({ profileUrlName, userID, nickname });
        },
        async executeDeferredFollowUserAction(userContextStore) {
            const deferredFollowUser = deferredFollowUserStorage.get();
            if (deferredFollowUser) {
                const { userID, profileUrlName, nickname } = deferredFollowUser;
                if (this.followedUsersIDs.includes(userID)
                    || userContextStore.userContext?.user?.userID === userID) {
                    deferredFollowUserStorage.remove();
                    return Promise.resolve(null);
                }
                else {
                    if (userContextStore.canFollowUsers) {
                        const response = await this.followUser(profileUrlName, userID);
                        if (response.data.success) {
                            deferredFollowUserStorage.remove();
                            return Promise.resolve(deferredFollowUser);
                        }
                    }
                    else if (userContextStore.isAuthenticated) {
                        this.showUserFollowSettingsModal(profileUrlName, userID, nickname);
                    }
                    return Promise.resolve(null);
                }
            }

            return Promise.resolve(null);
        },
        showUserLoginModal({ profileUrlName, userID, nickname, action, projectUrl }) {
            this.setActionType(action);
            this.isUserLoginVisible = true;

            if (action === 'follow-project') {
                this.setprojectUrlToFollow(projectUrl);
            }
            else if (action === 'follow-user') {
                this.deferFollowUserAction(profileUrlName, userID, nickname);
            }
        },
        setprojectUrlToFollow(projectUrl) {
            this.projectUrlToFollow = projectUrl;
        },
        setActionType(actionType) {
            this.actionType = actionType;
        },
        hideUserLoginModal() {
            this.isUserLoginVisible = false;
        },
        showUserFollowSettingsModal(profileUrlName, userID, nickname) {
            this.isUserFollowSettingsModalVisible = true;
            this.tempUserToFollow = { profileUrlName, userID, nickname };
        },
        hideUserFollowSettingsModal() {
            const deferredFollowUser = deferredFollowUserStorage.get();
            if (deferredFollowUser) {
                deferredFollowUserStorage.remove();
            }
            this.isUserFollowSettingsModalVisible = false;
            this.tempUserToFollow = null;
        },
        getUserFollowRestrictionReason(userContextStore) {
            const { nickname, isAuthenticated, isUserProfilePublic } = userContextStore;

            if (!isAuthenticated) {
                return 'Log in to follow this user. Only users with public profiles can follow other users.';
            }
            else if (nickname === null && isUserProfilePublic === false) {
                return 'Add a nickname and make your profile public to follow other users.';
            }
            else if (nickname === null) {
                return 'Add a nickname to follow other users.';
            }
            else if (isUserProfilePublic === false) {
                return 'Make your profile public to follow other users.';
            }

            return 'You need to have a public profile to follow other users.';
        },
    },
});
