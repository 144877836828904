var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isProjectInfoVisible
    ? _c("div", { staticClass: "gfu-media gfu-media--middle _mb-3" }, [
        _c(
          "div",
          { staticClass: "gfu-thumb" },
          [
            _c("base-image", {
              staticClass: "gfu-thumb__item",
              attrs: {
                src: _vm.currentProject.thumbImageUrl,
                width: "36",
                height: "36",
                loading: "lazy",
                alt: _vm.currentProject.name,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-media__body _ml-3" }, [
          _c(
            "div",
            {
              staticClass: "gfu-bt gfu-bt--b1",
              attrs: {
                "data-qa": `contact-context-project:${_vm.currentProject.name}`,
              },
            },
            [_vm._v(_vm._s(_vm.currentProject.name))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-bt gfu-bt--caption _tc--light",
              attrs: {
                "data-qa": `contact-context-project:${_vm.currentProject.creator.name}`,
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.format(
                      _vm.Resources.Contact.ContactSearchResultByCreatorLabel,
                      _vm.currentProject.creator.name
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ])
    : _c(
        "h4",
        {
          staticClass: "gfu-hd gfu-hd--h4",
          attrs: {
            "data-qa": `contact-form-subcategory:${_vm.currentCategoryGroupName}`,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.currentCategoryGroupName) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }