// -------------------------------------------------------------------------------------
// Boolean
// -------------------------------------------------------------------------------------

const Boolean = {};

export default Boolean;

Boolean.parse = function parse(value) {
    const v = value.trim().toLowerCase();
    if (v === 'false') return false;
    if (v === 'true') return true;
    throw new Error('Invalid format');
};
