const speed = 75;

export default class Typer {
    constructor() {
        this.isTyping = false;
        this.text = '';
        this.interval = null;
        this.timeout = null;
    }

    type(newText = '', isDeleting, fn = () => {}) {
        this.isTyping = true;

        if (this.text.length > 0 && isDeleting) {
            this.text = this.text.substring(0, this.text.length - 1);
            fn(this.text);

            this.timeout = setTimeout(() => {
                return this.type(newText, true, fn);
            }, speed / 3);
            return;
        }

        if (this.text.length < newText.length) {
            this.text += newText.charAt(this.text.length);
            fn(this.text);

            this.timeout = setTimeout(() => {
                return this.type(newText, false, fn);
            }, speed + speed * (Math.random() - 0.5));
            return;
        }

        this.isTyping = false;
        return;
    }

    typeInLoop(typedTexts, fn) {
        let i = 0;
        this.type(typedTexts[typedTexts.length - 1], false, fn);
        this.interval = setInterval(() => {
            if (!this.isTyping) {
                this.type(typedTexts[i++ % typedTexts.length], true, fn);
            }
        }, 8000);
    }

    dispose() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    }
}
