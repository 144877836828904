var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
    },
    [
      _c(
        "div",
        {
          ref: "commentsView",
          staticClass: "gfu-comments-view",
          attrs: { "data-qa": "project-comments-box:Comments" },
        },
        [
          _c(
            "div",
            { staticClass: "gfu-comments-view__main" },
            [
              _vm.isHeadVisible
                ? _c(
                    "div",
                    {
                      staticClass: "gfu-comments-view__title gfu-content-head",
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "gfu-hd gfu-hd--h1",
                          attrs: { "data-qa": "page-title" },
                        },
                        [_vm._v(_vm._s(_vm.Resources.Comments.PageHeader))]
                      ),
                      _vm._v(" "),
                      _vm.selectedFilterModel
                        ? _c("comment-filter-item", {
                            key: _vm.selectedFilterModel.name,
                            class: [_vm.selectedFilterModel.class, "_mx-1"],
                            attrs: {
                              name: _vm.selectedFilterModel.name,
                              "is-selected": _vm.selectedFilterModel.isSelected,
                              "is-thin": false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectedFilterModel.fn(
                                  _vm.filter.value
                                )
                              },
                            },
                          })
                        : _vm.isMobile
                        ? _c(
                            "icon-base-button",
                            {
                              staticClass: "_mx-1 _fw-b",
                              attrs: {
                                icon: "search",
                                weight: "thin",
                                lowercase: true,
                                size: "micro",
                                shape: "soft",
                              },
                              on: { click: _vm.onDrawerOpen },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.Resources.Comments.SearchTagButton
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                _vm.wrapperComponent.component,
                _vm._b(
                  { tag: "component" },
                  "component",
                  _vm.wrapperComponent.props,
                  false
                ),
                [
                  _vm.isMainFieldVisible
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "gfu-box__content",
                            attrs: {
                              "data-qa": "blocked-from-commenting-section",
                            },
                          },
                          [
                            _vm.isUserBanned
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "gfu-hd gfu-hd--h3" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.Comments
                                              .UserBannedHeader
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "localize",
                                      {
                                        attrs: {
                                          resource:
                                            _vm.Resources.Comments
                                              .UserBannedDescription,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "_fw-b",
                                            attrs: { "format-key": "0" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.Comments
                                                  .CommunityGuidelinesLink
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm.isUserAuthenticated
                              ? _c("project-comment-field", {
                                  ref: "addComment",
                                  attrs: {
                                    "extra-tag": _vm.filter.tag,
                                    "data-qa": "project-comments-field:AddNew",
                                  },
                                  on: {
                                    submit: _vm.addComment,
                                    "nickname-required": _vm.onNicknameRequired,
                                  },
                                })
                              : _c("project-comment-login"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "gfu-box-divider gfu-box-divider--content",
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "commentsSection",
                      class: [
                        "gfu-comments-view-content",
                        {
                          "gfu-comments-view-content--compact":
                            _vm.isLayoutCompact,
                          "gfu-box__content": !_vm.isLayoutModal,
                        },
                      ],
                    },
                    [
                      _vm.comments.length > 0
                        ? [
                            _vm.isFiltersFieldVisible
                              ? _c("multi-selector-field", {
                                  staticClass: "gfu-comment-filters",
                                  attrs: {
                                    name: "filter.sortType",
                                    "show-label": false,
                                    options: _vm.sortTypes,
                                    "data-qa":
                                      "project-comments-multi-selector",
                                  },
                                  model: {
                                    value: _vm.filter.sortType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "sortType", $$v)
                                    },
                                    expression: "filter.sortType",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isNewMessageButtonVisible
                              ? _c(
                                  "base-button",
                                  {
                                    staticClass: "_mt-6 _mb-6",
                                    attrs: {
                                      theme: "lightAlt",
                                      shape: "hard",
                                      display: "block",
                                      shadow: "tight",
                                      "data-qa":
                                        "project-comments-button:LoadMore",
                                    },
                                    on: { click: _vm.downloadNewMessages },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "gfu-bt gfu-bt--caption" },
                                      [
                                        _c(
                                          "localize",
                                          {
                                            key: `newMessagesCount:${_vm.newMessagesCount}`,
                                            attrs: {
                                              resource:
                                                _vm.Resources.Comments
                                                  .LoadNewMessagesButton,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gfu-comment-badge",
                                                attrs: { "format-key": "0" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.newMessagesCount)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { attrs: { "format-key": "1" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.newMessagesCountLabel
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.comments, function (thread) {
                              return _c("project-comment-thread", {
                                key: `commentID:${thread.commentID}`,
                                ref: thread.commentThreadID,
                                refInFor: true,
                                staticClass: "_mb-3",
                                attrs: {
                                  "has-pin-comment-action":
                                    _vm.hasPinCommentAction,
                                  "has-report-and-hide-action":
                                    _vm.hasReportAndHideAction,
                                  "allow-creator-hiding-comments":
                                    _vm.allowCreatorHidingComments,
                                  thread: thread,
                                },
                                on: {
                                  delete: _vm.onDelete,
                                  pin: _vm.onPin,
                                  manage: _vm.onManage,
                                  report: _vm.onReportComment,
                                  like: _vm.onLike,
                                  "nickname-required": _vm.onNicknameRequired,
                                  "reply-submit": _vm.onReplyComment,
                                  "edit-submit": _vm.onUpdateComment,
                                  "tag-selected": _vm.onTagSelected,
                                  "report-and-hide": _vm.onReportAndHide,
                                  "load-more-children": _vm.loadMoreChildren,
                                  "go-to-user-admin": _vm.onGoToUserAdmin,
                                },
                              })
                            }),
                            _vm._v(" "),
                            _vm.isLoadMoreCommentsVisible
                              ? _c(
                                  "base-button",
                                  {
                                    staticClass: "_mt-3",
                                    attrs: {
                                      theme: "lightAlt",
                                      shape: "hard",
                                      display: "block",
                                      shadow: "tight",
                                      "data-qa":
                                        "project-comments-button:LoadMore",
                                    },
                                    on: { click: _vm.loadMoreComments },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "gfu-bt gfu-bt--caption" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.Resources.Comments
                                                .LoadMoreButton
                                            ) +
                                            "\n                                "
                                        ),
                                        _c("span", {
                                          staticClass:
                                            "_fa _fa--chevron-down _ml-1 _tc--accent",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm.isInitialized
                        ? _c("empty-state", {
                            attrs: {
                              title: _vm.Resources.Comments.EmptyStateHeader,
                              description:
                                _vm.Resources.Comments.EmptyStateParagraph,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("progress-overlay", {
                        attrs: { visible: _vm.isProcessing },
                      }),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isSideVisible
            ? _c(
                "div",
                { staticClass: "gfu-comments-view__side" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "gfu-comments-view__title gfu-content-head",
                    },
                    [
                      _c("h3", { staticClass: "gfu-hd gfu-hd--h1" }, [
                        _vm._v(
                          _vm._s(_vm.Resources.Comments.TagsSectionHeader)
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "box",
                    {
                      staticClass: "gfu-comment-streams__box",
                      attrs: { "data-qa": "project-comments-box:Streams" },
                    },
                    [
                      _c(
                        "project-comments-streams",
                        {
                          attrs: {
                            "comment-thread-id": _vm.commentThreadId,
                            "current-tag": _vm.filter.tag,
                          },
                          on: {
                            "tag-deselected": _vm.onTagDeselected,
                            "tag-selected": _vm.onTagSelected,
                            "search-tags": _vm.onSearchTags,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "_mt-3 _flexwrap _gap-1" },
                            _vm._l(_vm.filters, function (filter) {
                              return _c("comment-filter-item", {
                                key: filter.name,
                                class: filter.class,
                                attrs: {
                                  name: filter.name,
                                  "is-selected": filter.isSelected,
                                  "data-qa": `comments-streams-filter:${filter.qa}`,
                                },
                                on: {
                                  click: function ($event) {
                                    return filter.fn(filter.value)
                                  },
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "gfu-comment-streams__tags _flexwrap _gap-1",
                            },
                            [
                              _vm._l(_vm.tags, function (tag) {
                                return _c("comment-filter-item", {
                                  key: tag.name,
                                  class: tag.class,
                                  attrs: {
                                    name: tag.name,
                                    "is-selected": tag.isSelected,
                                    "data-qa": `comments-streams:${tag.value}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return tag.fn(tag.value)
                                    },
                                  },
                                })
                              }),
                              _vm._v(" "),
                              !_vm.hasTags
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gfu-comment-streams__paragraph",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.Resources.Comments
                                              .TagsEmptyStateHeader
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showRecommendations && _vm.isInitialized
        ? _c("project-recommendations", {
            staticClass: "_mt-6",
            attrs: {
              quantity: 12,
              title: _vm.Resources.Comments.ProjectRecommendationsTitle,
              "exclude-project-ids": [_vm.projectContext.projectID],
              source: "projectComments",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("project-comments-mobile-menu", {
        attrs: {
          "comment-thread-id": _vm.commentThreadId,
          "current-tag": _vm.filter.tag,
          "is-drawer-open": _vm.isDrawerOpen,
        },
        on: {
          "tag-deselected": _vm.onTagDeselected,
          "tag-selected": _vm.onTagSelected,
          "search-tags": _vm.onSearchTags,
          "drawer-close": _vm.onDrawerClose,
        },
      }),
      _vm._v(" "),
      _c("report-project-comment-modal", {
        ref: "reportProjectCommentModal",
        on: { "comment-report-submitted": _vm.onCommentReportSubmitted },
      }),
      _vm._v(" "),
      _vm.isReportAndHideCommentModalOpen
        ? _c(
            "report-and-hide-project-comment-modal",
            _vm._b(
              {
                on: {
                  "comment-hidden": _vm.onCommentHidden,
                  "modal-close": _vm.onReportAndHideCommentModalClose,
                },
              },
              "report-and-hide-project-comment-modal",
              _vm.model,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _c("set-nickname-modal", {
        ref: "setNicknameModal",
        on: { "nickname-updated": _vm.onNicknameUpdated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }