
    export default {
        props: {
            to: { type: Object, default: null },
            href: { type: String, default: null },
            inContext: { type: Boolean, default: false },
        },
        render(h) {
            const options = {};

            if (this.inContext) {
                options.props = {
                    to: this.to,
                };
            }
            else {
                options.attrs = {
                    href: this.href,
                };
            }

            return h(this.inContext ? 'router-link' : 'a', options, this.$scopedSlots.default());
        },
    };
