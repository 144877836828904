var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    {
      staticClass: "_flexbtwn gfu-1of1 _gap-2",
      attrs: {
        tag: "a",
        href: _vm.backProjectUrl,
        theme: "accent",
        "data-qa": "back-project-button",
      },
    },
    [
      _c("base-icon", {
        attrs: { name: "gamefound-flat", "icon-namespace": "ga" },
      }),
      _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
      !_vm.isStacked
        ? _c("base-icon", {
            staticClass: "is-invisible",
            attrs: { name: "gamefound-flat", "icon-namespace": "ga" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }