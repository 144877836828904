var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-layout__main gfu-layout__main--extranarrow _mb-6 _mt-6",
    },
    [
      _c("div", { staticClass: "gfu-content-head" }, [
        _c(
          "h2",
          {
            staticClass: "gfu-hd gfu-hd--h1",
            attrs: { "data-qa": "terms-of-service:PageTitle" },
          },
          [_vm._v(_vm._s(_vm.Resources.TermsOfService.PageHeader))]
        ),
      ]),
      _vm._v(" "),
      _c("box", [
        _c("div", {
          staticClass: "gfu-bt gfu-bt--b1",
          domProps: { innerHTML: _vm._s(_vm.termsOfServiceDescription) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }