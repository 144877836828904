import Config from '@/Config';
import PlatformCapabilities from '@/Capabilities';

import Resources from '@/Resources.js';
import '@/FrameworkConfig.js';

import { BaseNotificator } from '@/Components/Base';
import BlogPostBreadcrumb from '@/Components/Blog/BlogPostBreadcrumb.vue';
import BlogPostRestrictedVisibility from '@/Components/Blog/BlogPostRestrictedVisibility.vue';
import Field from '@/Components/Field.vue';
import FieldLabel from '@/Components/FieldLabel.vue';
import Localization from '@/Components/Localization';
import RequestResetPasswordForm from '@/Views/Users/RequestResetPasswordForm.vue';
import CartsComponents from '@/Components/Carts';
import OrdersComponents from '@/Components/Orders';
import PaymentComponents from '@/Components/Payments';
import UserComponents from '@/Components/Users';
import SearchComponents from '@/Components/Search';
import SystemStatusComponents from '@/Components/SystemStatus';
import Tooltip from '@/Components/Tooltip.vue';
import NewsletterComponents from '@/Components/Newsletter';
import ProjectsComponents from '@/Components/Projects';
import TableOfContents from '@/Components/TableOfContents.vue';
import CheckoutAuthenticateForm from '@/Components/Users/CheckoutAuthenticateForm.vue';
import MessageBox from '@/Components/MessageBox.vue';
import TestimonialsSlider from '@/Components/TestimonialsSlider.vue';
import CreatorBannerSlider from '@/Components/CreatorBannerSlider.vue';
import ProductInfoView from '@/Components/ProductDetails/ProductInfoView.vue';
import SocialMediaActions from '@/Components/SocialMediaActions.vue';
import CurrencySelector from '@/Components/CurrencySelector';
import BrowseProjects from '@/Components/Home/BrowseProjects';
import BecomeACreatorSection from '@/Components/Creator/BecomeACreatorSection';
import BecomeACreatorStats from '@/Components/Creator/BecomeACreatorStats';
import BecomeACreatorValues from '@/Components/Creator/BecomeACreatorValues';
import CreatorProjects from '@/Components/Creator/CreatorProjects';
import ProjectAdvertBanner from '@/Components/ProjectAdvertBanners/ProjectAdvertBanner';
import Contact from '@/Components/Contact/ContactView.vue';
import TeamMembersSlider from '@/Components/TeamMembersSlider.vue';
import PartnersSlider from '@/Components/PartnersSlider.vue';
import EmptyState from '@/Components/EmptyState.vue';
import FloatingMessagesWrapper from '@/Components/FloatingMessagesWrapper.vue';
import ServerNotificationHandler from '@/Components/ServerNotificationHandler';
import ProjectBanner from '@/Components/Gallery/ProjectBanner.vue';
import LegacyProjectBanner from '@/Components/Gallery/LegacyProjectBanner.vue';
import ProgressOverlay from '@/Components/ProgressOverlay.vue';
import LoadingIndicator from '@/Components/LoadingIndicator.vue';
import Poll from '@/Components/Poll';
import FooterNavigation from '@/Components/FooterNavigation.vue';
import HeaderActions from '@/Components/TheHeader/HeaderActions.vue';
import CommentsSection from '@/Components/Comments/CommentsSection.vue';
import ProjectHomeDetails from '@/Components/Projects/ProjectHomeDetails.vue';
import UserLocation from '@/Components/UserLocation';
import Footer from '@/Components/Layout/Footer.vue';
import LoginButton from '@/Components/Login/LoginButton.vue';
import SignUpButton from './Components/CreateAccount/SignUpButton.vue';
import HeaderLogo from '@/Components/TheHeader/HeaderLogo.vue';

import Plugins from './Plugins';
import Stores from './Stores';

import Bus from './Bus';
import Router from './Router';
import Formatter from './Utils/Formatter';
import Scroller from './Utils/Scroller';
import VideoUtility from './Utils/VideoUtility';
import Formatting from './Utils/Formatting';

import { checkElementViewportFit } from './Utils';
import { EventBus } from './Utils/EventBus';
import { debounce } from './Utils';

import RealTimeServiceInstance from './Services/RealTimeServiceInstance';

// Views (in future this will be lazy loaded)
import FaqView from '@/Views/Faq/FaqView.vue';
import TermsOfServiceView from '@/Views/TermsOfServiceView.vue';
import AccountSettingsView from '@/Views/Users/AccountSettingsView.vue';
import AccountSubmittedView from '@/Views/AccountSubmittedView.vue';
import MyProjectsView from '@/Views/Users/MyProjectsView.vue';
import BeginOrderView from '@/Views/BeginOrderView.vue';
import CrowdfundingGuideView from '@/Views/CrowdfundingGuideView.vue';
import AboutUsView from '@/Views/AboutUsView.vue';
import ContactRequestSentView from '@/Views/Contact/ContactRequestSentView.vue';
import CreateAccountView from '@/Views/CreateAccountView.vue';
import CreatorAccountView from '@/Views/Creator/CreatorAccountView.vue';
import JoinProjectConfirmationView from '@/Views/JoinProjectConfirmationView.vue';
import KYCCompletedView from '@/Views/KYCCompletedView.vue';
import ProjectContactRequestSentView from '@/Views/Contact/ProjectContactRequestSentView.vue';
import ResetPasswordView from '@/Views/Users/ResetPasswordView.vue';
import SelectOrderView from '@/Views/SelectOrderView.vue';
import SubscriptionManagementView from '@/Views/Users/SubscriptionManagementView.vue';
import PasswordChangedView from '@/Views/Users/PasswordChangedView.vue';
import ResetPasswordRequestSentView from '@/Views/Users/ResetPasswordRequestSentView.vue';
import CompleteUserAccountView from '@/Views/Users/CompleteUserAccountView.vue';
import PledgeOrderView from '@/Views/Orders/PledgeOrderView.vue';
import CreateOrderView from '@/Views/Orders/CreateOrderView.vue';
import ResumePaymentView from '@/Views/Orders/ResumePaymentView.vue';
import MarketingServicesView from '@/Views/StaticPages/MarketingServicesView.vue';
import UserProfileView from '@/Views/UserProfile/UserProfileView.vue';
import FeesView from '@/Views/StaticPages/FeesView.vue';
import PaymentSuccessView from '@/Views/Orders/PaymentSuccessView.vue';
import LegacyCreateOrderView from '@/Views/Orders/LegacyCreateOrderView.vue';
import LegacyBeginOrderView from '@/Views/LegacyBeginOrderView.vue';
import HomepageView from '@/Views/Home/HomepageView.vue';
import TermsOfServicesView from '@/Views/TermsOfServicesView.vue';
import CreatorView from '@/Views/Creator/CreatorView.vue';

import LoginView from '@/Views/Users/LoginView.vue';
import UnsubscribeNotificationView from '@/Views/UnsubscribeNotificationView';
import UserProfileBanner from '@/Views/UserProfile/UserProfileBanner.vue';

const Components = {
    registeredComponents: {},
    // component imports
    BaseNotificator,
    BecomeACreatorSection,
    BecomeACreatorStats,
    BecomeACreatorValues,
    BlogPostBreadcrumb,
    BlogPostRestrictedVisibility,
    BrowseProjects,
    Carts: CartsComponents,
    CheckoutAuthenticateForm,
    Contact,
    CommentsSection,
    CreatorBannerSlider,
    CreatorProjects,
    CurrencySelector,
    EmptyState,
    Field,
    FieldLabel,
    FooterNavigation,
    Localization,
    MessageBox,
    Newsletter: NewsletterComponents,
    Orders: OrdersComponents,
    PartnersSlider,
    Payments: PaymentComponents,
    Poll,
    ProgressOverlay,
    LoadingIndicator,
    ProjectAdvertBanner,
    ProjectBanner,
    LegacyProjectBanner,
    Projects: ProjectsComponents,
    RequestResetPasswordForm,
    Search: SearchComponents,
    ServerNotificationHandler,
    SocialMediaActions,
    SystemStatus: SystemStatusComponents,
    TableOfContents,
    TeamMembersSlider,
    TestimonialsSlider,
    Tooltip,
    Users: UserComponents,
    UserProfileBanner,
    HeaderActions,
    HeaderLogo,
    ProjectHomeDetails,
    FloatingMessagesWrapper,
    UserLocation,
    Footer,
    LoginButton,
    SignUpButton,
};

const Utils = {
    checkElementViewportFit,
    debounce,
    EventBus,
    Formatter,
    VideoUtility,
    Formatting,
    Scroller,
};

const Views = {
    TermsOfServiceView,
    AccountSubmittedView,
    BeginOrderView,
    CompleteUserAccountView,
    CreateAccountView,
    CreatorAccountView,
    FaqView,
    JoinProjectConfirmationView,
    KYCCompletedView,
    LoginView,
    MyProjectsView,
    AccountSettingsView,
    ResetPasswordView,
    ContactRequestSentView,
    ProjectContactRequestSentView,
    SelectOrderView,
    SubscriptionManagementView,
    UnsubscribeNotificationView,
    MarketingServicesView,
    PledgeOrderView,
    ProductInfoView,
    UserProfileView,
    FeesView,
    AboutUsView,
    PasswordChangedView,
    CreateOrderView,
    ResumePaymentView,
    ResetPasswordRequestSentView,
    CrowdfundingGuideView,
    PaymentSuccessView,
    LegacyCreateOrderView,
    LegacyBeginOrderView,
    HomepageView,
    CreatorView,
    TermsOfServicesView,
};

const Gamefound = {
    Bus,
    Components,
    Config,
    Utils,
    Plugins,
    Stores,
    Router,
    RealTimeServiceInstance,
    Views,
    Resources,
    PlatformCapabilities,
};

Gamefound.registerComponent = (componentId, name, path, options) => {
    const component = new Vue({
        el: `#${componentId}`,
        name,
        router: Router,
        pinia: Stores,
        render: (h) => h(path, options),
    });

    Components.registeredComponents[componentId] = component;
};

window.Gamefound = Gamefound;
