import { defineStore } from 'pinia';

export const useDeferredNotificationsStore = defineStore({
    id: 'deferredNotifications',
    state: () => ({
        deferredNotifications: [],
    }),
    actions: {
        addDeferredNotification(state, message) {
            state.deferredNotifications.push(message);
        },
        clearDeferredNotifications(state) {
            state.deferredNotifications = [];
        },
    },
});