var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      !_vm.success
        ? _c("a", {
            staticClass:
              "gfu-link gfu-link--inline gfu-link--nofx _fa _fa--link",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.copyText.apply(null, arguments)
              },
            },
          })
        : _c(
            "tooltip",
            {
              ref: "tooltip",
              attrs: {
                text: "Copied to clipboard!",
                theme: _vm.light ? "light" : "default",
                "vertical-position": _vm.verticalPosition,
              },
            },
            [
              _c("span", {
                staticClass:
                  "gfu-link gfu-link--nofx gfu-link--block _fa _fa--link gfu-link--inline _tc--accent",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.copyText.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
      _vm._v(" "),
      _c("input", {
        ref: "copyLinkInput",
        staticClass: "gfu-countdown__hidden-link",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }