import { paymentStatuses } from '@/Models';
import Resources from '@/Resources.js';

export function getOrderPaymentStatuses(paymentStatus) {
    return {
        [paymentStatuses.deferredWithError]: Resources.OrderStatus.OrderPaymentStatusPaymentErrorName,
        [paymentStatuses.canceled]: Resources.OrderStatus.OrderPaymentStatusCanceledName,
        [paymentStatuses.completed]: Resources.OrderStatus.OrderPaymentStatusPaidName,
        [paymentStatuses.deferred]: Resources.OrderStatus.OrderPaymentStatusScheduledName,
    }[paymentStatus];
}