var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: ["gfu-like", { "is-liked": _vm.isLiked }] },
    [
      _vm.enabled
        ? _c(
            "a",
            {
              staticClass: "gfu-link gfu-link--nofx",
              attrs: { href: "", "data-qa": "like-action:Like" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("like")
                },
              },
            },
            [
              _c("span", { staticClass: "_fa _fa--thumbs-up" }),
              _vm._v(" "),
              _c("span", { attrs: { "data-qa": "like-action:LikesCount" } }, [
                _vm._v(_vm._s(_vm.likesCount)),
              ]),
            ]
          )
        : _c(
            "tooltip",
            {
              attrs: {
                text: _vm.tooltipText,
                "data-qa": "like-action:Tooltip",
              },
            },
            [
              _c("span", {
                staticClass: "_fa _fa--thumbs-up",
                attrs: { "data-qa": "like-action:Like" },
              }),
              _vm._v(" "),
              _c("span", { attrs: { "data-qa": "like-action:LikesCount" } }, [
                _vm._v(_vm._s(_vm.likesCount)),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }