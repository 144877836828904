var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    _vm._g(
      _vm._b(
        { class: [_vm.getClasses] },
        "base-button",
        _vm.baseButtonProps,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.isRightIcon ? _c("span", { class: _vm.getIconClasses }) : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.isRightIcon ? _c("span", { class: _vm.getIconClasses }) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }