var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_bxsh--base _rounded--common _bgc--white _fo" },
    _vm._l(_vm.items, function (item) {
      return _c(
        "a",
        {
          key: item.name,
          staticClass: "_flex _gap-2 _ai-c _px-2 _py-2 _tdh-n gfu-link--nofx",
          attrs: { href: item.url, "data-qa": `box-list-item:${item.name}` },
        },
        [
          _c(
            "span",
            {
              staticClass: "_grow-1 _fw-eb",
              attrs: { "data-qa": "box-list-item-name" },
            },
            [_vm._v(_vm._s(item.name))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "gfu-navbar-badge",
              attrs: { "data-qa": "box-list-item-count" },
            },
            [_vm._v(_vm._s(item.count))]
          ),
          _vm._v(" "),
          _c("base-icon", {
            staticClass: "_tc--accent",
            attrs: { name: "chevron-right", size: "lg" },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }