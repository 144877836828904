import { initializeApp } from 'firebase/app';
import { pushNotifications } from './Config';

const firebaseApp = initializeApp({
    apiKey: pushNotifications.apiKey,
    authDomain: pushNotifications.authDomain,
    projectId: pushNotifications.projectID,
    storageBucket: pushNotifications.storageBucket,
    messagingSenderId: pushNotifications.messagingSenderID,
    appId: pushNotifications.appID,
});

export default {
    firebaseApp,
};