var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-payment-form",
      attrs: {
        id: "vue-app-order-payment-options",
        "data-qa": "legacy-payment-section",
      },
    },
    [
      _c("div", { staticClass: "_mb-3 _mt-6" }, [
        _c("h2", { staticClass: "gfu-heading gfu-heading--top-divider" }, [
          _vm._v(
            _vm._s(
              _vm.Resources.Checkout.LegacyCheckoutPaymentMethodSectionTitle
            )
          ),
        ]),
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.Resources.Checkout.LegacyCheckoutPaymentMethodSectionSubtitle
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.isLegacyPaymentMethod
        ? _c(
            "form",
            {
              ref: "form",
              attrs: { action: _vm.formAction, method: "post" },
              on: { submit: _vm.submit },
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: "orderCode" },
                domProps: { value: _vm.orderInfo.orderCode },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: _vm.antiForgeryTokenKey },
                domProps: { value: _vm.antiforgeryToken },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.paymentToken,
                    expression: "paymentToken",
                  },
                ],
                attrs: { type: "hidden", name: "paymentToken" },
                domProps: { value: _vm.paymentToken },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.paymentToken = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.digitalDeliveryAccepted,
                    expression: "digitalDeliveryAccepted",
                  },
                ],
                attrs: { type: "hidden", name: "digitalDeliveryAccepted" },
                domProps: { value: _vm.digitalDeliveryAccepted },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.digitalDeliveryAccepted = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm._t("antiForgery"),
              _vm._v(" "),
              _vm.showPaymentSelection
                ? _vm._l(_vm.availablePaymentMethods, function (method) {
                    return _c(
                      "div",
                      {
                        key: `payment-${method.code}`,
                        class: { "has-error": _vm.errors.has("paymentMethod") },
                        attrs: {
                          "data-qa": "order-payment-option:" + method.code,
                        },
                      },
                      [
                        _c(
                          "checkbox-field",
                          {
                            attrs: {
                              "checked-value": method.code,
                              condensed: true,
                              name: "paymentMethod",
                              type: "radio",
                              "validator-layout": "none",
                            },
                            model: {
                              value: _vm.paymentMethod,
                              callback: function ($$v) {
                                _vm.paymentMethod = $$v
                              },
                              expression: "paymentMethod",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(method.name) +
                                "\n                    "
                            ),
                            method.code === _vm.paymentMethods.espago
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "gfu-payment-form__card-icons",
                                  },
                                  [
                                    _c("base-image", {
                                      attrs: {
                                        src: "/content/images/payments/card-icon-visa-34x22.png",
                                        alt: "Visa",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("base-image", {
                                      attrs: {
                                        src: "/content/images/payments/card-icon-mastercard-34x22.png",
                                        alt: "Mastercard",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("base-image", {
                                      attrs: {
                                        src: "/content/images/payments/card-icon-amex-34x22.png",
                                        alt: "Amex",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("base-image", {
                                      attrs: {
                                        src: "/content/images/payments/card-icon-jcb-34x22.png",
                                        alt: "Jcb",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("base-image", {
                                      attrs: {
                                        src: "/content/images/payments/card-icon-diners-34x22.png",
                                        alt: "Diners",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  })
                : [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.paymentMethod,
                          expression: "paymentMethod",
                        },
                      ],
                      attrs: { type: "hidden", name: "paymentMethod" },
                      domProps: { value: _vm.paymentMethod },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.paymentMethod = $event.target.value
                        },
                      },
                    }),
                  ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.availablePaymentMethods.some(
        (c) => c.code === _vm.paymentMethods.espago
      )
        ? [
            _c("order-espago-form", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.paymentMethod === _vm.paymentMethods.espago,
                  expression: "paymentMethod === paymentMethods.espago",
                },
              ],
              ref: "espagoForm",
              staticClass: "_mt-6",
              attrs: {
                "enable-american-express": true,
                "enable-diners-club": true,
                "enable-discover": false,
                "enable-jcb": true,
                "enable-master-card": true,
                "enable-visa": true,
                "public-key": _vm.paymentProviderOptions.publicKey,
                "use-live-api": _vm.paymentProviderOptions.useLiveApi,
              },
              on: {
                "token-created": _vm.onEspagoTokenCreated,
                "espago-submit": _vm.onEspagoSubmit,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isFakePaymentMethod
        ? [
            _c("fake-instant-payment-form", {
              ref: "fakeForm",
              attrs: {
                "order-code": _vm.orderInfo.orderCode,
                "checkout-mode": _vm.checkoutMode,
              },
              on: {
                "start-processing": _vm.onChildStartProcessing,
                "stop-processing": _vm.onChildStopProcessing,
                error: _vm.onChildError,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.errors.has("paymentMethod")
        ? _c("div", { staticClass: "gfu-field has-error" }, [
            _c(
              "p",
              {
                staticClass: "gfu-field__error",
                attrs: { "data-qa": "field-error" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.Resources.Checkout.InvalidPaymentOptionErrorMessage
                  )
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLegacyPaymentMethod && _vm.requireAcceptDigitalDelivery
        ? _c(
            "div",
            { staticClass: "_mt-6" },
            [
              _c(
                "h2",
                { staticClass: "gfu-heading gfu-heading--top-divider" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.Checkout
                        .CheckoutDigitalProductsTermsSectionTitle
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "checkbox-field",
                {
                  attrs: {
                    condensed: true,
                    "display-name": "Digital delivery agreement",
                    name: "digitalDeliveryAccepted",
                    "data-qa": "digital-delivery-agreement",
                  },
                  model: {
                    value: _vm.digitalDeliveryAccepted,
                    callback: function ($$v) {
                      _vm.digitalDeliveryAccepted = $$v
                    },
                    expression: "digitalDeliveryAccepted",
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.Resources.Checkout
                          .CheckboxDigitalDeliveryAgreementMessage
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLegacyPaymentMethod || _vm.isFakePaymentMethod
        ? _c("div", { staticClass: "gfu-box-actions" }, [
            _c("div", { staticClass: "gfu-box-actions__item" }, [
              !_vm.orderInfo.orderState
                ? _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: { href: _vm.backAction },
                    },
                    [
                      _c("base-icon", {
                        staticClass: "_fl _lhb",
                        attrs: { name: "chevron-left" },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "_ml-1",
                          attrs: { "data-qa": "create-order-btn:Back" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.Resources.Checkout.CheckoutGoBackLink)
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.orderInfo.orderCode
                ? _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.defaultBackAction.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("base-icon", {
                        staticClass: "_fl _lhb",
                        attrs: { name: "chevron-left" },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "_ml-1",
                          attrs: { "data-qa": "create-order-btn:Back" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.Resources.Checkout.CheckoutGoBackLink)
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gfu-box-actions__item _ml-a" },
              [
                _c(
                  "tooltip",
                  { attrs: { text: _vm.validationMessage } },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "gfu-btn--block",
                        attrs: {
                          href: "",
                          enabled: _vm.isValid,
                          theme: "accent",
                          "data-qa": "create-order-btn:Submit",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.Resources.Checkout.CheckoutSubmitOrderButton
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }