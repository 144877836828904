var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("project-summary-card", {
    attrs: { "is-flipped": _vm.showDetails },
    scopedSlots: _vm._u([
      {
        key: "front",
        fn: function () {
          return [
            _c(
              "project-summary-box",
              {
                attrs: {
                  borderless: true,
                  "data-qa": "project-summary-box:Active",
                },
                scopedSlots: _vm._u(
                  [
                    _vm.fundedAtText
                      ? {
                          key: "sticky",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-project-summary-funded-info",
                                  attrs: {
                                    "data-qa":
                                      "project-summary-box:FundedBanner",
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.goalReachedInText),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "actions",
                      fn: function () {
                        return [
                          _vm.showBackerInfo
                            ? _c("project-summary-backer", {
                                attrs: {
                                  number: _vm.backerNumber,
                                  href: _vm.backProjectUrl,
                                },
                              })
                            : _c(
                                "div",
                                { staticClass: "gfu-project-summary-action" },
                                [_c("back-project-button")],
                                1
                              ),
                          _vm._v(" "),
                          _vm.showFollowButton
                            ? _c("project-summary-follow", {
                                attrs: { "show-button-follower-count": false },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "_tac _pt-2" }, [
                            _c(
                              "a",
                              {
                                staticClass: "gfu-link gfu-link--accent",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.toggleShowDetails.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .ProjectSummaryLearnMoreButton
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c(
                  "project-summary-progress",
                  _vm._b(
                    { staticClass: "gfu-project-summary-box__progress" },
                    "project-summary-progress",
                    _vm.progressModel,
                    false
                  )
                ),
                _vm._v(" "),
                _vm.promoItem
                  ? _c("project-promo-item", {
                      staticClass: "gfu-project-summary-promo-item",
                      attrs: {
                        "data-qa": `project-summary-promo-item:${_vm.promoItem.type}`,
                      },
                    })
                  : [
                      _vm.campaignEndInThreeDays
                        ? _c("project-summary-clock", {
                            staticClass: "_mt-3",
                            attrs: {
                              "elapsed-message": "End imminent",
                              "target-date": _vm.campaignEndDate,
                              title: "Campaign ends in",
                            },
                          })
                        : [
                            _vm.nextStretchGoal
                              ? _c(
                                  "project-summary-stretch-goal",
                                  _vm._b(
                                    { staticClass: "_my-3" },
                                    "project-summary-stretch-goal",
                                    _vm.nextStretchGoal,
                                    false
                                  )
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.allStretchGoalCompleted
                              ? _c("project-summary-stretch-goal-completed", {
                                  staticClass: "_my-3",
                                  attrs: {
                                    "stretch-goals-url": _vm.stretchGoalsUrl,
                                  },
                                })
                              : _vm._e(),
                          ],
                    ],
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "back",
        fn: function () {
          return [
            _vm.showDetails
              ? _c(
                  "project-summary-details",
                  _vm._b(
                    { on: { close: _vm.toggleShowDetails } },
                    "project-summary-details",
                    _vm.detailsModel,
                    false
                  )
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }