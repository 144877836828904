var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: _vm.Resources.ProjectHome.AddToCartRestrictedModalTitle,
        "is-visible": true,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "base-button",
                {
                  attrs: {
                    width: "wide",
                    theme: "accent",
                    "data-qa": "modal-button:Confirm",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalConfirmButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.Resources.ProjectHome.AddToCartRestrictedModalFirstParagraph
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        [
          _c(
            "localize",
            {
              attrs: {
                resource:
                  _vm.Resources.ProjectHome
                    .AddToCartRestrictedModalSecondParagraph,
              },
            },
            [
              _c("strong", { attrs: { "format-key": "0" } }, [
                _vm._v(_vm._s(_vm.shippingLocation)),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "_mt-0" },
        [
          _c(
            "localize",
            {
              attrs: {
                resource:
                  _vm.Resources.ProjectHome
                    .AddToCartRestrictedModalThridParagraph,
              },
            },
            [
              _c("strong", { attrs: { "format-key": "0" } }, [
                _vm._v(_vm._s(_vm.currentLocation)),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c(
            "localize",
            {
              attrs: {
                resource:
                  _vm.Resources.ProjectHome
                    .AddToCartRestrictedModalFourthPagraph,
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "_tc--accent _pa-0",
                  attrs: { "format-key": "0" },
                  on: { click: _vm.handleClick },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.ProjectHome
                        .AddToCartRestrictedModalParagraphAction
                    )
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }