var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_w-100" },
    [
      _c(
        "div",
        { staticClass: "gfu-project-summary-stats__content" },
        [
          _c(
            "h5",
            {
              staticClass: "gfu-hd gfu-hd--h4 _tc--inherit _mt-3",
              attrs: { "data-qa": "project-summary-stats:BackersCount" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.format(
                      _vm.Resources.ProjectHome
                        .ProjectSummaryBackerCountSubHeader,
                      _vm.totalBackersCount
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass: "gfu-hd gfu-hd--d1 _tc--inherit _mb-3",
              attrs: { "data-qa": "project-summary-stats:FundsGathered" },
            },
            [
              _c("display-currency-value", {
                attrs: { "display-tooltip": false, tag: "span" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.convertedFundsGathered) +
                            "\n                "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.formattedFundsGathered) +
                            "\n                "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isExternalCampaign
            ? [
                _c(
                  "p",
                  {
                    staticClass: "_mt-0",
                    attrs: {
                      "data-qa": "project-summary-stats:PercentageOfGoal",
                    },
                  },
                  [
                    _c(
                      "localize",
                      {
                        attrs: {
                          resource:
                            _vm.Resources.ProjectHome
                              .ProjectSummaryStatisticsPercentageOfGoalText,
                        },
                      },
                      [
                        _c("strong", { attrs: { "format-key": "0" } }, [
                          _vm._v(_vm._s(_vm.percentageOfGoal)),
                        ]),
                        _vm._v(" "),
                        _c("display-currency-value", {
                          attrs: {
                            "format-key": "1",
                            "display-tooltip": false,
                            tag: "strong",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function ({ displayCurrency }) {
                                  return [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$format(
                                            _vm.campaignGoal /
                                              displayCurrency.relativeFactor,
                                            "C0",
                                            displayCurrency.symbol
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$format(_vm.campaignGoal, "C0")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            896069120
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.hasStretchGoals
                  ? _c("p", {
                      staticClass: "_mt-0",
                      attrs: {
                        "data-qa": "project-summary-stats:StretchGoalsCount",
                      },
                      domProps: { innerHTML: _vm._s(_vm.stretchGoalsText) },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.fundedAtText
        ? _c(
            "div",
            {
              staticClass:
                "gfu-project-summary-funded-info gfu-project-summary-funded-info--outlined",
              attrs: { "data-qa": "project-summary-box:FundedBanner" },
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.goalReachedInText) },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showFollowButton
        ? _c("project-summary-follow", {
            staticClass: "_mt-3",
            attrs: { theme: "inverse", "button-theme": "transparentInverse" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fundedAtDate
        ? _c(
            "div",
            {
              staticClass: "_mt-3",
              attrs: { "data-qa": "project-summary-box:FundedAt" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.Resources.format(
                      _vm.Resources.ProjectHome.ProjectSummaryBoxFundedAtText,
                      _vm.fundedAtDate
                    )
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }