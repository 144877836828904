var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "bottomBar", staticClass: "gfu-layout-bar gfu-layout-bar--bottom" },
    [
      _vm.isBottomActionVisible
        ? _c(
            "bottom-bar",
            {
              attrs: { "with-transition": _vm.withTransition },
              on: { "after-enter": _vm.afterEnter },
            },
            [
              _vm.isBackProjectVisible
                ? _c("back-project-button")
                : _vm.isFollowVisible
                ? _c("follow-project-button", {
                    staticClass: "gfu-1of1",
                    attrs: {
                      "project-id": _vm.projectId,
                      "project-name": _vm.projectName,
                      "show-overlay": false,
                      section: "bottom-bar",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm.isMiniWizardVisible
        ? _c(
            "mini-wizard-expandable",
            _vm._b(
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isMiniWizardVisible,
                    expression: "isMiniWizardVisible",
                  },
                ],
              },
              "mini-wizard-expandable",
              _vm.$props,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }