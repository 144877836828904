var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_rounded--common _bxsh--base _fo" },
    [
      _c("become-a-creator-section", {
        attrs: { "creator-statistics": _vm.creatorStatistics },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }