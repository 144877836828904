var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-profile-banner" }, [
    _c(
      "div",
      {
        staticClass: "gfu-layout__main gfu-layout__main--extranarrow _pb-0",
        attrs: { "data-qa": "user-profile:Header" },
      },
      [
        _c(
          "div",
          { staticClass: "_flexcol _jc-sb _ai-c _fd-r--m _gap-2" },
          [
            _c(
              "div",
              {
                class: [
                  "gfu-media gfu-1of1 gfu-2of3--m",
                  { "_ai-c": !_vm.hasLabel },
                ],
              },
              [
                _c("base-image", {
                  staticClass: "gfu-avatar gfu-avatar--clean _mr-3",
                  attrs: {
                    src: _vm.avatarUrl,
                    alt: _vm.nickname,
                    width: "64",
                    height: "64",
                    loading: "lazy",
                    "data-qa": "user-profile:Avatar",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-media__body _fo" }, [
                  _c(
                    "div",
                    { staticClass: "_flex _gap-1" },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "gfu-profile-banner__name gfu-hd gfu-hd--h1 _tc--inherit _toe",
                          attrs: { "data-qa": "user-profile:Username" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.nickname) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isUserVerified
                        ? _c(
                            "icon-base-label",
                            _vm._b(
                              {
                                attrs: {
                                  "data-qa": _vm.verifiedLabelModel.dataQa,
                                },
                              },
                              "icon-base-label",
                              _vm.verifiedLabelModel,
                              false
                            )
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-profile-banner__followers _tc--light gfu-bt gfu-bt--caption",
                      attrs: { "data-qa": "user-profile:FollowersCount" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.formattedFollowersLabel) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasLabel
                    ? _c(
                        "div",
                        { staticClass: "_mt-1" },
                        _vm._l(_vm.userAchievedLabels, function (label) {
                          return _c(
                            "icon-base-label",
                            _vm._b(
                              {
                                key: label.name,
                                attrs: { "data-qa": label.dataQa },
                              },
                              "icon-base-label",
                              label,
                              false
                            )
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isFollowingEnabled
              ? _c("user-follow-button", {
                  staticClass: "gfu-1of1 gfu-1of3--m",
                  attrs: {
                    nickname: _vm.nickname,
                    "user-url-name": _vm.userUrlName,
                    "user-id": _vm.userId,
                    "data-qa": "user-profile:FollowButton",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "gfu-profile-banner__bio _tc--light _mt-2",
            attrs: { "data-qa": "user-profile:Bio" },
          },
          [_vm._v("\n            " + _vm._s(_vm.bio) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "gfu-profile-banner__social _flex _ai-c _tc--light _mt-2",
          },
          _vm._l(_vm.socialLinks, function (link) {
            return _c(
              "a",
              {
                key: link.name,
                staticClass: "_mr-2 gfu-link gfu-link--nofx",
                attrs: {
                  href: link.url,
                  target: "_blank",
                  rel: "nofollow noopener noreferrer",
                  "data-qa": `user-profile-social-link:${link.name}`,
                },
              },
              [
                _c("base-icon", {
                  attrs: {
                    name: link.iconName,
                    "icon-namespace": "brands",
                    size: "lg",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }