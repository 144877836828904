var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-grid__cell gfu-1of1 _my-6" },
    [
      _c("h2", { staticClass: "gfu-heading gfu-heading--text" }, [
        _vm._v(
          _vm._s(_vm.Resources.AccountSettings.SavedCreditCardsSectionTitle)
        ),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.storedCreditCardsCopy))]),
      _vm._v(" "),
      _vm._l(_vm.savedCards, function (card) {
        return _c("credit-card-overview", {
          key: card.userCreditCardID,
          attrs: {
            "image-url": card.cardImageUrl,
            name: card.cardName,
            "card-id": card.userCreditCardID,
            "masked-number": card.cardMaskedNumber,
          },
          on: { "delete-card": _vm.onDelete },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }