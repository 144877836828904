var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": _vm.isModalVisible,
        "show-action-close": true,
        width: "wide",
      },
      on: { "action-close": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [_c("adyen-payment-modal-caption")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("adyen-payment-modal-element", { ref: "adyenAction" }),
      _vm._v(" "),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, fixed: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }