import ServiceBase from './ServiceBase';

export default class ProjectSearchService extends ServiceBase {

    getContactRelevantProjects(searchHints, previouslyFetchedProjectIDs, pageSize = null, pageIndex = null) {
        const params = {
            pageSize,
            pageIndex,
            previouslyFetchedProjectIDs,
            ...searchHints.referrerValueDictionary || {},
        };

        return this.request('post', 'projectSearch/getContactRelevantProjects', params);
    }

    getProjectSearchLists() {
        return this.request('get', 'projectSearch/getProjectSearchLists');
    }

    searchProjects(filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            pageSize,
            pageIndex,
            ...filters || {},
        };

        return this.request('post', 'projectSearch/searchProjects', params);
    }

    searchProjectsForContact(filters = {}) {
        const params = {
            ...filters,
        };

        return this.request('post', 'projectSearch/searchProjectsForContact', params);
    }

    searchProjectsForCards(filters = {}) {
        const params = {
            ...filters,
        };

        return this.request('post', 'projectSearch/searchProjectsForCards',  params);
    }
}
