var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: { title: "Warning", "is-visible": _vm.isModalVisible },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "_tc--accent _px-0",
                  attrs: { "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onCancel.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "gfu-btn gfu-btn--accent gfu-btn--compact gfu-btn--soft gfu-btn--wide _ml-3",
                  attrs: { type: "button", "data-qa": "modal-button:Continue" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onContinue.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            Continue\n        ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          "\n        This action will delete all test orders assigned to your account. Are you sure you want to proceed?\n    "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }