var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-card", {
    staticClass: "gfu-profile-card",
    attrs: { "extended-wrapper-class": "gfu-profile-card__wrapper" },
    scopedSlots: _vm._u(
      [
        {
          key: "labels",
          fn: function () {
            return [
              _c("user-follow", {
                attrs: {
                  "user-url-name": _vm.urlName,
                  nickname: _vm.nickname,
                  "user-id": _vm.userID,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ isFollowing }) {
                      return [
                        isFollowing
                          ? _c(
                              "base-label",
                              {
                                attrs: {
                                  theme: "accent",
                                  "data-qa": "user-card:FollowingBadge",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.Resources.Profile
                                        .UserProfileCardFollowingBadge
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "media",
          fn: function () {
            return [
              _c("div", { staticClass: "_flex _fd-c--l _gap-2 _ai-c" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "gfu-profile-card__avatar _ai-c gfu-embed gfu-embed--1x1",
                    attrs: { href: _vm.profileUrl, title: _vm.nickname },
                  },
                  [
                    _c("base-image", {
                      staticClass:
                        "gfu-embed__item gfu-avatar gfu-avatar--clean",
                      attrs: {
                        src: _vm.avatarImageUrl,
                        alt: _vm.nickname,
                        loading: "lazy",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-profile-card__media" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "_max-w-100",
                        { "_flex _gap-1": _vm.isUserVerified },
                      ],
                    },
                    [
                      _c(
                        "h4",
                        {
                          staticClass:
                            "gfu-profile-card__title _toe gfu-hd gfu-hd--h2",
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.profileUrl,
                                "data-qa": "user-card:Nickname",
                              },
                            },
                            [_vm._v(_vm._s(_vm.nickname))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isUserVerified
                        ? _c(
                            "icon-base-label",
                            _vm._b(
                              {
                                staticClass: "gfu-profile-card__verified",
                                attrs: {
                                  "data-qa": _vm.verifiedLabelModel.dataQa,
                                },
                              },
                              "icon-base-label",
                              _vm.verifiedLabelModel,
                              false
                            )
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "_tc--light gfu-bt gfu-bt--caption",
                      attrs: { "data-qa": "user-card:FollowersCountText" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formattedFollowersLabel) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gfu-profile-card__badges",
                      attrs: { "data-qa": "badge:UserCardBadges" },
                    },
                    _vm._l(_vm.userAchievedLabels, function (label) {
                      return _c(
                        "icon-base-label",
                        _vm._b(
                          {
                            key: label.name,
                            attrs: { "data-qa": label.dataQa },
                          },
                          "icon-base-label",
                          label,
                          false
                        )
                      )
                    }),
                    1
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        _vm.isFollowUserActionVisible
          ? {
              key: "actions",
              fn: function () {
                return [
                  _c("user-follow", {
                    attrs: {
                      "user-url-name": _vm.urlName,
                      nickname: _vm.nickname,
                      "user-id": _vm.userID,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ isFollowing, onFollow, onUnfollow }) {
                            return [
                              isFollowing
                                ? _c(
                                    "div",
                                    { staticClass: "gfu-profile-card__follow" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.Profile
                                              .UserFollowingLabel
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "_tc--accent",
                                          attrs: {
                                            href: "#",
                                            "data-qa": "user-card:Unfollow",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return onUnfollow.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.Resources.Profile
                                                .UserUnfollowAction
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "gfu-profile-card__follow _tc--accent",
                                        attrs: {
                                          href: "#",
                                          "data-qa": "user-card:Follow",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return onFollow.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.Profile
                                              .UserProfileCardFollowThisUserAction
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      507388321
                    ),
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }