import ServiceBase from './ServiceBase';

export default class PromotedProjectsService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    getProjects() {
        return this.request('get', 'promotedProjects/getProjects');
    }
}
