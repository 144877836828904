var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-grid__cell gfu-1of1" },
    [
      _c("box", [
        _c(
          "section",
          { staticClass: "_mb-3" },
          [
            _c("h2", { staticClass: "gfu-hd gfu-hd--h2" }, [
              _vm._v(
                _vm._s(
                  _vm.Resources.SubscriptionManagement.SystemNotificationHeader
                )
              ),
            ]),
            _vm._v(" "),
            _c("option-field", {
              staticClass: "_mb-3",
              attrs: {
                "first-column-width": 8,
                "read-only": true,
                options: _vm.basePreferenceOptions,
                "display-name":
                  _vm.Resources.SubscriptionManagement.SystemMessagesFieldLabel,
                name: "model.systemMessages",
                layout: "columns",
                description:
                  _vm.Resources.SubscriptionManagement
                    .SystemMessagesFieldDescription,
                "data-qa": "general-notifications:SystemMessages",
              },
              on: { input: _vm.onSystemMessageNotificationPreferenceChange },
              model: {
                value: _vm.model.systemMessages,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "systemMessages", $$v)
                },
                expression: "model.systemMessages",
              },
            }),
            _vm._v(" "),
            _c("option-field", {
              staticClass: "_mb-3",
              attrs: {
                "first-column-width": 8,
                options: _vm.basePreferenceOptions,
                "display-name":
                  _vm.Resources.SubscriptionManagement.GamefoundFeastFieldLabel,
                name: "model.gamefoundFeast",
                layout: "columns",
                description:
                  _vm.Resources.SubscriptionManagement
                    .GamefoundFeastFieldDescription,
                "data-qa": "general-notifications:GamefoundFeast",
              },
              on: { input: _vm.onGamefoundFestNotificationPreferenceChange },
              model: {
                value: _vm.model.gamefoundFeast,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "gamefoundFeast", $$v)
                },
                expression: "model.gamefoundFeast",
              },
            }),
            _vm._v(" "),
            _c("option-field", {
              staticClass: "_mb-3",
              attrs: {
                "first-column-width": 8,
                options: _vm.basePreferenceOptions,
                "display-name":
                  _vm.Resources.SubscriptionManagement.NewsletterFieldLabel,
                name: "model.newsletter",
                layout: "columns",
                description:
                  _vm.Resources.SubscriptionManagement
                    .NewsletterFieldDescription,
                "data-qa": "general-notifications:Newsletter",
              },
              on: { input: _vm.onNewsletterNotificationPreferenceChange },
              model: {
                value: _vm.model.newsletter,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "newsletter", $$v)
                },
                expression: "model.newsletter",
              },
            }),
            _vm._v(" "),
            _c("option-field", {
              staticClass: "_mb-3",
              attrs: {
                "first-column-width": 8,
                options: _vm.notificationPreferenceOptions,
                "display-name":
                  _vm.Resources.SubscriptionManagement.CommentRepliesFieldLabel,
                name: "model.commentReply",
                layout: "columns",
                description:
                  _vm.Resources.SubscriptionManagement
                    .CommentRepliesFieldDescription,
                "data-qa": "general-notifications:CommentReply",
              },
              on: { input: _vm.onCommentReplyNotificationPreferenceChange },
              model: {
                value: _vm.model.commentReply,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "commentReply", $$v)
                },
                expression: "model.commentReply",
              },
            }),
            _vm._v(" "),
            _c("option-field", {
              staticClass: "_mt-0",
              attrs: {
                "first-column-width": 8,
                options: _vm.digestFrequencyPreferenceOptions,
                "display-name":
                  _vm.Resources.SubscriptionManagement.CommunityLifeFieldLabel,
                name: "model.preferredDigestFrequency",
                layout: "columns",
                description:
                  _vm.Resources.SubscriptionManagement
                    .CommunityLifeFieldDescription,
                "data-qa": "general-notifications:CommunityLife",
              },
              on: { input: _vm.onDigestFrequencyNotificationPreferenceChange },
              model: {
                value: _vm.model.preferredDigestFrequency,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "preferredDigestFrequency", $$v)
                },
                expression: "model.preferredDigestFrequency",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "section",
          [
            _c("h2", { staticClass: "gfu-hd gfu-hd--h2" }, [
              _vm._v(
                _vm._s(
                  _vm.Resources.SubscriptionManagement.ProjectNotificationHeader
                )
              ),
            ]),
            _vm._v(" "),
            _vm.isEnablePushNotificationsMessageVisible
              ? _c(
                  "base-notificator",
                  { staticClass: "_my-3", attrs: { "can-close": false } },
                  [
                    _c("div", { staticClass: "_flex _jc-sb _fd-c _fd-r--m" }, [
                      _c("p", { staticClass: "_mt-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.SubscriptionManagement
                              .WebPushEnableParagraph
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "_flex _jc-sb _ai-c _shrink-0" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "_pa-0 _mx-1 _tdu",
                              attrs: {
                                "data-qa": "push-notification:HideThisMessage",
                              },
                              on: { click: _vm.onNotificationHide },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.Resources.SubscriptionManagement
                                      .WebPushHideLabel
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-button",
                            {
                              staticClass: "_ml-a",
                              attrs: {
                                theme: "transparentInverse",
                                "data-qa": "push-notification:Activate",
                              },
                              on: { click: _vm.onActivatePushNotifications },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.Resources.SubscriptionManagement
                                      .WebPushActivateLabel
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isPushNotificationsBlockedByBrowserMessageVisible
              ? _c(
                  "base-notificator",
                  { staticClass: "_my-3", attrs: { "can-close": false } },
                  [
                    _c("div", { staticClass: "_flex _jc-sb _fd-c _fd-r--m" }, [
                      _c("p", { staticClass: "_mt-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.SubscriptionManagement
                              .WebPushBlockedByBrowserParagraph
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "_flex _jc-sb _ai-c _shrink-0" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "_pa-0 _mx-1 _tdu",
                              attrs: {
                                "data-qa": "push-notification:HideThisMessage",
                              },
                              on: { click: _vm.onNotificationHide },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.Resources.SubscriptionManagement
                                      .WebPushHideLabel
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-button",
                            {
                              staticClass: "_ml-a",
                              attrs: {
                                theme: "transparentInverse",
                                tag: "a",
                                href: _vm.browserHelpUrl,
                                target: "_blank",
                                "data-qa": "push-notification:ReadInstructions",
                              },
                              on: { click: _vm.onBrowserHelpButtonClick },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.Resources.Layout.WebPushHowToUnblockLink
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("option-field", {
              staticClass: "_mb-3",
              attrs: {
                "first-column-width": 8,
                options: _vm.notificationPreferenceOptions,
                "display-name":
                  _vm.Resources.SubscriptionManagement
                    .ProjectMilestonesFieldLabel,
                name: "model.projectMilestones",
                layout: "columns",
                description:
                  _vm.Resources.SubscriptionManagement
                    .ProjectMilestonesInputFieldDescription,
                "data-qa": "general-notifications:General",
              },
              on: {
                input: _vm.onProjectMilestonesNotificationPreferenceChange,
              },
              model: {
                value: _vm.model.projectMilestones,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "projectMilestones", $$v)
                },
                expression: "model.projectMilestones",
              },
            }),
            _vm._v(" "),
            _c("option-field", {
              staticClass: "_mt-0",
              attrs: {
                "first-column-width": 8,
                options: _vm.notificationPreferenceOptions,
                "display-name":
                  _vm.Resources.SubscriptionManagement.ProjectUpdatesFieldLabel,
                name: "model.projectUpdates",
                layout: "columns",
                description:
                  _vm.Resources.SubscriptionManagement
                    .ProjectUpdatesInputFieldDescription,
                "data-qa": "general-notifications:Updates",
              },
              on: { input: _vm.onProjectUpdatesNotificationPreferenceChange },
              model: {
                value: _vm.model.projectUpdates,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "projectUpdates", $$v)
                },
                expression: "model.projectUpdates",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }