var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fieldset", { staticClass: "gfu-grid gfu-grid--gutter-columns" }, [
    _c(
      "div",
      { staticClass: "gfu-grid__cell gfu-1of1 gfu-6of12--m" },
      [
        _c(
          "field",
          {
            staticClass: "_mt-0",
            attrs: {
              "display-name":
                _vm.Resources.Checkout.CheckoutAvailableBanksLabel,
              name: "issuer",
            },
          },
          [_c("div", { ref: "ideal-wrapper" })]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }