var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.userIsFollowingProject
    ? _c(
        "div",
        [
          _c(
            "base-button",
            {
              staticClass: "_flex _gap-2 gfu-1of1 _jc-sb",
              attrs: { theme: _vm.theme, "data-qa": "follow-project-button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubscribe.apply(null, arguments)
                },
              },
            },
            [
              _c("base-icon", {
                attrs: { name: "heart-solid-icon", "icon-namespace": "ga" },
              }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.Resources.ProjectHome.FollowProjectButton) +
                  "\n        "
              ),
              !_vm.isStacked
                ? _c("base-icon", {
                    staticClass: "is-invisible",
                    attrs: { name: "heart-solid-icon", "icon-namespace": "ga" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showOverlay
            ? _c("progress-overlay", {
                attrs: {
                  visible: _vm.isFollowRequestProcessing,
                  "minimum-visible-time": 0,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }