var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.productData
    ? _c(
        "modal-box",
        {
          ref: "modal",
          attrs: { title: _vm.modalTitle, "is-visible": true },
          on: { "after-enter": _vm.onOpen },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass:
                          "gfu-link gfu-link--accent gfu-link--inline",
                        attrs: {
                          type: "button",
                          "data-qa": "modal-button:Cancel",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.close.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
                    ),
                    _vm._v(" "),
                    _c(
                      "tooltip",
                      {
                        staticClass: "_flexinline",
                        attrs: {
                          enabled: _vm.tooltipVisible,
                          text: _vm.tooltipContent,
                        },
                      },
                      [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              enabled: !_vm.isDisabled,
                              width: "wide",
                              theme: "accent",
                              "data-qa": "modal-button:Confirm",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.Resources.Common.ModalConfirmButton
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2456654825
          ),
        },
        [
          _vm.isStockLimitReached
            ? _c(
                "base-notificator",
                {
                  staticClass: "_my-3",
                  attrs: { "can-close": false, type: "warning" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.Resources.Product
                          .ProductValidationLimitReachedTooltipText
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isProductSet
            ? [
                _c(
                  "div",
                  { staticClass: "_bgc--light _pa-3 _rounded--common" },
                  [
                    _c(
                      "product-basic-info",
                      {
                        attrs: {
                          quantity: _vm.formattedQuantity,
                          "product-data": _vm.productData,
                          "price-with-options": _vm.priceWithOptionModifiers,
                          "effective-price-with-options":
                            _vm.effectivePriceWithOptionModifiers,
                        },
                      },
                      [
                        _c("number-field", {
                          attrs: {
                            "display-name":
                              _vm.Resources.YourPledge.CartTableQuantityHeader,
                            name: "model.quantity",
                            "text-align": "left",
                            min: 1,
                            maxlength: 5,
                            integer: true,
                            max: _vm.quantityLimit,
                            disabled: _vm.quantityChangeDisabled,
                            "data-qa": "modal-field:Quantity",
                          },
                          on: {
                            input: _vm.recalculateSetItemQuantitiesWithDebounce,
                          },
                          model: {
                            value: _vm.model.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "quantity", $$v)
                            },
                            expression: "model.quantity",
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasGroupedOptions && !_vm.isProductSetItemsVisible
                          ? _vm._l(_vm.groupedOptions, function (option) {
                              return _c("product-grouped-option-field", {
                                key: option.text,
                                attrs: {
                                  option: option,
                                  "set-items": option.setItems,
                                  "selected-option-value-id":
                                    option.selectedOptionValueId,
                                  "currency-symbol": _vm.currencySymbol,
                                  "relative-factor-with-commission":
                                    _vm.relativeFactorWithCommission,
                                  disabled: _vm.optionsChangeDisabled,
                                },
                                on: { change: _vm.onGroupedOptionsSelect },
                              })
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "_bgc--light _rounded--common _flexcol _mt-4",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "_flex _jc-sb _pa-2" },
                      [
                        _c(
                          "span",
                          { staticClass: "_tc--light" },
                          [
                            _c(
                              "localize",
                              {
                                attrs: {
                                  resource:
                                    _vm.Resources.Product
                                      .RewardCardSetItemTableHeader,
                                },
                              },
                              [
                                _c("span", { attrs: { "format-key": "0" } }, [
                                  _vm._v(_vm._s(_vm.pluralizedSetItemsCount)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.hasGroupedOptions
                          ? [
                              !_vm.isProductSetItemsVisible
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "_tc--accent",
                                      attrs: {
                                        href: "#",
                                        "data-qa": "modal-button:ShowItems",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onShowItems.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .AddToCartOptionsModalShowItemsInSetButton
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("base-icon", {
                                        attrs: { name: "angle-down" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.isProductSetItemsVisible
                      ? _vm._l(
                          _vm.productSetItemsDetailsModel,
                          function (product) {
                            return _c(
                              "product-set-item-details",
                              _vm._b(
                                {
                                  key: `product-set-item-${product.index}`,
                                  on: {
                                    "split-product-item":
                                      _vm.onSplitProductItem,
                                    "remove-product-item":
                                      _vm.onRemoveProductItem,
                                    "option-selected":
                                      _vm.setSelectedProductOption,
                                  },
                                },
                                "product-set-item-details",
                                product,
                                false
                              )
                            )
                          }
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _c(
                "div",
                { staticClass: "_tal" },
                [
                  _c(
                    "product-basic-info",
                    {
                      attrs: {
                        "product-data": _vm.productData,
                        "is-single-item": true,
                        quantity: _vm.formattedQuantity,
                        "price-with-options": _vm.priceWithOptionModifiers,
                        "effective-price-with-options":
                          _vm.effectivePriceWithOptionModifiers,
                      },
                    },
                    [
                      _c("number-field", {
                        attrs: {
                          "display-name":
                            _vm.Resources.YourPledge.CartTableQuantityHeader,
                          name: "model.quantity",
                          "text-align": "left",
                          min: 1,
                          maxlength: 5,
                          integer: true,
                          disabled: _vm.quantityChangeDisabled,
                          max: _vm.quantityLimit,
                          "data-qa": "modal-field:Quantity",
                        },
                        on: {
                          input: _vm.recalculateSetItemQuantitiesWithDebounce,
                        },
                        model: {
                          value: _vm.model.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "quantity", $$v)
                          },
                          expression: "model.quantity",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "_mt-2" },
                        _vm._l(
                          _vm.productData.options,
                          function (option, index) {
                            return _c(
                              "div",
                              {
                                key: `product-item-option-${index}`,
                                attrs: {
                                  "data-qa": "modal-field:ProductOption",
                                },
                              },
                              [
                                _c("product-option-field", {
                                  attrs: {
                                    option: option,
                                    "selected-option-value-id":
                                      option.productOptionValueID,
                                    "currency-symbol": _vm.currencySymbol,
                                    "relative-factor-with-commission":
                                      _vm.relativeFactorWithCommission,
                                    "label-class": "gfu-field__label--light",
                                    disabled: _vm.optionsChangeDisabled,
                                  },
                                  on: { change: _vm.setSelectedProductOption },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }