import Overlay from '@/Plugins/Overlay';

let modalCounter = 0;

const globalLayoutOverlay = new Overlay('body');

export function modalOpen() {
    if (modalCounter < 1) {
        globalLayoutOverlay.enable();
    }
    modalCounter++;
}

export function modalClose() {
    if (modalCounter <= 1) {
        globalLayoutOverlay.disable();
        modalCounter = 0;
    }
    else {
        modalCounter--;
    }
}

export default {
    close: modalClose,
    open: modalOpen,
    counter: modalCounter,
};
