var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "_mt-3 _ba-2 _rounded--common",
        { "_bc--input": !_vm.isSelected },
        { "_bc--primary": _vm.isSelected },
      ],
      attrs: { "data-qa": "payment-type:Money" },
    },
    [
      _c(
        "div",
        {
          class: ["_cur-p _pa-3"],
          on: {
            click: function ($event) {
              return _vm.onSelectPaymentType()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "_flex _ai-c" },
            [
              _c("checkbox-field", {
                staticClass: "_mt-0 _mr-2",
                attrs: {
                  "show-label": false,
                  name: "paymentTypeItem",
                  type: "radio",
                },
                model: {
                  value: _vm.isSelected,
                  callback: function ($$v) {
                    _vm.isSelected = $$v
                  },
                  expression: "isSelected",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "_flexcol" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.Resources.Checkout.CheckoutPaymentTypeMoneyLabel
                    ) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }