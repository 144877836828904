var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-homepage-wrapper _grid _grid-cols-1 _grid-cols-24--l _grid-cols-24--xl _row-gap-6",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "_col-span-1 _col-span-5--l _col-span-4--xl _order-9 _order-1--l _flexcol _ai-st",
        },
        [
          _c(
            "home-section-title",
            { staticClass: "_mb-3", attrs: { "icon-name": "grid-horizontal" } },
            [_vm._v(_vm._s(_vm.Resources.Homepage.ProjectListsSectionTitle))]
          ),
          _vm._v(" "),
          _c("home-project-list"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "_col-span-1 _col-span-13--l _col-span-15--xl _order-1 _order-2--l gfu-homepage-featured-projects",
          attrs: { "data-qa": "homepage-section:Featured" },
        },
        [
          _c(
            "home-section-title",
            {
              staticClass: "gfu-homepage-featured-projects__title",
              attrs: { "icon-name": "fire" },
            },
            [_vm._v(_vm._s(_vm.Resources.Homepage.FeaturedSectionTitle))]
          ),
          _vm._v(" "),
          _c("featured-projects"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "_col-span-1 _col-span-6--l _col-span-5--xl _order-8 _order-3--l _flexcol _ai-st",
          attrs: { "data-qa": "homepage-section:Spotlight" },
        },
        [
          _c(
            "home-section-title",
            { staticClass: "_mb-3", attrs: { "icon-name": "lighthouse" } },
            [_vm._v(_vm._s(_vm.Resources.Homepage.SpotlightSectionTitle))]
          ),
          _vm._v(" "),
          _c("spotlight"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasBanner
        ? _c(
            "div",
            { staticClass: "_col-span-1 _col-span-24--l _order-2 _order-4--l" },
            [
              _c("announcements", {
                attrs: {
                  "preview-banner-id": _vm.previewBannerId,
                  "data-qa": "home-page-box:InfoBanners",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "_col-span-1 _col-span-24--l _order-4 _order-5--l",
          attrs: { "data-qa": "homepage-section:Explore" },
        },
        [
          _c(
            "home-section-title",
            { attrs: { "icon-name": "magnifying-glass" } },
            [_vm._v(_vm._s(_vm.Resources.Homepage.ExploreSectionTitle))]
          ),
          _vm._v(" "),
          _c("explore-projects-section"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "_col-span-1 _col-span-24--l _order-5 _order-6--l",
          attrs: { "data-qa": "homepage-section:Crowdfunding" },
        },
        [
          _c(
            "crowdfunding-projects-section",
            [
              _c("home-section-title", { attrs: { "icon-name": "rocket" } }, [
                _vm._v(
                  _vm._s(
                    _vm.Resources.Homepage.CrowdfundingProjectsSectionTitle
                  )
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "_col-span-1 _col-span-24--l _order-6 _order-7--l",
          attrs: { "data-qa": "homepage-section:Upcoming" },
        },
        [
          _c(
            "upcoming-projects-section",
            [
              _c("home-section-title", { attrs: { "icon-name": "clock" } }, [
                _vm._v(_vm._s(_vm.Resources.Homepage.UpcomingSectionTitle)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "_col-span-1 _col-span-24--l _order-7 _order-8--l",
          attrs: { "data-qa": "homepage-section:LatePledge" },
        },
        [
          _c(
            "late-pledge-projects-section",
            [
              _c(
                "home-section-title",
                { attrs: { "icon-name": "shopping-bag" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.Homepage.LatePledgeProjectsSectionTitle
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "_col-span-1 _col-span-24--l _order-3 _order-9--l",
          attrs: { "data-qa": "homepage-section:Rankings" },
        },
        [
          _c("projects-ranking", {
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _c(
                      "home-section-title",
                      { attrs: { "icon-name": "trophy" } },
                      [
                        _vm._v(
                          _vm._s(_vm.Resources.Homepage.RankingsSectionTitle)
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_col-span-1 _col-span-24--l _order-10" },
        [_c("home-platform-statistics")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }