var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-table-of-contents" }, [
    _c("div", { staticClass: "gfu-table-of-contents__wrapper" }, [
      _c(
        "nav",
        {
          staticClass: "gfu-table-of-contents__list gfu-list",
          attrs: { "data-qa": "crowdfunding-description-navigation" },
        },
        [
          _vm._l(_vm.pageSections, function (section, index) {
            return [
              _c(
                "router-link",
                {
                  key: section.urlName,
                  class: [
                    "gfu-table-of-contents__item gfu-list-item",
                    {
                      "_pt-0": index === 0,
                      "_pb-0": index === _vm.pageSections.length - 1,
                    },
                  ],
                  attrs: {
                    "active-class": "is-active",
                    to: {
                      name: "page-section",
                      params: { section: section.urlName },
                    },
                    "data-qa": `sidebar-category-section:${section.name}`,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(section.name) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              section.sections && section.sections.length
                ? _c(
                    "nav",
                    { key: `nav-${section.urlName}` },
                    _vm._l(section.sections, function (subSection) {
                      return _c(
                        "router-link",
                        {
                          key: `${section.urlName}/${subSection.urlName}`,
                          staticClass:
                            "gfu-table-of-contents__item gfu-list-subitem",
                          attrs: {
                            "active-class": "is-active",
                            to: {
                              name: "page-section",
                              params: {
                                section: section.urlName,
                                subSection: subSection.urlName,
                              },
                            },
                            "data-qa": `sidebar-category-section:${subSection.name}`,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(subSection.name) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }