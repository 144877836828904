var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-layout-wrapper gfu-layout-wrapper--extranarrow _py-5" },
    [
      _c(
        "span",
        {
          staticClass: "gfu-project-banner__title _mb-1",
          attrs: { "data-qa": "project-banner-title" },
        },
        [
          _c(
            "a",
            {
              staticClass: "gfu-link gfu-link--nofx",
              attrs: {
                href: _vm.projectHomeUrl,
                "data-qa": "project-banner-title-url",
              },
            },
            [_vm._v(_vm._s(_vm.projectName))]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          class: [
            "gfu-project-banner__title-annotation",
            {
              "gfu-project-banner__title-annotation--has-image":
                _vm.hasCreatorImage,
            },
          ],
        },
        [
          _c(
            "localize",
            {
              attrs: {
                resource: _vm.Resources.ProjectHome.ProjectHeaderByCreatorTitle,
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--nofx",
                  attrs: {
                    "format-key": "0",
                    href: _vm.creatorUrl,
                    "data-qa": "project-properties:AvatarUrl",
                  },
                },
                [
                  _vm.hasCreatorImage
                    ? _c("img", {
                        staticClass:
                          "gfu-project-banner__title-annotation-image",
                        attrs: { src: _vm.creatorImage, alt: _vm.creatorName },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "gfu-project-banner__title-annotation-text",
                      attrs: { "data-qa": "project-properties:CreatorName" },
                    },
                    [_vm._v(_vm._s(_vm.creatorName))]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gfu-project-banner__description",
          attrs: { "data-qa": "project-banner-description" },
        },
        [_vm._v("\n        " + _vm._s(_vm.projectShortDescription) + "\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }