var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flexcol" },
    [
      _vm._l(_vm.availableProviders, function (provider) {
        return _c(
          "base-button",
          {
            key: provider.name,
            staticClass: "gfu-btn-signing _flexbtwn _ai-c",
            on: {
              click: function ($event) {
                return _vm.onSignIn(provider.instance, provider.type)
              },
            },
          },
          [
            _c("base-image", {
              attrs: {
                src: provider.imageUrl,
                alt: "Provider logo",
                width: "24",
                height: "24",
              },
            }),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.Resources.format(
                    _vm.Resources.Account.ExternalLoginProviderChoiceButton,
                    provider.name
                  )
                ) +
                "\n        "
            ),
            _c("span", { staticClass: "_fa _fa--chevron-right _tc--accent" }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.availableProviders.length > 0
        ? _c("span", { staticClass: "_tc--lighter _flexcntr _mt-3" }, [
            _vm._v("\n        " + _vm._s(_vm.messageViewType) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }