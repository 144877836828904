export default class StatisticsInterval {
    constructor({
        keys = [],
        steps = 3,
        duration = 12, // Default value, ensure it executed before 15s request from server.
    }) {
        this.interval = null;
        this.currentTargetValue = null;
        this.keys = keys;
        this.steps = steps;
        this.duration = duration * 1000 / this.steps;
    }

    getDifference(initialValue, targetValue) {
        return this.keys.reduce((acc, key) => {
            acc[key] = Math.floor((targetValue[key] - initialValue[key]) / this.steps);
            return acc;
        }, {});
    }

    getSteps(initialValue, targetValue) {
        const diff = this.getDifference(initialValue, targetValue);
        const steps = Array.apply(initialValue, { length: this.steps - 1 })
            .map((_, index) => {
                const model = {};
                Object.keys(diff).forEach(key => {
                    model[key] = initialValue[key] + diff[key] * (index + 1);
                });
                return { ...initialValue, ...model };
            });

        steps.push(targetValue);

        return steps;
    }

    valuesAreDifferent(initialValue, targetValue) {
        return this.keys.filter(key => initialValue[key] !== targetValue[key]).length > 0;
    }

    clearInterval() {
        clearInterval(this.interval);
        this.interval = null;
        this.currentTargetValue = null;
    }

    update({ initialValue, targetValue, onUpdate = () => { } }) {
        let currentIndex = 0;
        const steps = this.getSteps(initialValue, targetValue);

        if (this.valuesAreDifferent(initialValue, targetValue)) {

            if (this.interval) {
                onUpdate(this.currentTargetValue);
                this.clearInterval();
            }

            this.interval = setInterval(() => {
                const targetModel = steps[currentIndex];
                onUpdate(targetModel);

                if (currentIndex >= steps.length - 1) {
                    this.clearInterval();
                }

                currentIndex++;
            }, this.duration);

            this.currentTargetValue = { ...targetValue };
        }
    }
}