var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-card gfu-product-card",
      attrs: { "data-qa": `product-card:${_vm.productID}` },
    },
    [
      _vm.badges.length > 0
        ? _c(
            "div",
            { staticClass: "gfu-product-card__tag-container _ml-3" },
            _vm._l(_vm.badges, function (badge, index) {
              return _c(
                "base-badge",
                { key: index, staticClass: "gfu-product-card__tag" },
                [_vm._v("\n            " + _vm._s(badge) + "\n        ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-card__wrap" },
        [
          _c(
            "context-link",
            _vm._b(
              { staticClass: "gfu-embed gfu-embed--1x1" },
              "context-link",
              _vm.contextLinkProps,
              false
            ),
            [
              _c("base-image", {
                staticClass: "gfu-embed__item",
                attrs: { loading: "lazy", src: _vm.imageUrl, alt: _vm.name },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "context-link",
            _vm._b(
              {
                class: {
                  "gfu-product-card__body": _vm.showAbstract,
                  "gfu-product-card__distinction": !_vm.showAbstract,
                },
              },
              "context-link",
              _vm.contextLinkProps,
              false
            ),
            [
              _c(
                "h3",
                {
                  class: [
                    "gfu-product-card__title gfu-heading",
                    {
                      "gfu-product-card__distinction-title": !_vm.showAbstract,
                    },
                  ],
                  attrs: { "data-qa": "product-name" },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.name) + "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.showAbstract
                ? _c(
                    "div",
                    { staticClass: "gfu-card__desc gfu-product-card__desc" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.abstract) +
                          "\n                "
                      ),
                      _c("span", {
                        staticClass: "gfu-product-card__desc-fade",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("display-currency-price", {
                attrs: {
                  "effective-price": _vm.effectivePrice,
                  price: _vm.price,
                  "is-discounted": _vm.isDiscounted,
                  "data-qa": "product-price",
                },
                scopedSlots: _vm._u([
                  {
                    key: "effective-price",
                    fn: function ({ effectivePrice }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "gfu-price gfu-price--text-base",
                            class: [
                              { "gfu-price--discounted": _vm.isDiscounted },
                            ],
                            attrs: { "data-qa": "price-type:Effective" },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(effectivePrice) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "price",
                    fn: function ({ price }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass:
                              "gfu-price gfu-price--old gfu-price--tiny _fw-n _ml-1",
                            attrs: { "data-qa": "price-type:Old" },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(price) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.isDiscounted
                ? _c(
                    "localize",
                    {
                      staticClass: "gfu-bt gfu-bt--caption _tc--lighter",
                      attrs: {
                        resource:
                          _vm.Resources.Product
                            .RewardCardLowestPriceInLast30Days,
                        tag: "div",
                      },
                    },
                    [
                      _c("display-currency-price", {
                        attrs: {
                          "format-key": "0",
                          "effective-price": _vm.effectivePrice,
                          price: _vm.price,
                          tag: "span",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "effective-price",
                              fn: function ({ effectivePrice }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "_mx-1",
                                      attrs: {
                                        "data-qa":
                                          "price-type:ThirtyDaysLowest",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(effectivePrice) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          173952906
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasAction
            ? _c(
                "div",
                {
                  staticClass: "_mt-a",
                  attrs: { "data-qa": "product-box-action" },
                },
                [_vm._t("action")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }