import ServiceBase from './ServiceBase';

export default class PlatformEventsService extends ServiceBase {
    constructor($http) {
        super($http);
    }

    getSpotlightPlatformEvents(params) {
        return this.request('get', 'platformEvents/getSpotlightPlatformEvents', { ...params } );
    }
}
