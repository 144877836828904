var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("display-currency-price", {
    staticClass: "_fw-eb gfu-bt--b1 _whs-nw _tc--base",
    attrs: {
      tag: "span",
      "effective-price": _vm.price,
      "reverse-tooltip-position": true,
    },
    scopedSlots: _vm._u([
      {
        key: "effective-price",
        fn: function ({ effectivePrice }) {
          return [_vm._v("\n        " + _vm._s(effectivePrice) + "\n    ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }