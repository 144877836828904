var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_bgc--light _rounded--common _pa-1 _flex _gap-2 _jc-st" },
    [
      _c(
        "router-link",
        { attrs: { to: _vm.to, title: _vm.name } },
        [
          _c("base-image", {
            staticClass: "_rounded--input",
            attrs: {
              src: _vm.imageUrl,
              alt: _vm.name,
              loading: "lazy",
              width: "72",
              height: "72",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grow-1" }, [
        _c(
          "h5",
          { staticClass: "gfu-hd gfu-hd--h4" },
          [
            _c("router-link", { attrs: { to: _vm.to, title: _vm.name } }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-bt gfu-bt--caption _tc--light" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.Resources.YourPledge.CartTableQuantityHeader) +
              ": " +
              _vm._s(_vm.quantity) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gfu-bt gfu-bt--caption _tc--light" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.optionsFormatted) + "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.hasError
        ? _c(
            "div",
            { staticClass: "_flex _ai-c _ml-a _px-2" },
            [
              _c(
                "tooltip",
                {
                  attrs: {
                    text: _vm.errorMessage,
                    "data-qa": "quantity-error-warning-icon-tooltip",
                  },
                },
                [
                  _c("base-icon", {
                    staticClass: "_tc--error",
                    attrs: {
                      name: "triangle-exclamation",
                      "data-qa":
                        "cart-table-item-row-quantity-error:WarningIcon",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }