var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "gfu-fields" }, [
        _c(
          "fieldset",
          [
            _c(
              "field",
              {
                attrs: {
                  name: "model.email",
                  "display-name": _vm.Resources.Account.EmailFieldLabel,
                  "data-qa": "create-account:email",
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.email,
                      expression: "model.email",
                    },
                  ],
                  staticClass: "gfu-field__input",
                  attrs: {
                    type: "email",
                    name: "email",
                    "data-qa": "text-field-input",
                  },
                  domProps: { value: _vm.model.email },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.onSubmit.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.model, "email", $event.target.value)
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_mt-3 _tac" },
              [
                _c(
                  "field",
                  [
                    _c(
                      "base-button",
                      {
                        attrs: {
                          type: "submit",
                          width: "wide",
                          theme: "accent",
                          "data-qa": "create-account-button:Continue",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Resources.Account.ContinueActionButton)
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_mt-3 _tac" },
              [
                _c(
                  "localize",
                  {
                    attrs: {
                      resource:
                        _vm.Resources.Account.SignUpConsequenceIntroParagraph,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "gfu-link gfu-link--accent",
                        attrs: {
                          "format-key": "0",
                          href: _vm.privacyPolicyUrl,
                          "data-qa": "create-account:PrivacyPolicy",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.Account
                              .SignUpConsequencePrivacyPolicyLink
                          )
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "_mt-3 _tac",
                attrs: {
                  "data-qa": "login-form:AlreadyHaveAnAccountLogInLink",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "gfu-link gfu-link--accent",
                    attrs: { href: _vm.loginUrl },
                  },
                  [_vm._v(_vm._s(_vm.Resources.Account.LogInLink))]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "_mt-3 _tac",
                attrs: { "data-qa": "login-form:ForgotPassword" },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "gfu-link gfu-link--accent",
                    attrs: { href: _vm.resetPasswordUrl },
                  },
                  [_vm._v(_vm._s(_vm.Resources.Account.ForgotPasswordLink))]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }