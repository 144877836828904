var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("project-test-mode-bar", {
        attrs: {
          "current-testing-phase": _vm.currentTestingPhase,
          "testing-user-groups": _vm.testingUserGroups,
          "unpublished-content-visible": _vm.isUnpublishedContextVisible,
          "category-names-map": _vm.categoryNamesMap,
        },
        on: {
          "open-filters": _vm.onOpenFilterDrawer,
          "open-modal": _vm.onModalOpen,
          "close-tester-mode": _vm.closeTesterMode,
        },
      }),
      _vm._v(" "),
      _c("project-test-mode-filters", {
        attrs: {
          "is-drawer-open": _vm.isFilterDrawerOpen,
          "user-groups": _vm.userGroups,
          "original-phase": _vm.originalPhase,
          "current-testing-phase": _vm.currentTestingPhase,
          "project-id": _vm.projectId,
          "testing-user-groups": _vm.testingUserGroups,
          "is-unpublished-context-visible": _vm.isUnpublishedContextVisible,
          "category-names-map": _vm.categoryNamesMap,
          "project-url": _vm.projectUrl,
        },
        on: { "drawer-close": _vm.onCloseFilterDrawer },
      }),
      _vm._v(" "),
      _c("project-test-mode-modal", {
        attrs: { "is-modal-visible": _vm.isModalVisible },
        on: { close: _vm.onModalClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }