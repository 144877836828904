var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      !_vm.readOnly
        ? _c(
            "input",
            _vm._b(
              {
                ref: "dateInput",
                class: [
                  "gfu-field__input",
                  { "gfu-field__input--small": _vm.small },
                ],
                attrs: {
                  name: _vm.name,
                  type: "date",
                  pattern: "\\d{4}-\\d{2}-\\d{2}",
                  placeholder: "YYYY-MM-DD",
                  "data-qa": "date-field",
                },
                on: {
                  blur: _vm.onBlur,
                  keyup: _vm.onKeyUp,
                  input: _vm.handleDateInputValueChange,
                },
              },
              "input",
              { min: _vm.minDate, max: _vm.maxDate },
              false
            )
          )
        : _c("input", {
            staticClass: "gfu-field__input gfu-field__input--readonly",
            attrs: {
              type: "text",
              readonly: "readonly",
              name: "model.personalInfo.nationalityLocationID",
              "data-qa": "date-field",
            },
            domProps: {
              value: _vm.useValueAsDate
                ? _vm.$format(_vm.model, "d")
                : _vm.model,
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }