import { loadScript } from '@/Utils/ScriptUtility';
import EventEmitter from '@/Utils/EventEmitter';
import { externalScripts } from '@/Config';

export const youTubeEvents = {
    playing: 'playing',
    paused: 'paused',
    pause: 'pause',
};

const youTubeApiScriptUrl = externalScripts.youTubeApiScriptUrl;

class YouTubeApiLoader {
    constructor() {
        this.scriptUrl = youTubeApiScriptUrl;
        if (YouTubeApiLoader.instance) {
            return YouTubeApiLoader.instance;
        }

        // Load the YouTube iframe API script
        this.loadScript();

        // Create a promise to track when the API is ready
        this.apiReady = new Promise((resolve) => {
            window.onYouTubeIframeAPIReady = () => {
                this.isReady = true;
                resolve();
            };
        });

        YouTubeApiLoader.instance = this;
    }

    async loadScript() {
        try {
            const script = await loadScript(youTubeApiScriptUrl);
            return script;
        }
        catch {
            throw new Error('Cannot load YouTube API script.');
        }
    }

    static getInstance() {
        return YouTubeApiLoader.instance || new YouTubeApiLoader();
    }
}

export class YouTubePlayerElement {
    constructor(element) {
        this.element = element;
        this.eventEmitter = new EventEmitter();
        this.status = null;

        this.attachEventHandlers();

        YouTubeApiLoader.getInstance().apiReady.then(() => {
            this.attachYouTubePlayer();
        });
    }

    attachEventHandlers() {
        this.eventEmitter.on('pause', () => {
            this.currentVideo.pauseVideo();
        });
    }

    attachYouTubePlayer() {
        this.currentVideo = new window.YT.Player(this.element, {
            events: {
                'onStateChange': (event) => {
                    if (event.data === window.YT.PlayerState.PLAYING) {
                        this.eventEmitter.emit(youTubeEvents.playing);
                        this.status = youTubeEvents.playing;
                    }
                    else if (event.data === window.YT.PlayerState.PAUSED) {
                        this.eventEmitter.emit(youTubeEvents.paused);
                        this.status = youTubeEvents.paused;
                    }
                },
            },
        });
    }
}