var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-box gfu-box--flat gfu-project-summary-box",
        {
          "gfu-box--fill": _vm.fill,
          "gfu-project-summary-box--borderless": _vm.borderless,
        },
      ],
      attrs: { "data-qa": "project-summary-box:PledgeManager" },
    },
    [
      _vm.$scopedSlots.sticky
        ? _c(
            "div",
            {
              staticClass: "gfu-project-summary-box__sticky",
              attrs: { "data-qa": "project-summary-box:Sticky" },
            },
            [_vm._t("sticky")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$scopedSlots.header
        ? _c(
            "div",
            {
              staticClass: "gfu-project-summary-box__header",
              attrs: { "data-qa": "project-summary-box:Header" },
            },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.$scopedSlots.actions
        ? _c(
            "div",
            {
              staticClass: "gfu-project-summary-box__actions",
              attrs: { "data-qa": "project-summary-box:Actions" },
            },
            [_vm._t("actions")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }