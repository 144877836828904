var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-project-summary-pledge" },
    [
      _c("project-summary-box", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "gfu-1of1" },
                  [
                    _c("base-icon", {
                      staticClass: "_tc--accent",
                      attrs: {
                        name: "gamefound-outline",
                        "icon-namespace": "ga",
                        size: "2x",
                      },
                    }),
                    _vm._v(" "),
                    _c("project-summary-box-header", {
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .ProjectSummaryBoxPreorderHeader
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "subheader",
                          fn: function () {
                            return [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.Resources.ProjectHome
                                      .ProjectSummaryBoxPreorderSubHeader
                                  ),
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _vm.isCreditsVisible ? _c("project-summary-credits") : _vm._e(),
                _vm._v(" "),
                !_vm.hasAnyOrder && _vm.isPledgeAvailable
                  ? _c(
                      "div",
                      { staticClass: "gfu-project-summary-action" },
                      [_c("back-project-button")],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasAnyOrder ? _c("project-summary-follow") : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }