export default class Confettiful {
    constructor(el, maxConfettiCount) {
        this.el = el;
        this.containerEl = null;

        this.confettiFrequency = 3;
        this.confettiColors = ['#fce18a', '#ff726d', '#b48def', '#f4306d'];
        this.confettiAnimations = ['slow', 'medium', 'fast'];
        this.maxConfettiCount = maxConfettiCount || 150;
        this.confettiCounter = 0;

        this._setupElements();
        this._renderConfetti();
    }

    _setupElements() {
        const containerEl = document.createElement('div');
        containerEl.classList.add('confettiful-container');
        this.el.appendChild(containerEl);
        this.containerEl = containerEl;
    }

    _renderConfetti() {
        this.confettiInterval = setInterval(() => {
            if (this.confettiCounter < this.maxConfettiCount) {
                const confettiEl = document.createElement('div');
                const confettiSize = `${ Math.floor(Math.random() * 3) + 7 }px`;
                const confettiBackground = this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)];
                const confettiLeft = `${ Math.floor(Math.random() * this.el.offsetWidth) }px`;
                const confettiAnimation = this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)];

                confettiEl.classList.add('confettiful-confetti', `confettiful-confetti--animation-${ confettiAnimation }`);
                confettiEl.style.left = confettiLeft;
                confettiEl.style.width = confettiSize;
                confettiEl.style.height = confettiSize;
                confettiEl.style.backgroundColor = confettiBackground;

                confettiEl.removeTimeout = setTimeout(function () {
                    confettiEl.parentNode.removeChild(confettiEl);
                }, 3000);

                this.containerEl.appendChild(confettiEl);

                this.confettiCounter++;
            }
        }, 25);
    }
}