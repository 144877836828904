var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "drawer",
        {
          attrs: { "is-open": _vm.isDrawerOpen },
          on: { close: _vm.onCancel },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "_flex _ai-c _jc-fe _pa-2" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "_tc--accent _ttl _px-0",
                          attrs: { "data-qa": "adjust-preview:Cancel" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "base-button",
                        {
                          staticClass: "_ml-3",
                          attrs: {
                            theme: "accent",
                            width: "compact",
                            "data-qa": "adjust-preview:Apply",
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _vm._v(
                            "\n                    Apply\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "_pt-3 _pb-3 _px-3 _tal" },
            [
              _c("h2", { staticClass: "gfu-hd gfu-hd--h1 _mb-3" }, [
                _vm._v("Adjust preview"),
              ]),
              _vm._v(" "),
              _c("option-field", {
                staticClass: "_mb-3",
                attrs: {
                  options: _vm.projectPhaseOptions,
                  "read-only": _vm.projectPhaseIsReadOnly,
                  "display-name": "Project phase",
                  description:
                    "You will see the project as if it were in the selected phase.",
                  "data-qa": "adjust-preview:ProjectPhase",
                },
                model: {
                  value: _vm.model.projectPhase,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "projectPhase", $$v)
                  },
                  expression: "model.projectPhase",
                },
              }),
              _vm._v(" "),
              _c("multi-checkbox-field", {
                staticClass: "_mb-3",
                attrs: {
                  options: _vm.userGroupsOptions,
                  "read-only": _vm.userGroupsIsReadOnly,
                  placeholder: _vm.userGroupsIsReadOnly
                    ? "No user groups available"
                    : "Please select...",
                  "no-option-capitalize": true,
                  "display-name": "User groups",
                  description:
                    "You will see the project as if you were a member of the selected user groups.",
                  "data-qa": "adjust-preview:UserGroups",
                },
                model: {
                  value: _vm.model.userGroupIDs,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "userGroupIDs", $$v)
                  },
                  expression: "model.userGroupIDs",
                },
              }),
              _vm._v(" "),
              _vm.selectedUserGroupsNames.length > 0
                ? _c(
                    "div",
                    { staticClass: "_flexwrap _mt-2 _mb-3" },
                    _vm._l(
                      _vm.selectedUserGroupsNames,
                      function ({ value, label }) {
                        return _c(
                          "base-button",
                          {
                            key: value,
                            staticClass: "_mr-1 _mb-1",
                            attrs: {
                              width: "compact",
                              theme: "searchSecondary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onCategoryDelete(value)
                              },
                            },
                          },
                          [
                            _c("strong", { staticClass: "_ttl" }, [
                              _vm._v(_vm._s(label)),
                            ]),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "_fa _fa--window-close _ml-1",
                            }),
                          ]
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _tc--base" }, [
                _vm._v("Content visibility options"),
              ]),
              _vm._v(" "),
              _c(
                "checkbox-field",
                {
                  staticClass: "_mt-0",
                  attrs: {
                    condensed: true,
                    "data-qa": "adjust-preview:ShowUnpublishedContent",
                  },
                  model: {
                    value: _vm.model.showUnpublishedContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "showUnpublishedContent", $$v)
                    },
                    expression: "model.showUnpublishedContent",
                  },
                },
                [
                  _vm._v(
                    "\n                show unpublished content\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("project-test-model-phase-changed-warning-modal", {
        attrs: {
          "is-modal-visible":
            _vm.isProjectTestModelPhaseChangedWarningModalVisible,
        },
        on: { close: _vm.onModalClose, continue: _vm.toggleTestingMode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }