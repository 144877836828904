import { defineStore } from 'pinia';
import { projectPhases, projectCampaignOutcomes, projectTypes, projectVersions } from '@/Models';
import InitialState from '@/InitialState';

export const useProjectContextStore = defineStore({
    id: 'projectContext',
    state: () => ({
        projectContext: InitialState.projectContext,
    }),
    actions: {
        updateProjectContext(state, projectContext) {
            state.projectContext = projectContext;
        },
    },
    getters: {
        isProjectInDraftPhase: state => {
            return state.projectContext.project?.phase === projectPhases.crowdfundingDraft;
        },
        isProjectInLatePledgePhase: state => {
            return state.projectContext.project?.phase === projectPhases.latePledge;
        },
        isProjectInCrowdfundingPhase: state => {
            return state.projectContext.project?.phase === projectPhases.crowdfunding;
        },
        isProjectInCrowdfundingEndedPhase: state => {
            return state.projectContext.project?.phase === projectPhases.crowdfundingEnded;
        },
        isProjectInPledgeManagerPhase: state => {
            return state.projectContext.project?.phase === projectPhases.pledgeManager;
        },
        isProjectInFundingPhase: state => { // @todo: find better name, late pledge ain't really funding phase
            return [projectPhases.crowdfunding, projectPhases.latePledge]
                .includes(state.projectContext.project?.phase);
        },
        isProjectInPhasePreview: state => {
            return state.projectContext && state.projectContext.snapshotPhase !== null;
        },
        isProjectCampaignFunded: state => {
            return state.projectContext.project?.campaignOutcome === projectCampaignOutcomes.success;
        },
        isProjectRetailType: state => {
            return [projectTypes.store, projectTypes.preorder].includes(state.projectContext.project?.originalType);
        },
        isStore: state => {
            return state.projectContext.project?.originalType === projectTypes.store;
        },
        // TODO: it is used currently to determine if installment plan can be paid off
        // it should be refactored to get validation from backend: CheckoutManager.ValidateInstallmentsCanBePaidOff
        // add this flag to projectContext.installmentsSettings
        isExternalCampaign: state => {
            return state.projectContext.project?.originalType !== projectTypes.crowdfunding;
        },
        isV2: state => {
            return state.projectContext.project?.version === projectVersions.v2;
        },
        effectivePhase: state => {
            return state.projectContext.snapshotPhase ?? state.projectContext.project?.phase;
        },
        projectID: state => state.projectContext.projectID,
        hasEarlyShippingCollectionEnabled: state => {
            return state.projectContext?.settings.earlyShippingCollectionEnabled;
        },
        isDccEnabled: state => state.projectContext?.isDccEnabled,
        installmentsSettings: state => state.projectContext?.installmentsSettings,
        isOpenForOrders: state => state.projectContext?.project.isOpenForOrders,
    },
});