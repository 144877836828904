import { defineStore } from 'pinia';

export const useAdminFormStore = defineStore({
    id: 'adminForm',
    state() {
        return {
            hasUnsavedChanges: false,
        };
    },
    actions: {
        setHasUnsavedChanges(hasUnsavedChanges) {
            this.hasUnsavedChanges = hasUnsavedChanges;
        },
    },
});