var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "gfu-field gfu-comment-streams__search-input-container _mt-0",
          class: {
            "gfu-comment-streams__search-input-container--active":
              _vm.isSelecting && !_vm.isSearching,
          },
        },
        [
          _c("button", {
            staticClass:
              "gfu-search-form-fields__search-button gfu-btn _fa _fa--search",
            attrs: { "data-qa": "comments-streams-button:Search" },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.term,
                expression: "model.term",
              },
            ],
            ref: "input",
            staticClass: "gfu-field__input gfu-search-form-fields__input",
            class: { "gfu-comment-streams__search-input--alt": _vm.isMobile },
            attrs: {
              placeholder: _vm.Resources.Comments.SearchTagsFieldPlaceholder,
              type: "text",
              name: "term",
              autocomplete: "off",
              "data-qa": "comments-streams-field:Search",
            },
            domProps: { value: _vm.model.term },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "term", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("button", {
            staticClass:
              "gfu-search-form-fields__close-button gfu-btn _fa _fa--times",
            attrs: { "data-qa": "comments-streams-button:ResetSearch" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.resetSearch.apply(null, arguments)
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-comment-streams__suggestions-wrapper" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSelecting && !_vm.isSearching,
                expression: "isSelecting && !isSearching",
              },
            ],
            staticClass: "gfu-comment-streams__suggestions",
            class: { "gfu-comment-streams__suggestions--alt": _vm.isMobile },
            attrs: { "data-qa": "comments-streams-suggestions" },
          },
          [
            _c(
              "ul",
              [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.isTermValidTag &&
                          !_vm.searchTags.includes(_vm.sanitizedTagSearchTerm),
                        expression:
                          "isTermValidTag && !searchTags.includes(sanitizedTagSearchTerm)",
                      },
                    ],
                    attrs: { "data-qa": "comments-streams-suggestions:GoTo" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onTagSelected(_vm.model.term)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        return _vm.onTagSelected(_vm.model.term)
                      },
                    },
                  },
                  [
                    _c(
                      "localize",
                      {
                        key: _vm.sanitizedTagSearchTerm,
                        attrs: {
                          resource:
                            _vm.Resources.Comments.TagSearchGoToTagResult,
                        },
                      },
                      [
                        _c("span", { attrs: { "format-key": "0" } }, [
                          _vm._v(_vm._s(_vm.sanitizedTagSearchTerm)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.searchTags, function (tag) {
                  return _c(
                    "li",
                    {
                      key: `tag-${tag}`,
                      attrs: {
                        "data-qa": `comments-streams-suggestions:${tag}`,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onTagSelected(tag)
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          )
                            return null
                          $event.preventDefault()
                          return _vm.onTagSelected(tag)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    #" +
                          _vm._s(tag) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.searchTags.length,
                        expression: "!searchTags.length",
                      },
                    ],
                    staticClass: "gfu-comment-streams__suggestions--no-matches",
                    attrs: {
                      "data-qa": "comments-streams-suggestions:NoMatches",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.Resources.Comments.TagSearchNoMatchesResult
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }