var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "span",
        {
          ref: "trigger",
          staticClass: "gfu-floating-content-trigger",
          attrs: {
            "data-value": _vm.guid,
            "data-qa": "floating-content:trigger",
          },
          on: { mouseover: _vm.onMouseOver, mouseleave: _vm.onMouseLeave },
        },
        [_vm._t("trigger")],
        2
      ),
      _vm._v(" "),
      _vm.isContentVisible
        ? [
            _c("teleport", { attrs: { to: "#floating-content" } }, [
              _c(
                "div",
                {
                  ref: "content",
                  staticClass: "gfu-floating-content",
                  attrs: {
                    "data-value": _vm.guid,
                    "data-qa": "floating-content:content",
                  },
                  on: {
                    mouseover: _vm.onMouseOver,
                    mouseleave: _vm.onMouseLeave,
                  },
                },
                [_vm._t("content")],
                2
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }