var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-test-mode-bar",
      attrs: { "data-qa": "project-test-mode-info" },
    },
    [
      _c("div", { staticClass: "_tc--white" }, [
        _c(
          "p",
          { staticClass: "_mt-0" },
          [
            _vm._v("\n            Testing: "),
            _c(
              "strong",
              { attrs: { "data-qa": "testing-bar:CurrentTestingPhaseName" } },
              [_vm._v(_vm._s(_vm.currentTestingPhaseName))]
            ),
            _vm._v(".\n            "),
            _vm.testingUserGroups.length
              ? [
                  _vm._v("\n                User group: "),
                  _c(
                    "strong",
                    {
                      attrs: {
                        "data-qa": "testing-bar:TestingUserGroupsNames",
                      },
                    },
                    [_vm._v(_vm._s(_vm.testingUserGroupsNames))]
                  ),
                  _vm._v(".\n            "),
                ]
              : _vm._e(),
            _vm._v("\n            Unpublished content: "),
            _c(
              "strong",
              {
                attrs: {
                  "data-qa": "testing-bar:UnpublishedContentVisibleLabel",
                },
              },
              [_vm._v(_vm._s(_vm.unpublishedContentVisibleLabel))]
            ),
            _vm._v(".\n        "),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "_mt-2" }, [
          _c(
            "button",
            {
              staticClass: "_tdu _pa-0 _mr-2",
              attrs: { "data-qa": "testing-bar:LearnMore" },
              on: { click: _vm.onOpenModal },
            },
            [_vm._v("learn more")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "_tdu _pa-0",
              attrs: { "data-qa": "testing-bar:QuitTesterMode" },
              on: { click: _vm.onCloseTesterMode },
            },
            [_vm._v("quit tester mode")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "_ttr _as-fs _ml-2",
          attrs: {
            theme: "searchPrimary",
            width: "compact",
            weight: "thin",
            shadow: "tight",
            shape: _vm.buttonShape,
            "data-qa": "testing-bar:AdjustPreview",
          },
          on: { click: _vm.onFiltersClick },
        },
        [
          _c("base-icon", {
            staticClass: "_tc--accent",
            attrs: { name: "sliders-h" },
          }),
          _vm._v(" "),
          !_vm.isMobile
            ? _c("strong", { staticClass: "_ml-1" }, [_vm._v("adjust preview")])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }