var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-embed gfu-embed--16x9 _flexcntr _ai-c" },
    [
      _c("transition", { attrs: { name: "gfu-fade", mode: "out-in" } }, [
        _vm.isVisible
          ? _c("iframe", {
              ref: `iframe:${_vm.videoUrl}`,
              class: ["gfu-embed__item", { "youtube-player": _vm.isYoutube }],
              attrs: {
                src: _vm.videoUrl,
                width: "100%",
                height: "100%",
                frameborder: "0",
                loading: "lazy",
                allow:
                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "true",
              },
            })
          : _c(
              "div",
              [
                _c(
                  "base-button",
                  {
                    staticClass: "gfu-gallery__play-button",
                    attrs: { theme: "primary" },
                    on: { click: _vm.onPlay },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.Resources.Common.PlayVideoButton) +
                        " "
                    ),
                    _c("span", {
                      staticClass: "_fa _ga--play-solid gfu-icon--micro _ml-1",
                    }),
                  ]
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }