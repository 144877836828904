var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("footer-navigation", {
        attrs: { "current-page": _vm.Resources.Layout.CrowdfundingGuideLink },
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-crowdfunding-guide" },
        [
          _c(
            "div",
            { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
            [
              _c("div", { staticClass: "gfu-content-head" }, [
                _c(
                  "h1",
                  {
                    ref: "what-is-crowdfunding",
                    staticClass: "gfu-hd gfu-hd--h1",
                    attrs: { id: "what-is-crowdfunding" },
                  },
                  [_vm._v(_vm._s(_vm.Resources.CrowdfundingGuide.PageHeader))]
                ),
              ]),
              _vm._v(" "),
              _c("box", {
                staticClass:
                  "gfu-box--content _foh gfu-bt gfu-bt--b1 gfu-crowdfunding-guide__box",
                attrs: { content: true },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "_bg-primary gfu-crowdfunding-guide__idea",
                          },
                          [
                            _c("p", { staticClass: "_mt-0" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.CrowdfundingGuide
                                      .IntroductorySectionParagraph1
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "_mt-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.CrowdfundingGuide
                                    .IntroductorySectionParagraph2
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "_mt-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.CrowdfundingGuide
                                    .IntroductorySectionParagraph3
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "_mt-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.Resources.CrowdfundingGuide
                                    .IntroductorySectionParagraph4
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "gfu-crowdfunding-guide__content" },
                          [
                            _vm._l(
                              _vm.crowdfundingGuideMediaModel,
                              function (section) {
                                return _c(
                                  "div",
                                  {
                                    key: section.sectionID,
                                    class: [
                                      {
                                        "gfu-media-description--reverse":
                                          section.isReversed,
                                      },
                                      "gfu-media-description _mb-6",
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-media-description__body",
                                      },
                                      [
                                        _c(
                                          "h3",
                                          {
                                            ref: section.sectionID,
                                            refInFor: true,
                                            staticClass:
                                              "gfu-hd gfu-hd--h2 gfu-heading--top-divider",
                                            attrs: { id: section.sectionID },
                                          },
                                          [_vm._v(_vm._s(section.header))]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "_mt-3" }, [
                                          _vm._v(_vm._s(section.paragraph)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-media-description__aside",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: section.image,
                                            alt: section.header,
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "h1",
                              {
                                staticClass: "gfu-hd gfu-hd--h1",
                                attrs: { id: "for-better-understanding" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.CrowdfundingGuide
                                      .ExplainingStepsSectionHeader
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.stepsExplanationModel,
                              function (section) {
                                return _c(
                                  "div",
                                  {
                                    key: section.sectionID,
                                    staticClass: "_mt-4",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        ref: section.sectionID,
                                        refInFor: true,
                                        staticClass:
                                          "gfu-hd gfu-hd--h3 _ttu _flex",
                                        attrs: { id: section.sectionID },
                                      },
                                      [
                                        _c("base-icon", {
                                          staticClass:
                                            "_mr-1 gfu-icon--big _tc--accent",
                                          attrs: {
                                            name: section.icon,
                                            "icon-namespace":
                                              section.iconNamespace,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "gfu-icon--hd" },
                                          [_vm._v(_vm._s(section.header))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "_mt-3" }, [
                                      _vm._v(_vm._s(section.paragraph)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("table-of-contents"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }