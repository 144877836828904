var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPositiveCreditsBalance
    ? _c("div", { attrs: { "data-qa": "mini-wizard-credits-box" } }, [
        _c("div", { staticClass: "_flex _ai-c" }, [
          _c(
            "span",
            { class: _vm.captionClass },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.ProjectHome.MiniWizardCreditValueText) +
                  "\n            "
              ),
              _c(
                "tooltip",
                {
                  attrs: {
                    text: _vm.Resources.ProjectHome
                      .MiniWizardCreditValueTooltip,
                  },
                },
                [
                  _c("base-icon", {
                    staticClass: "_ml-h",
                    attrs: { name: "exclamation-circle" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "strong",
            {
              class: _vm.amountClass,
              attrs: { "data-qa": "available-credits-box:Amount" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.creditBalanceFormatted) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }