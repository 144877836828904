var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLanguageSelectionInfoVisible
    ? _c(
        "div",
        { staticClass: "_mb-2 _flex _ai-c" },
        [
          _vm.isLanguageFlagVisible
            ? _c("img", {
                class: [
                  "gfu-icon gfu-icon--elevate _mr-1",
                  { _baw: !_vm.isContentTranslated },
                ],
                attrs: { width: "24", src: _vm.currentLanguageFlagImage },
              })
            : _c("base-icon", {
                staticClass: "_tc--accent _mr-1",
                attrs: { size: "lg", name: "language" },
              }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "_mt-0" },
            [
              _vm._v(_vm._s(_vm.info)),
              _vm.hasTranslations
                ? _c(
                    "tooltip",
                    { attrs: { text: _vm.tooltipText } },
                    [_c("base-icon", { attrs: { name: "info" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasTranslations
            ? _c(
                "button",
                {
                  staticClass: "_pt-0 _pb-0 _tc--accent",
                  on: { click: _vm.changeLanguage },
                },
                [_vm._v(_vm._s(_vm.Resources.ProjectHome.ChangeLanguageButton))]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }