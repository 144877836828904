import { defineStore } from 'pinia';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
import { apiClient } from '@/Clients/ApiClient.js';
import CartsService from '@/Services/CartsService';
import InitialState from '@/InitialState';

const cartService = new CartsService(apiClient);

export const useProjectUserContextStore = defineStore({
    id: 'projectUserContext',
    state: () => ({
        projectUserContext: {
            projectID: null,
            userID: null,
            creditBalance: 0,
            backerNumber: null,
            hasOrderAwaitingPayment: false,
            relationType: null,
            // In case of v1 projectUserState is null
            ...InitialState.projectUserState?.projectUserContext ?? {},
        },
        orderAwaitingPayment: null,
    }),
    actions: {
        async loadOrderAwaitingPayment() {
            const projectContextStore = useProjectContextStore();
            if (this.projectUserContext?.hasOrderAwaitingPayment && projectContextStore.isV2) {
                return await cartService.getOrderAwaitingPayment(projectContextStore.projectContext.projectID)
                    .then(response => {
                        if (response.data) {
                            this.orderAwaitingPayment = response.data;
                        }
                    });
            }
        },
    },
    getters: {
        hasPositiveCreditsBalance: state => {
            return state.creditBalance > 0;
        },
        creditBalance: state => {
            return state.projectUserContext?.creditBalance ?? 0;
        },
        backerNumber: state => {
            return state.projectUserContext?.backerNumber ?? null;
        },
    },
});