var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "gfu-product-recommendation__item _shrink-0 _grow-0 _flex",
      attrs: { to: _vm.route },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "_flex _grow-1 _rounded--common _bgc--light _pa-1 _ai-st",
        },
        [
          _c("div", { staticClass: "_flex-basis-30 _shrink-0 _flex _ai-fs" }, [
            _c(
              "div",
              { staticClass: "gfu-embed gfu-embed--1x1" },
              [
                _c("base-image", {
                  staticClass: "gfu-embed__item _rounded--common",
                  attrs: {
                    src: _vm.item.mainImageUrl,
                    alt: _vm.item.productName,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "_flex _flex-basis-70" }, [
            _c(
              "div",
              {
                staticClass:
                  "_pl-2 _whs-normal gfu-flex__container gfu-flex__container--vertical _grow-1",
              },
              [
                _c(
                  "h4",
                  {
                    staticClass: "gfu-hd gfu-hf--h4 _line-clamp-2",
                    attrs: {
                      title: _vm.item.productName,
                      "data-qa": "product-name",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.item.productName) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_flex _grow-1 _jc-sb" },
                  [
                    _c(
                      "div",
                      [
                        _c("display-currency-price", {
                          attrs: {
                            "effective-price": _vm.item.effectivePrice,
                            price: _vm.item.price,
                            "is-discounted": _vm.item.isDiscounted,
                            "data-qa": "product-price",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "effective-price",
                              fn: function ({ effectivePrice }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "gfu-price gfu-price--text-base",
                                      class: [
                                        { "gfu-price--discounted": true },
                                      ],
                                      attrs: {
                                        "data-qa": "price-type:Effective",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(effectivePrice) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "price",
                              fn: function ({ price }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "gfu-price gfu-price--old gfu-price--tiny _fw-n _ml-1",
                                      attrs: { "data-qa": "price-type:Old" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(price) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _vm.item.isDiscounted
                          ? _c(
                              "localize",
                              {
                                staticClass:
                                  "gfu-bt gfu-bt--caption _tc--lighter",
                                attrs: {
                                  resource:
                                    _vm.Resources.Product
                                      .RewardCardLowestPriceInLast30Days,
                                  tag: "div",
                                },
                              },
                              [
                                _c("display-currency-price", {
                                  attrs: {
                                    "format-key": "0",
                                    "effective-price": _vm.item.effectivePrice,
                                    price: _vm.item.price,
                                    tag: "span",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "effective-price",
                                        fn: function ({ effectivePrice }) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "_mx-1",
                                                attrs: {
                                                  "data-qa":
                                                    "price-type:ThirtyDaysLowest",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(effectivePrice) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    574834570
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "add-product-to-cart",
                      _vm._b(
                        {
                          staticClass: "_flex _as-fe _mt-a _ml-1",
                          on: { "item-added": _vm.onProductAdded },
                        },
                        "add-product-to-cart",
                        _vm.addProductToCartProps,
                        false
                      )
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }