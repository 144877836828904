var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: {
        "gfu-balloon__content-link": !_vm.onlyIcon,
        "gfu-balloon__content-link--disabled": !_vm.onlyIcon && !_vm.enabled,
      },
      attrs: { href: _vm.href },
      on: { click: _vm.onClick },
    },
    [
      _c("span", {
        class: [
          "gfu-btn--toggler",
          _vm.icon ? `_fa _fa--${_vm.icon}` : "",
          _vm.onlyIcon
            ? `gfu-btn gfu-btn--circle gfu-btn--${_vm.type}`
            : "gfu-balloon__content-icon",
          _vm.buttonClass,
        ],
      }),
      _vm._v(" "),
      !_vm.onlyIcon
        ? _c("span", { attrs: { "data-qa": `action-button:${_vm.label}` } }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }