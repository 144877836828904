import CookieNotification from './CookieNotification.vue';
import CookieSettingsForm from './CookieSettingsForm.vue';
import UserFollowSettingsModal from './UserFollowSettingsModal.vue';
import UserLoginModal from './UserLoginModal.vue';
import UserBox from './UserBox';

export default {
    UserLoginModal,
    CookieNotification,
    CookieSettingsForm,
    UserFollowSettingsModal,
    UserBox,
};