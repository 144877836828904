var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "floating-message",
        _vm._b(
          {
            attrs: { "data-qa": "reverse-language-notification" },
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("circle-icon", {
                        staticClass: "_shrink-0",
                        attrs: { theme: "info", icon: "language" },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "_mt-1" }, [
                        _c(
                          "a",
                          {
                            staticClass: "gfu-link gfu-link--accent _fw-b _ttl",
                            attrs: {
                              href: "#",
                              "data-qa":
                                "reverse-language-notification-link:Ok",
                            },
                            on: { click: _vm.toastClose },
                          },
                          [_vm._v("ok, keep this setting")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "gfu-link gfu-link--accent _fw-b _ttl",
                            attrs: {
                              href: "#",
                              "data-qa":
                                "reverse-language-notification-link:Revert",
                            },
                            on: { click: _vm.onRevert },
                          },
                          [_vm._v("revert to English")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2706929603
            ),
          },
          "floating-message",
          _vm.floatingMessageConfig,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }