var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fieldset",
    { staticClass: "gfu-1of2--m" },
    [
      _c("text-field", {
        ref: "field",
        staticClass: "_mt-0",
        attrs: {
          name: "code",
          "display-name": _vm.Resources.Checkout.EnterBlikCodeLabel,
          description: _vm.Resources.Checkout.EnterBlikCodeDescription,
          "data-qa": "adyen-blik-form:Code",
        },
        model: {
          value: _vm.code,
          callback: function ($$v) {
            _vm.code = $$v
          },
          expression: "code",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }