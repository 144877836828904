var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_mt-a" }, [
    _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" }, [
      _vm._v("Launching in:"),
    ]),
    _vm._v(" "),
    !_vm.shouldStopCounting
      ? _c("div", [
          _vm._v("\n        " + _vm._s(_vm.days) + " "),
          _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" }, [
            _vm._v("d"),
          ]),
          _vm._v("\n        " + _vm._s(_vm.hours) + " "),
          _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" }, [
            _vm._v("h"),
          ]),
          _vm._v("\n        " + _vm._s(_vm.minutes) + " "),
          _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" }, [
            _vm._v("m"),
          ]),
          _vm._v("\n        " + _vm._s(_vm.seconds) + " "),
          _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" }, [
            _vm._v("s"),
          ]),
        ])
      : _c("div", [_vm._v("Launched!")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }