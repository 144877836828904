var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.installmentStateComponent.name,
    _vm._b(
      { key: _vm.installmentStateComponent.key, tag: "component" },
      "component",
      _vm.installmentStateComponent.model,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }