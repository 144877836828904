var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_mt-3" },
    [
      _c(
        "div",
        {
          class: [
            "_mt-3",
            { "gfu-field--disabled": !_vm.canBePaidInInstallments },
          ],
          attrs: { "data-qa": "payment-type:Installments" },
        },
        [
          _c(
            "div",
            {
              class: [
                "_cur-p _pa-3 _ba-2 _rounded--common",
                { "_bc--input": !_vm.isSelected },
                { "_bc--primary": _vm.isSelected },
              ],
              on: {
                click: function ($event) {
                  return _vm.onSelectPaymentType()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "_flex _ai-c" },
                [
                  _c("checkbox-field", {
                    staticClass: "_mt-0 _mr-2",
                    attrs: {
                      "show-label": false,
                      name: "paymentTypeItem",
                      "read-only": !_vm.canBePaidInInstallments,
                      type: "radio",
                    },
                    model: {
                      value: _vm.isSelected,
                      callback: function ($$v) {
                        _vm.isSelected = $$v
                      },
                      expression: "isSelected",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "_flexcol" },
                    [
                      !_vm.canBePaidInInstallments
                        ? [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.Resources.Checkout
                                    .CheckoutPaymentTypeInstallmentsDisabledLabel
                                ) +
                                "\n                    "
                            ),
                          ]
                        : _vm.isInUpgradeMode
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.upgradeInstallmentValueInformation)
                            ),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(_vm.installmentValueInformation)),
                          ]),
                      _vm._v(" "),
                      _c(
                        "base-label",
                        {
                          staticClass: "gfu-label--achievement",
                          attrs: { theme: "light" },
                        },
                        [
                          _c(
                            "localize",
                            {
                              attrs: {
                                resource:
                                  _vm.Resources.Checkout
                                    .CheckoutPaymentTypeInstallmentsBadge,
                              },
                            },
                            [
                              _c("span", { attrs: { "format-key": "0" } }, [
                                _vm._v(_vm._s(_vm.numberOfInstallments)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.shouldDisplayInstallmentDetails
                ? _c("installments-details", {
                    attrs: {
                      installments: _vm.installments,
                      "currency-symbol": _vm.currencySymbol,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.canBePaidInInstallments
        ? [
            _c(
              "div",
              {
                staticClass: "_mt-2 _tc--base _flexwrap _ai-c _gap-1",
                attrs: { "data-qa": "stretch-pay-text" },
              },
              [
                _c(
                  "localize",
                  {
                    key: _vm.effectivePayableAmount,
                    attrs: {
                      resource:
                        _vm.Resources.Checkout
                          .CheckoutInstallmentsAvailabilityParagraph,
                    },
                  },
                  [
                    _c("span", { attrs: { "format-key": "0" } }, [
                      _vm._v(_vm._s(_vm.remainingValueToUnlockInstallments)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("base-image", {
                  attrs: {
                    src: "/content/images/installments-logo.svg",
                    alt: "stretchPay",
                  },
                }),
                _vm._v(" "),
                _c("tooltip", {
                  attrs: { "icon-name": "info", text: _vm.tooltipText },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }