import { defineStore } from 'pinia';

import InitialState from '@/InitialState';

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        preventCartLoading: InitialState.preventCartLoading,
    }),
    actions: {
        setPreventCartLoading(preventCartLoading) {
            this.preventCartLoading = preventCartLoading;
        },
    },
});