var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "gfu-product-details-gallery",
        { "_mb-6": _vm.images.length > 1 },
      ],
    },
    [
      _c("base-slider", {
        staticClass: "gfu-product-details-slider",
        attrs: { autoplay: true },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ currentItem }) {
              return _vm._l(_vm.images, function (image, index) {
                return _c(
                  "a",
                  {
                    key: image.productImage + index,
                    class: [
                      "gfu-product-details-slider__slide",
                      { "is-active": currentItem === index },
                    ],
                    attrs: { href: image.productZoomImage },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onZoom.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.shouldLoad(index)
                      ? _c("base-image", {
                          staticClass: "gfu-product-details-slider__image",
                          attrs: {
                            alt: `gallery-image-${index + 1}`,
                            src: image.productImage,
                            loading: "lazy",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              })
            },
          },
          {
            key: "nav",
            fn: function ({ onNext, onPrev }) {
              return [
                _c("base-slider-navigation", {
                  attrs: {
                    type: "prev",
                    icon: "angle-left",
                    "has-icon-base-class": false,
                  },
                  on: { click: onPrev },
                }),
                _vm._v(" "),
                _c("base-slider-navigation", {
                  attrs: {
                    type: "next",
                    icon: "angle-right",
                    "has-icon-base-class": false,
                  },
                  on: { click: onNext },
                }),
              ]
            },
          },
          {
            key: "indicators",
            fn: function ({ currentItem, onSetCurrentIndex }) {
              return [
                _c("base-slider-indicators", {
                  staticClass: "gfu-product-details-slider__indicators",
                  attrs: {
                    "current-item": currentItem,
                    "items-count": _vm.images.length,
                  },
                  on: { selected: onSetCurrentIndex },
                }),
              ]
            },
          },
          {
            key: "actions",
            fn: function () {
              return [
                _vm.hasZoom
                  ? _c("base-slider-navigation", {
                      attrs: { type: "zoom", icon: "search-plus" },
                      on: { click: _vm.onZoom },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.modalIsVisible
        ? _c("zoomable-gallery", {
            attrs: {
              "current-image": _vm.currentImage,
              images: _vm.zoomableImages,
            },
            on: { close: _vm.onClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }