var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_mt-3" },
    [
      _c(
        "div",
        [
          _c(
            "localize",
            {
              attrs: {
                resource:
                  _vm.Resources.ProjectHome
                    .AddToCartSuccessModalInstallmentMissingAmountParagraph,
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "_mt-0 _tc--base",
                  attrs: { "format-key": "0", "data-qa": "stretch-pay-text" },
                },
                [_vm._v(_vm._s(_vm.installmentMissingValue))]
              ),
              _vm._v(" "),
              _c("base-image", {
                attrs: {
                  "format-key": "1",
                  src: "/content/images/installments-logo.svg",
                  alt: "stretchPay",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-bar", {
        staticClass: "_mt-1",
        attrs: {
          value: _vm.totalCost,
          max: _vm.installmentsMinPaymentAmount,
          "progression-class": "gfu-progress-bar__progression--accent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }