var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("flip-card", {
    ref: "card",
    attrs: { "is-flipped": _vm.showDetails },
    scopedSlots: _vm._u([
      {
        key: "face",
        fn: function () {
          return [
            _c(
              "base-card",
              {
                staticClass: "gfu-project-card",
                style: { visibility: _vm.isFaceVisible },
                attrs: {
                  orientation: _vm.orientation,
                  "media-size": _vm.mediaSize,
                  scheme: _vm.scheme,
                  "data-qa": `project-card-ID:${_vm.projectID}`,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.showLabels
                      ? {
                          key: "labels",
                          fn: function () {
                            return _vm._l(_vm.labels, function (label) {
                              return _c(
                                "base-badge",
                                {
                                  key: label.name,
                                  staticClass: "_mr-1 _my-1",
                                  attrs: {
                                    theme: label.theme,
                                    "data-qa": "project-card:BadgeText",
                                  },
                                },
                                [_vm._v(_vm._s(label.name))]
                              )
                            })
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "media",
                      fn: function () {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-embed gfu-embed--1x1",
                              attrs: { href: _vm.url, title: _vm.name },
                            },
                            [
                              _c("base-image", {
                                staticClass: "gfu-embed__item",
                                style: _vm.mask,
                                attrs: {
                                  src: _vm.imageUrl,
                                  alt: _vm.name,
                                  loading: "lazy",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "gfu-project-flip-card__stamp" },
                                [
                                  _vm.isFollowControlsVisible
                                    ? _c("project-card-follow", {
                                        staticClass: "_mr-2",
                                        attrs: {
                                          "project-id": _vm.projectID,
                                          "project-url": _vm.url,
                                          "project-name": _vm.name,
                                          "follower-count": _vm.followerCount,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isBackersCountVisible
                                    ? _c(
                                        "span",
                                        {
                                          class: [
                                            "gfu-project-card-stamp gfu-project-card-follow _px-2",
                                            _vm.highlitedClass,
                                          ],
                                          attrs: {
                                            "data-qa":
                                              "project-card:BackersCount",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "_ga _ga--user-solid-icon _mr-1",
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.backersCountLabelFormatted
                                            ) + "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "gfu-project-card__body" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "gfu-bt gfu-bt--caption _tc--light _mt-0 _mb-1 _mt-3",
                      },
                      [
                        _c(
                          "strong",
                          {
                            class: [
                              "_ttu",
                              _vm.currentProjectInfo.projectPhaseTitleClass,
                            ],
                            attrs: {
                              "data-qa": "project-card:ProjectPhaseLabel",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.currentProjectInfo.projectPhaseLabel
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.timeLeft
                          ? _c(
                              "time-left",
                              _vm._b(
                                {
                                  staticClass: "_whs-nw _tc--accent _ttl",
                                  attrs: { "data-qa": "project-card:TimeLeft" },
                                },
                                "time-left",
                                _vm.timeLeft,
                                false
                              )
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isPledgeManagerLimited
                          ? _c(
                              "tooltip",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "content",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.pledgeManagerLimitedTooltip
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3317793876
                                ),
                              },
                              [_c("span", { staticClass: "_fa _fa--info" })]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "h4",
                      {
                        staticClass:
                          "gfu-hd gfu-project-card__title gfu-hd _line-clamp _line-clamp-2",
                        attrs: {
                          title: _vm.name,
                          "data-qa": "project-card:ProjectName",
                        },
                      },
                      [
                        _c("a", { attrs: { href: _vm.url } }, [
                          _vm._v(_vm._s(_vm.name)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "gfu-bt gfu-bt--caption _tc--lighter _mt-1 _mb-3",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.format(
                              _vm.Resources.Homepage.ProjectCardByCreatorLabel,
                              _vm.creatorName
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isProjectCanceled
                      ? _c("project-card-last-update", {
                          attrs: { title: _vm.lastUpdateTitle },
                        })
                      : _c(
                          _vm.currentProjectInfo.summaryComponent,
                          _vm._b(
                            { tag: "component" },
                            "component",
                            _vm.currentProjectInfo.props,
                            false
                          )
                        ),
                    _vm._v(" "),
                    _c("progress-bar", {
                      staticClass: "gfu-project-card__progress-bar",
                      attrs: {
                        value: _vm.currentProjectInfo.fundsGathered,
                        max: _vm.currentProjectInfo.fundsRequired,
                        "can-go-over": true,
                        "progression-over-class":
                          "gfu-progress-bar__progression-over gfu-progress-bar__progression-over--animated",
                        "container-class":
                          _vm.currentProjectInfo.progressBarContainerClass,
                        "progression-class":
                          "gfu-progress-bar__progression--accent gfu-progress-bar--animated",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "gfu-project-card__action-button",
                        on: { click: _vm.toggleDetails },
                      },
                      [
                        _c("span", {
                          staticClass: "_fa _ga--revert",
                          attrs: { "data-qa": "project-card:ReverseButton" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "reverse",
        fn: function () {
          return [
            _vm.showDetails
              ? _c(
                  "base-card",
                  {
                    staticClass: "gfu-project-card",
                    attrs: {
                      orientation: _vm.orientation,
                      "media-size": _vm.mediaSize,
                      scheme: _vm.scheme,
                      "is-scrollable": _vm.showDetails,
                      "data-qa": `project-card-ID:${_vm.projectID}`,
                    },
                  },
                  [
                    _c("div", { staticClass: "_pa-3" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "gfu-hd gfu-project-card__title",
                          attrs: { "data-qa": "project-card:ProjectName" },
                        },
                        [
                          _c("a", { attrs: { href: _vm.url } }, [
                            _vm._v(_vm._s(_vm.name)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "_my-2",
                          attrs: { "data-qa": "project-Card:ShortDescription" },
                        },
                        [
                          _c("a", { attrs: { href: _vm.url } }, [
                            _vm._v(_vm._s(_vm.shortDescription)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.projectProperties.enableBoardGameProperties
                        ? _c(
                            "div",
                            {
                              staticClass: "_flex _jc-se _mt-6",
                              attrs: {
                                "data-qa": "project-card:GameProperties",
                              },
                            },
                            _vm._l(_vm.boardgameProperties, function (prop) {
                              return _c(
                                "div",
                                {
                                  key: prop.name,
                                  staticClass:
                                    "gfu-bt gfu-bt--caption _tc--light _mt-0 _ttu _flex-basis-33 _tac",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(prop.name) +
                                      "\n                        "
                                  ),
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "gfu-hd gfu-hd--h4",
                                      attrs: {
                                        "data-qa": `project-card:${prop.dataQa}`,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(prop.value) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "gfu-bt gfu-bt--caption _tc--light _mt-0 _ttu _tac _mt-3",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.Resources.Homepage
                                    .ProjectCardCategoryPropertyLabel
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showTags && _vm.tags.length > 0
                          ? _c(
                              "ul",
                              { staticClass: "_mx-0 _my-1 _tac" },
                              _vm._l(_vm.tags, function (tag) {
                                return _c(
                                  "base-tag",
                                  {
                                    key: tag.name,
                                    staticClass: "_mr-1",
                                    attrs: { tag: "a", href: tag.url },
                                  },
                                  [_vm._v(_vm._s(tag.name))]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.displayProjectCardBadges.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gfu-bt gfu-bt--caption _tc--light _mt-0 _ttu _tac _mt-3",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.Resources.Homepage
                                        .ProjectCardBenefitsPropertyLabel
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "_mx-0 _my-1 _tac" },
                              _vm._l(
                                _vm.displayProjectCardBadges,
                                function (badge) {
                                  return _c("project-card-badge", {
                                    key: badge.text,
                                    attrs: {
                                      "tooltip-text": badge.tooltipText,
                                      icon: badge.icon,
                                      "data-qa": `project-card-benefit:${badge.name}`,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "gfu-project-card__action-button",
                          on: { click: _vm.toggleDetails },
                        },
                        [
                          _c("span", {
                            staticClass: "_fa _ga--revert",
                            attrs: { "data-qa": "project-card:FlipButton" },
                          }),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }