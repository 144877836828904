var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("installments-description-box", {
    attrs: {
      "number-of-installments": _vm.numberOfInstallments,
      "is-notificator-visible": _vm.isNotificatorVisible,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "installments-plan",
              _vm._b({}, "installments-plan", _vm.installmentsPlanData, false)
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }