import { areObjectsEqual } from '@/Utils';
import MagicAnchor from '@/Plugins/MagicAnchor.js';
import router from '@/Router.js';

export function scrollToSection(to, routeDifferentCallback, routeSameCallback) {
    if (!areObjectsEqual(router.currentRoute?.params, to.params)) {
        routeDifferentCallback
            ? routeDifferentCallback.call()
            : router.push(to);
    }
    else {
        routeSameCallback
            ? routeSameCallback.call()
            : MagicAnchor.scrollToAnchor(router.resolve(to)?.resolved);
    }
}
