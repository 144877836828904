var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    {
      staticClass: "_cur-d _flex _jc-sb _gap-1 _ttr",
      attrs: {
        theme: "lightOutline",
        weight: "thin",
        shape: "default",
        "data-qa": `mini-wizard-item:${_vm.name}`,
      },
    },
    [
      _c(
        "span",
        {
          class: ["_line-clamp-2 _tal", { "_tc--accent": _vm.isNew }],
          attrs: { "data-qa": "mini-wizard-item-label" },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.formattedAmount) + " "),
          _c("strong", { staticClass: "_fw-eb" }, [_vm._v(_vm._s(_vm.name))]),
        ]
      ),
      _vm._v(" "),
      _vm.canBeDeleted
        ? _c("base-icon", {
            staticClass: "_tc--error _cur-p",
            attrs: {
              name: "times-circle",
              "data-qa": "mini-wizard-item-btn:Remove",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.onRemoveItem.apply(null, arguments)
              },
            },
          })
        : _vm.isLocked
        ? _c(
            "tooltip",
            { attrs: { text: _vm.lockedReason } },
            [
              _c("base-icon", {
                staticClass: "_tc--lighter",
                attrs: { name: "lock", "data-qa": "mini-wizard-item-btn:Lock" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }