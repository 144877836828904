var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-project-summary-update" }, [
    _c(
      "a",
      {
        staticClass: "gfu-bt gfu-bt--b2 _line-clamp _line-clamp-2",
        attrs: { href: _vm.projectUpdateUrl, title: _vm.title },
      },
      [
        _c("strong", [
          _vm._v(_vm._s(_vm.Resources.ProjectHome.ProjectSummaryBoxLastUpdate)),
        ]),
        _vm._v(" " + _vm._s(_vm.title) + "\n    "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }