import { ProductsServiceBase } from './BaseServices';

export default class ProductsService extends ProductsServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    getProductAddToCartModel(productID, projectID) {
        return this.request('get', 'products/getProductAddToCartModel', { productID, projectID });
    }

    getProductStockLimits(productIDs, projectID) {
        const params = {
            productIDs,
            projectID,
        };
        return this.request('post', 'products/getProductStockLimits', params);
    }

    getProductDetails(productID, projectID, snapshotPhase) {
        return this.request('get', 'products/getProductDetails', { productID, projectID, snapshotPhase });
    }

    getAvailableProductOptions(productID) {
        return this.request('get', 'products/getAvailableProductOptions', { productID });
    }

    getAvailableProductOptionValues(productID, productOptionID) {
        return this.request('get', 'products/getAvailableProductOptionValues', { productID, productOptionID });
    }
}