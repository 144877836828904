var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.sectionClass }, [
    _c(
      "div",
      {
        class: _vm.wrapperClass,
        attrs: { "data-qa": "project-description-section:Legal entity info" },
      },
      [
        _c("span", [_vm._v(_vm._s(_vm.legalEntityInformation))]),
        _vm._v(" "),
        _vm.hasCustomTos
          ? _c(
              "localize",
              {
                attrs: {
                  resource:
                    _vm.Resources.ProjectHome.AcceptingStoreTermsOfServiceText,
                },
              },
              [
                _c("span", { attrs: { "format-key": "0" } }, [
                  _vm._v(_vm._s(_vm.legalEntityName)),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "gfu-link gfu-link--accent",
                    attrs: {
                      "format-key": "1",
                      href: _vm.customTosUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                      "data-qa":
                        "legal-entity-information-link:CustomTermsOfService",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.Resources.ProjectHome.CustomTermsOfServiceLink)
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }