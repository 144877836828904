var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-credit-card-overview _mt-2",
      attrs: { "data-qa": "card-overview" },
    },
    [
      _c("base-image", {
        staticClass: "_mr-2",
        attrs: { src: _vm.imageUrl, alt: _vm.name, "data-qa": "card-image" },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "gfu-credit-card-overview__label _mr-1",
          attrs: { "data-qa": "masked-card-number" },
        },
        [_vm._v(_vm._s(_vm.maskedNumber))]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "gfu-link gfu-link--accent",
          attrs: { href: "", "data-qa": "card-remove-button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onDelete.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.Resources.AccountSettings.DeleteCreditCardButton))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }