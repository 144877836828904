var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["gfu-project-summary-flipper", { "is-flipped": _vm.isFlipped }] },
    [
      _c("div", { staticClass: "gfu-project-summary-flipper__card" }, [
        _c(
          "div",
          { staticClass: "gfu-project-summary-flipper__front" },
          [_vm._t("front")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gfu-project-summary-flipper__back" },
          [_vm._t("back")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }