var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-bt gfu-bt--caption _tc--gray _tac" },
    [
      _c(
        "localize",
        { attrs: { resource: _vm.Resources.Common.CaptchaDisclaimerCaption } },
        [
          _c(
            "base-link",
            {
              attrs: { "format-key": "0", url: _vm.reCaptchaPrivacyPolicyUrl },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.Common.CaptchaPrivacyPolicyLink) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "base-link",
            { attrs: { "format-key": "1", url: _vm.reCaptchaTermsUrl } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.Common.CaptchaTermsOfServiceLink) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }