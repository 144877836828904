var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_pos-r" },
    [
      _vm.hasIcon
        ? _c("span", { staticClass: "_fa _fa--search _ha-left _px-2" })
        : _vm._e(),
      _vm._v(" "),
      _c("text-field", {
        staticClass: "_mt-3 _mb-2",
        attrs: {
          value: _vm.value,
          placeholder: _vm.Resources.Common.SearchFieldPlaceholder,
          "has-icon": _vm.hasIcon,
        },
        on: { input: _vm.onInput },
      }),
      _vm._v(" "),
      _c("button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "_ha-right _tc--accent gfu-btn _fa _fa--times",
        attrs: { "data-qa": "search-field:ClearInput" },
        on: { mousedown: _vm.clearInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }