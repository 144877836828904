var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("installments-description-box", {
    attrs: {
      "number-of-installments": _vm.numberOfInstallments,
      "is-notificator-visible": _vm.isNotificatorVisible,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "installment-state",
              _vm._b({}, "installment-state", _vm.installmentsStateProps, false)
            ),
            _vm._v(" "),
            _c(
              "installments-plan",
              _vm._b({}, "installments-plan", _vm.installmentsPlanData, false),
              [
                _vm.isInProgressStatus
                  ? _c(
                      "installments-progress",
                      _vm._b(
                        {},
                        "installments-progress",
                        _vm.installmentsProgressProps,
                        false
                      )
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }