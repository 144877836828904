var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("toggle", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ onToggle, isExpanded, onCollapse }) {
          return [
            _c("click-outside", { attrs: { call: onCollapse } }, [
              _c(
                "div",
                { staticClass: "gfu-comment-item-actions" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "gfu-comment-item-actions__btn gfu-btn gfu-btn--light-outline gfu-btn--no-shadow",
                      attrs: {
                        type: "button",
                        "data-qa": `comment-actions:${_vm.commentID}`,
                      },
                      on: { click: onToggle },
                    },
                    [_c("span", { staticClass: "_fa _fa--ellipsis-v" })]
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "gfu-fade" } }, [
                    isExpanded
                      ? _c(
                          "div",
                          {
                            class: _vm.balloonClasses,
                            attrs: {
                              "data-qa": `comment-actions-content:${_vm.commentID}`,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "gfu-balloon__content" },
                              _vm._l(_vm.actions, function (action) {
                                return _c(
                                  "div",
                                  {
                                    key: action.key,
                                    class: [
                                      "gfu-balloon__content-link",
                                      {
                                        "gfu-balloon__content-link--disabled":
                                          action.disabled,
                                      },
                                    ],
                                    attrs: {
                                      "data-qa": `comment-action:${action.key}`,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onClick(action.key, onToggle)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      class: `gfu-balloon__content-icon fa-fw _fa ${action.iconClassName}`,
                                    }),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(action.label) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }