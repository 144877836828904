var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-notificator",
    {
      staticClass: "_mb-3",
      attrs: { "can-close": false, qa: _vm.actionDataQa },
    },
    [
      _c(
        "div",
        { staticClass: "gfu-project-engagement-card-action" },
        [
          _c("div", { attrs: { "data-qa": "call-to-action-text" } }, [
            _vm._v(_vm._s(_vm.actionText)),
          ]),
          _vm._v(" "),
          _c(
            "base-button",
            {
              attrs: {
                tag: "a",
                href: _vm.actionUrl,
                theme: "transparentInverse",
                "data-qa": "call-to-action-button",
              },
              on: {
                click: function ($event) {
                  return _vm.onAction($event, _vm.callback)
                },
              },
            },
            [_vm._v(_vm._s(_vm.actionLabel) + "\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }