export default class ZoomableImageHandler {
    /**
     * Constructs a new ZoomableImageHandler object.
     * @param {Object} options - The configuration options.
     * @param {HTMLElement} options.container - The container element.
     * @param {HTMLElement} options.element - The image element.
     * @param {number} options.minScale - The minimum scale value.
     * @param {number} options.maxScale - The maximum scale value.
     * @param {number} [options.scaleSensitivity=10] - The sensitivity of scaling.
     */

    constructor({
        container,
        element,
        minScale,
        maxScale,
        scaleSensitivity = 10,
    }) {
        this.state = {
            container,
            element,
            minScale,
            maxScale,
            scaleSensitivity,
            transform: {
                originOffset: false,
                originX: 0,
                originY: 0,
                translateX: 0,
                translateY: 0,
                scale: 1,
            },
        };
    }

    // Method to check if the position of the image has changed
    hasPositionChanged({ pos, prevPos }) {
        return pos !== prevPos;
    }
    // Method to check if the scale value is within the defined range
    valueInRange({ minScale, maxScale, transform: { scale } }) {
        return scale <= maxScale && scale >= minScale;
    }

    // Method to calculate the translation based on position and axis
    getTranslate(pos, axis) {
        const { originX, originY, translateX, translateY, scale } = this.state.transform;
        const axisIsX = axis === 'x';
        const prevPos = axisIsX ? originX : originY;
        const translate = axisIsX ? translateX : translateY;

        return this.valueInRange(this.state) && this.hasPositionChanged({ pos, prevPos })
            ? translate + (pos - prevPos * scale) * (1 - 1 / scale)
            : translate;
    }

    // Method to construct the CSS matrix value for transformations
    getMatrix(scale, translateX, translateY) {
        return `matrix(${scale}, 0, 0, ${scale}, ${translateX}, ${translateY})`;
    }

    // Function ensures that the value will always be within the range specified by the minimum and maximum values. This applies to the scale and movement of the image.
    clamp(value, min, max) {
        return Math.max(Math.min(value, max), min);
    }

    // Method to calculate the new scale after a zoom action
    getNewScale(deltaScale) {
        const { minScale, scaleSensitivity, maxScale } = this.state;
        const scale = this.getScale();
        const newScale = scale + deltaScale / (scaleSensitivity / scale);
        return this.clamp(newScale, minScale, maxScale);
    }

    /* This function, clampedTranslate, calculates and returns a translation value for a given axis (either 'x' or 'y')
     while ensuring that the translated image remains within the boundaries of its container. */
    clampedTranslate(axis, translate) {
        const { container, element } = this.state;
        const { scale, originX, originY } = this.state.transform;
        const axisIsX = axis === 'x';
        const origin = axisIsX ? originX : originY;
        // The axisKey variable is used to determine the size of the container and the image based on the axis.
        const axisKey = axisIsX ? 'offsetWidth' : 'offsetHeight';

        const containerSize = container[axisKey];
        const imageSize = element[axisKey];
        const bounds = element.getBoundingClientRect();
        // The imageScaledSize variable is used to determine the size of the image after scaling.
        const imageScaledSize = axisIsX ? bounds.width : bounds.height;

        // The values of the defaultOrigin and originOffset variables are used to calculate the origin offset.
        const defaultOrigin = imageSize / 2;
        const originOffset = (origin - defaultOrigin) * (scale - 1);

        // The range variable is used to calculate the range of the image's movement.
        const range = Math.max(0, Math.round(imageScaledSize) - containerSize);

        // The max and min variables are used to calculate the maximum and minimum translation values.
        const max = Math.round(range / 2);
        const min = 0 - max;

        // The clamp function is used to ensure that the translation value is within the specified range.
        return this.clamp(translate, min + originOffset, max + originOffset);
    }

    // This function is responsible for rendering the image while ensuring that its translation values are within certain bounds.
    renderClamped(translateX, translateY) {
        const { originX, originY, scale } = this.state.transform;
        this.state.transform.translateX = this.clampedTranslate('x', translateX);
        this.state.transform.translateY = this.clampedTranslate('y', translateY);

        requestAnimationFrame(() => {
            if (this.state.transform.originOffset) {
                this.state.element.style.transformOrigin = `${originX}px ${originY}px`;
            }
            this.state.element.style.transform = this.getMatrix(
                scale,
                this.state.transform.translateX,
                this.state.transform.translateY,
            );
        });
    }

    // Method to handle the movement of the image
    pan({ originX, originY }) {
        this.renderClamped(
            this.state.transform.translateX + originX,
            this.state.transform.translateY + originY,
        );
    }

    // Method to handle the zoom action into specific position of the image
    panTo({ originX, originY, scale }) {
        this.state.transform.scale = this.clamp(scale, this.state.minScale, this.state.maxScale);
        this.pan({
            originX: originX - this.state.transform.translateX,
            originY: originY - this.state.transform.translateY,
        });
    }

    // Method to handle the zoom action into specific position of the image by multiGesture touch. This method is used for zooming in and out of the image to a specific position.
    zoomPan({ scale: scaleValue, x, y, deltaX, deltaY }) {
        const newScale = this.clamp(scaleValue, this.state.minScale, this.state.maxScale);
        const { left, top } = this.state.element.getBoundingClientRect();
        /* Calculate Origin Coordinates:
        it calculates the coordinates of the zoom origin by subtracting the element's positions from the provided coordinate. */
        const originX = x - left;
        const originY = y - top;
        /* Calculate New Origin Coordinates
        it calculates the new coordinate of the zoom origin relative to the current scale. */
        const newOriginX = originX / this.state.transform.scale;
        const newOriginY = originY / this.state.transform.scale;
        /* Calculate Translations:
        it calculates the translation amount along the axis. */
        const translateX = this.getTranslate(originX, 'x');
        const translateY = this.getTranslate(originY, 'y');

        // Update the state with the new transform values.
        this.state.transform = {
            originOffset: true,
            originX: newOriginX,
            originY: newOriginY,
            translateX,
            translateY,
            scale: newScale,
        };

        this.pan({ originX: deltaX, originY: deltaY });
    }

    zoom({ x, y, deltaScale }) {
        const { left, top } = this.state.element.getBoundingClientRect();
        const newScale = this.getNewScale(deltaScale);
        /* Calculate Origin Coordinates:
        it calculates the coordinates of the zoom origin by subtracting the element's positions from the provided coordinate. */
        const originX = x - left;
        const originY = y - top;
        /* Calculate New Origin Coordinates
        it calculates the new coordinate of the zoom origin relative to the current scale. */
        const newOriginX = originX / this.state.transform.scale;
        const newOriginY = originY / this.state.transform.scale;
        /* Calculate Translations:
        it calculates the translation amount along the axis. */
        const translateX = this.getTranslate(originX, 'x');
        const translateY = this.getTranslate(originY, 'y');

        // Update the state with the new transform values.
        this.state.transform = {
            ...this.state.transform,
            originOffset: true,
            originX: newOriginX,
            originY: newOriginY,
            scale: newScale,
        };

        // Render the image with the new transform values.
        this.renderClamped(translateX, translateY);
    }

    // Function to handle the zoom action into specific position of the image by double tap. This method is used for zooming in and out of the image to a specific position.
    zoomTo({ newScale, x, y }) {
        const { left, top } = this.state.element.getBoundingClientRect();
        /* Calculate Origin Coordinates:
        it calculates the coordinates of the zoom origin by subtracting the element's positions from the provided coordinate. */
        const originX = x - left;
        const originY = y - top;
        /* Calculate New Origin Coordinates
        it calculates the new coordinate of the zoom origin relative to the current scale. */
        const newOriginX = originX / this.state.transform.scale;
        const newOriginY = originY / this.state.transform.scale;
        /* Calculate Translations:
        it calculates the translation amount along the axis. */
        const translateX = this.getTranslate(originX, 'x');
        const translateY = this.getTranslate(originY, 'y');

        // Update the state with the new transform values.
        this.state.transform = {
            originOffset: true,
            originX: newOriginX,
            originY: newOriginY,
            scale: newScale,
            translateX,
            translateY,
        };

        requestAnimationFrame(() => {
            this.state.element.style.transformOrigin = `${newOriginX}px ${newOriginY}px`;
            this.state.element.style.transform = this.getMatrix(newScale, translateX, translateY);
        });
    }

    getScale() {
        return this.state.transform.scale;
    }

    reset() {
        this.state.transform.scale = this.state.minScale;
        this.pan({ originX: 0, originY: 0 });
        this.state.transform = {
            originOffset: false,
            originX: 0,
            originY: 0,
            translateX: 0,
            translateY: 0,
            scale: 1,
        };
    }
    getState() {
        return this.state;
    }
}