var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.classList.wrapper, "gfu-featured-projects"] },
    [
      _vm.isInitialized
        ? _vm._l(_vm.projectsList, function (project) {
            return _c(
              "div",
              { key: project.projectID, class: _vm.classList.card },
              [
                _c(
                  project.component,
                  _vm._b({ tag: "component" }, "component", project, false)
                ),
              ],
              1
            )
          })
        : _vm._l(_vm.placeholders, function (placeholder) {
            return _c(
              "div",
              { key: placeholder, class: _vm.classList.card },
              [_c("skeleton-card", { staticClass: "_h-full" })],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }