var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flex" },
    [
      _vm._t("default", null, {
        onEdit: _vm.onEdit,
        onSave: _vm.onSave,
        onCancel: _vm.onCancel,
        isEditing: _vm.isEditing,
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_flex _gap-1" },
        _vm._l(
          _vm.actions,
          function ({ key, theme, shape, icon, onlyIcon, action }) {
            return _c("icon-base-button", {
              key: key,
              attrs: {
                theme: theme,
                shape: shape,
                icon: icon,
                "only-icon": onlyIcon,
                "data-qa": `field-action:${key}`,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return action.apply(null, arguments)
                },
              },
            })
          }
        ),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }