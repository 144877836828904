var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-grid gfu-grid--gutter-columns _mt-4" }, [
    _c(
      "div",
      {
        staticClass: "gfu-grid__cell gfu-1of1",
        attrs: { "data-qa": "shipping-method-section" },
      },
      [
        _c(
          "fieldset",
          [
            _c("h2", { staticClass: "gfu-heading gfu-heading--top-divider" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.Resources.Checkout.ShippingMethodSectionTitle)
              ),
              _c("em", [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c(
              "field",
              {
                staticClass: "_mt-0",
                attrs: {
                  "base-class": "",
                  name: "shippingModelID",
                  "show-label": false,
                },
              },
              _vm._l(_vm.mappedShippingModels, function (shippingModel) {
                return _c(
                  "div",
                  {
                    key: `shipping-method-${shippingModel.shippingModelID}`,
                    attrs: {
                      "data-qa":
                        "begin-order-form-shipping-method:" +
                        shippingModel.shippingModelID,
                    },
                  },
                  [
                    _c(
                      "checkbox-field",
                      {
                        attrs: {
                          "checked-value": shippingModel.shippingModelID,
                          condensed: true,
                          type: "radio",
                        },
                        model: {
                          value: _vm.shippingModelID,
                          callback: function ($$v) {
                            _vm.shippingModelID = $$v
                          },
                          expression: "shippingModelID",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(shippingModel.name) +
                            "\n                        "
                        ),
                        shippingModel.shippingCostDifferenceConverted !== null
                          ? _c(
                              "span",
                              {
                                attrs: {
                                  "data-qa": `shipping-cost:${shippingModel.shippingCostDifferenceConverted}`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(shippingModel.shippingInfo) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        shippingModel.description
                          ? _c(
                              "tooltip",
                              { attrs: { text: shippingModel.description } },
                              [
                                _c("em", [
                                  _c("span", { staticClass: "_fa _fa--info" }),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._t("actions"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }