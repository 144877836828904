var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("skeleton-wrapper", { attrs: { "has-animation": false } }, [
    _c(
      "div",
      { staticClass: "gfu-summary-list-wrapper" },
      _vm._l(_vm.itemsCount, function (item) {
        return _c(
          "div",
          { key: item, staticClass: "_flex _jc-sb _gap-2" },
          [
            _c("skeleton-line", {
              staticClass: "_flex-basis-30 gfu-animation-pulse",
              attrs: { height: 20 },
            }),
            _vm._v(" "),
            _c("skeleton-line", {
              staticClass: "_flex-basis-10 gfu-animation-pulse",
              attrs: { height: 20 },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }