import { guid } from '@/Utils';
import { toPascalCase } from '@/Utils/StringUtility';

/**
 * Types of navigation links.
 * @readonly
 * @enum {string}
 */
export const navigationItemTypes = {
    /**
     * Internal links using Vue Router.
     */
    internal: 'internal',
    /**
     * External links, such as HTML links.
     */
    external: 'external',
    /**
     * Internal HTML links.
     */
    link: 'link',
    /**
     * Actions that are not links.
     */
    action: 'action',
};

/**
 * Creates a navigation item with the specified options.
 *
 * @param {Object} options - An object containing the following properties:
 * @param {string} options.label - The label or text for the navigation item.
 * @param {string|Object} options.to - The destination of the navigation item, which can be a Vue Router route name or an external URL, depending on the `navigationType`.
 * @param {boolean} [options.isEnabled=true] - Indicates whether the navigation item is enabled.
 * @param {string} [options.iconName] - The name of the icon associated with the navigation item (optional).
 * @param {Array} [options.subItems] - An array of sub-navigation items (optional).
 * @param {string} [options.navigationItemType='internal'] - The type of navigation link, which can be 'internal' (Vue Router link), 'external' (HTML link), 'link' (internal HTML link), or 'action' (non-link action).
 * @param {Function} [options.action] - An optional function to execute when the navigation item is activated (optional).
 *
 * @returns {Object} A navigation item object.
 * @throws {Error} If an unsupported navigationItemType is provided.
 */
export function createNavigationItem(options) {
    const {
        label,
        to,
        isEnabled = true,
        iconName = null,
        subItems = [],
        navigationItemType = navigationItemTypes.internal,
        action = () => { },
        namespace = 'fa',
    } = options;

    const icon = options.icon || { name: options.iconName || null, namespace };
    const key = options.key || toPascalCase(label);

    if (!Object.keys(navigationItemTypes).includes(navigationItemType)) {
        throw new Error('Unsupported link type');
    }

    return {
        key,
        navigationItemId: guid(),
        label,
        to,
        iconName,
        icon,
        isEnabled,
        navigationItemType,
        subItems,
        action,
    };

}
/**
 * Creates a list of navigation items and filters it based on the provided filter function.
 *
 * @param {Array} items - An array of navigation items to filter.
 * @param {Function} [filter] - An optional filter function that determines which items are included in the resulting list (optional).
 *
 * @returns {Array} A filtered list of navigation items.
 */
export function createNavigationItemList(items, filter = item => item.isEnabled) {
    return items.filter(filter);
}

/**
 * Configures target and rel attributes for external navigation items.
 *
 * @param {Object} createNavigationItem - The navigation item to configure.
 * @returns {Object} The navigation item with updated target and rel attributes.
 */
export function configureExternalNavigationItems(item) {
    return {
        ...item,
        target: navigationItemTypes.external === item.navigationItemType ? '_blank' : null,
        rel: navigationItemTypes.external === item.navigationItemType ? 'noopener noreferrer' : null,
        isTypeExternal: navigationItemTypes.external === item.navigationItemType,
    };
}