var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "adyen-payment-context-provider",
    _vm._b(
      {},
      "adyen-payment-context-provider",
      _vm.paymentConfiguration,
      false
    ),
    [
      _vm.isOrderPayable
        ? _c("adyen-order-payment-options", {
            attrs: {
              "project-id": _vm.projectContext.projectID,
              "project-phase": _vm.projectContext.project.phase,
              "project-type": _vm.projectContext.project.type,
              "is-instant": _vm.isInstant,
              "checkout-mode": _vm.checkoutMode,
              "effective-payable-amount": _vm.effectivePayableAmount,
              "order-code": _vm.orderCode,
              "handle-tax": _vm.handleTax,
              "order-validation-id": _vm.orderValidationID,
              "payment-type": _vm.paymentType,
              reason: _vm.reason,
              "order-id": _vm.orderID,
              "is-in-test-mode": _vm.isInTestMode,
              "data-qa": "payment-section",
            },
            on: {
              "payment-error": _vm.onPaymentError,
              "payment-method-change": _vm.onPaymentMethodChange,
              "toggle-payment-group-visibility-change":
                _vm.onTogglePaymentGroupVisibilityChange,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "actions",
                  fn: function ({
                    onSubmit,
                    isProceedPaymentBlocked,
                    paymentMethodErrorMessage,
                  }) {
                    return [
                      _c("div", { staticClass: "gfu-box-actions" }, [
                        _c(
                          "div",
                          { staticClass: "gfu-box-actions__item" },
                          [
                            _c(
                              "base-link",
                              {
                                staticClass: "_tc--accent _flex _ai-c",
                                attrs: { url: _vm.projectUrl },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onGoBack.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c("base-icon", {
                                  attrs: {
                                    name: "chevron-left _mr-1",
                                    "data-qa": "create-order-btn:Back",
                                  },
                                }),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.Resources.Common.GoBackButton) +
                                    "\n                    "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "gfu-box-actions__item" },
                          [
                            _c(
                              "tooltip-button",
                              {
                                attrs: {
                                  disabled: isProceedPaymentBlocked,
                                  "tooltip-text": paymentMethodErrorMessage,
                                  enabled: isProceedPaymentBlocked,
                                  "data-qa": "create-order-btn:Submit",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return onSubmit.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.payableButtonLabel) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-qa": "create-order-btn:Price",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.amount))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.installmentsSelectedAndPhaseValid
                              ? _c("installments-info-box", {
                                  attrs: { amount: _vm.orderAmountWithSymbol },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1299039597
            ),
          })
        : _c(
            "div",
            [
              _c("terms-of-service-checkbox-field", {
                attrs: { name: "model.termsAccepted" },
                model: {
                  value: _vm.model.termsAccepted,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "termsAccepted", $$v)
                  },
                  expression: "model.termsAccepted",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-box-actions" },
                [
                  _c(
                    "div",
                    { staticClass: "gfu-box-actions__item _ml-a _pt-0" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "gfu-btn--block",
                          attrs: {
                            theme: "accent",
                            "data-qa": "create-order-btn:Submit",
                          },
                          on: { click: _vm.submitChangedOrder },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.nonPayableButtonLabel) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("progress-overlay", {
                    attrs: { visible: _vm.isProcessing },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }