import {
    getMidPoint,
    getPinchDistance,
    isState,
    defaultOptions,
    onDoubleTap,
} from '@/Components/Gallery/ZoomableGallery/ZoomableGalleryUtils';

import ZoomableImageHandler from '@/Components/Gallery/ZoomableGallery/ZoomableImageHandler';

// ZoomableImage class definition
class ZoomableImage {
    constructor({ container, image, zoomInButton, zoomOutButton }) {
        // Initializing instance properties
        this.container = container;
        this.image = image;
        this.zoomInButton = zoomInButton;
        this.zoomOutButton = zoomOutButton;
        this.state = 'default';
        this.scale = 1;
        this.lastTapTime = 0;
        this.isTouchDevice = false;
        this.isTouching = false;
        this.isMoving = false;
        this.wheelTimeout = null;
        this.startTouch = {
            x: 0,
            y: 0,
            distance: 0,
            touches: [],
        };
        // Creating an instance of ZoomableImageHandler which has a main logic of zooming and panning
        this.instance = new ZoomableImageHandler({
            container: this.container,
            element: this.image,
            minScale: defaultOptions.minScale,
            maxScale: defaultOptions.maxScale,
            scaleSensitivity: 20,
        });

        // Bind event handlers
        this.onStart = this.onStart.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onEndTouch = this.onEndTouch.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseEnd = this.onMouseEnd.bind(this);
        this.onZoomInClick = this.onZoomInClick.bind(this);
        this.onZoomOutClick = this.onZoomOutClick.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        // Adding event listeners to the container and buttons
        Object.entries(this.eventMethods).forEach(([event, method]) => {
            this.container.addEventListener(event, method, { passive: false });
        });
        this.zoomInButton.addEventListener('click', this.onZoomInClick);
        this.zoomOutButton.addEventListener('click', this.onZoomOutClick);
    }

    // Method to handle touchstart event
    onStart(event) {
        // Generally this function setup the state to define how much of the touch event is happening
        this.isTouchDevice = true;
        // Check state and return if not allowed
        if (isState(this.state, 'multiGesture')) return;
        // Get touch count
        const touchCount = event.touches.length;
        // Handle multi-touch
        if (touchCount === 2 && isState(this.state, 'default', 'singleGesture')) {
            const { x, y } = getMidPoint(event);
            this.startTouch.x = x;
            this.startTouch.y = y;
            this.startTouch.distance = getPinchDistance(event) / this.scale;
            this.startTouch.touches = [event.touches[0], event.touches[1]];
            this.lastTapTime = 0;
            this.state = 'multiGesture';
            return;
        }
        if (touchCount !== 1) {
            this.state = 'default';
            return;
        }
        // Handle single touch
        this.state = 'singleGesture';
        const [touch] = event.touches;
        this.startTouch.x = touch.pageX;
        this.startTouch.y = touch.pageY;
        this.startTouch.distance = 0;
        this.startTouch.touches = [touch];
    }

    // Method to handle touchmove event
    onMove(event) {
        if (isState(this.state, 'default')) return;
        const touchCount = event.touches.length;
        // Handle multi-touch movement
        if (isState(this.state, 'multiGesture') && touchCount === 2) {
            event.preventDefault();
            const scaleFactor = getPinchDistance(event) / this.startTouch.distance;
            const { x, y } = getMidPoint(event);
            this.instance.zoomPan({ scale: scaleFactor, x, y, deltaX: x - this.startTouch.x, deltaY: y - this.startTouch.y });
            this.startTouch.x = x;
            this.startTouch.y = y;
            this.isTouching = true;
            return;
        }
        if (this.scale === defaultOptions.minScale || !isState(this.state, 'singleGesture') || touchCount !== 1 || event.touches[0].identifier !== this.startTouch.touches[0].identifier) {
            return;
        }
        // Handle single touch movement
        event.preventDefault();
        const [touch] = event.touches;
        const deltaX = touch.pageX - this.startTouch.x;
        const deltaY = touch.pageY - this.startTouch.y;
        this.instance.pan({ originX: deltaX, originY: deltaY });
        this.startTouch.x = touch.pageX;
        this.startTouch.y = touch.pageY;
        this.isTouching = true;
    }

    // Method to handle touchend and touchcancel events
    onEndTouch(event) {
        if (isState(this.state, 'default') || event.touches.length !== 0) {
            return;
        }
        // Logic to check if user double tapped
        const currentTime = Date.now();
        const tapLength = currentTime - this.lastTapTime;
        if (tapLength < defaultOptions.doubleTapTime && tapLength > 0) {
            event.preventDefault();
            const [touch] = event.changedTouches;
            if (!touch) return;
            this.scale = onDoubleTap({ instance: this.instance, scale: this.scale, x: touch.clientX, y: touch.clientY });
        }
        this.lastTapTime = currentTime;
        this.scale = this.instance.getScale();
        this.state = 'default';
        /* This setTimeout is used for proper work of Touch.js component.
         If this setTimeout won't exists, the slide will be changed after the minimum scale is reached by zoom out gesture.*/
        if (this.scale === 1) {
            setTimeout(() => {
                this.isTouching = false;
            }, 200);
        }
    }

    // Method to handle wheel event
    onWheel(event) {
        if (this.isTouchDevice) return;
        event.preventDefault();
        this.instance.zoom({
            deltaScale: Math.sign(event.deltaY) > 0 ? -1 : 1,
            x: event.pageX,
            y: event.pageY,
        });
        this.wheelTimeout = setTimeout(() => {
            this.scale = this.instance.getScale();
        }, 100);
    }

    // Method to handle zoom in button click event
    onZoomInClick(event) {
        event.preventDefault();
        this.instance.zoom({
            deltaScale: 5,
            x: window.screen.width / 2,
            y: window.screen.height / 2,
        });
        this.scale = this.instance.getScale();
    }

    // Method to handle zoom out button click event
    onZoomOutClick(event) {
        event.preventDefault();
        this.instance.zoom({
            deltaScale: -5,
            x: window.screen.width / 2,
            y: window.screen.height / 2,
        });
        this.scale = this.instance.getScale();
    }

    // Method to handle mouse move event
    onMouseMove(event) {
        event.preventDefault();
        const isDisabled = [this.isTouchDevice, event.buttons !== 1, this.scale === defaultOptions.minScale, event.movementX === 0, event.movementY === 0].some(c => c);
        if (isDisabled) return;
        this.state = 'mouse';
        this.isMoving = true;
        this.instance.pan({ originX: event.movementX, originY: event.movementY });
    }

    // Method to handle mouse end event
    onMouseEnd() {
        if (this.isTouchDevice) return;
        this.state = 'default';
        this.isMoving = false;
        this.scale = this.instance.getScale();
    }

    onMouseUp() {
        const isDisabled = [this.isTouchDevice, this.scale === defaultOptions.minScale].some(c => c);
        if (isDisabled) return;
        this.state = 'default';
        this.isMoving = false;
    }

    // Method to reset the instance
    reset() {
        this.state = 'default';
        this.scale = 1;
        this.lastTapTime = 0;
        this.startTouch.x = 0;
        this.startTouch.y = 0;
        this.startTouch.distance = 0;
        this.startTouch.touches = [];
        this.isTouching = false;
        this.isMoving = false;
        this.instance.reset();
    }

    // Method to remove event listeners
    destroy() {
        Object.entries(this.eventMethods).forEach(([event, method]) => {
            this.container.removeEventListener(event, method);
        });
        this.zoomInButton.removeEventListener('click', this.onZoomInClick.bind(this));
        this.zoomOutButton.removeEventListener('click', this.onZoomOutClick.bind(this));
    }

    get eventMethods() {
        return {
            'touchstart': this.onStart,
            'touchmove': this.onMove,
            'touchend': this.onEndTouch,
            'touchcancel': this.onEndTouch,
            'mousemove': this.onMouseMove,
            'mouseup': this.onMouseUp,
            'mouseleave': this.onMouseEnd,
            'mouseout': this.onMouseEnd,
            'wheel': this.onWheel,
        };
    }
}

export default ZoomableImage;