var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper gfu-signing-page" }, [
    _c(
      "div",
      {
        staticClass:
          "gfu-1of1 gfu-6of12--m gfu-6of12--xl gfu-signing-page__intro",
      },
      [
        _c(
          "div",
          { staticClass: "gfu-signing-page__benefits" },
          [
            _c("span", { staticClass: "_fw-b" }, [
              _vm._v(
                _vm._s(_vm.Resources.Account.LoginToGamefoundBenefitsListHeader)
              ),
            ]),
            _vm._v(" "),
            _c("account-benefit-list"),
          ],
          1
        ),
        _vm._v(" "),
        _c("base-image", {
          staticClass: "gfu-signing-page__image",
          attrs: { src: "/content/images/static/signing/signing-image.svg" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gfu-1of1 gfu-5of12--m gfu-4of12--xl" },
      [
        _c("div", { staticClass: "gfu-content-head _mt-0" }, [
          _c(
            "h1",
            {
              staticClass: "gfu-hd gfu-hd--h1",
              attrs: { "data-qa": "page-title" },
            },
            [_vm._v(_vm._s(_vm.Resources.Account.LogInHeader))]
          ),
        ]),
        _vm._v(" "),
        _c("external-login-actions", {
          staticClass: "_mb-3",
          attrs: { "return-url": _vm.returnUrl },
        }),
        _vm._v(" "),
        _c("login-form", {
          staticClass: "gfu-box gfu-box--thick",
          attrs: { "return-url": _vm.returnUrl, "data-qa": "login-form" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }