const __ISODateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,7})?(Z|[+-]\d{2}:\d{2})$/;
export function reviveJSONDates(key, value) {
    if (typeof value === 'string' && __ISODateTimeRegex.test(value))  {
        return new Date(value);
    }
    else {
        return value;
    }
}

export function parseJSON(data) {
    if (typeof data === 'string') {
        try {
            data = JSON.parse(data, reviveJSONDates);
        }
        catch (e) { /* Ignore */ }
    }
    return data;
}