var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      {
        class: [
          "gfu-field__drop-area",
          {
            "gfu-field__drop-area--active": _vm.isActive && _vm.enabled,
            "gfu-field__drop-area--preview": _vm.preview,
            "gfu-field__drop-area--disabled": !_vm.enabled,
          },
        ],
        attrs: { "data-qa": "drop-area" },
      },
      _vm.fieldListeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }