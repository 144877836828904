var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "fieldset",
        [
          _c("div", [
            _vm._v(
              _vm._s(
                _vm.Resources.Account
                  .TwoFactorAuthenticationEmailSecurityCodeLeadParagraph
              )
            ),
          ]),
          _vm._v(" "),
          _c("text-field", {
            attrs: {
              name: "authenticationData",
              "display-name": _vm.Resources.Account.SecurityCodeFieldLabel,
              "data-qa": "login-form-two-factor-step:SecurityCodeField",
            },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.submit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.authenticationData,
              callback: function ($$v) {
                _vm.authenticationData = $$v
              },
              expression: "authenticationData",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "_mt-1" }, [
            _c(
              "button",
              {
                staticClass:
                  "gfu-btn gfu-btn--accent gfu-btn--block gfu-btn--soft gfu-btn__text",
                attrs: {
                  type: "button",
                  "data-qa": "login-form-two-factor-step:LoginButton",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "_pl-6 _pr-6" }, [
                  _vm._v(_vm._s(_vm.Resources.Account.LogInButton)),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_mt-3" },
            [
              _c(
                "localize",
                {
                  attrs: {
                    resource:
                      _vm.Resources.Account
                        .TwoFactorAuthenticationEmailSecurityCodeResendParagraphPart1,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: {
                        "format-key": "0",
                        href: "",
                        "data-qa": "login-form-two-factor-step:ResendCodeLink",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.resendCode.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.Account
                            .TwoFactorAuthenticationEmailSecurityCodeResendParagraphPart2
                        )
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_mt-3" },
            [
              _c(
                "localize",
                {
                  attrs: {
                    resource:
                      _vm.Resources.Account
                        .TwoFactorLoginStepCancelParagraphPart1,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: { "format-key": "0", href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.cancel.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.Resources.Account
                            .TwoFactorLoginStepCancelParagraphPart2
                        )
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }