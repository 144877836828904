// Constants
export const defaultOptions = {
    minScale: 1,
    maxScale: 4,
    doubleTapTime: 185,
};

// Utility functions
export function isState(state, ...states) {
    return states.includes(state);
}

export function getPinchDistance(event) {
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    return Math.hypot(touch2.pageX - touch1.pageX, touch2.pageY - touch1.pageY);
}

export function getMidPoint(event) {
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    return {
        x: (touch1.pageX + touch2.pageX) / 2,
        y: (touch1.pageY + touch2.pageY) / 2,
    };
}

export function onDoubleTap({ instance, scale, x, y }) {
    if (scale < defaultOptions.maxScale) {
        instance.zoomTo({ newScale: defaultOptions.maxScale, x, y });
        return defaultOptions.maxScale;
    }
    else {
        instance.reset();
        return defaultOptions.minScale;
    }
}