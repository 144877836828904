var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
    [
      _c("div", { staticClass: "gfu-content-head" }, [
        _c("h1", { staticClass: "gfu-hd gfu-hd--h1" }, [
          _vm._v(_vm._s(_vm.Resources.Contact.PageTitle)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "_mt-0" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.Resources.Contact.PageSubtitle) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        _vm.component.name,
        _vm._b(
          {
            key: _vm.component.key,
            tag: "component",
            on: { error: _vm.onError, back: _vm.onBack },
          },
          "component",
          _vm.component.props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }