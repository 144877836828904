import ServiceBase from './ServiceBase';

export default class PushNotificationsService extends ServiceBase {
    getPushNotifications() {
        return this.request('get', 'pushNotifications/getPushNotifications');
    }
    enablePushNotifications(deviceToken) {
        return this.request('post', 'pushNotifications/enablePushNotifications', { deviceToken });
    }
    getUnreadNotificationsCount() {
        return this.request('get', 'pushNotifications/getUnreadNotificationsCount');
    }
}