var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.validatorLayout === "default"
    ? _c(
        "p",
        {
          staticClass: "gfu-field__error",
          attrs: {
            "data-qa-value": _vm.$slots.default[0].text,
            "data-qa": "field-error",
          },
        },
        [_vm._t("default")],
        2
      )
    : _vm.validatorLayout === "tooltip"
    ? _c(
        "tooltip",
        {
          staticClass: "gfu-field__error gfu-field__error--tooltip",
          attrs: {
            theme: "error",
            "vertical-position": "down",
            "data-qa-value": _vm.$slots.default[0].text,
            text: _vm.$slots.default[0].text,
            "data-qa": "field-error",
          },
        },
        [_c("span", { staticClass: "_fa _fa--info" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }