
    import { mapState } from 'pinia';
    import { orderStates } from '@/Models';
    import { useCurrencyStore } from '@/Stores/Web/currencyStore';
    import { useProjectUserContextStore } from '@/Stores/Web/projectUserContextStore';
    import { useCartSummaryStore } from '@/Stores/Web/cartSummaryStore';
    import { usePageSectionsStore } from '@/Stores/Web/pageSectionsStore';
    import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
    import ComponentBase from '@/Components/ComponentBase';
    import Tooltip from '@/Components/Tooltip.vue';
    import CartsService from '@/Services/CartsService';
    import { cancelPledge } from '@/Utils/CancelPledgeUtility';
    import DisplayCurrencyPrice from '@/Components/DisplayCurrencyPrice.vue';
    import GoToCheckout from '@/Components/Carts/GoToCheckout.js';
    import DetectOverflow from '@/Components/Utils/DetectOverflow.js';
    import ProgressOverlay from '@/Components/ProgressOverlay.vue';
    import MiniWizardItem from '@/Components/Projects/MiniWizard/MiniWizardItem.vue';
    import MiniWizardItemsContent from '@/Components/Projects/MiniWizard/MiniWizardItemsContent.vue';
    import { useProjectStore } from '@/Stores/Web/projectStore';

    export default {
        extends: ComponentBase,
        inject: ['productSystemCategories'],
        components: {
            Tooltip,
            MiniWizardItem,
            MiniWizardItemsContent,
            DisplayCurrencyPrice,
            GoToCheckout,
            DetectOverflow,
            ProgressOverlay,
        },
        data() {
            return {
                cartsService: new CartsService(this.$http),
            };
        },
        methods: {
            onDeleteItem(item) {
                return this.wrapAsyncTask(this.wrapApiAction(this.cartsService.removeOrderItem(
                    item.orderItemID, this.pledge.projectID, 0))
                    .then(() => this.updateCartSummary(this.projectContext.projectID)));
            },
            onRevertChanges() {
                return this.wrapAsyncTask(this.wrapApiAction(this.cartsService.revertChanges(this.pledge.projectID))
                    .then(() => this.updateCartSummary(this.projectContext.projectID)));
            },
            onCancelPledge() {
                return cancelPledge.call(this, this.projectContext.project.phase, this.currentPledge.cancelOrderUrl);
            },
            onEditPledge() {
                return this.wrapAsyncTask(this.wrapApiAction(this.cartsService.enablePledgeEdit(this.pledge.projectID))
                    .then(() => this.updateCartSummary(this.projectContext.projectID)));
            },
        },
        computed: {
            canSetShipping() {
                return !this.isProjectInFundingPhase && this.cartCandidate?.cart?.orderState === orderStates.pledgedAndPaid;
            },
            currencySymbol() {
                return this.pledge.currencySymbol || this.currentCheckoutCurrency.symbol;
            },
            addonsSection() {
                return this.pageSections.find(s => s.urlName === 'addons');
            },
            rewardsSection() {
                return this.pageSections.find(s => s.urlName === 'rewards');
            },
            pledge() {
                return this.currentPledge.cart || {
                    hasSameItemsAsParent: true,
                    orderState: orderStates.unfinished,
                    parentOrder: null,
                };
            },
            pledgeDate() {
                return this.isPledgePlaced ? this.pledge.createDate : null;
            },
            isUnfinishedOrder() {
                return this.pledge.orderState === orderStates.unfinished;
            },
            isPledgePlaced() {
                return !this.isUnfinishedOrder
                    || this.pledge.parentOrder !== null;
            },
            hasSameItemsAsParent() {
                return this.pledge.hasSameItemsAsParent;
            },
            isUnmodifiedPledge() {
                return !this.isUnfinishedOrder && this.pledge.parentOrder === null;
            },
            canDeleteItems() {
                return !this.isAwaitingPaymentPledge
                    && (this.isEditingPledge && (this.currentPledge.canEditPledgedItems || this.currentPledge.cart?.canAddNewItems)
                        || this.pledge.parentOrder === null && this.isUnfinishedOrder);
            },
            canAddNewItems() {
                return this.currentPledge.cart?.canAddNewItems;
            },
            isPledgeEmpty() {
                return this.currentPledge.itemsCount === 0;
            },
            allItemsRemoved() {
                return this.pledge.parentOrder !== null && this.isPledgeEmpty;
            },
            allChargeableItemsRemoved() {
                return this.pledge.parentOrder !== null && this.pledge.orderItems?.every(i => i.promoItemID !== null);
            },
            isEditingPledge() {
                return !!this.pledge.parentOrder;
            },
            isAwaitingPaymentPledge() {
                return this.projectUserContext?.hasOrderAwaitingPayment;
            },
            hasTaxHandlingEnabled() {
                return this.currentPledge.cart?.hasTaxHandlingEnabled;
            },
            taxesSummaryLabel() {
                return this.currentPledge.cart?.hasTaxInfoDefined
                    ? this.Resources.ProjectHome.MiniWizardTaxSummaryInclTaxLabel
                    : this.Resources.ProjectHome.MiniWizardTaxSummaryPlusTaxLabel;
            },
            canEditPledge() {
                return this.isPledgeEditable(this.orderAwaitingPayment?.canEditPledgedItems);
            },
            ...mapState(usePageSectionsStore, ['pageSections']),
            ...mapState(useProjectStore, ['promoItem']),
            ...mapState(useCurrencyStore, ['currentCheckoutCurrency']),
            ...mapState(useProjectUserContextStore, ['projectUserContext', 'orderAwaitingPayment']),
            ...mapState(useProjectContextStore, ['isProjectInFundingPhase']),
            ...mapState(useCartSummaryStore, [
                'currentPledge',
                'cartCandidate',
                'isPledgeEditable',
                'isPledgeImplicitEditMode',
            ]),
        },
    };
