var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: [
        "gfu-poll-option gfu-media",
        {
          "is-muted": _vm.isMuted,
          "is-disabled": _vm.isDisabled,
          "is-voted": _vm.userVotedOption,
          "is-blocked": !_vm.canVote,
        },
      ],
      attrs: { href: "#" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.showRadio
        ? _c(
            "div",
            { staticClass: "_mr-2 _tc--light" },
            [
              _c("checkbox-field", {
                class: [
                  "gfu-poll-option__radio",
                  { "is-invisible": _vm.cannotVote },
                ],
                attrs: { type: "radio", name: `poll-${_vm.pollID}` },
                model: {
                  value: _vm.userVotedOption,
                  callback: function ($$v) {
                    _vm.userVotedOption = $$v
                  },
                  expression: "userVotedOption",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-media__body" },
        [
          _c("div", { staticClass: "gfu-poll-option__text" }, [
            _c("span", { staticClass: "_mr-2 _my-0 _ml-0" }, [
              _vm._v(_vm._s(_vm.text)),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "span",
                {
                  staticClass: "gfu-bt gfu-bt--caption",
                  attrs: { "data-qa": "poll-option-vote-label" },
                },
                [_vm._v(_vm._s(_vm.voteLabel))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "gfu-bt gfu-bt--caption _tc--light" }, [
                _vm._v(_vm._s(_vm.voteLabelPercentage)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.isVoteCountVisible
            ? _c("progress-bar", {
                attrs: {
                  value: _vm.voteCount,
                  max: _vm.totalVoteCount,
                  "progression-class":
                    "gfu-progress-bar__progression--accent gfu-progress-bar--animated-quick",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }