var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("empty-state", {
    attrs: {
      title: "There's nothing to show here",
      description:
        "The resource you're looking for doesn't exist or you don't have permissions to view it.",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }