var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("box", { staticClass: "gfu-box--huge" }, [
    _c(
      "div",
      { staticClass: "_flexwrap _tac _jc-c" },
      _vm._l(_vm.marketingServicesValues, function (value) {
        return _c(
          "values-section-item",
          _vm._b(
            { key: value.valueID, staticClass: "gfu-1of2 gfu-1of4--m _pa-4" },
            "values-section-item",
            value,
            false
          )
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }