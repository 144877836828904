var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-hd gfu-hd--h3 _tc--inherit",
      attrs: { "data-qa": "mini-wizard-header:Backer" },
    },
    [
      _vm.backerNumber
        ? _c(
            "span",
            [
              _c(
                "localize",
                {
                  attrs: {
                    resource:
                      _vm.Resources.ProjectHome.MiniWizardBackerNumberText,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      attrs: {
                        "format-key": "0",
                        "data-qa": "mini-wizard-header:BackerNumber",
                      },
                    },
                    [_vm._v(_vm._s(_vm.backerNumberFormatted))]
                  ),
                ]
              ),
            ],
            1
          )
        : _c("span", [_vm._v("\n        " + _vm._s(_vm.header) + "\n    ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }