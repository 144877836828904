import {
    clone,
    cloneDeep,
    escape,
    escapeRegExp,
    findKey,
    isEmpty,
    isEqual,
    isNil,
    merge,
    max,
    last,
    groupBy,
    // TODO: These should be removed and native js methods should be used instead
    // calls to those methods should be rewritten and tested on by one to make sure nothing breaks
    filter,
    find,
    each,
} from 'lodash';

export {
    clone,
    cloneDeep,
    escape,
    escapeRegExp,
    findKey,
    isEmpty,
    isEqual,
    isNil,
    merge,
    filter,
    find,
    max,
    last,
    groupBy,
    each,
};

const bailRE = /[^\w.$]/;

export function parsePath(path) {
    if (bailRE.test(path)) {
        return;
    }
    const segments = path.split('.');
    return function (obj) {
        for (let i = 0; i < segments.length; i++) {
            if (obj == null || !Object.hasOwnProperty.call(obj, segments[i])) return;
            obj = obj[segments[i]];
        }
        return obj;
    };
}

export function flatten(obj) {
    if (!obj || typeof obj !== 'object')
        return obj;

    const dstObj = {};

    const _flatten = function (srcObj, fieldName) {
        Object.keys(srcObj).forEach(key => {
            const newFieldName = fieldName.length ? `${fieldName}.${key}` : key;
            if (typeof srcObj[key] === 'object' && srcObj[key])
                _flatten(srcObj[key], newFieldName);
            else
                dstObj[newFieldName] = srcObj[key];
        });
    };

    _flatten(obj, '');

    return dstObj;
}

/**
 * Generates a new guid
 */
export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

const nativeMax = Math.max;
const nativeMin = Math.min;
export function debounce(func, wait = 500, options) {
    let lastArgs;
    let lastThis;
    let maxWait;
    let result;
    let timerId;
    let lastCallTime;
    let lastInvokeTime = 0;
    let leading = false;
    let maxing = false;
    let trailing = true;
    if (typeof func !== 'function') {
        throw new TypeError('Expected a function');
    }
    wait = Number(wait) || 0;
    if (typeof options === 'object') {
        leading = !!options.leading;
        maxing = 'maxWait' in options;
        maxWait = maxing
            ? nativeMax(Number(options.maxWait) || 0, wait)
            : maxWait;
        trailing = 'trailing' in options
            ? !!options.trailing
            : trailing;
    }

    function invokeFunc(time) {
        const args = lastArgs;
        const thisArg = lastThis;

        lastArgs = lastThis = undefined;
        lastInvokeTime = time;
        result = func.apply(thisArg, args);
        return result;
    }

    function leadingEdge(time) {
        // Reset any `maxWait` timer.
        lastInvokeTime = time;
        // Start the timer for the trailing edge.
        timerId = setTimeout(timerExpired, wait);
        // Invoke the leading edge.
        return leading
            ? invokeFunc(time)
            : result;
    }

    function remainingWait(time) {
        const timeSinceLastCall = time - lastCallTime;
        const timeSinceLastInvoke = time - lastInvokeTime;
        const result = wait - timeSinceLastCall;
        return maxing
            ? nativeMin(result, maxWait - timeSinceLastInvoke)
            : result;
    }

    function shouldInvoke(time) {
        const timeSinceLastCall = time - lastCallTime;
        const timeSinceLastInvoke = time - lastInvokeTime;
        // Either this is the first call, activity has stopped and we're at the trailing
        // edge, the system time has gone backwards and we're treating it as the
        // trailing edge, or we've hit the `maxWait` limit.
        return lastCallTime === undefined || timeSinceLastCall >= wait || timeSinceLastCall < 0 || maxing && timeSinceLastInvoke >= maxWait;
    }

    function timerExpired() {
        const time = Date.now();
        if (shouldInvoke(time)) {
            return trailingEdge(time);
        }
        // Restart the timer.
        timerId = setTimeout(timerExpired, remainingWait(time));
    }

    function trailingEdge(time) {
        timerId = undefined;

        // Only invoke if we have `lastArgs` which means `func` has been debounced at
        // least once.
        if (trailing && lastArgs) {
            return invokeFunc(time);
        }
        lastArgs = lastThis = undefined;
        return result;
    }

    function cancel() {
        if (timerId !== undefined) {
            clearTimeout(timerId);
        }
        lastInvokeTime = 0;
        lastArgs = lastCallTime = lastThis = timerId = undefined;
    }

    function flush() {
        return timerId === undefined
            ? result
            : trailingEdge(Date.now());
    }

    function debounced() {
        const time = Date.now();
        const isInvoking = shouldInvoke(time);
        lastArgs = arguments;
        lastThis = this;
        lastCallTime = time;

        if (isInvoking) {
            if (timerId === undefined) {
                return leadingEdge(lastCallTime);
            }
            if (maxing) {
                // Handle invocations in a tight loop.
                timerId = setTimeout(timerExpired, wait);
                return invokeFunc(lastCallTime);
            }
        }
        if (timerId === undefined) {
            timerId = setTimeout(timerExpired, wait);
        }
        return result;
    }
    debounced.cancel = cancel;
    debounced.flush = flush;
    return debounced;
}

export function throttle(func, wait, options) {
    let leading = true;
    let trailing = true;

    if (typeof func !== 'function') {
        throw new TypeError('Expected a function');
    }
    if (typeof options === 'object') {
        leading = 'leading' in options
            ? !!options.leading
            : leading;
        trailing = 'trailing' in options
            ? !!options.trailing
            : trailing;
    }
    return debounce(func, wait, {
        leading,
        maxWait: wait,
        trailing,
    });
}

export function checkElementViewportFit(elementOrBoundingRect) {
    const rect = elementOrBoundingRect.tagName ? elementOrBoundingRect.getBoundingClientRect() : elementOrBoundingRect;

    return {
        top: rect.top >= 0 || false,
        left: rect.left >= 0 || false,
        bottom: rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) || false,
        right: rect.right <= (window.innerWidth || document.documentElement.clientWidth) || false,
    };
}

export function mapObject(sourceObject, targetObject) {
    Object.keys(targetObject).forEach(key => {
        if (Object.hasOwnProperty.call(sourceObject, key)) {
            if (Array.isArray(sourceObject[key])) {// This is needed because typeof (array) returns 'object'
                targetObject[key] = sourceObject[key];
            }
            else if (typeof sourceObject[key] === 'object'
                && sourceObject[key] !== null
                && !(sourceObject[key] instanceof Date)) {
                mapObject(sourceObject[key], targetObject[key]);
            }
            else {
                targetObject[key] = sourceObject[key];
            }
        }
    });
}

/**
 * Behaves the same as setInterval except uses requestAnimationFrame for better performance
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
export function requestInterval(fn, delay) {
    let start = new Date().getTime();
    const handle = new Object();

    function loop() {
        const current = new Date().getTime();
        const delta = current - start;

        if (delta >= delay) {
            fn.call();
            start = new Date().getTime();
        }

        handle.value = window.requestAnimationFrame(loop);
    }

    handle.value = window.requestAnimationFrame(loop);
    return handle;
}

export function areArraysEqual(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}

export function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * Checks if value is a Number.
 * @param value The value to check.
 * @return Returns true if value is correctly classified, else false.
 */
export function isNumber(value) {
    return typeof value === 'number' && isFinite(value);
}

/**
 * Checks if the provided value is a date. Dont check numbers as dates.
 *
 * @param {*} value - The value to check.
 * @returns {boolean} Returns `true` if the value is a date, otherwise `false`.
 */
export function isDate(value) {
    const valueAsDate = new Date(value);
    return valueAsDate instanceof Date
        && !isNaN(valueAsDate)
        && isNaN(value)
        && !Array.isArray(value); // Chrome parse array with numbers as valid date.
}
