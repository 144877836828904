import { LocationsServiceBase } from './BaseServices';

export default class LocationsService extends LocationsServiceBase {
    constructor($http, errorHandler) {
        super($http, errorHandler);
    }

    createLocation(location) {
        const params = {
            ...location,
        };

        return this.request('post', 'siteadmin/locations/createLocation', params);
    }

    createSubLocation(location) {
        const params = {
            ...location,
        };

        return this.request('post', 'siteadmin/locations/createSubLocation', params);
    }

    deleteLocation(locationID) {
        const params = {
            locationID,

        };

        return this.request('post', 'siteadmin/locations/deleteLocation', params);
    }

    deleteSubLocation(subLocationID) {
        const params = {
            subLocationID,

        };

        return this.request('post', 'siteadmin/locations/deleteSubLocation', params);
    }

    getLocation(locationID) {
        const params = {
            locationID,
        };

        return this.request('get', 'siteadmin/locations/getLocation', params);
    }

    getProjectLocations(projectID) {
        return this.request('get', 'locations/getProjectLocations', { params: { projectID } });
    }

    getProjectLocation(projectID, projectLocationID) {
        return this.request('get', 'locations/getProjectLocation', { params: { projectID, projectLocationID } });
    }

    getLocationSpecificFieldRules(projectLocationID) {
        return this.request('get', 'locations/getLocationSpecificFieldRules', { params: { projectLocationID } });
    }

    getProjectSubLocations(projectLocationID) {
        return this.request('get', 'locations/getProjectSubLocations', { params: { projectLocationID } });
    }

    validateVatNumber(projectID, projectLocationID, vatNumber) {
        return this.request('get', 'locations/validateVatNumber', { params: { projectID, projectLocationID, vatNumber } });
    }

    searchLocations(filters = {}, pageSize = 0, pageIndex = 0) {
        const params = {
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('get', 'siteadmin/locations/searchLocations', params);

    }

    searchProjectLocations(projectID, filters = {}, pageSize = 0, pageIndex = 0) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'locations/searchProjectLocations', params);
    }

    searchSubLocations(filters = {}, pageSize = 0, pageIndex = 0) {
        const params = {
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'siteadmin/locations/searchSubLocations', params);
    }

    searchProjectSubLocations(projectID, filters = {}, pageSize = 0, pageIndex = 0) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'locations/searchProjectSubLocations', params);
    }

    updateLocation(location) {
        const params = {
            ...location,
        };

        return this.request('post', 'siteadmin/locations/updateLocation', params);
    }

    updateProjectLocation(model) {
        return this.request('post', 'locations/updateProjectLocation', model);
    }

    updateProjectLocationFromShippingZone(model) {
        return this.request('post', 'locations/updateProjectLocationFromShippingZone', model);
    }

    updateShippingZoneForProjectLocations(model) {
        return this.request('post', 'locations/updateShippingZoneForProjectLocations', model);
    }

    updateLocationForProjects(location, currency, shippingZone, projectIDs) {

        const params = {
            currencyID: !currency ? null : currency.currencyID,
            projectIDs,
            shippingZoneName: shippingZone.name,
            ...location,
        };

        return this.request('post', 'locations/updateLocationForProjects', params);
    }

    updateProjectSubLocation(subLocation) {
        const params = {
            projectID: subLocation.projectID,
            projectSubLocationID: subLocation.projectSubLocationID,
            taxRate: subLocation.taxRate,
            isEnabled: subLocation.isEnabled,
            shippingZoneID: subLocation.shippingZoneID,
        };
        return this.request('post', 'locations/updateProjectSubLocation', params);
    }

    getSystemLocations() {
        return this.request('get', 'locations/getSystemLocations');
    }

    saveUserPreferredLocation(model) {
        return this.request('post', 'locations/saveUserPreferredLocation', model);
    }
}