var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: _vm.transitionName } }, [
    _vm.isOpen
      ? _c(
          "div",
          {
            class: [
              "gfu-drawer",
              _vm.positionClassName,
              { "is-open": _vm.isOpen },
            ],
          },
          [
            !_vm.isFilled
              ? _c("div", {
                  staticClass: "gfu-drawer__backdrop",
                  on: { click: _vm.onBackdropClick },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.$slots.header
              ? _c(
                  "div",
                  { staticClass: "gfu-drawer__header" },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "gfu-drawer__content",
                  { "gfu-drawer__content--fill": _vm.isFilled },
                ],
                attrs: { "data-qa": "drawer-content" },
              },
              [
                _c(
                  "div",
                  { staticClass: "gfu-drawer__body" },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _vm.$slots.footer
                  ? _c(
                      "div",
                      { staticClass: "gfu-drawer__footer" },
                      [_vm._t("footer")],
                      2
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }