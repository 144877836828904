var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-bar",
    {
      staticClass: "_flexwrap _jc-c _ai-c _tac _py-1",
      attrs: { "data-qa": "delivery-to:LocationBar" },
    },
    [
      _c("base-icon", {
        staticClass: "_tc--accent _mr-1",
        attrs: { size: "lg", name: "location-dot" },
      }),
      _vm._v(
        "\n    " +
          _vm._s(_vm.Resources.Layout.UserLocationModalFieldsetHeading) +
          ": \n    "
      ),
      _c("span", { attrs: { "data-qa": "delivery-to:UserLocation" } }, [
        _vm._v("\n        " + _vm._s(_vm.userCurrentLocationName) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "_tc--accent",
          attrs: { "data-qa": "delivery-to:ChangeLocation" },
          on: { click: _vm.changeLocation },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Layout.UserLocationBoxButtonLabel) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }