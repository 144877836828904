var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.notification
        ? _c("base-notificator", {
            staticClass: "_mt-2 _mb-2",
            attrs: {
              "can-close": false,
              message: _vm.notification.message,
              type: _vm.notification.type,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isProceedPaymentBlocked
        ? _c("base-notificator", {
            staticClass: "_mt-2 _mb-2",
            attrs: {
              "can-close": false,
              message: _vm.paymentMethodErrorMessage,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.groupedAvailablePaymentMethodItems, function (paymentGroup) {
        return _c(
          "div",
          {
            key: paymentGroup.title,
            attrs: { "data-qa": `payment-group:${paymentGroup.dataQa}` },
          },
          [
            paymentGroup.isCollapsible
              ? _c(
                  "div",
                  {
                    staticClass: "_tc--accent _mt-1 _ml-1 _select-none",
                    on: {
                      click: function ($event) {
                        return _vm.toggleGroupVisibility(
                          paymentGroup.sectionType
                        )
                      },
                    },
                  },
                  [
                    paymentGroup.isCollapsed
                      ? _c("div", { staticClass: "_flex _cur-p" }, [
                          _c("div", {
                            staticClass: "_fa _fa--chevron-down _mr-1",
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(paymentGroup.collapsedTitle) +
                              "\n            "
                          ),
                        ])
                      : _c("div", { staticClass: "_flex _cur-p" }, [
                          _c("div", {
                            staticClass: "_fa _fa--chevron-up _mr-1",
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(paymentGroup.title) +
                              "\n            "
                          ),
                        ]),
                  ]
                )
              : paymentGroup.isTitleVisible
              ? _c(
                  "h2",
                  { staticClass: "gfu-heading gfu-heading--light _mt-2" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(paymentGroup.title) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !paymentGroup.isCollapsible || !paymentGroup.isCollapsed
              ? _c(
                  "div",
                  _vm._l(paymentGroup.paymentMethods, function (paymentMethod) {
                    return _c(
                      "div",
                      {
                        key: paymentMethod.paymentDataSource,
                        class: [
                          "gfu-payment-tile",
                          {
                            "gfu-payment-tile--selected":
                              paymentMethod.isSelected,
                          },
                        ],
                        attrs: { "data-qa": paymentMethod.dataQa },
                      },
                      [
                        paymentMethod.label
                          ? _c(
                              "div",
                              { staticClass: "gfu-payment-tile__labels" },
                              [
                                _c(
                                  "base-label",
                                  {
                                    attrs: {
                                      theme: paymentMethod.labelTheme,
                                      "data-qa": `payment-method-label:${paymentMethod.label}`,
                                    },
                                  },
                                  [_vm._v(_vm._s(paymentMethod.label))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: ["_cur-p _pa-3"],
                            on: {
                              click: function ($event) {
                                return _vm.onPaymentMethodSelect(
                                  paymentMethod.paymentDataSource
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "_flex _ai-c" },
                              [
                                _c("checkbox-field", {
                                  staticClass: "_mt-0 _mr-2",
                                  attrs: {
                                    "show-label": false,
                                    name: "paymentMethod",
                                    "checked-value":
                                      paymentMethod.paymentDataSource,
                                    type: "radio",
                                  },
                                  model: {
                                    value: _vm.selectedPaymentMethod,
                                    callback: function ($$v) {
                                      _vm.selectedPaymentMethod = $$v
                                    },
                                    expression: "selectedPaymentMethod",
                                  },
                                }),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(paymentMethod.name) +
                                    "\n\n                        "
                                ),
                                paymentMethod.imageUrl
                                  ? _c("base-image", {
                                      staticClass: "_ml-a",
                                      attrs: {
                                        src: paymentMethod.imageUrl,
                                        alt: paymentMethod.name,
                                        width: "34",
                                        "data-qa": "payment-method-image",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            paymentMethod.isSelected
                              ? [
                                  !!paymentMethod.paymentDisclaimer
                                    ? _c(
                                        "ul",
                                        {
                                          staticClass: "gfu-list _mt-4",
                                          attrs: {
                                            "data-qa": "payment-disclaimer",
                                          },
                                        },
                                        _vm._l(
                                          paymentMethod.paymentDisclaimer,
                                          function (item) {
                                            return _c(
                                              "li",
                                              {
                                                key: item.iconName,
                                                staticClass: "_mt-1 _flex",
                                              },
                                              [
                                                _c("base-icon", {
                                                  staticClass:
                                                    "_mr-2 _tc--accent _shrink-0 _py-h",
                                                  attrs: {
                                                    size: "lg",
                                                    "is-fixed-width": true,
                                                    name: item.iconName,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "_flex _ai-c",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.paragraph)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        paymentMethod.isSelected
                          ? _c(
                              paymentMethod.form,
                              _vm._b(
                                {
                                  ref: "paymentMethodForm",
                                  refInFor: true,
                                  tag: "component",
                                  staticClass: "_pa-3",
                                  on: {
                                    "form-details-changed":
                                      _vm.onPaymentMethodFormChange,
                                  },
                                },
                                "component",
                                _vm.getPaymentMethodProps(paymentMethod),
                                false
                              )
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      !_vm.isPaymentMethodSelected && !_vm.isProceedPaymentBlocked
        ? _c("div", { staticClass: "gfu-field has-error" }, [
            _c(
              "p",
              {
                staticClass: "gfu-field__error _my-0",
                attrs: { "data-qa": "payment-method-required" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.Resources.Checkout.InvalidPaymentOptionErrorMessage
                    ) +
                    "\n        "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.requireAcceptTos && !_vm.isProceedPaymentBlocked
        ? _c("terms-of-service-checkbox-field", {
            attrs: { name: "model.termsAccepted" },
            model: {
              value: _vm.model.termsAccepted,
              callback: function ($$v) {
                _vm.$set(_vm.model, "termsAccepted", $$v)
              },
              expression: "model.termsAccepted",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.requireAcceptDigitalDelivery
        ? _c(
            "checkbox-field",
            {
              attrs: {
                condensed: true,
                "display-name": "Digital delivery agreement",
                name: "model.digitalDeliveryAccepted",
                "data-qa": "digital-delivery-agreement",
              },
              model: {
                value: _vm.model.digitalDeliveryAccepted,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "digitalDeliveryAccepted", $$v)
                },
                expression: "model.digitalDeliveryAccepted",
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.Resources.Checkout
                      .CheckboxDigitalDeliveryAgreementMessage
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { ref: "adyenRedirectAction" }),
      _vm._v(" "),
      _vm.isAdyenPaymentModalVisible
        ? _c("adyen-payment-modal", {
            attrs: {
              "project-id": _vm.projectId,
              "is-modal-visible": _vm.isAdyenPaymentModalVisible,
              "is-instant": _vm.isInstantPaymentSelected,
              "order-code": _vm.effectiveOrderCode,
              "provider-payment-id": _vm.currentProviderPaymentID,
              "checkout-mode": _vm.effectiveCheckoutMode,
              "payment-data-source": _vm.selectedPaymentMethod,
              "store-credit-card": _vm.shouldStoreCreditCard,
              "payment-type": _vm.model.paymentType,
              "payment-action": _vm.paymentActionData,
              "available-payment-methods": _vm.availablePaymentMethods,
            },
            on: {
              close: _vm.onAdyenPaymentModalClose,
              "payment-method-changed": _vm.onPaymentMethodChanged,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("actions", null, null, {
        onSubmit: _vm.onSubmit,
        isProceedPaymentBlocked: _vm.isProceedPaymentBlocked,
        paymentMethodErrorMessage: _vm.paymentMethodErrorMessage,
      }),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }