import { ExternalSignInErrorTypes } from '@/Types/ExternalSignInErrorTypes.js';

export class ExternalSignInError {
    constructor(code = null, msg = null) {
        if (Object.values(ExternalSignInErrorTypes).includes(code)) {
            this.statusCode = code;
            this.statusMessage = msg;
        }
        else {
            this.statusCode = 'Unspecified error.';
            this.statusMessage = 'Unspecified error. Please try again or contact us if problem persists.';
        }

    }
}