var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isInitialized
    ? _c(
        _vm.currentComponent,
        _vm._b({ tag: "component" }, "component", { ..._vm.$props }, false)
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }