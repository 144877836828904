var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.currentComponent.name,
    _vm._b(
      { key: _vm.currentComponent.name, tag: "component" },
      "component",
      _vm.currentComponent.props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }