var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("project-promo-item-provider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ model, promoItem, project, isVisible }) {
          return [
            isVisible
              ? _c(
                  _vm.getComponent(promoItem.type),
                  _vm._b(
                    {
                      tag: "component",
                      class: ["gfu-media gfu-link--nofx", _vm.alignItemClass],
                    },
                    "component",
                    _vm.getContextLink(model.contextLink),
                    false
                  ),
                  [
                    _c(
                      "span",
                      { staticClass: "gfu-thumb _mr-2" },
                      [
                        _c("base-image", {
                          staticClass: "gfu-thumb__item",
                          attrs: {
                            src: model.image.imageUrl,
                            width: _vm.imageSize,
                            height: _vm.imageSize,
                            alt: model.image.alt,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "gfu-media__body",
                        attrs: { "data-qa": "promo-item-copy" },
                      },
                      [
                        project.phase === _vm.projectPhases.crowdfunding
                          ? [
                              promoItem.type === _vm.promoItemTypes.discount
                                ? [
                                    !!model.countdown
                                      ? [
                                          _c(
                                            "localize",
                                            {
                                              attrs: {
                                                resource:
                                                  _vm.Resources.ProjectHome
                                                    .ProjectPromoItemTimeLeftForDiscountText,
                                              },
                                            },
                                            [
                                              _c(
                                                "strong",
                                                {
                                                  attrs: { "format-key": "0" },
                                                },
                                                [
                                                  _c(
                                                    "time-left",
                                                    _vm._b(
                                                      {},
                                                      "time-left",
                                                      _vm.getCountdownProps(
                                                        model
                                                      ),
                                                      false
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "strong",
                                                {
                                                  attrs: { "format-key": "1" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      model.discountAmountFormatted
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(model.countdown) +
                                              "\n                        " +
                                              _vm._s(model.label) +
                                              "\n                        "
                                          ),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.Resources.format(
                                                  _vm.Resources.ProjectHome
                                                    .ProjectPromoItemDiscountLabel,
                                                  model.discountAmountFormatted
                                                )
                                              ),
                                            },
                                          }),
                                          _vm._v(".\n                    "),
                                        ],
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              promoItem.type === _vm.promoItemTypes.product
                                ? [
                                    !!model.countdown
                                      ? [
                                          _c(
                                            "localize",
                                            {
                                              attrs: {
                                                resource:
                                                  _vm.Resources.ProjectHome
                                                    .ProjectPromoItemTimeLeftForGiftText,
                                              },
                                            },
                                            [
                                              _c(
                                                "strong",
                                                {
                                                  attrs: { "format-key": "0" },
                                                },
                                                [
                                                  _c(
                                                    "time-left",
                                                    _vm._b(
                                                      {},
                                                      "time-left",
                                                      _vm.getCountdownProps(
                                                        model
                                                      ),
                                                      false
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(model.label) +
                                              "\n                    "
                                          ),
                                        ],
                                  ]
                                : _vm._e(),
                            ]
                          : [
                              _vm._v(
                                "\n                " + _vm._s(model.label)
                              ),
                              promoItem.type === _vm.promoItemTypes.discount
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.Resources.format(
                                          _vm.Resources.ProjectHome
                                            .ProjectPromoItemDiscountLabel,
                                          model.discountAmountFormatted
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(".\n            "),
                            ],
                        _vm._v(" "),
                        _c("tooltip", { attrs: { text: model.tooltip } }, [
                          _c("span", { staticClass: "_fa _fa--info" }),
                        ]),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }