var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "social-media-actions" } },
    [
      _vm.enableCopy
        ? _c("copy-to-clipboard", {
            staticClass: "gfu-social-media-action",
            attrs: {
              "text-to-copy": _vm.shareUrl,
              fixed: true,
              "vertical-position": "down",
              "data-qa": "social-media-actions:Copy",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.enableFacebook
        ? _c(
            "span",
            {
              staticClass:
                "gfu-link gfu-link--inline gfu-link--nofx gfu-social-media-action",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.shareOnFacebook.apply(null, arguments)
                },
              },
            },
            [
              _c("base-icon", {
                attrs: {
                  "icon-namespace": "brands",
                  name: "facebook",
                  "data-qa": "social-media-actions:Facebook",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.enableTwitter
        ? _c(
            "span",
            {
              staticClass:
                "gfu-link gfu-link--inline gfu-link--nofx gfu-social-media-action",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.shareOnTwitter.apply(null, arguments)
                },
              },
            },
            [
              _c("base-icon", {
                attrs: {
                  "icon-namespace": "brands",
                  name: "x-twitter",
                  "data-qa": "social-media-actions:Twitter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }