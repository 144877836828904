var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isNotificatorVisible
    ? _c(
        "base-notificator",
        {
          staticClass: "_my-3",
          attrs: { type: "warning", "can-close": false },
        },
        [
          _c(
            "localize",
            { attrs: { resource: _vm.paragraphText } },
            [
              _c(
                "base-link",
                {
                  staticClass: "_tdu",
                  attrs: {
                    "format-key": "0",
                    url: _vm.checkoutReturnActionUrl,
                  },
                },
                [_vm._v("\n            " + _vm._s(_vm.linkText) + "\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }