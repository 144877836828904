export default class Overlay {
    constructor(elementSelector, styleOnEnable = null) {
        this.styleOnEnable = styleOnEnable || 'position: fixed; width: 100%;';
        this.scrollPosition = 0;
        this.enabled = false;
        this.element = document.querySelector(elementSelector);
    }

    enable() {
        if (this.enabled)
            return;

        this.scrollPosition = window.pageYOffset;

        if (this.element !== null) {
            this.enabled = true;
            this.originalStyle = this.element.style.cssText;
            this.element.style.top = `-${ this.scrollPosition }px`;
            this.element.style.cssText = this.element.style.cssText + this.styleOnEnable;
        }
    }

    disable() {
        if (!this.enabled)
            return;

        if (this.element !== null) {
            this.enabled = false;
            this.element.classList.remove(this.layoutFixedClass);
            this.element.style.cssText = this.originalStyle;
            this.element.style.top = 0;

            window.scrollTo(0, this.scrollPosition);
        }
    }
}