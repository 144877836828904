var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-slider", attrs: { "data-qa": "slider-container" } },
    [
      _c("div", { staticClass: "_flex _jc-sb _ai-c" }, [
        _c("div", [_vm._t("header")], 2),
        _vm._v(" "),
        _vm.displayArrows
          ? _c("div", { staticClass: "gfu-slider__controls" }, [
              _c("button", {
                class: [
                  "gfu-slider__arrow gfu-slider__arrow--prev",
                  "_mr-1",
                  {
                    "gfu-slider__arrow--visible":
                      _vm.displayArrows && _vm.isOverflownLeft,
                    "gfu-slider__arrow--circle": _vm.arrowStyle === "circle",
                  },
                ],
                attrs: {
                  draggable: "false",
                  "data-qa": "slider-button-previous",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onScrollLeft.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("button", {
                class: [
                  "gfu-slider__arrow gfu-slider__arrow--next",
                  "_ml-1",
                  {
                    "gfu-slider__arrow--visible":
                      _vm.displayArrows && _vm.isOverflownRight,
                    "gfu-slider__arrow--circle": _vm.arrowStyle === "circle",
                  },
                ],
                attrs: { draggable: "false", "data-qa": "slider-button-next" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onScrollRight.apply(null, arguments)
                  },
                },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: ["gfu-slider__slides-wrapper", _vm.slidesWrapperClass] },
        [
          _c(
            "div",
            {
              ref: "slides",
              class: [
                "gfu-slider__slides",
                {
                  "gfu-slider__slides--negative-margin": _vm.hasNegativeMargin,
                },
                {
                  "gfu-slider__slides--center":
                    _vm.centered && !_vm.isOverflown,
                  "gfu-slider__slides--no-scroll": _vm.preventScroll,
                },
                _vm.slidesClass,
              ],
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }