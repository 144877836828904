var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-link",
    {
      staticClass: "gfu-link-underline _mb-1 _block",
      attrs: { url: _vm.actionUrl },
    },
    [
      _c(
        "base-card",
        {
          attrs: { "extended-wrapper-class": "_flexrow _pa-1" },
          scopedSlots: _vm._u([
            {
              key: "media",
              fn: function () {
                return [
                  _c("base-image", {
                    staticClass: "_mr-2 _rounded--common",
                    attrs: { width: "80", src: _vm.imageUrl },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "gfu-bt gfu-bt--caption _tc--lighter _ma-0 _flex _jc-sb",
            },
            [
              _c("span", { staticClass: "_ttu" }, [
                _vm._v(_vm._s(_vm.messageTitle)),
              ]),
              _vm._v(" "),
              _c("notification-delivery-time", {
                staticClass: "_shrink-0",
                attrs: { timestamp: _vm.notificationCreatedAt },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "_ma-0" }, [_vm._v(_vm._s(_vm.messageText))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }