var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-creator-banner _tc--lighter",
      attrs: { "data-qa": "creator-banner" },
    },
    [
      _c(
        "div",
        { staticClass: "_flex _jc-c _ai-c _h-full _w-100" },
        [
          _c("creator-banner-slider", { attrs: { items: _vm.bannerImages } }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "gfu-layout__main gfu-layout__main--extranarrow _w-100 _pb-0",
            },
            [
              _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--nofx _flex _ai-c",
                  attrs: {
                    draggable: "false",
                    url: _vm.urlName,
                    "data-qa": "creator-banner:url",
                  },
                },
                [
                  _c("img", {
                    staticClass: "gfu-creator-banner__image",
                    attrs: {
                      draggable: "false",
                      src: _vm.creatorImageUrl,
                      alt: _vm.name,
                      "data-qa": "creator-banner:creatorImage",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "_px-2" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "gfu-hd gfu-hd--h1 _my-0 _tc--white",
                        attrs: { "data-qa": "creator-banner:title" },
                      },
                      [_vm._v(_vm._s(_vm.name))]
                    ),
                    _vm._v(" "),
                    _vm.displayedLocation
                      ? _c(
                          "div",
                          { attrs: { "data-qa": "creator-banner:location" } },
                          [
                            _c("span", {
                              staticClass: "_fa _fa--map-marker _mr-1",
                            }),
                            _vm._v(_vm._s(_vm.displayedLocation)),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "_mt-2 _pos-r",
                  attrs: { "data-qa": "creator-banner:description" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.description) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isSocialSectionVisible
                ? _c(
                    "div",
                    {
                      staticClass: "_mt-2 _pos-r",
                      attrs: { "data-qa": "creator-banner-social-media" },
                    },
                    _vm._l(_vm.socialAccounts, function (socialAccount) {
                      return _c("base-icon", {
                        key: socialAccount.type,
                        class: socialAccount.class,
                        attrs: {
                          name: socialAccount.iconName,
                          rel: socialAccount.rel,
                          draggable: socialAccount.draggable,
                          href: socialAccount.href,
                          tag: "a",
                          "data-qa": socialAccount.dataQa,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }