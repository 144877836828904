var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["gfu-processing-wrapper", { "is-active": _vm.showAsActive }],
      attrs: { "data-qa": "progress-wrapper" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }