import { isInstalledPWA } from '@/Utils/Browser';
import { displayModeTypes } from '@/Utils/PwaUtility';
import { isIOSApp } from '@/Utils/PwaUtility';

const getDisplayModeValues = (displayModes, displayMode) => ({
    isPWA: displayMode === displayModes.pwa,
    isWeb: displayMode === displayModes.web,
});

export default {
    install(app) {
        app.mixin({
            computed: {
                $displayMode() {
                    const displayMode = isInstalledPWA() || isIOSApp() ? displayModeTypes.pwa : displayModeTypes.web;

                    return {
                        ...getDisplayModeValues(displayModeTypes, displayMode),
                    };
                },
            },
        });
    },
};