var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _c(
        "label",
        {
          class: [
            "gfu-field-switch",
            { "gfu-field-switch--left": _vm.labelPosition === "left" },
            { "gfu-field-switch--small": _vm.small },
          ],
          attrs: { tabindex: "0", "data-qa": "switch-field-label" },
          on: { blur: _vm.onBlur },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checkboxValue,
                expression: "checkboxValue",
              },
            ],
            staticClass: "gfu-field-switch__input",
            attrs: {
              type: "checkbox",
              disabled: _vm.isDisabled,
              "data-qa": "switch-field-input",
            },
            domProps: {
              value: _vm.model,
              checked: Array.isArray(_vm.checkboxValue)
                ? _vm._i(_vm.checkboxValue, _vm.model) > -1
                : _vm.checkboxValue,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.checkboxValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.model,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkboxValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.checkboxValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.checkboxValue = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("span", {
            class: [
              "gfu-field-switch__slider",
              {
                "gfu-field-switch__slider--readonly": _vm.readOnly,
                "gfu-field-switch__slider--left": _vm.labelPosition === "left",
              },
            ],
            attrs: { name: _vm.name, "data-qa": "switch-field-slider" },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              class: [
                "gfu-field-switch__label",
                {
                  "gfu-field-switch__label--readonly": _vm.readOnly,
                  "gfu-field-switch__label--left": _vm.labelPosition === "left",
                },
              ],
            },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.label))]
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }