var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "fieldset",
        [
          _c(
            "field",
            {
              staticClass: "_mt-0",
              attrs: {
                name: "model.email",
                "display-name": _vm.Resources.Account.EmailFieldLabel,
                "data-qa": "login-form:Email",
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.email,
                    expression: "model.email",
                  },
                ],
                staticClass: "gfu-field__input",
                attrs: {
                  type: "email",
                  name: "email",
                  "data-qa": "text-field-input",
                },
                domProps: { value: _vm.model.email },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.submit.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.model, "email", $event.target.value)
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "field",
            {
              attrs: {
                name: "model.password",
                "display-name": _vm.Resources.Account.PasswordFieldLabel,
                "data-qa": "login-form:Password",
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.password,
                    expression: "model.password",
                  },
                ],
                staticClass: "gfu-field__input",
                attrs: {
                  type: "password",
                  name: "password",
                  "data-qa": "text-field-input",
                },
                domProps: { value: _vm.model.password },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.submit.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.model, "password", $event.target.value)
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_mt-1" },
            [
              _c("field", [
                _c(
                  "button",
                  {
                    staticClass:
                      "gfu-btn gfu-btn--accent gfu-btn--block gfu-btn--soft gfu-btn__text",
                    attrs: { type: "button", "data-qa": "login-form:Login" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "_pl-6 _pr-6" }, [
                      _vm._v(_vm._s(_vm.Resources.Account.LogInButton)),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "_mt-3 _tac",
              attrs: { "data-qa": "login-form:SignUp" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: { href: _vm.signUpUrl },
                },
                [_vm._v(_vm._s(_vm.Resources.Account.SignUpLink))]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "_mt-3 _tac",
              attrs: { "data-qa": "login-form:ForgotPassword" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: { href: _vm.resetPasswordUrl },
                },
                [_vm._v(_vm._s(_vm.Resources.Account.ForgotPasswordLink))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }