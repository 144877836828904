var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      { attrs: { "show-label": !_vm.condensed && _vm.showLabel } },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _c(
        "label",
        {
          class: [
            "gfu-field__toggle gfu-field-toggle",
            `gfu-field-toggle--${_vm.type}`,
            _vm.labelClass,
          ],
        },
        [
          _vm.type === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                staticClass: "gfu-field-toggle__input",
                attrs: {
                  name: _vm.name,
                  disabled: _vm.isDisabled,
                  "data-qa": `checkbox-input`,
                  type: "checkbox",
                },
                domProps: {
                  value: _vm.checkedValue,
                  checked: Array.isArray(_vm.model)
                    ? _vm._i(_vm.model, _vm.checkedValue) > -1
                    : _vm.model,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.model,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.checkedValue,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.model = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.model = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.model = $$c
                    }
                  },
                },
              })
            : _vm.type === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                staticClass: "gfu-field-toggle__input",
                attrs: {
                  name: _vm.name,
                  disabled: _vm.isDisabled,
                  "data-qa": `checkbox-input`,
                  type: "radio",
                },
                domProps: {
                  value: _vm.checkedValue,
                  checked: _vm._q(_vm.model, _vm.checkedValue),
                },
                on: {
                  change: function ($event) {
                    _vm.model = _vm.checkedValue
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                staticClass: "gfu-field-toggle__input",
                attrs: {
                  name: _vm.name,
                  disabled: _vm.isDisabled,
                  "data-qa": `checkbox-input`,
                  type: _vm.type,
                },
                domProps: { value: _vm.checkedValue, value: _vm.model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.model = $event.target.value
                  },
                },
              }),
          _vm._v(" "),
          _c(
            "span",
            {
              class: [
                "gfu-field-toggle__label",
                { "gfu-field-toggle__label--disabled": _vm.disabled },
                { "gfu-field-toggle--readonly": _vm.readOnly },
              ],
            },
            [
              _c("span", {
                staticClass: "gfu-field-toggle__icon",
                attrs: {
                  "data-selected": _vm.valueAsString,
                  "data-qa": "checkbox-field-icon",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.condensed && _vm.showLabel,
                      expression: "condensed && showLabel",
                    },
                  ],
                  staticClass: "gfu-field-toggle__label-text",
                },
                [
                  _c(
                    "em",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.shouldShowRequiredIndicator,
                          expression: "shouldShowRequiredIndicator",
                        },
                      ],
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "span",
                      {
                        attrs: {
                          "data-qa": `checkbox-name:${_vm.checkedValue}`,
                        },
                      },
                      [
                        _vm._t("default", function () {
                          return [_vm._v(_vm._s(_vm.displayName))]
                        }),
                        _vm._v(" "),
                        _vm._t("additional-content"),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }