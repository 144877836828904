var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { class: ["gfu-footer", _vm.visibilityClass] }, [
    _c("div", { staticClass: "gfu-footer-section" }, [
      _c("div", { staticClass: "gfu-layout-wrapper" }, [
        _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
          _c("div", { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--m" }, [
            _c("div", { staticClass: "_mb-2" }, [
              _c(
                "a",
                { attrs: { href: _vm.homeUrl } },
                [
                  _c("base-image", {
                    attrs: {
                      src: "/content/images/site-logo-inverse.svg",
                      alt: "Gamefound",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "_mb-4" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.Resources.Common.MainTagline) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_flex _ai-c _mb-3" },
              _vm._l(_vm.socials, function (social) {
                return _c(
                  "a",
                  {
                    key: social.name,
                    staticClass: "_mr-2 gfu-link gfu-link--nofx",
                    attrs: {
                      href: social.url,
                      target: "_blank",
                      rel: "nofollow noopener noreferrer",
                      "data-qa": `layout-footer-link:${social.name}`,
                    },
                  },
                  [
                    _c("base-icon", {
                      attrs: {
                        name: social.icon,
                        "icon-namespace": "brands",
                        size: "lg",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", [_c("language-dropdown")], 1),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "gfu-grid__cell gfu-1of1 gfu-1of2--m gfu-footer-communication",
            },
            _vm._l(_vm.platformLinks, function (section) {
              return _c(
                "div",
                { key: section.sectionName, class: section.classList },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "gfu-hd gfu-hd--light gfu-hd--h4 _ttu _mb-2",
                    },
                    [_vm._v(_vm._s(section.sectionName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "nav",
                    _vm._l(section.links, function (link) {
                      return _c(
                        "a",
                        {
                          key: link.name,
                          staticClass: "_mb-1",
                          attrs: {
                            target: link.target,
                            href: link.url,
                            "data-qa": `layout-footer-link:${link.name}`,
                          },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(link.name) +
                              "\n                            "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gfu-layout-wrapper _py-3" },
      [
        _c("div", { staticClass: "gfu-grid" }, [
          _c(
            "nav",
            {
              staticClass:
                "gfu-grid__cell gfu-1of1 gfu-2of3--m gfu-3of4--l gfu-footer-info-links",
            },
            _vm._l(_vm.servicePolicies, function (link) {
              return _c(
                "a",
                {
                  key: link.name,
                  class: link.classList,
                  attrs: {
                    href: link.url,
                    "data-qa": `layout-footer-link:${link.qa}`,
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(link.name) +
                      "\n                "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of3--m gfu-1of4--l" },
            [
              _c(
                "div",
                { staticClass: "gfu-footer-payment" },
                _vm._l(_vm.paymentProviders, function (payment, index) {
                  return _c("base-image", {
                    key: payment.alt,
                    class: `${index !== 0 ? "_ml-2" : ""}`,
                    attrs: {
                      src: payment.imgUrl,
                      alt: payment.alt,
                      width: "30",
                      height: "18",
                    },
                  })
                }),
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.isCaptchaEnabled
          ? _c("re-captcha-policy", { staticClass: "_mt-3" })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }