var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-button",
    {
      attrs: { width: "narrow", theme: "default" },
      on: {
        click: function ($event) {
          return _vm.share(_vm.url)
        },
      },
    },
    [
      _c("span", {
        class: [
          "gfu-icon gfu-icon--fw _fa _fa-brands _fa--facebook",
          _vm.sizeClssName,
        ],
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }