var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper _flex _jc-c _mt-8" }, [
    _c(
      "div",
      { staticClass: "gfu-2of3--m" },
      [
        !_vm.isUnsubscribeConfirmed
          ? _c("unsubscribe-notification", {
              attrs: {
                "unsubscribe-confirmation-key": _vm.unsubscribeConfirmationKey,
                "context-name": _vm.contextName,
                topic: _vm.topic,
              },
              on: { unsubscribe: _vm.confirmUnsubscribe },
            })
          : _c("unsubscribe-notification-confirmation", {
              attrs: { "context-name": _vm.contextName, topic: _vm.topic },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }