var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "_tc--primary _mr-2" },
    [
      _c(
        "tooltip",
        {
          staticClass: "_flexinline _ai-c _cur-d",
          attrs: { text: _vm.tooltipText },
        },
        [_c("img", { attrs: { height: "14", src: _vm.icon } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }