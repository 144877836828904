var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.addressFields, function (field) {
      return _c(
        "div",
        {
          key: field.dataQa,
          staticClass: "gfu-grid gfu-grid--small",
          attrs: { "data-qa": field.dataQa },
        },
        [
          _c(
            "label",
            {
              staticClass:
                "gfu-field__label gfu-field__label--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-1of3--l _tal",
              attrs: { "data-qa": "label" },
            },
            [_vm._v(_vm._s(field.label))]
          ),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass:
                "gfu-field__value gfu-field__value--big gfu-grid__cell gfu-1of2 gfu-1of2--m gfu-2of3--l _tal",
              attrs: { "data-qa": "value" },
            },
            [_vm._v(_vm._s(field.value))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }