var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "creator-banner",
        _vm._b({}, "creator-banner", _vm.creatorBannerModel, false)
      ),
      _vm._v(" "),
      _c("creator-projects", { attrs: { "creator-id": _vm.creatorId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }