var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-installments-box" },
    [
      _c(
        "div",
        [
          _c("base-image", {
            attrs: {
              src: "/content/images/installments-logo.svg",
              alt: "stretchPay",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("base-summary-list", {
        attrs: { "summary-list-data": _vm.installmentsInfo, size: "large" },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [
                _c("p", {
                  staticClass: "_tc--dark _my-0",
                  domProps: { innerHTML: _vm._s(_vm.installmentCountText) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.isNotificatorVisible
        ? _c("base-notificator", { attrs: { "can-close": false } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.Resources.YourPledge.InstallmentCollectionText) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }