var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "login-form" } },
    [
      _vm.currentStep === _vm.LoginFormStep.Password
        ? _c("login-form-password-step", {
            attrs: {
              "return-url": _vm.returnUrl,
              "data-qa": "login-form:PasswordStep",
            },
            on: {
              success: _vm.onSuccess,
              "require-two-factor-authentication":
                _vm.onRequireTwoFactorAuthentication,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStep === _vm.LoginFormStep.TwoFactorAuthentication
        ? _c("login-form-two-factor-step", {
            attrs: {
              "return-url": _vm.returnUrl,
              "authentication-type": _vm.twoFactorAuthenticationType,
              "user-email-address": _vm.userEmailAddress,
              "data-qa": "login-form:TwoFactorStep",
            },
            on: {
              success: _vm.onSuccess,
              cancel: _vm.onTwoFactorAuthenticationCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }