import ServiceBase from './ServiceBase';

export default class ProjectTestingService extends ServiceBase {

    disableTestMode(projectID) {
        return this.request('post', 'projectTesting/disableTestMode', { projectID });
    }

    toggleTestMode(params) {
        return this.request('post', 'projectTesting/toggleTestMode', { ...params });
    }

    inviteTestersToProject(projectID, emails) {
        return this.request('post', 'projectTesting/inviteTestersToProject', { projectID, emails });
    }

    removeTesterFromProject(projectID, userID) {
        return this.request('post', 'projectTesting/removeTesterFromProject', { projectID, userID });
    }

    clearTestOrders(projectID) {
        return this.request('post', 'projectTesting/clearTestOrders', { projectID });
    }
}