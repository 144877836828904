export default class Toggler {
    constructor(triggerElements, contentElements, visibleContentClass, hideOnFocusOut = false, onShow = null, onHide = null) {
        this.triggerElements = triggerElements;
        this.contentElemens = contentElements;
        this.visibleContentClass = visibleContentClass;
        this.hideOnFocusOut = hideOnFocusOut;
        this.onShow = onShow;
        this.onHide = onHide;
        this.isVisible = false;

        for (let i = 0; i < this.triggerElements.length; i++) {
            const triggerElement = this.triggerElements[i];
            triggerElement.addEventListener('click', this._onClick.bind(this));
            if (this.hideOnFocusOut) {
                triggerElement.addEventListener('focusout', this._onFocusOut.bind(this, triggerElement));
            }
        }
    }

    hide() {
        [].forEach.call(this.contentElemens, contentElement => {
            contentElement.classList.remove(this.visibleContentClass);
        });

        this.isVisible = false;

        if (this.onHide !== null) {
            this.onHide();
        }
    }

    show() {
        [].forEach.call(this.contentElemens, contentElement => {
            contentElement.classList.add(this.visibleContentClass);
        });
        this.isVisible = true;

        if (this.onShow !== null) {
            this.onShow();
        }
    }

    toggle() {
        if (this.isVisible) {
            this.hide();
        }
        else {
            this.show();
        }
    }

    _onClick(e) {
        if (e) {
            e.preventDefault();
            this.toggle();
        }
    }

    _onFocusOut(triggerElement, e) {
        if (e) {
            e.preventDefault();

            if (triggerElement.parentElement.contains(e.relatedTarget)) {
                e.relatedTarget.addEventListener('focusout', this._onFocusOut.bind(this, triggerElement), { once: true });
            }
            else {
                this.hide();
            }
        }
    }
}