var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: ["gfu-category-box", "_px-2", "_py-2", "_tdn", ..._vm.classList],
      attrs: { href: _vm.url },
    },
    [_vm._t("content")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }