var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        title: "Tester mode",
        "is-visible": _vm.isModalVisible,
        "show-action-close": "",
      },
      on: { "action-close": _vm.onClose },
    },
    [
      _c("p", [
        _vm._v(
          "\n        This feature is exclusively available to users with a tester role in the project.\n        It allows you to preview the project page for the current and next active phase exactly as users will see it.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n        As a tester, you can select a specific group of users and view the project configuration from their perspective.\n        The tester mode enables you to complete the entire purchase path, just like a real user would.\n        This includes verifying the correctness of displayed information such as product options, prices,\n        taxes, shipping costs, and even test invoices for test orders.\n        As a tester, you have the ability to make a test order in any phase,\n        copy an existing order and turn it into a test order for modification,\n        export test orders for further analysis, and filter out test orders and remove them if necessary.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n        Please note: Comments and FAQ are not in test mode and changes will be public.\n    "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }