import { defineStore } from 'pinia';

import { useUserPledgesStore } from '@/Stores/Web/userPledgesStore';
import { useProjectStore } from '@/Stores/Web/projectStore';
import { useCurrencyStore } from '@/Stores/Web/currencyStore';

import { buildOrderSummaryModel } from '@/Components/Orders/OrderSummaryUtils';
import { buildAvailablePaymentMethods } from '@/Components/Payments/PaymentUtils';
import { methodQueue } from '@/Utils/AsyncUtility';

import { paymentMethodReasons, orderStages } from '@/Models.js';

import Resources from '@/Resources.js';
import Formatter from '@/Utils/Formatter';

import { apiClient } from '@/Clients/ApiClient';
import CartsLegacyService from '@/Services/CartsLegacyService';
import ShippingService from '@/Services/ShippingService';
import OrderShippingService from '@/Services/OrderShippingService';
import PledgeService from '@/Services/PledgeService';

const shippingService = new ShippingService(apiClient);
const orderShippingService = new OrderShippingService(apiClient);
const cartsLegacyService = new CartsLegacyService(apiClient);
const pledgeService = new PledgeService(apiClient);

const paymentMethodsRequests = {};

export const useLegacyOrderStore = defineStore({
    id: 'legacyOrder',
    state: () => ({
        projectLocations: [],
        shippingModels: [],
        shippingModel: null,
        isLocationSelected: false,
        orderSummaryListModel: {},
        currentOrderId: null,
        orderDetails: {},
        selectedPaymentMethod: null,
        availablePaymentMethods: [],
        disabledPaymentMethods: [],
    }),
    actions: {
        async updateOrder(model) {
            const projectStore = useProjectStore();
            try {
                const { data } = await cartsLegacyService.getCartDetails(model);
                this.setOrderDetails(data);
                const locations = await projectStore.getProjectLocations(data.project.projectID);
                this.setOrderSummaryListModel(data, locations);

                return data;
            }
            catch (error) {
                throw error.message;
            }
        },
        setOrderDetails(order) {
            const { orderID } = order.cart;
            this.currentOrderId = orderID;
            this.orderDetails = {
                ...this.orderDetails,
                [orderID]: order,
            };
        },

        setOrderSummaryListModel(model, locations) {
            const userPledgesStore = useUserPledgesStore();
            this.orderSummaryListModel = {
                ...this.orderSummaryListModel,
                [model.cart.orderID]: buildOrderSummaryModel(
                    model,
                    userPledgesStore.firstPledge,
                    locations,
                ),
            };
        },
        // Locations
        setLocationStatus(status) {
            this.isLocationSelected = status;
        },
        // Shipping methods
        async loadShippingModels(projectID, orderCode, selectedShippingModelID, shippingLocationID = null, shippingSubLocationID = null) {

            try {
                const { data } = await shippingService.getEnabledShippingModels(projectID, shippingLocationID, shippingSubLocationID, orderCode);
                this.shippingModels = data;
                const isShippingModelNotSelected = !selectedShippingModelID || !this.shippingModels.find(m => m.shippingModelID === selectedShippingModelID);
                if (isShippingModelNotSelected) {
                    const selectedModel = this.shippingModels.find(m => m.isDefault) || this.shippingModels[0];
                    if (!selectedModel) {
                        throw Resources.Checkout.ShippingModelsNotAvailableErrorMessage;
                    }
                    this.updateShippingModel(selectedModel.shippingModelID);
                }
                else {
                    this.updateShippingModel(selectedShippingModelID);
                }
            }
            catch (error) {
                this.resetShippingModelData();
                throw new Error(error);
            }
        },
        resetShippingModelData() {
            this.shippingModel = null;
            this.shippingModels = [];
        },
        updateShippingModel(shippingModelID) {
            this.shippingModel = shippingModelID;
        },
        async setShippingModel(projectID, shippingModel) {
            if (this.isLocationSelected) {
                try {
                    await orderShippingService.saveCartShippingModel(projectID, shippingModel);
                }
                catch (error) {
                    throw new Error(error);
                }
            }
        },
        // Payments
        async getAvailablePaymentMethods(locationIsoCode = this.locationIsoCode, reason = paymentMethodReasons.unknown, projectID) {
            const hasOrderCode = this.currentOrderDetails?.cart?.orderStage !== orderStages.unfinished || this.currentOrderDetails?.cart?.parentOrder !== null;
            await methodQueue.enqueue(async () => {
                const { data } = await this.loadAvailablePaymentMethods({
                    currencyShortName: this.currencyShortName,
                    projectID,
                    locationIsoCode,
                    orderCode: hasOrderCode ? this.currentOrderDetails?.cart.orderCode : null,
                    reason,
                });
                this.setAvailablePaymentMethods(buildAvailablePaymentMethods(data, this.disabledPaymentMethods));
            });
        },
        setAvailablePaymentMethods(paymentMethods) {
            // If the selected payment method is not available, reset it.
            if (paymentMethods.length === 1) {
                this.setSelectedPaymentMethod(paymentMethods[0].paymentDataSource);
            }
            else if (!paymentMethods.some(pm => pm.paymentDataSource === this.selectedPaymentMethod)) {
                this.setSelectedPaymentMethod(null);
            }

            this.availablePaymentMethods = paymentMethods;
        },
        async loadAvailablePaymentMethods(params) {
            const paramsKey = JSON.stringify(params);
            // If the payment methods are already loaded, return them.
            if (paymentMethodsRequests[paramsKey]) {
                return await paymentMethodsRequests[paramsKey];
            }
            const requestPromise = pledgeService.getAvailablePaymentMethods(params);
            paymentMethodsRequests[paramsKey] = requestPromise;
            return await requestPromise;
        },
        setSelectedPaymentMethod(paymentDataSource) {
            this.selectedPaymentMethod = paymentDataSource;
        },
        setDisabledPaymentMethods(disabledPaymentMethods = []) {
            this.disabledPaymentMethods = disabledPaymentMethods;
        },

    },
    getters: {
        getOrderDetails: state => (orderID) => state.orderDetails[orderID],
        shippingModelsLength: state => state.shippingModels.length,
        currentOrderDetails: state => state.orderDetails[state.currentOrderId],
        currencyShortName() {
            const currencyStore = useCurrencyStore();
            const selectedDccCurrency = currencyStore.selectedCurrency;

            return !this.currentOrderDetails
                ? null
                : selectedDccCurrency
                    ? selectedDccCurrency.shortName
                    : this.currentOrderDetails?.cart.currencyShortName;
        },
        locationIsoCode() {
            return this.currentOrderDetails
                ? this.currentOrderDetails?.cart.shippingProjectLocationIsoCode ?? this.currentOrderDetails?.cart.customerProjectLocationIsoCode
                : null;
        },
        orderAmount() {
            const currencyStore = useCurrencyStore();
            const selectedDccCurrency = currencyStore.selectedCurrency;

            return !this.currentOrderDetails
                ? null
                : selectedDccCurrency
                    ? currencyStore.selectedCurrency.amountConverted
                    : this.currentOrderDetails?.cart.effectivePayableAmount;

        },
        originalCurrencyOrderAmount() {
            return this.currentOrderDetails?.cart.effectivePayableAmount;
        },
        orderAmountWithSymbol() {
            const currencyStore = useCurrencyStore();
            const selectedDccCurrency = currencyStore.selectedCurrency;

            return !this.currentOrderDetails
                ? null
                : Formatter.format(this.orderAmount, 'C2', selectedDccCurrency?.symbol ?? this.currentOrderDetails?.cart.currencySymbol);
        },
        hasOrderRestrictedItems() {
            return this.currentOrderDetails?.orderItemIDsRestrictedInCartLocation?.length > 0;
        },
    },
});