// Trivial logger implementation, this could be exapnded into something more complex
export default {
    error(message) {
        if (typeof window.Rollbar !== 'undefined') {
            window.Rollbar.error(message);
        }
    },
    warn(message) {
        if (typeof window.Rollbar !== 'undefined') {
            window.Rollbar.warning(message);
        }
        else {
            // eslint-disable-next-line no-console
            window.console.warn(message);
        }
    },
};